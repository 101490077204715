<template>
  <div class="burger-button" @click="$emit('click')">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</template>
<script setup>
</script>
<style lang="scss" scoped>
  .burger-button {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #C3CEDA;
    border-radius: 4px;
    cursor: pointer;

    .line {
      width: 18px;
      height: 2px;
      background-color: $color-main;

      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }
  }

  .burger-button.active {
    .line {
      position: absolute;
      background-color: $color-main;
      top: 22px;

      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }

      &:last-child {
        display: none;
      }
    }
  }

  .burger-button:hover .line {
    background-color: $color-active;
  }
</style>
