const BASE_URL = '/private/dictionaries'
// const BASE_URL_V2 = '/v2/private'

class DictionariesService {
  constructor(api) {
    this.api = api
  }

  getDictionaries() {
    return this.api.get(BASE_URL)
  }

  getSearchTypes() {
    return this.api.get(`${BASE_URL}/action-search-types`)
  }

  getRules() {
    return this.api.get(`${BASE_URL}/rules`)
  }

  getFieldRules() {
    return this.api.get(`${BASE_URL}/field-rules`)
  }

  getOrgs() {
    return this.api.get(`${BASE_URL}/orgs`)
  }

  getLayers() {
    return this.api.get(`${BASE_URL}/layers`)
  }

  getComplexityExploitation() {
    return this.api.get(`${BASE_URL}/complexity-exploitation`)
  }

  getLandTypes() {
    return this.api.get(`${BASE_URL}/land-types`)
  }

  getRegions() {
    return this.api.get(`${BASE_URL}/region-names`)
  }

  getEventsType() {
    return this.api.get(`${BASE_URL}/action-types`)
  }

  getLandOwnerships() {
    return this.api.get(`${BASE_URL}/land-ownership`)
  }

  getActualExploitations() {
    return this.api.get(`${BASE_URL}/actual-exploitations`)
  }

  getTypeCulture() {
    return this.api.get(`${BASE_URL}/culture-types`)
  }

  getKugiUseCategories() {
    return this.api.get(`${BASE_URL}/kugi-use-category`)
  }

  getCheckStatuses() {
    return this.api.get(`${BASE_URL}/check-statuses`)
  }

  getCheckInnerStatuses() {
    return this.api.get(`${BASE_URL}/check-statuses-inner`)
  }

  getPeriods() {
    return this.api.get(`${BASE_URL}/periods`)
  }

  getOperationYears() {
    return this.api.get(`${BASE_URL}/field-operation-years`)
  }

  getEfisDataCultureRotation() {
    return this.api.get(`${BASE_URL}/efis-data-culture-rotation`)
  }

  getEfisDataUnusedLandGroups() {
    return this.api.get(`${BASE_URL}/efis-data-unused-land_groups`)
  }

  getOrgTypes() {
    return this.api.get(`${BASE_URL}/org-types`)
  }

}

export { DictionariesService }
