<template>
  <q-form ref="formRef">
    <div class="input-file">
      <span class="label-select">Выберите файл</span>
      <q-file
        v-model="file"
        outlined
        accept=".pdf, .doc, .docx, .xls"
        :rules="[val => !!val || 'Обязательное поле']"
        dense
      />
      <p v-if="fileError" class="text-negative">{{ fileError }}</p>
    </div>
    <div class="input">
      <span class="label-select">Примечание</span>
      <q-input
        v-model="fileForm.description"
        placeholder="Примечание"
        outlined
        type="textarea"
        no-error-icon
        :rules="[val => val.length <= 2000 || 'Не более 2000 символов']"
        dense
      />
    </div>
  </q-form>
</template>
<script setup>
import { ref, reactive, defineEmits, defineExpose, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()
const emits = defineEmits(['close'])

const formRef = ref()

const file = ref()
const fileError = ref('')

const fileForm = reactive({
  description: ''
})

const informations = computed(() => store.state.mapFilter.currentPage)

const saveFile = () => {
  const formData = new FormData()
  formData.append('comment', fileForm.description)
  formData.append('file', file.value)

  const formEl = formRef.value
  formEl.validate().then((result) => {
    if (result && !fileError.value) {
      store.commit('modals/setDisableModal', true)
      ApiService.map.createFile(informations.value.id, 'files', formData)
        .then(() => {
          store.commit('modals/setDisableModal', false)
          fileForm.description = ''
          store.commit('modals/setIsShowFileModal', false)
          store.dispatch('details/fetchFiles', { id: informations.value.id })
          emits('close')
          $q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Успешно',
            timeout: 5000
          })
        })
        .catch((error) => {
          store.dispatch('auth/fetchUser')
          store.commit('modals/setDisableModal', false)
          const errorText = error?.response?.data?.message
          $q.notify({
            type: 'negative',
            position: 'bottom',
            message: errorText ? errorText : 'Ошибка',
            timeout: 5000
          })
        })
    }
  })
}

watch(file, (value) => {
  if (value) {
    const maxSize = 10 * 1024 * 1024
    if (file.value.size > maxSize) {
      fileError.value = 'Размер файла более 10Мб'
    } else {
      fileError.value = ''
    }
  }
})

defineExpose({ saveFile })
</script>
<style lang="scss" scoped>
.input-file {
  margin-bottom: 5px;
}
</style>
