const BASE_URL = '/private/import/kugi/reestr'
const BASE_URL_V2 = '/v2/private'

class RegisterService {
  constructor(api, apiPost) {
    this.api = api
    this.apiPost = apiPost
  }

  uploadRegister(data) {
    return this.apiPost.post(BASE_URL, data)
  }

  getRegisters(params) {
    return this.api.get(`${BASE_URL}/history`, { params })
  }

  getUsers() {
    return this.api.get(`${BASE_URL}/history/causers`)
  }

  getCurrentRegister() {
    return this.api.get(`${BASE_URL}/current`)
  }

  getRegister(id) {
    return this.api.get(`${BASE_URL}/${id}`)
  }

  cancelImport(id) {
    return this.api.post(`${BASE_URL}/${id}/cancel`)
  }

  downloadRegister(id) {
    return this.api.get(`${BASE_URL}/${id}/reestr`, { responseType: 'arraybuffer' })
  }

  setColumns(importId, map) {
    return this.api.post(`${BASE_URL}/${importId}/map`, { map })
  }

  getTemporaryData(importId, params) {
    return this.api.get(`${BASE_URL}/${importId}/sections`, { params })
  }

  getTemporarySectionData(importId, sectionId) {
    return this.api.get(`${BASE_URL}/${importId}/sections/${sectionId}`)
  }

  deleteTemporarySectionData(importId, sectionId) {
    return this.api.delete(`${BASE_URL}/${importId}/sections/${sectionId}`)
  }

  confirmColumns(importId) {
    return this.api.post(`${BASE_URL}/${importId}/map/submit`)
  }

  saveSectionData(importId) {
    return this.api.post(`${BASE_URL}/${importId}/sections/submit`)
  }

  applyRegister(importId) {
    return this.api.post(`${BASE_URL}/${importId}/apply`)
  }

  updateTemporarySectionData(importId, sectionId, form) {
    return this.api.post(`${BASE_URL}/${importId}/sections/${sectionId}`, form)
  }
}

export { RegisterService }
