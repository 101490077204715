<template>
  <div class="rosreestr">
    <q-form ref="formRef">
      <div class="rosreestr__field">
        <span class="label-select">Кадастровый номер</span>
        <q-input
          v-model="form.cadastralNumber"
          :rules="[val => !!val || 'Обязательное поле']"
          placeholder="Кадастровый номер"
          outlined
          no-error-icon
          dense
        />
      </div>
    </q-form>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, defineExpose, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const props = defineProps({
  currentScheme: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['close'])

const formRef = ref()
const form = reactive({
  id: '',
  cadastralNumber: null,
})

const updateScheme = () => {
  const formEl = formRef.value

  formEl.validate().then((result) => {
    if (result) {
      store.commit('modals/setDisableModal', true)

      ApiService.schemes.updateCadastralNumberScheme(form)
        .then(() => {
          store.dispatch('schemes/fetchScheme', form.id)
          emits('close')
          store.commit('modals/setDisableModal', false)
          $q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Успешно',
            timeout: 5000
          })
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message
          store.commit('modals/setDisableModal', false)
          $q.notify({
            type: 'negative',
            position: 'bottom',
            message: errorText ? errorText : 'Ошибка',
            timeout: 5000
          })
        })
    }
  })
}

onMounted(() => {
  form.cadastralNumber = props.currentScheme.form.cadastralNumber
  form.id = props.currentScheme.form.id
})

defineExpose({ updateScheme })
</script>

<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 250px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    display: block;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}
</style>
