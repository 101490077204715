const ACTIVE_COLOR = '#dc1c0a'

export const MAIN_COLOR = '#1589FF'

export const compareSource = {
  name: 'fieldsCompare',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/fields/{z}/{x}/{y}.mvt']
  },
  layers: [
    {
      id: 'fields_fill_2',
      type: 'fill',
      source: 'fieldsCompare',
      'source-layer': 'fields',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'fill-color': ['case',
          ['boolean', ['feature-state', 'edited'], false], 'transparent',
          ['boolean', ['feature-state', 'selected'], false], 'transparent',
          ['boolean', ['feature-state', 'active'], false], ACTIVE_COLOR,
          MAIN_COLOR
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'edited'], false], 0,
          ['boolean', ['feature-state', 'active'], false], 0.7,
          ['boolean', ['feature-state', 'focus'], false], 1,
          ['boolean', ['feature-state', 'hover'], false], 0.4,
          0.2,
        ]
      },
    },
    {
      id: 'fields_line_2',
      type: 'line',
      source: 'fieldsCompare',
      'source-layer': 'fields',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'line-width': ['case',
          ['boolean', ['feature-state', 'focus'], false], 7,
          ['boolean', ['feature-state', 'field'], false], 3,
          1
        ],
        'line-color': ['case',
          ['boolean', ['feature-state', 'edited'], false], 'transparent',
          ['boolean', ['feature-state', 'selected'], false], 'transparent',
          ['boolean', ['feature-state', 'active'], false], ACTIVE_COLOR,
          MAIN_COLOR
        ]
      },
    }]
}

export default [{
  name: 'fields',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/fields/{z}/{x}/{y}.mvt']
  },
  layers: [
    {
      id: 'fields_fill',
      type: 'fill',
      source: 'fields',
      'source-layer': 'fields',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'fill-color': ['case',
          ['boolean', ['feature-state', 'edited'], false], 'transparent',
          ['boolean', ['feature-state', 'selected'], false], 'transparent',
          ['boolean', ['feature-state', 'active'], false], ACTIVE_COLOR,
          MAIN_COLOR
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'edited'], false], 0,
          ['boolean', ['feature-state', 'active'], false], 0.7,
          ['boolean', ['feature-state', 'focus'], false], 1,
          ['boolean', ['feature-state', 'hover'], false], 0.4,
          0.2,
        ]
      },
    },
    {
      id: 'fields_line',
      type: 'line',
      source: 'fields',
      'source-layer': 'fields',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'line-width': ['case',
          ['boolean', ['feature-state', 'focus'], false], 7,
          ['boolean', ['feature-state', 'field'], false], 3,
          1
        ],
        'line-color': ['case',
          ['boolean', ['feature-state', 'edited'], false], 'transparent',
          ['boolean', ['feature-state', 'selected'], false], 'transparent',
          ['boolean', ['feature-state', 'active'], false], ACTIVE_COLOR,
          MAIN_COLOR
        ]
      },
    }]
}]
