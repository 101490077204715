const BASE_URL = '/private'
const BASE_URL_V2 = '/v2/private'

class MapService {
  constructor(api) {
    this.api = api
  }

  getPoints() {
    return this.api.get(`${BASE_URL}/map/points`)
  }

  createPoint(point) {
    return this.api.post(`${BASE_URL}/map/points`, point)
  }

  updatePoint(point) {
    return this.api.put(`${BASE_URL}/map/points/${point.id}`, { comment: point.comment })
  }

  deletePoint(point) {
    return this.api.delete(`${BASE_URL}/map/points/${point.id}`)
  }

  updateMapGroup(areas) {
    // return this.api.put('/map', areas)
    return this.api.put(`${BASE_URL_V2}/map/sections`, areas)
  }

  updateArea(form) {
    // return this.api.post(`/map/${form.id}`, form)
    return this.api.put(`${BASE_URL_V2}/sections/${form.id}`, form)
  }

  updateLayer(hash, form) {
    // return this.api.post(`/map/${hash}/lr`, form)
    return this.api.post(`${BASE_URL_V2}/sections/${hash}/lr`, form)
  }

  updateCadastralNumber(hash, form) {
    // return this.api.post(`/map/${hash}/kn`, form)
    return this.api.post(`${BASE_URL_V2}/sections/${hash}/kn`, form)
  }

  addSnt(hashes) {
    // return this.api.post('/map/snt', { hashes })
    return this.api.post(`${BASE_URL_V2}/map/sections/snt`, { hashes })
  }

  // getCadastralByFilter(filter) {
  //   return this.api.get('/map/filter', {
  //     params: filter
  //   })
  // }

  // getAreasByFilter(filter, page) {
  //   return this.api.get('/map/filter/paginated', {
  //     params: { ...filter, page }
  //   })
  // }

  getPaginateSectionsFieldsByFilter(filter) {
    return this.api.post(`${BASE_URL}/map/filter/v2/paginated`, filter)
  }

  getSectionsFieldsByFilter(filter) {
    return this.api.post(`${BASE_URL}/map/filter/v2`, filter)
  }

  getSectionsByFilter(filter) {
    return this.api.post(`${BASE_URL_V2}/map/filter/sections`, filter)
  }

  getPaginateSectionsByFilter(filter) {
    return this.api.post(`${BASE_URL_V2}/map/filter/sections/paginated`, filter)
  }

  getFieldsByFilter(filter) {
    return this.api.post(`${BASE_URL_V2}/map/filter/fields`, filter)
  }

  getPaginateFieldsByFilter(filter) {
    return this.api.post(`${BASE_URL_V2}/map/filter/fields/paginated`, filter)
  }

  getCurrentPage(params) {
    // return this.api.get(`/map/${params.id}`)
    return this.api.get(`${BASE_URL_V2}/map/${params.id}`)
  }

  getHistory(params) {
    // return this.api.get(`/map/${params.id}/history_v2`)
    return this.api.get(`${BASE_URL_V2}/sections/${params.id}/history`)
  }

  createArea(form) {
    return this.api.post(`${BASE_URL_V2}/map/sections`, form)
    // return this.api.post('/sections/create', form)
  }

  checkCadastralNumber(cadastralNumber) {
    return this.api.post(`${BASE_URL}/sections/create/cn-check`, { cadastralNumber })
  }

  getGeoPolygon(hash) {
    // return this.api.get(`/sections/${hash}/geo`)
    return this.api.get(`${BASE_URL_V2}/sections/${hash}/geo`)
  }

  checkOverlaps(geometry) {
    // return this.api.post('/sections/overlaps-check', { geometry })
    return this.api.post(`${BASE_URL_V2}/sections/overlaps-check`, { geometry })
  }

  getOverlaps(hash) {
    // return this.api.get(`/sections/${hash}/overlaps`)
    return this.api.get(`${BASE_URL_V2}/sections/${hash}/overlaps`)
  }

  getOverlapsForInherite(hash) {
    return this.api.get(`${BASE_URL_V2}/sections/${hash}/inherit`)
  }

  checkArea(geometry) {
    // return this.api.post('/sections/area-check', { geometry })
    return this.api.post(`${BASE_URL_V2}/sections/area-check`, { geometry })
  }

  deleteArea(hash) {
    // return this.api.put(`/sections/${hash}/delete`)
    return this.api.delete(`${BASE_URL_V2}/sections/${hash}`)
  }

  undeleteArea(hash) {
    // return this.api.put(`/sections/${hash}/undelete`)
    return this.api.patch(`${BASE_URL_V2}/sections/${hash}`)
  }

  updatePolygon(params) {
    // return this.api.put(`sections/${params.id}/polygon-v2`, params)
    return this.api.put(`${BASE_URL_V2}/sections/${params.id}/polygon`, params)
  }

  getCacheLifeTime() {
    const URL = process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.dev.sovhoz.tech/api'
    return this.api.get(`${URL}/cache/lt`)
  }

  getImages(params) {
    // return this.api.get(`/sections/${params.id}/images`)
    return this.api.get(`${BASE_URL_V2}/sections/${params.id}/images`)
  }

  getFiles(params) {
    // return this.api.get(`/sections/${params.id}/files`)
    return this.api.get(`${BASE_URL_V2}/sections/${params.id}/files`)
  }


  createFile(id, type, form) {
    return this.api.post(`${BASE_URL_V2}/sections/${id}/${type}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  editFile(id, fileId, type, form) {
    return this.api.put(`${BASE_URL_V2}/sections/${id}/${type}/${fileId}`, form)
  }

  deleteFile(id, type, fileId) {
    return this.api.delete(`${BASE_URL_V2}/sections/${id}/${type}/${fileId}`)
  }
}

export { MapService }
