const BASE_URL = '/private/admin'
// const BASE_URL_V2 = '/v2/private'

class AdminService {
  constructor(api) {
    this.api = api
  }

  getEvents(params) {
    return this.api.get(`${BASE_URL}/logs`, { params })
  }

  getEventsV2(params) {
    return this.api.get(`${BASE_URL}/logs_v2`, { params })
  }

  exportEvents(filter) {
    return this.api.post(`${BASE_URL}/logs_v2/export`, { ...filter } )
  }
}

export { AdminService }
