<template>
  <header class="header" :class="{'header--overlay': isMobile && isOpenMenu}">
    <div class="header__nav">
      <div class="logo">
        <router-link to="/">SOVHOZ.AI</router-link>
      </div>
      <div v-if="!isMobile" class="links">
        <template v-for="item in routeList.filter((route) => route.meta.label !== 'GeoJson' && route.meta.isShowHeader)">
          <router-link
            v-if="item.meta.isShowHeader && (!item.meta.access || userPermissions.includes(item.meta.access))"
            :key="item.meta.label"
            :to="item.path"
            exact=""
            active-class="router-link-active"
            class="link"
          >
            {{ item.meta.label }}
          </router-link>
        </template>
        <template v-for="item in routeList.filter((route) => route.meta.label === 'GeoJson' && route.meta.isShowHeader)">
          <router-link
            v-if="user.email === 'demo@sovhoz.ai'"
            :key="item.meta.label"
            :to="item.path"
            class="link"
            exact=""
            active-class="router-link-active"
          >
            {{ item.meta.label }}
          </router-link>
        </template>
      </div>
      <div v-if="!isMobile" class="user-nav">
        <div class="user" @click="isShowLogout = !isShowLogout">{{ user.email ?? 'Профиль' }}</div>
        <div v-if="isShowLogout" class="logout" @click="logout">Выйти</div>
      </div>
    </div>
    <div v-if="isMobile" class="header__controls">
      <div v-if="isMobile && isOpenMenu" @click="isOpenMenu = false" class="header__overlay" />
      <div class="burger">
        <BurgerButton @click="isOpenMenu = !isOpenMenu" :class="{active: isOpenMenu}" />
        <div v-if="isOpenMenu" class="burger-menu">
          <div v-if="isMobile" class="header__nav-mobile">
            <template v-for="item in routeList.filter((route) => route.meta.label !== 'GeoJson' && route.meta.isShowHeader)">
              <router-link
                v-if="item.meta.isShowHeader && (!item.meta.access || userPermissions.includes(item.meta.access))"
                :key="item.meta.label"
                :to="item.path"
                exact=""
                active-class="router-link-active"
                class="link link--mobile"
              >
                {{ item.meta.label }}
              </router-link>
            </template>
            <template v-for="item in routeList.filter((route) => route.meta.label === 'GeoJson' && route.meta.isShowHeader)">
              <router-link
                v-if="user.email === 'demo@sovhoz.ai'"
                :key="item.meta.label"
                :to="item.path"
                class="link"
                exact=""
                active-class="router-link-active"
              >
                {{ item.meta.label }}
              </router-link>
            </template>
          </div>
          <div class="user">{{ user.email }}</div>
          <div class="logout" @click="logout">Выйти</div>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import BurgerButton from '@/components/base/BurgerButton'
import ApiService from '@/services'

const store = useStore()
const router = useRouter()
const route = useRoute()

const isOpenMenu = ref(false)
const isMobile = ref(false)
const isShowLogout = ref(false)

const userPermissions = computed(() => store.state.auth.user.permissions)
const user = computed(() =>store.state.auth.user)
const routeList = computed(() => router.options.routes)

const getWidth = () => {
  isMobile.value = window.innerWidth < 1440
}

const logoutHandler = () => {
  const timeStart = localStorage.getItem('start')
  const timeEnd = localStorage.getItem('end')
  const timeWorklog = (Number(timeEnd) - Number(timeStart)) / 1000
  if (timeWorklog > 0) {
    ApiService.user.addWorklog({ time: Math.round(timeWorklog) })
  }

  localStorage.setItem('start', '')
  localStorage.setItem('end', '')
}

const logout = () => {
  logoutHandler()
  store.dispatch('auth/logout')
    .then(() => {
      router.push('/login').catch(() => {})
    })
}

watch(() => route.path, () => {
  isOpenMenu.value = false
})

onMounted(() => {
  getWidth()
  window.addEventListener('resize', getWidth)
})
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: stretch;
  min-height: 68px; // 68px
  position: sticky;
  top: 0;
  padding: 0;
  border-bottom: 1px solid #C3CEDA;
  width: 100%;
  z-index: 3;
  justify-content: space-between;
  background-color: #ffffff;

  &--overlay {
    z-index: 4;
  }

  &__nav {
    width: 100%;
    justify-content: space-between;
  }

  &__nav,
  &__controls {
    display: flex;
    align-items: stretch;
  }

  &__controls {
    margin-right: 10px;
    margin-top: 9px;
  }

  &__nav-mobile {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(191, 191, 191, 0.45);
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.select {
  width: 284px;
  margin-right: 20px;
}

.logo {
  margin-left: 30px;
  margin-right: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.links {
  display: flex;
  align-items: stretch;

  a {
    padding: 18px 16px;
  }
}

.link {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  width: auto;
  text-align: center;
  display: flex;
  align-items: center;

  &.router-link-exact-active {
    background-color: $color-gray1;
  }

  .burger-menu &.router-link-exact-active {
    background-color: transparent;
  }

  @include below('d_md') {
    text-align: left;
  }

  &--mobile {
    display: block;
    margin-bottom: 10px;
  }
}

.burger {
  position: relative;
}

.burger-menu {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: white;
  border: 2px solid rgba(191, 191, 191, 0.45);
  padding: 20px 15px;
  border-radius: 6px;
  z-index: 3;
  min-width: 200px;
}

.user-nav {
  position: relative;
  margin-left: auto;
  display: flex;
  display: flex;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;

  .logout {
    display: block;
    position: absolute;
    top: 68px;
    left: 0;
    right: 0;
    background-color: #ffffff;
    text-align: center;
    padding: 14px 0;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
  }

  .logout:hover {
    background-color: $color-active;
    color: #ffffff;
  }
}

.user {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  @include below('d_sm') {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.logout {
  cursor: pointer;
}

</style>
