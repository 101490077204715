<template>
  <div class="notifications-modal" :class="{'maintenance': maintenanceNotice}">
    <div class="header">
      <template v-if="notifications?.length">
        <h3>Уведомления</h3>
        <button class="button" @click="deleteAll">
          Удалить все
        </button>
      </template>
      <h3 v-else>
        Уведомлений нет
      </h3>
      <button class="button" @click="close">
        Закрыть
      </button>
    </div>
    <div class="content">
      <q-scroll-area :style="{height: '100%'}" :thumb-style="{ width: '5px', opacity: '0.5' }">
        <q-list>
          <q-expansion-item v-if="notifications?.length" label="Блокировка редактирования границ" expand-separator header-class="header-item">
            <ul class="list">
              <li class="item" v-for="item in notifications.slice().reverse()" :key="`${item.message}${item.time}`">
                <p>
                  <span>
                    {{ item.message }}
                  </span>
                  <br>
                  <span>
                    {{ item.time  }}
                  </span>
                </p>
                <div class="close" aria-label="Удалить">
                  <CloseButton title="Удалить" @close="deleteItem(item)" />
                </div>
              </li>
            </ul>
          </q-expansion-item>
        </q-list>
      </q-scroll-area>
    </div>
  </div>
</template>
<script setup>
import { computed, watch, ref, toRaw, defineEmits } from 'vue'
import { useStore } from 'vuex'
import localforage from 'localforage'
import CloseButton from '@/components/uikit/buttons/CloseButton'

const store = useStore()
const notifications = ref([])
const emits = defineEmits(['close'])

localforage.getItem('notifications')
  .then((items) => {
    if (items?.length) {
      notifications.value = items
    }
  })
  .catch((error) => {
    console.log(error)
  })

const countUpdateNotifications = computed(() => {
  return store.state.subscribes.countUpdate
})

const maintenanceNotice = computed(() => {
  return store.state.settings.maintenanceNotice
})

const deleteAll = () => {
  notifications.value = []
  localforage.setItem('notifications', notifications.value.map(toRaw))
    .then(() => {
      store.commit('subscribes/setCountDelete')
    })
    .catch((error) => console.log(error))
}

const close = () => {
  emits('close')
}

const deleteItem = (item) => {
  notifications.value = notifications.value
    .filter((notification) => notification.id !== item.id || notification.message !== item.message || notification.time !== item.time)
  localforage.setItem('notifications', notifications.value.map(toRaw))
    .then(() => {
      store.commit('subscribes/setCountDelete')
    })
    .catch((error) => console.log(error))
}

watch(countUpdateNotifications, () => {
  localforage.getItem('notifications')
    .then((notifications) => {
      notifications.value = notifications
    })
    .catch((error) => {
      console.log(error)
    })
})
</script>
<style lang="scss">
.notifications-modal {
  .header-item {
    background-color: #fff;
  }

  .q-list {
    margin-right: 10px;
  }

  .q-expansion-item {
    border-radius: 8px;
    overflow: hidden;
  }

  .q-expansion-item--expanded .header-item {
    border-bottom: 1px solid $color-gray3;
  }
}
</style>
<style lang="scss" scoped>
.notifications-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: fixed;
  z-index: 10;
  top: 68px;
  right: 0;
  bottom: 0;
  width: 344px;
  background-color: rgba($color-gray1, 0.8);
  backdrop-filter: blur(15px);

  &.maintenance {
    top: 108px;
  }

  .header {
    min-height: 68px;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    padding-right: 0;
    border-bottom: 1px solid $color-gray3;
    background-color: #fff;

    h3 {
      width: 100%;
      text-align: center;
      color: $color-active;
    }
  }

  .content {
    height: 100%;
    padding: 10px;
    padding-right: 0;
  }

  .item {
    padding: 10px;
    padding-right: 15px;
    background-color: #fff;
  }

  p {
    line-height: 1.2;
    margin: 0;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    color: $color-main;
  }

  .button {
    width: fit-content;
  }
}
</style>
