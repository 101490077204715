import ApiService from '@/services'

const actions = {
  fetchPaginatedSections({ commit }, filter) {
    commit('setIsLoadingFilter', true)
    commit('setPaginatedSectionsByFilter', {})

    return new Promise((resolve, reject) => {
      ApiService.map.getPaginateSectionsByFilter(filter)
        .then((response) => {
          commit('setPaginatedSectionsByFilter', response.data)
          commit('setIsLoadingFilter', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingFilter', false)
          reject(error)
        })
    })
  },
  fetchPaginatedFields({ commit }, filter) {
    commit('setIsLoadingFilter', true)
    commit('setPaginatedFieldsByFilter', {})

    return new Promise((resolve, reject) => {
      ApiService.map.getPaginateFieldsByFilter(filter)
        .then((response) => {
          commit('setPaginatedFieldsByFilter', response.data)
          commit('setIsLoadingFilter', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingFilter', false)
          reject(error)
        })
    })
  },
  fetchAreasByFilter({ commit }, filter) {
    commit('setIsLoadingFilter', true)
    commit('setAreasByFilter', [])

    return new Promise((resolve, reject) => {
      // ApiService.map.getAreasByFilter(this.state.mapFilter.filter, page)
      ApiService.map.getPaginateSectionsFieldsByFilter(filter)
        .then((response) => {
          commit('setAreasByFilter', response.data)
          commit('setIsLoadingFilter', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingFilter', false)
          reject(error)
        })
    })
  },
  fetchFieldsByFilter({ commit }, filter) {
    commit('setIsLoadingFilter', true)

    return new Promise((resolve, reject) => {
      ApiService.map.getFieldsByFilter(filter)
        .then((response) => {
          commit('setIsLoadingFilter', false)
          commit('setFieldsByFilter', {
            count: response?.data?.fieldsTotal ?? 0,
            ids: response?.data?.fields ?? [],
            area: response?.data?.fieldsArea ?? 0,
            reports: response?.data?.reports ?? [],
          })
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingFilter', false)
          reject(error)
        })
    })
  },
  fetchCadastralByFilter({ commit }, filter) {
    // commit('setFilter', filter)
    commit('setIsLoadingFilter', true)

    return new Promise((resolve, reject) => {
      ApiService.map.getSectionsByFilter(filter)
        .then((response) => {
          commit('setIsLoadingFilter', false)
          commit('setCadastralByFilter', {
            count: response?.data?.sectionsTotal ?? 0,
            hashes: response?.data?.sections ?? [],
            area: response?.data?.sectionsArea ?? 0,
            reports: response?.data?.reports ?? [],
          })

          if (response.data?.sectionsTotal) {
            commit('setAreasCount', response.data.sectionsTotal)
          }
          resolve(response.data)
        })
      // ApiService.map.getSectionsFieldsByFilter(filter)
      //   .then((response) => {
      //     commit('setCadastralByFilter', {
      //       count: response?.data?.sectionsTotal ? response.data.sectionsTotal : 0,
      //       hashes: response?.data?.sections ? response.data.sections : [],
      //       area: response?.data?.sectionsArea ? response.data.sectionsArea : 0,
      //       reports: response?.data?.sectionsReports ? response.data.sectionsReports : [],
      //     })

      //     if (response.data?.sectionsTotal) {
      //       commit('setAreasCount', response.data.sectionsTotal)
      //     }

      //     commit('setFieldsByFilter', {
      //       count: response?.data?.fieldsTotal ? response.data.fieldsTotal : 0,
      //       ids: response?.data?.fields ? response.data.fields : [],
      //       area: response?.data?.fieldsArea ? response.data.fieldsArea : 0,
      //       reports: response?.data?.fieldsReports ? response.data.fieldsReports : [],
      //     })
      //     resolve(response.data)
      //   })
        .catch((error) => {
          commit('setIsLoadingFilter', false)
          reject(error)
        })
    })
  },
  fetchCurrentPage({ commit }, params) {
    commit('setCurrentPage', null)
    // commit('setIsLoadingFilter', true)

    return new Promise((resolve, reject) => {
      ApiService.map.getCurrentPage(params)
        .then((response) => {
          commit('setCurrentPage', response.data)
          // commit('setIsLoadingFilter', false)
          resolve(response.data)
        })
        .catch((error) => {
          // commit('setIsLoadingFilter', false)
          reject(error)
        })
    })
  },
  fetchHistory({ commit }, params) {
    commit('setHistory', [])
    commit('setIsLoadingFilter', true)

    return new Promise((resolve, reject) => {
      ApiService.map.getHistory(params)
        .then((response) => {
          commit('setHistory', response.data)
          commit('setIsLoadingFilter', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingFilter', false)
          reject(error)
        })
    })
  },
}

const mutations = {
  setCadastralByFilter(state, data) {
    state.cadastralByFilter = data
  },
  setFieldsByFilter(state, data) {
    state.fieldsByFilter = data
  },
  setFilter(state, data) {
    state.filter = data
  },
  setFieldsFilter(state, data) {
    state.fieldsFilter = data
  },
  setFilterCount(state) {
    state.filterCount++
  },
  setDeleteCount(state) {
    state.deleteCount++
  },
  // setCreateCount(state) {
  //   state.createCount++
  // },
  setAreasCount(state, data) {
    state.areasCount = data
  },
  setCurrentPage(state, data) {
    state.currentPage = data
  },
  setHistory(state, data) {
    state.history = data
  },
  setIsLoadingFilter(state, data) {
    state.isLoadingFilter = data
  },
  setAreasByFilter(state, data) {
    state.areasByFilter = data
  },
  setEditCount(state) {
    state.editCount++
  },
  setEditingLayer(state, data) {
    state.editingLayer = data
  },
  setIsShowCheckOverlaps(state, data) {
    state.isShowCheckOverlaps = data
  },
  setPaginatedSectionsByFilter(state, data) {
    state.paginatedSections = data
  },
  setPaginatedFieldsByFilter(state, data) {
    state.paginatedFields = data
  },
  setEditFieldCount(state) {
    state.editFieldCount++
  },
}

const state = () => ({
  areasByFilter: {},
  paginatedSections: {},
  paginatedFields: {},
  cadastralByFilter: {
    count: 0,
    hashes: [],
    polygons: [],
    area: 0
  },
  fieldsByFilter: {
    count: 0,
    ids: [],
    area: 0
  },
  filter: {
    layers: []
  },
  fieldsFilter: {},
  currentPage: null,
  history: [],
  isLoadingFilter: false,
  filterCount: 0,
  deleteCount: 0,
  areasCount: 0,
  editCount: 0,
  //createCount: 0
  editingLayer: '',
  isShowCheckOverlaps: false,
  editFieldCount: 0
})

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
