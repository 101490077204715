import ApiService from '@/services'

const state = () => ({
  editingOrg: null,
  deletingOrg: null,
  linkingOrg: null,
  isLoading: false,
  changeCount: 0,
  orgsByIds: []
})

const actions = {
  fetchOrgs({ commit }, ids) {
    return new Promise((resolve, reject) => {
      const notNullIds = ids.filter((id) => !!id)
      if (notNullIds?.length > 0) {
        ApiService.org.getOrg({ orgIds: Array.from(new Set(notNullIds)) })
          .then((response) => {
            commit('setOrgsByIds', response.data.map((item) => ({ ...item, value: item.id, label: item.name })))
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  },
}

const mutations = {
  setEditingOrg(state, data) {
    state.editingOrg = data
  },
  setDeletingOrg(state, data) {
    state.deletingOrg = data
  },
  setLinkingOrg(state, data) {
    state.linkingOrg = data
  },
  setIsLoading(state, data) {
    state.isLoading = data
  },
  setChangeCount(state) {
    state.changeCount += 1
  },
  setOrgsByIds(state, data) {
    state.orgsByIds = state.orgsByIds.concat(data.filter((org) => !state.orgsByIds.map((org) => org.id).includes(org.id)))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
