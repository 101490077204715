const removeInnerRingsLayer = (map) => {
  if (map.getSource('innerRingsLayer_data')) {
    map.removeLayer('innerRingsLayer_fill')
    map.removeLayer('innerRingsLayer_line')
    map.removeSource('innerRingsLayer_data')
  }
}

const addInnerRingsLayer = (params) => {

  const source = {
    type: 'FeatureCollection',
    features: params.polygons.map((el) => {
      return {
        id: el.id,
        type: 'Feature',
        geometry: {
          coordinates: el.coordinates,
          type: el.type
        },
        properties: { id: el.id },
      }
    })
  }

  if (!params.map.getSource('innerRingsLayer_data')) {
    params.map.addSource('innerRingsLayer_data', {
      type: 'geojson',
      data: source
    })
  }


  params.map.addLayer({
    id: 'innerRingsLayer_fill',
    type: 'fill',
    source: 'innerRingsLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'fill-color': params.color,
      'fill-opacity': 0.4,
    },
  })

  params.map.addLayer({
    id: 'innerRingsLayer_line',
    type: 'line',
    source: 'innerRingsLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-color': params.color,
      'line-width': 1
    },
  })
}

export { addInnerRingsLayer, removeInnerRingsLayer }
