const landUseLayerNames = [
  { name: 'bare', label: 'Без растительности', color: '#f3a6b2' },
  { name: 'built', label: 'Зоны построек', color: '#eb1713' } ,
  { name: 'crops', label: 'Обрабатываемые земли', color: ' #ffb71d' } ,
  { name: 'flooded', label: 'Затопленные зоны', color: '#7bddb7' } ,
  { name: 'rangeland', label: 'Пастбища', color: '#e2e519' } ,
  { name: 'showice', label: 'Снежно-ледяной покров', color: '#a8ebff' } ,
  { name: 'trees', label: 'Лесная растительность', color: '#427313' } ,
  { name: 'water', label: 'Водные зоны', color: '#419bdf' },
]

const landUseLayers = landUseLayerNames.map(({ name, label, color }) => ({
  type: 'farm',
  group: 'landuse',
  name,
  label,
  value: `${name}_fill`,
  // icon: 'forestedness',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/' + name + '/{z}/{x}/{y}.mvt']
  },
  layers: [{
    id: `${name}_fill`,
    type: 'fill',
    source: name,
    'source-layer': name,
    layout: {
      'visibility': 'none'
    },
    paint: {
      'fill-color': color,
      'fill-opacity': 0.5,
    },
    // maxzoom: 6
  }]
}))

export { landUseLayers }
