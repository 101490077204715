const efisLayers = [
  {
    type: 'farm',
    group: 'efis',
    name: 'efis',
    label: 'Поля по анализу вегетации',
    value: 'efis',
    options: {
      type: 'vector',
      tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/vegetation_analysis/{z}/{x}/{y}.mvt']
    },
    layers: [{
      id: 'efis_fill',
      type: 'fill',
      source: 'efis',
      'source-layer': 'vegetation_analysis',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-color': '#F2C40C',
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false], 0.7,
          0.5,
        ],
      },
    },]
  },
  {
    type: 'farm',
    group: 'efis',
    name: 'yar22',
    label: 'Яровой 2022',
    value: 'yar22',
    options: {
      type: 'vector',
      tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/yar22/{z}/{x}/{y}.mvt']
    },
    layers: [{
      id: 'yar22_fill',
      type: 'fill',
      source: 'yar22',
      'source-layer': 'yar22',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-color': '#b625f5',
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false], 0.7,
          0.5,
        ],
      },
    },]
  },
]

export { efisLayers }
