const municipalCenters = {
  1:[86.74228695,53.9819768],
  2:[86.2627284,54.391979750000004],
  3:[85.54313479999999,54.37450715],
  4:[86.77893320000001,56.13666755],
  5:[86.29000065,55.48375795],
  6:[87.0947923,54.983764300000004],
  7:[85.8836775,54.6607644],
  8:[87.846417,56.38658055],
  9:[88.54350465,53.780613349999996],
  10:[87.55383409999999,53.86362995],
  11:[85.5172085,54.8857638],
  12:[88.0057472,55.29306285],
  13:[85.43370895000001,55.35804625],
  14:[88.7704693,56.074521149999995],
  15:[87.52766995,55.7873758],
  16:[85.04261155,55.761938799999996],
  17:[86.0838803,56.23363755],
  18:[85.50494284999999,55.917293799999996],
  19:[86.72295489999999,53.8607564],
  20:[87.28520505,53.48277365],
  21:[86.3940047,54.1555843],
  23:[86.26693835,54.570315300000004],
  24:[87.73816375,53.634735649999996],
  25:[86.5748206,54.027636099999995],
  26:[88.0760868,52.7609296],
  27:[86.12160405,56.0274826],
  28:[86.66749899999999,54.33892685],
  57:[86.2589021,55.648667849999995],
  87:[86.10091505,55.4007461],
  238:[86.1252206,54.66340855],
  270:[87.15508425,53.79430895],
  303:[87.38298505,53.64777505],
  337:[85.66478330000001,56.086623349999996],
  372:[84.9060532,55.7202141] }


const centers = [
  { 'id':16, value: 372,'name':'Юргинский','center':[84.9060532,55.7202141] },
  { 'id':26, value: 11,'name':'Промышленновский','center':[85.5172085,54.8857638] },
  { 'id':32, value: 16,'name':'Юргинский','center':[85.04261155,55.761938799999996] },
  { 'id':13, value: 23,'name':'Полысаевский','center':[86.26693835,54.570315300000004] },
  { 'id':8, value: 238,'name':'Ленинск-Кузнецкий','center':[86.1252206,54.66340855] },
  { 'id':14, value: 19,'name':'Прокопьевский','center':[86.72295489999999,53.8607564] },
  { 'id':12, value: 303,'name':'Осинниковский','center':[87.38298505,53.64777505] },
  { 'id':34, value: 18,'name':'Яшкинский','center':[85.50494284999999,55.917293799999996] },
  { 'id':6, value: 25,'name':'Киселёвский','center':[86.5748206,54.027636099999995] },
  { 'id':15, value: 337,'name':'Тайгинский','center':[85.66478330000001,56.086623349999996] },
  { 'id':9, value: 9,'name':'Междуреченский','center':[88.54350465,53.780613349999996] },
  { 'id':7, value: 21,'name':'Краснобродский','center':[86.3940047,54.1555843] },
  { 'id':10, value: 24,'name':'Мысковский','center':[87.73816375,53.634735649999996] },
  { 'id':2, value: 2, 'name':'Беловский','center':[86.2627284,54.391979750000004] },
  { 'id':21, value: 6,'name':'Крапивинский','center':[87.0947923,54.983764300000004] },
  { 'id':11, value: 270,'name':'Новокузнецкий','center':[87.15508425,53.79430895] },
  { 'id':4, value: 20,'name':'Калтанский','center':[87.28520505,53.48277365] },
  { 'id':18, value: 3,'name':'Гурьевский','center':[85.54313479999999,54.37450715] },
  { 'id':3, value: 57,'name':'Берёзовский','center':[86.2589021,55.648667849999995] },
  { 'id':20, value: 5,'name':'Кемеровский','center':[86.29000065,55.48375795] },
  { 'id':17, value: 28, 'name':'Беловский','center':[86.66749899999999,54.33892685] },
  { 'id':1, value: 27,'name':'Анжеро-Судженский','center':[86.12160405,56.0274826] },
  { 'id':27, value: 26,'name':'Таштагольский','center':[88.0760868,52.7609296] },
  { 'id':5, value: 87,'name':'Кемеровский','center':[86.10091505,55.4007461] },
  { 'id':22, value: 7,'name':'Ленинск-Кузнецкий','center':[85.8836775,54.6607644] },
  { 'id':31, value: 15,'name':'Чебулинский','center':[87.52766995,55.7873758] },
  { 'id':23, value: 8,'name':'Мариинский','center':[87.846417,56.38658055] },
  { 'id':25, value: 1,'name':'Прокопьевский','center':[86.74228695,53.9819768] },
  { 'id':29, value: 13,'name':'Топкинский','center':[85.43370895000001,55.35804625] },
  { 'id':33, value: 17,'name':'Яйский','center':[86.0838803,56.23363755] },
  { 'id':30, value: 14,'name':'Тяжинский','center':[88.7704693,56.074521149999995] },
  { 'id':19, value: 4,'name':'Ижморский','center':[86.77893320000001,56.13666755] },
  { 'id':24, value: 10,'name':'Новокузнецкий','center':[87.55383409999999,53.86362995] },
  { 'id':28, value: 12,'name':'Тисульский','center':[88.0057472,55.29306285] }
]

export { municipalCenters, centers }
