class UserService {
  constructor(api) {
    this.api = api
  }

  addWorklog(params) {
    return this.api.put(`${process.env.VUE_APP_API_PUBLIC_URL}/user/worklog`, params)
  }
}

export { UserService }
