import Mapbox from 'mapbox-gl'
import StyleMap from './style-map'
// import SourceQuarter from './area'
import SourceSubtraction from './layer-subtraction'
import additionalSources from '@/modules/map/layers/additionalLayers' // TODO modules
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import MapboxDrawStaticMode from '@mapbox/mapbox-gl-draw-static-mode'
import RulerControl from 'mapbox-gl-controls/lib/ruler'
import { addLayerMapType } from '@/utils/layers/map-type'

export default {
  data() {
    return {
      map: null,
      mapDraw: null,
      additionalSources: additionalSources,
      isLoadMap: false,
      // styleLoading: false
    }
  },
  created() {
    // this.addMapboxDraw()
    this.$store.dispatch('fetchPolygons')
  },
  mounted() {
    if (document.querySelector('.map')) {
      this.addMapboxDraw()
      this.mapInit()
    }
  },
  methods: {
    addLayerMapType,
    mapInit() {
      Mapbox.accessToken = 'pk.eyJ1Ijoid2FucjM2IiwiYSI6ImNrZTJmdmJnYjA4enQyem1zOTR2YThqNWwifQ.-VE2zwB6AV3nL8kyb1Tqdg'
      this.map = new Mapbox.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [86.2, 55.3], // Kemerovo
        zoom: 7,
        maxZoom: 18,
        minZoom: 6,
      })

      this.map.on('load', this.onLoadMap )

      this.map.addControl(this.mapDraw, 'bottom-left')
      this.map.on('draw.create', this.updatePolygon)
      this.map.on('draw.delete', this.updatePolygon)
      this.map.on('draw.update', this.updatePolygon)
      this.map.on('click', this.checkSubstruction)

      this.map.addControl(new RulerControl({
        units: 'miles',
        labelFormat: n => `${(n * 1000).toFixed(2)} м.`,
      }), 'bottom-left')

    // map.on('draw.modechange', this.changeMode)
    },
    onLoadMap() {
      this.addLayerMapType(this.map)
      this.loadLayers()
    },
    loadLayers() {
      let sources = []
      // sources.push(SourceQuarter)
      sources.push(SourceSubtraction)
      sources.forEach((source) => {
        this.map.addSource(source.name, source.options)
        source.layers.forEach((layer) => (this.map.addLayer(layer)))
      })

      this.addCustomLayer({ polygons: this.polygons, map: this.map, color: '#F25749' })

      // this.map.on('mousemove', 'customLayer_fill', this.showPolygonInfo)
      // this.map.on('mouseleave', 'customLayer_fill', this.hidePolygonInfo)
      this.map.on('mousemove', 'quarter_fill', this.showRosreestrPolygonInfo)
      this.map.on('mouseleave', 'quarter_fill', this.hideRosreestrPolygonInfo)
      this.map.on('click', 'customLayer_fill', this.showDetailsPolygon)
      this.isLoadMap = true
    },
    addControls() {
      // add ruler
      this.map.addControl(new RulerControl({
        units: 'miles',
        labelFormat: n => `${(n * 1000).toFixed(2)} м.`,
      }), 'bottom-left')
    },
    addMapboxDraw() {
      let mapModes = MapboxDraw.modes
      mapModes.static = MapboxDrawStaticMode

      this.mapDraw = new MapboxDraw({
        userProperties: true,
        displayControlsDefault: false,
        controls: {
          trash: true,
          polygon: true,
        },
        modes: {
          ...mapModes,
        },
        styles: StyleMap
      })
    }
  },
  computed: {
    mapState() {
      return this.$store.state.map.mapState
    },
    polygons() {
      return this.$store.state.polygons
    },
  },
}
