<template>
  <Modal
    v-if="isShowCheckStatusModal"
    title="Статус проверки"
    @closeModal="closeCheckStatusModal"
    @confirmClick="$refs.checkStatusMapForm.sendForm()"
  >
    <CheckStatusMap
      ref="checkStatusMapForm"
      :id="informations.hash"
      :fields="informations.form"
      :layer="informations.layer"
      @close="closeCheckStatusModal"
    />
  </Modal>

  <Modal
    v-if="isShowDevCheckStatusModal"
    title="Статус внутренней проверки"
    @closeModal="closeDevCheckStatusModal"
    @confirmClick="$refs.checkDevStatusForm.sendForm()"
  >
    <CheckDevStatus
      ref="checkDevStatusForm"
      :id="informations.hash"
      :fields="informations.form"
      @close="closeDevCheckStatusModal"
    />
  </Modal>

  <Modal
    v-if="isShowChangeModal"
    v-show="!isShowCreateOrgModal && !isShowChangeOrgModal"
    title="Изменить информацию"
    showClose
    @closeModal="closeChangeAreaModal"
    @cancelClick="closeChangeAreaModal"
    @confirmClick="$refs.changeAreaMapForm.sendForm()"
  >
    <EditSection
      type="single"
      ref="changeAreaMapForm"
      :fields="informations.form"
      :id="informations.hash"
      @close="closeChangeAreaModal"
    />
  </Modal>

  <Modal
    v-if="isShowChangeLayerModal"
    title="Изменить источник данных/муниципалитет"
    @closeModal="closeChangeLayerModal"
    @confirmClick="$refs.changeLayerForm.sendForm()"
  >
    <ChangeLayerMap
      ref="changeLayerForm"
      :hash="informations.hash"
      :fields="informations.form"
      :layer="informations.layer"
      @close="closeChangeLayerModal"
    />
  </Modal>

  <Modal
    v-if="isShowChangeKNModal"
    title="Изменить кадастровый номер"
    showClose
    @cancelClick="closeChangeKNModal"
    @closeModal="closeChangeKNModal"
    @confirmClick="updateKN"
  >
    Кадастровый номер присвоится автоматически
    <!-- ChangeKNMap(
      ref="changeKNForm"
      :hash='informations.hash'
      :regionId='informations.form ? informations.form.regionId : null'
      :cadastralNumber='informations.cadastralNumber'
      @close='closeChangeKNModal'
    ) -->
  </Modal>

  <Modal
    v-if="isShowDeleteModal"
    title="Удаление участка"
    showClose
    @cancelClick="closeDeleteModal"
    @closeModal="closeDeleteModal"
    @confirmClick="deletePolygon"
  >
    Вы уверены, что хотите удалить данный участок?
  </Modal>

  <Modal
    v-if="isShowUndeleteModal"
    title="Восстановление участка"
    showClose
    @cancelClick="closeUndeleteModal"
    @closeModal="closeUndeleteModal"
    @confirmClick="undeletePolygon"
  >
    Вы уверены, что хотите восстановить данный участок?
  </Modal>

  <Modal
    v-if="isShowGroupChangeModal"
    v-show="!isShowCreateOrgModal && !isShowChangeOrgModal"
    title="Изменить информацию"
    @closeModal="closeChangeGroupModal"
    @confirmClick="$refs.groupEditForm.sendForm()"
  >
    <EditSection
      type="group"
      ref="groupEditForm"
      :areas="areas"
      @close="closeChangeGroupModal"
      @clearEditingGroup="clearEditingGroup"
    />
  </Modal>

  <Modal
    v-if="isShowAddSntModal"
    title="СНТ"
    showClose
    @cancelClick="closeAddSntModal"
    @closeModal="closeAddSntModal"
    @confirmClick="addSnt"
  >
    Редактирование {{ hashes && hashes.length ? hashes.length : 0 }} участков(ка), присваиваются атрибуты:<br><br>
    Категория пользования: "Садоводство-огородничество"<br>
    Категория прав собственности: "Частная собственность"<br>
    Категория угодий: "Несельскохозяйственные угодья"<br>
    Статус проверки: "Данные верны"<br>
  </Modal>

  <Modal
    v-if="isNotOverlapsModal"
    title="Проверка пересечения границ"
    textConfirm="Закрыть"
    @cancelClick="closeNotOverlapsModal"
    @closeModal="closeNotOverlapsModal"
    @confirmClick="closeNotOverlapsModal"
  >
    Пересечений нет
  </Modal>

  <Modal
    v-if="isShowEditWithoutCurrentModal"
    title="Подтверждение изменения границ"
    showClose
    @cancelClick="closeEditWithoutCurrentModal"
    @closeModal="closeEditWithoutCurrentModal"
    @confirmClick="saveWithoutCurrentPolygons"
  >
    Вы уверены, что хотите изменить границы участков?
  </Modal>

  <Modal
    v-if="isShowEditModal"
    title="Подтверждение изменения границ"
    showClose
    @cancelClick="closeEditModal"
    @closeModal="closeEditModal"
    @confirmClick="savePolygon"
  >
    Вы уверены, что хотите изменить границы данного участка?
  </Modal>

  <Modal
    v-if="isShowLoadedCreateModal"
    title="Загрузить координаты"
    showClose
    @cancelClick="closeLoadedCreateModal"
    @closeModal="closeLoadedCreateModal"
    @confirmClick="$refs.downloadCreateAreaForm.addCreatePolygon()"
  >
    <UploadScheme ref="downloadCreateAreaForm" />
  </Modal>

  <Modal
    v-if="isShowCheckPolygonModal"
    title="Проверка пересечения границ"
    showClose
    @cancelClick="closeCheckPolygon"
    @closeModal="closeCheckPolygon"
    @confirmClick="$refs.checkPolygonForm.savePolygon()"
  >
    <CheckPolygon
      ref="checkPolygonForm"
      @cancelCheck="closeCheckPolygon"
      @close="closeCheckPolygon"
    />
  </Modal>

  <Modal
    v-if="isShowCreateModal"
    title="Создание участка"
    showClose
    @cancelClick="closeCreateModal"
    @closeModal="closeCreateModal"
    @confirmClick="$refs.createAreaForm.sendForm()"
  >
    <CreateArea
      ref="createAreaForm"
      @close="closeCreateModal"
      @cancelCreate="closeCreateModal"
    />
  </Modal>

  <Modal
    v-if="isShowImageModal"
    title="Загрузить изображение"
    showClose
    @cancelClick="closeAddImageModal"
    @closeModal="closeAddImageModal"
    @confirmClick="$refs.addImageForm.saveImage()"
  >
    <AddImageSection
      ref="addImageForm"
      @close="closeAddImageModal"
    />
  </Modal>

  <Modal
    v-if="isShowFileModal"
    title="Загрузить файл"
    showClose
    @cancelClick="closeAddFileModal"
    @closeModal="closeAddFileModal"
    @confirmClick="$refs.addFileForm.saveFile()"
  >
    <AddFileSection
      ref="addFileForm"
      @close="closeAddFileModal"
    />
  </Modal>

  <Modal
    v-if="isShowEditFilesModal"
    title="Редактировать примечание"
    showClose
    @cancelClick="closeEditFileModal"
    @closeModal="closeEditFileModal"
    @confirmClick="$refs.editFileForm.saveFile()"
  >
    <EditFileSection
      ref="editFileForm"
      @close="closeEditFileModal"
    />
  </Modal>

  <Modal
    v-if="isShowDeleteFilesModal"
    title="Удалить файл"
    showClose
    @cancelClick="closeDeleteFileModal"
    @closeModal="closeDeleteFileModal"
    @confirmClick="deleteFile"
  >
    <span v-if="typeFile === 'files'">Вы уверены, что хотите удалить файл?</span>
    <span v-if="typeFile === 'images'">Вы уверены, что хотите удалить изображение?</span>
  </Modal>

  <Modal
    v-if="isShowInheriteAttributesModal"
    title="Перенести атрибуты из пересекающего участка"
    showClose
    @cancelClick="closeInheriteAttributesModal"
    @closeModal="closeInheriteAttributesModal"
    @confirmClick="$refs.inheriteAttributeForm.saveAttributes()"
  >
    <InheriteAttributes
      ref="inheriteAttributeForm"
      :currentSection="informations"
      :overlapSections="inheriteAttributesSections"
      @close="closeInheriteAttributesModal"
      @switchLoading="switchLoading"
      @updateCurrentSection="store.dispatch('mapFilter/fetchCurrentPage', { id: informations.hash })"
    />
  </Modal>

  <Modal
    v-if="isShowNonInheriteAttributesModal"
    title="Перенести атрибуты из пересекающего участка"
    @closeModal="closeNonInheriteAttributesModal"
    @confirmClick="closeNonInheriteAttributesModal"
  >
    Участков нет
  </Modal>

  <FullScreenModal
    v-if="isShowOverlapsModal"
    title="Пересечения"
    showClose
    @closeModal="closeOverlapModal"
  >
    <SectionOverlaps :overlaps="overlaps" />
  </FullScreenModal>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import { useRoute } from 'vue-router'
import Modal from '@/components/common/modals/Modal'
import FullScreenModal from '@/components/common/modals/FullScreenModal'
import EditSection from './forms/sections/EditSection'
import CheckStatusMap from './forms/sections/CheckStatusMap'
import CheckDevStatus from './forms/sections/CheckDevStatus'
import ChangeLayerMap from './forms/sections/ChangeLayerMap'
// import ChangeKNMap from './forms/sections/ChangeKNMap'
import CheckPolygon from './forms/sections/CheckPolygon'
import CreateArea from './forms/sections/CreateArea'
import AddImageSection from './forms/sections/AddImageSection'
import AddFileSection from './forms/sections/AddFileSection'
import EditFileSection from './forms/sections/EditFileSection'
import UploadScheme from './forms/schemes/UploadScheme'
import SectionOverlaps from './tables/SectionOverlaps'
import InheriteAttributes from '@/modules/map/components/InheriteAttributes'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()
const route = useRoute()

const typeFile = computed(() => store.state.files.typeFile)
const commentFile = computed(() => store.state.files.comment)
const idFile = computed(() => store.state.files.id)
const isShowDeleteFilesModal = computed(() => store.state.modals.isShowDeleteFilesModal)
const isShowEditFilesModal = computed(() => store.state.modals.isShowEditFilesModal)
const isShowImageModal = computed(() => store.state.modals.isShowImageModal)
const isShowFileModal = computed(() => store.state.modals.isShowFileModal)
const isShowOverlapsModal = computed(() => store.state.modals.isShowOverlapsModal)
const isShowCheckStatusModal = computed(() => store.state.modals.isShowCheckStatusModal)
const isShowDevCheckStatusModal = computed(() => store.state.modals.isShowDevCheckStatusModal)
const isShowChangeModal = computed(() => store.state.modals.isShowChangeModal)
const isShowCreateOrgModal = computed(() => store.state.modals.isShowCreateOrgModal)
const isShowChangeOrgModal = computed(() => store.state.modals.isShowChangeOrgModal)
const isShowChangeLayerModal = computed(() => store.state.modals.isShowChangeLayerModal)
const isShowChangeKNModal = computed(() => store.state.modals.isShowChangeKNModal)
const isShowDeleteModal = computed(() => store.state.modals.isShowDeleteModal)
const isShowUndeleteModal = computed(() => store.state.modals.isShowUndeleteModal)
const isShowGroupChangeModal = computed(() => store.state.modals.isShowGroupChangeModal)
const isShowAddSntModal = computed(() => store.state.modals.isShowAddSntModal)
const isNotOverlapsModal = computed(() => store.state.modals.isNotOverlapsModal)
const isShowEditWithoutCurrentModal = computed(() => store.state.modals.isShowEditWithoutCurrentModal)
const isShowEditModal = computed(() => store.state.modals.isShowEditModal)
const isShowLoadedCreateModal = computed(() => store.state.modals.isShowLoadedCreateModal)
const isShowCheckPolygonModal = computed(() => store.state.modals.isShowCheckPolygonModal)
const isShowCreateModal = computed(() => store.state.modals.isShowCreateModal)
// const isShowConfirmFieldsModal = computed(() => store.state.modals.isShowConfirmFieldsModal)
const isShowInheriteAttributesModal = computed(() => store.state.modals.isShowInheriteAttributesModal)
const isShowNonInheriteAttributesModal = computed(() => store.state.modals.isShowNonInheriteAttributesModal)
const informations = computed(() => store.state.mapFilter.currentPage)
const overlaps = computed(() => store.state.details.overlaps)
const areas = computed(() => store.state.map.editingGroup)
const hashes = computed(() => Object.keys(store.state.map.editingGroup))
const mapInfo = computed(() => store.state.settings.mapInfo)
const inheriteAttributesSections = computed(() => store.state.map.inheriteAttributesSections)

const closeOverlapModal = () => {
  store.commit('modals/setIsShowOverlapsModal', false)
}

const closeCheckStatusModal = () => {
  store.commit('modals/setIsShowCheckStatusModal', false)
}

const closeDevCheckStatusModal = () => {
  store.commit('modals/setIsShowDevCheckStatusModal', false)
}

const closeChangeAreaModal = () => {
  store.commit('modals/setIsShowChangeModal', false)
}

const showCreateOrgModal = () => {
  store.commit('modals/setIsShowCreateOrgModal', true)
}

const closeChangeLayerModal = () => {
  store.commit('modals/setIsShowChangeLayerModal', false)
}

const closeChangeKNModal = () => {
  store.commit('modals/setIsShowChangeKNModal', false)
}

const closeDeleteModal = () => {
  store.commit('modals/setIsShowDeleteModal', false)
}

const closeUndeleteModal = () => {
  store.commit('modals/setIsShowUndeleteModal', false)
}

const closeChangeGroupModal = () => {
  store.commit('modals/setIsShowGroupChangeModal', false)
}

const closeAddSntModal = () => {
  store.commit('modals/setIsShowAddSntModal', false)
}

const closeNotOverlapsModal = () => {
  store.commit('modals/setIsNotOverlapsModal', false)
}

const closeEditWithoutCurrentModal = () => {
  store.commit('modals/setIsShowEditWithoutCurrentModal', false)
}

const closeLoadedCreateModal = () => {
  store.commit('map/setPolygonFromXml', null)
  store.commit('modals/setIsShowLoadedCreateModal', false)
}

const closeCheckPolygon = () => {
  store.commit('modals/setIsShowCheckPolygonModal', false)
  store.commit('mapFilter/setIsShowCheckOverlaps', false)
}

const closeCreateModal = () => {
  store.commit('modals/setIsShowCreateModal', false)
  store.commit('map/setCreatingSection', null)
}

const closeEditModal = () => {
  store.commit('modals/setIsShowEditModal', false)
}

const closeAddImageModal = () => {
  store.commit('modals/setIsShowImageModal', false)
}

const closeAddFileModal = () => {
  store.commit('modals/setIsShowFileModal', false)
}

const closeEditFileModal = () => {
  store.commit('modals/setIsShowEditFilesModal', false)
}

const closeDeleteFileModal = () => {
  store.commit('modals/setIsShowDeleteFilesModal', false)
}

const closeInheriteAttributesModal = () => {
  store.commit('modals/setIsShowInheriteAttributesModal', false)
  store.commit('map/setInheriteAttributeSections', [])
}

const closeNonInheriteAttributesModal = () => {
  store.commit('map/setInheriteAttributeSections', [])
  store.commit('modals/setIsShowNonInheriteAttributesModal', false)
}

const onSuccess = () => {
  store.commit('modals/setDisableModal', false)
  $q.notify({
    type: 'positive',
    position: 'bottom',
    message: 'Успешно',
    timeout: 5000
  })
}

const onError = (error) => {
  const errorText = error?.response?.data?.message
  $q.notify({
    type: 'negative',
    position: 'bottom',
    message: errorText ? errorText : 'Ошибка',
    timeout: 5000
  })
  store.dispatch('auth/fetchUser')
  store.commit('modals/setDisableModal', false)
}

const updateKN = () => {
  store.commit('modals/setDisableModal', true)

  ApiService.map.updateCadastralNumber(informations.value.hash, { regionId: informations.value.form ? informations.value.form.regionId : null })
    .then((response) => {
      const hash = response?.data?.hash
      const layer = response?.data?.layer

      $q.notify({
        type: 'positive',
        position: 'bottom',
        message: 'Успешно',
        timeout: 5000
      })
      store.commit('modals/setDisableModal', false)
      if (hash) {
        store.dispatch('mapFilter/fetchCurrentPage', { id: hash })
      }
      store.commit('mapFilter/setEditingLayer', { hash: hash, oldHash: informations.value.hash, layer })
      store.commit('mapFilter/setEditCount')
      closeChangeKNModal()
    })
    .catch((error) => {
      store.dispatch('auth/fetchUser')
      store.commit('modals/setDisableModal', false)
      onError(error)
    })
}

const updateCurrentPage = (hash) => {
  store.dispatch('mapFilter/fetchCurrentPage', { id: hash })
  store.commit('details/setPolygon', {})
  store.dispatch('details/fetchPolygon', { id: route.params.id })
}

const updateCurrentPageForDeleting = (hash) => {
  store.dispatch('mapFilter/fetchCurrentPage', { id: hash })
  store.commit('details/setPolygon', {})
}

const deletePolygon = () => {
  if (window.location.href.includes('lk.sovhoz.tech/')) {
    window.ym(75689863,'reachGoal','map-zucard-edit-deletezu-delete-click')
  }

  store.commit('modals/setDisableModal', true)

  ApiService.map.deleteArea(informations.value.hash)
    .then(() => {
      onSuccess()
      store.commit('modals/setIsShowDeleteModal', false)
      store.commit('mapFilter/setDeleteCount')
      store.commit('mapFilter/setEditCount')
      updateCurrentPageForDeleting(informations.value.hash)
    })
    .catch((error) => {
      onError(error)
    })
}

const undeletePolygon = () => {
  store.commit('modals/setDisableModal', true)

  ApiService.map.undeleteArea(informations.value.hash)
    .then(() => {
      onSuccess()
      store.commit('modals/setIsShowUndeleteModal', false)
      store.commit('mapFilter/setDeleteCount')
      store.commit('mapFilter/setEditCount')
      updateCurrentPage(informations.value.hash)
    })
    .catch((error) => {
      onError(error)
    })
}

const clearEditingGroup = () => {
  store.commit('map/setEditingGroup', {})
}

const addSnt = () => {
  store.commit('modals/setDisableModal', true)

  ApiService.map.addSnt(hashes.value)
    .then(() => {
      onSuccess()
      store.commit('modals/setIsShowAddSntModal', false)
      clearEditingGroup()
    })
    .catch((error) => {
      onError(error)
    })
}

const saveWithoutCurrentPolygons = () => {
  store.commit('map/setCountDeletingOverlaps')
}

const savePolygon = () => {
  if (informations.value?.hash) {
    store.commit('subscribes/setSubscribedSections', informations.value.hash)
  }

  store.commit('map/setCountEditingPolygon')
}

const cancelDeleteFile = () => {
  store.commit('files/setTypeFile', null)
  store.commit('files/setIdFile', null)
  store.commit('files/setCommentFile', '')
  store.commit('modals/setIsShowDeleteFilesModal', false)
}

const deleteFile = () => {
  store.commit('modals/setDisableModal', true)

  ApiService.map.deleteFile(informations.value.id, typeFile.value, idFile.value)
    .then(() => {
      if (typeFile.value === 'files') {
        store.dispatch('details/fetchFiles', { id: informations.value.id })
      }

      if (typeFile.value === 'images') {
        store.dispatch('details/fetchImages', { id: informations.value.id })
      }
      cancelDeleteFile()
      onSuccess()
    })
    .catch((error) => {
      onError(error)
    })
}

const switchLoading = (state) => {
  store.commit('modals/setDisableModal', state)
}
</script>
