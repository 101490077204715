<template>
  <q-form ref="formRef">
    <div class="input">
      <span class="label-select">Примечание</span>
      <q-input
        v-model="comment"
        :rules="[val => val.length <= 2000 || 'Не более 2000 символов']"
        placeholder="Примечание"
        outlined
        type="textarea"
        no-error-icon
        dense
      />
    </div>
  </q-form>
</template>
<script setup>
import { defineEmits, defineExpose, computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const formRef = ref()
const comment = ref('')
const emits = defineEmits(['close'])

const typeFile = computed(() => store.state.files.typeFile)
const commentFile = computed(() => store.state.files.comment)
const idFile = computed(() => store.state.files.id)
const informations = computed(() => store.state.mapFilter.currentPage)

const cancelEditFile = () => {
  store.commit('files/setTypeFile', null)
  store.commit('files/setIdFile', null)
  store.commit('files/setCommentFile', '')
  store.commit('modals/setIsShowEditFilesModal', false)
}

const saveFile = () => {
  const formEl = formRef.value
  formEl.validate().then((result) => {
    if (result) {
      store.commit('modals/setDisableModal', true)
      ApiService.map.editFile(informations.value.id, idFile.value, typeFile.value, { comment: comment.value })
        .then(() => {
          store.commit('modals/setDisableModal', false)
          if (typeFile.value === 'images') {
            store.dispatch('details/fetchImages', { id: informations.value.id })
          }

          if (typeFile.value === 'files') {
            store.dispatch('details/fetchFiles', { id: informations.value.id })
          }
          cancelEditFile()
          $q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Успешно',
            timeout: 5000
          })
        })
        .catch((error) => {
          store.dispatch('auth/fetchUser')
          store.commit('modals/setDisableModal', false)
          const errorText = error?.response?.data?.message
          $q.notify({
            type: 'negative',
            position: 'bottom',
            message: errorText ? errorText : 'Ошибка',
            timeout: 5000
          })
        })
    }
  })
}
onMounted(() => {
  comment.value = commentFile.value
})

defineExpose({ saveFile })
</script>
<style lang="scss" scoped>
.input-file {
  margin-bottom: 20px;
}
</style>
