<template>
  <div class="width-slider" :draggable="true" @dragend="onDrag" />
</template>
<script setup>
import { defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'

const MAX_WIDTH = 40 + 344
const MIN_WIDTH = 344

const store = useStore()

const props = defineProps({
  width: {
    type: Number,
    required: true,
    default: 344
  }
})

const emits = defineEmits(['setWidth'])

const onDrag = (evt) => {
  let newWidth = props.width + evt.offsetX
  if (newWidth < MIN_WIDTH) {
    newWidth = MIN_WIDTH
  }

  if (newWidth > (window.innerWidth - MAX_WIDTH)) {
    newWidth = window.innerWidth - MAX_WIDTH
  }
  emits('setWidth', newWidth)
  store.commit('details/setIsCountChangeWidthDetails')
}
</script>
<style lang="scss">
.width-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 3px;
  cursor: col-resize;
}
</style>
