export default { // Кадастровые районы
  name: 'subtraction-source',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/substraction/{z}/{x}/{y}.mvt'],
    // http://geo.sovhoz.ai/geoserver/test/wms?service=WMS&version=1.1.0&request=GetMap&layers=test%3Ash_rosreestr&bbox=84.53514411704948%2C52.59926453459482%2C89.34783003250722%2C56.59111135794798&width=768&height=637&srs=EPSG%3A4326&format=application/openlayers#toggle
  },
  layers: [{
    id: 'subtraction_fill',
    type: 'fill',
    source: 'subtraction-source',
    'source-layer': 'substraction',
    paint: {
      'fill-color': '#b625f5',
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.1,
        0.2,
      ],
    },
    // maxzoom: 14,
  }, {
    id: 'subtraction_line',
    type: 'line',
    source: 'subtraction-source',
    'source-layer': 'subtraction',
    paint: {
      'line-color': '#b625f5',
      'line-width': 1,
    },
  },]
}
