import ApiService from '@/services'

const actions = {
  fetchMapInfo({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.settings.getMapInfo()
        .then((response) => {
          commit('setMapInfo', response?.data)
          resolve(response?.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchMaintenance({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.settings.getMaintenance()
        .then((response) => {
          if (response.data && response.data.maintenanceText) {
            commit('setMaintenancePage', response.data.maintenanceText)
          }

          if (response.data && !response.data.maintenanceText) {
            commit('setMaintenancePage', '')
          }

          if (response.data && response.data.maintenanceNotice) {
            commit('setMaintenanceNotice', response.data.maintenanceNotice)
          }
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setMapInfo(state, data) {
    state.mapInfo = data
  },
  setMaintenanceNotice(state, data) {
    state.maintenanceNotice = data
  },
  setMaintenancePage(state, data) {
    state.maintenancePage = data
  },
  setIsLoading(state, data) {
    state.isLoading = data
  }
}

const state = () => ({
  mapInfo: '',
  maintenanceNotice: '',
  maintenancePage: '',
  isLoading: false
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
