import { createRouter, createWebHistory } from 'vue-router'

import store from '../store/index.js'
import Login from '@/modules/auth/pages/Login.vue'
import UpdatePassword from '@/modules/auth/pages/UpdatePassword.vue'
import MapPage from '@/modules/map/pages/MapPage'
const HelpPage = () => import('@/modules/help/pages/HelpPage')
const Events = () => import('@/modules/events/pages/Events')
const Dashboard = () => import('@/modules/main/pages/Dashboard')
const MapDetails = () => import('@/modules/map/pages/Details')
const Reports = () => import('@/modules/reports/pages/Reports')
const NotAccess = () => import('@/modules/notAccess/pages/NotAccess')
const Maintenance = () => import('@/modules/maintenance/pages/Maintenance')
const Analitics = () => import('@/modules/dashboard/pages/Analitics')
const Intersections = () => import('@/modules/intersections/pages/Intersections')
const Kpt = () => import('@/modules/kpt/pages/Kpt')
// const Passports = () => import('@/modules/passports/pages/Passports')
const Geojson = () => import('@/modules/geojson/pages/Geojson')
// const Harve = () => import('@/modules/harve/pages/Harve')
const Register = () => import('@/modules/registers/pages/Register')
// const Enrichments = () => import('@/modules/enrichment/pages/Enrichments')
const Orgs = () => import('@/modules/orgs/pages/Orgs')
// import Statistics from '@/modules/statistics/pages/Statistics'
// const WithoutBorders = () => import('@/modules/withoutBorders/pages/WithoutBorders')
// const Details = () => import('@/modules/withoutBorders/pages/Details')
// const Applications = () => import('@/modules/applications/pages/Applications')
// const AreaCheck = () => import('@/modules/areaCheck/pages/AreaCheck')
// const Questionnaires = () => import('@/modules/questionnaire/pages/Questionnaires')
// const Questionnaire = () => import('@/modules/questionnaire/pages/Questionnaire')

const routes = [
  {
    path: '/map',
    name: 'map',
    component: MapPage,
    meta: {
      requiresAuth: true,
      isShowHeader: true,
      layout: 'LayoutMapView',
      access: 'access_cabinet_map',
      label: 'Карта',
      icon: 'mapIcon'
    }
  },
  {
    path: '/analitics',
    name: 'analitics',
    component: Analitics,
    meta: {
      isShowHeader: true,
      requiresAuth: true,
      label: 'Дашборд',
      access: 'access_cabinet_dashboards',
      icon: 'analitics',
    }
  },
  {
    path: '/orgs',
    name: 'orgs',
    component: Orgs,
    meta: {
      isShowHeader: true,
      requiresAuth: true,
      label: 'СХП',
      access: 'orgs_access',
      icon: 'orgs',
    }
  },
  // {
  //   path: '/harve',
  //   name: 'harve',
  //   component: Harve,
  //   meta: {
  //     isShowHeader: true,
  //     requiresAuth: true,
  //     label: 'Сбор',
  //     access: 'harvest_access',
  //     icon: 'harve',
  //   }
  // },
  // {
  //   path: '/enrichments',
  //   name: 'enrichments',
  //   component: Enrichments,
  //   meta: {
  //     isShowHeader: true,
  //     requiresAuth: true,
  //     label: 'Удобрения',
  //     access: 'fertilizers_access',
  //     icon: 'enrichments',
  //   }
  // },
  {
    path: '/intersections',
    name: 'intersections',
    component: Intersections,
    meta: {
      isShowHeader: true,
      requiresAuth: true,
      label: 'Пересечения',
      access: 'access_overlaps_info',
      icon: 'overlaps',
    }
  },
  {
    path: '/kpt',
    name: 'kpt',
    component: Kpt,
    meta: {
      isShowHeader: true,
      requiresAuth: true,
      label: 'КПТ',
      icon: 'kpt',
      access: 'access_import_KPT'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      isShowHeader: true,
      requiresAuth: true,
      label: 'Реестры',
      access: 'access_kugi_reestr_import',
      icon: 'register',
    }
  },
  // {
  //   path: '/passports',
  //   name: 'passports',
  //   component: Passports,
  //   meta: {
  //     isShowHeader: true,
  //     requiresAuth: true,
  //     label: 'Паспорта',
  //     icon: 'passports',
  //     access: 'docs_passport_agro_view_passport'
  //   }
  // },
  // {
  //   path: '/areas',
  //   name: 'areas',
  //   component: WithoutBorders,
  //   meta: {
  //     isShowHeader: true,
  //     requiresAuth: true,
  //     layout: 'LayoutMapEdit',
  //     access: 'access_cabinet_sections',
  //     label: 'Участки без границ',
  //     icon: 'withoutBorders',
  //   }
  // },
  // {
  //   path: '/applications',
  //   name: 'applications',
  //   component: Applications,
  //   meta: {
  //     isShowHeader: true,
  //     requiresAuth: true,
  //     label: 'Заявки',
  //     access: 'access_cabinet_investor',
  //     icon: 'request',
  //   }
  // },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      isShowHeader: false,
      requiresAuth: true,
      label: 'Главная',
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta: {
      isShowHeader: true,
      requiresAuth: true,
      label: 'Экспорт',
      access: 'access_reports',
      icon: 'exportFiles',
    }
  },
  // {
  //   path: '/area-check',
  //   name: 'AreaCheck',
  //   component: AreaCheck,
  //   meta: {
  //     isShowHeader: true,
  //     requiresAuth: true,
  //     label: 'Участки на проверку',
  //     icon: 'areaCheck',
  //     access: 'access_cabinet_sections_review',
  //   }
  // },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Statistics,
  //   meta: {
  //     isShowHeader: false,
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/questionnaires',
  //   name: 'questionnaires',
  //   component: Questionnaires,
  //   meta: {
  //     isShowHeader: true,
  //     requiresAuth: true,
  //     label: 'Анкеты фермеров',
  //     access: 'access_cabinet_questionnaire',
  //     icon: 'questionnaire',
  //   }
  // },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      isShowHeader: true,
      onlyAdmin: true,
      requiresAuth: true,
      access: 'access_cabinet_actions',
      label: 'События',
      icon: 'events',
    }
  },
  // {
  //   path: '/questionnaire/:id',
  //   name: 'questionnaire',
  //   component: Questionnaire,
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  {
    path: '/help',
    name: 'help',
    component: HelpPage,
    meta: {
      isShowHeader: true,
      requiresAuth: true,
      access: 'access_cabinet_helps',
      label: 'Помощь',
      icon: 'help',
    }
  },
  {
    path: '/map/:id',
    name: 'mapDetails',
    component: MapDetails,
    meta: {
      isShowHeader: false,
      requiresAuth: true,
      layout: 'LayoutMapView',
      access: 'access_cabinet_map'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isShowHeader: false,
    }
  },
  {
    path: '/update-password',
    name: 'UpdatePassword',
    component: UpdatePassword,
    meta: {
      isShowHeader: false,
    },
    beforeEnter: (to, from, next) => {
      store.state.auth.passwordExpired ? next() : next('/')
    }
  },
  // {
  //   path: '/details/:id',
  //   name: 'Details',
  //   component: Details,
  //   meta: {
  //     isShowHeader: false,
  //     requiresAuth: true,
  //     layout: 'LayoutMapEdit',
  //     access: 'access_cabinet_sections',
  //   },
  // },
  {
    path: '/geojson',
    name: 'geojson',
    component: Geojson,
    meta: {
      requiresAuth: true,
      isShowHeader: true,
      layout: 'LayoutGeoJson',
      access: 'access_cabinet_map',
      label: 'GeoJson',
      icon: 'mapIcon'
    }
  },
  {
    path: '/not-access',
    name: 'NotAccess',
    component: NotAccess,
    meta: {
      isShowHeader: false,
      isHidden: true
    }
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: {
      isShowHeader: false,
      layout: 'LayoutEmpty'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotAccess,
    meta: {
      isShowHeader: false,
      isHidden: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('settings/fetchMaintenance')
  next()
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.dispatch('auth/fetchUser')
      .then(() => {
        if (!to.meta.access || store.state.auth.user.permissions.includes(to.meta.access)) {
          next()
          return
        } else {
          next('/not-access')
        }
      })
      .catch(() => {
        next('/login')
      })
  } else {
    next()
  }
})

export { routes }
export default router
