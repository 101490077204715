<template>
  <q-form ref="formRef">
    <div class="field field--validate" data-testid="Организация">
      <OrgsSelect
        v-model="orgId"
        label="Организация"
        :clearable="true"
        :selectedOrgs="[orgId]"
      />
    </div>
  </q-form>
</template>

<script setup>
import { defineEmits, defineExpose, ref } from 'vue'
import OrgsSelect from '@/components/common/selects/OrgsSelect'

const emits = defineEmits(['linkOrg'])

const orgId = ref(null)
const formRef = ref()

const sendForm = () => {
  const formEl = formRef.value
  formEl.validate().then(async (result) => {
    if (result) {
      emits('linkOrg', orgId.value)
    }
  })
}

defineExpose({ sendForm })
</script>

<style lang="scss" scoped>
.field {
  max-width: 420px;
}
</style>
