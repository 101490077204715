import api from '@/api'

const actions = {
  fetchAreas({ commit }, areas) {
    return new Promise((resolve, reject) => {
      api.post('/private/map/filter/extended', { hashes: areas })
        .then((response) => {
          commit('setAreas', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  setAreas(state, data){
    state.areas = data
  },
}

const state = () => ({
  areas: [],
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
