const removeFieldLayer = (map) => {
  if (map.getSource('fieldLayer_data')) {
    map.removeLayer('fieldLayer_fill')
    map.removeLayer('fieldLayer_line')
    map.removeSource('fieldLayer_data')
  }
}

const addFieldLayer = (params) => {
  const source = {
    type: 'FeatureCollection',
    features: params.polygons.map((el) => {
      return {
        id: el.id,
        type: 'Feature',
        geometry: el.geometry,
        properties: el.properties,
      }
    })
  }

  if (!params.map.getSource('fieldLayer_data')) {
    params.map.addSource('fieldLayer_data', {
      type: 'geojson',
      data: source
    })
  }

  params.map.addLayer({
    id: 'fieldLayer_fill',
    type: 'fill',
    source: 'fieldLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'fill-color': ['case',
        ['boolean', ['feature-state', 'active'], false], params.activeColor,
        params.color
      ],
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'active'], false], 0.5,
        ['boolean', ['feature-state', 'hover'], false], 0.6,
        ['boolean', ['feature-state', 'edited'], false], 0,
        0.4,
      ],
    },
  })

  params.map.addLayer({
    id: 'fieldLayer_line',
    type: 'line',
    source: 'fieldLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-color': ['case',
        ['boolean', ['feature-state', 'active'], false], params.activeColor,
        ['boolean', ['feature-state', 'edited'], false], 'transparent',
        params.color
      ],
      'line-width': ['case',
        ['boolean', ['feature-state', 'field'], false], 3,
        1
      ]
    },
  })
}

export { addFieldLayer, removeFieldLayer }
