<template>
  <div class="pagination">
    <div class="item" @click="goToPage(1)" :class="{ active : currentPage === 1 }">
      <span>1</span>
    </div>
    <div v-if="currentPage > 3 && last > 5" class="item points">
      <span>..</span>
    </div>
    <div class="item" @click="goToPage(page)" v-for="page in pages" :key="page" :class="{ active : currentPage === page }">
      <span>{{ page }}</span>
    </div>
    <div v-if="currentPage <= last - 3 && last > 5" class="item points">
      <span>..</span>
    </div>
    <div class="item" @click="goToPage(last)" :class="{ active : currentPage === last }">
      <span>{{ last }}</span>
    </div>
    <div class="input">
      <input v-model="inputPage" type="number" min="1" :max="last" @keyup.enter.stop="nextPage" class="item">
      <span @click="nextPage">перейти</span>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'

const props = defineProps(['last', 'currentPage'])
const emits = defineEmits(['goToPage'])

const inputPage = ref(null)

const pages = computed(() => {
  const listPage = []
  let startPage = 1

  if (props.last < 6) {
    for (let index = 2; index < props.last; index++) {
      listPage.push(index)
    }
    return listPage
  }

  switch (props.currentPage) {
  case 1:
    startPage = 2
    break

  case 2:
    startPage = 2
    break

  case props.last - 3:
    startPage = props.currentPage - 1
    break

  case props.last - 2:
    startPage = props.last - 3
    break
  case props.last - 1:
    startPage = props.last - 3
    break
  case props.last:
    startPage = props.last - 3
    break

  default:
    startPage = props.currentPage - 1
    break
  }

  for (let index = startPage; index < startPage + 3; index++) {
    listPage.push(index)
  }
  return listPage
})

const goToPage = (page) => {
  emits('goToPage', page)
  window.scrollTo(0, 0)
  inputPage.value = null
}

const nextPage = () => {
  if (!inputPage.value || inputPage.value < 1) {
    inputPage.value = 1
  } else if (inputPage.value > props.last) {
    inputPage.value = props.last
  }
  goToPage(inputPage.value)
  inputPage.value = null
}
</script>
<style lang="scss" scoped>
.pagination {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 4px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    min-width: 40px;
    height: 40px;
    font-weight: 700;
    cursor: pointer;
    border: 2px solid #C3CEDA;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;

    &.active {
      background-color: $color-active;
      border-color: $color-active;
      color: white;
    }

    &:hover,
    &:focus {
      border-color: $color-active;
    }
  }

  .input {
    margin-left: auto;
    display: flex;
    align-items: center;

    input {
      width: 70px;
      background-color: transparent;
    }

    span {
      cursor: pointer;
      margin-left: 4px;
    }
  }

  .points,
  .points:hover,
  .points:focus {
    border-color: transparent;
  }

  .wrapper-buttons {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 128px;

    .button-right {
      align-self: flex-end;
    }
  }
}
</style>
