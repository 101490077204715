<template>
  <div class="info-popup">
    <div class="info-popup__close" aria-label="Закрыть">
      <CloseButton @close="$emit('closePopup')" />
    </div>
    <div>
      <p class="info-popup__title">Открыть участок</p>
      <div
        class="info-popup__field"
        v-for="polygon in polygons"
        :key="polygon.hash"
        @mousemove="onMouseMove(polygon)"
        @mouseleave="onMouseLeave(polygon)"
      >
        <router-link v-if="mode !== 'create-field-section'" :to="{ name: 'mapDetails', params: { id: polygon.hash } }">
          {{ polygon.cadastralNumber }}
        </router-link>
        <span v-else @click="createFieldFromSection(polygon.hash)">{{ polygon.cadastralNumber }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useStore } from 'vuex'
import CloseButton from '@/components/uikit/buttons/CloseButton'

const store = useStore()

defineProps(['polygons'])
const emits = defineEmits(['showHover', 'hideHover', 'closePopup'])

const mode = computed(() => store.state.map.mode)

const onMouseMove = (polygon) => {
  emits('showHover', polygon)
}

const onMouseLeave = (polygon) => {
  emits('hideHover', polygon)
}

const createFieldFromSection = (hash) => {
  store.commit('fields/setSectionPolygonForField', hash)
}
</script>
<style lang="scss" scoped>
.info-popup {
  position: absolute;
  display: block;
  z-index: 100000;
  /*bottom: 0;*/
  /*right: 0;*/
  width: 224px;
  padding: 12px;
  background: #FFFFFF;
  border-radius: 4px;

  &__field:not(:last-child) {
    margin-bottom: 10px;
  }

  &__title {
    margin-bottom: 15px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
  }

}
</style>
