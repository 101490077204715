<template>
  <div class="create-org">
    <a @click="createOrg">Создать новую организацию</a>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'

const store = useStore()

const createOrg = () => {
  store.commit('modals/setIsShowCreateOrgModal', true)
}
</script>
<style lang="scss">
.create-org {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.create-org a {
  position: relative;
  margin-left: auto;
  color: $color-active;
  text-align: right;
  text-decoration: underline;
  vertical-align: center;
  line-height: 14px;

  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "+";
    position: absolute;
    left: -20px;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
    border: 1px solid $color-active;
    border-radius: 50%;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

</style>
