import { polygonColor } from '@/modules/map/layers/rosreestr'

const removeEditedLayer = (map) => {
  if (map.getSource('editLayer_data')) {
    map.removeLayer('editLayer_fill')
    map.removeLayer('editLayer_line')
    // map.removeLayer('editLayer_pattern')
    map.removeSource('editLayer_data')
  }
}

const addEditedLayer = (params) => {
  const source = {
    type: 'FeatureCollection',
    features: params.polygons.map((el) => {
      return {
        id: el.id,
        type: 'Feature',
        geometry: el.geometry,
        properties: el.properties,
      }
    })
  }

  // const source = {
  //   id: params.polygon.id,
  //   type: 'Feature',
  //   geometry: params.polygon.geometry,
  //   properties: params.polygon.properties,
  // }

  if (!params.map.getSource('editLayer_data')) {
    params.map.addSource('editLayer_data', {
      type: 'geojson',
      data: source
    })
  }

  // params.map.addLayer({
  //   id: 'editLayer_pattern',
  //   type: 'fill',
  //   source: 'editLayer_data',
  //   layout: {
  //     'visibility': 'none'
  //   },
  //   paint: {
  //     'fill-pattern': 'pattern-cell',
  //   },
  // })

  params.map.addLayer({
    id: 'editLayer_fill',
    type: 'fill',
    source: 'editLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'fill-color': ['case',
        ['boolean', ['feature-state', 'selected'], false], 'transparent',
        ['boolean', ['feature-state', 'active'], false], params.activeColor,
        ['==', ['get', 'name'], 'rosreestr'], polygonColor.rosreestr,
        ['==', ['get', 'name'], 'kugi'], polygonColor.kugi,
        ['==', ['get', 'name'], 'kumi'], polygonColor.kumi,
        ['==', ['get', 'name'], 'pais'], polygonColor.pais,
        ['==', ['get', 'name'], 'federal'], polygonColor.federal,
        ['==', ['get', 'name'], 'rosreestr2'], polygonColor.rosreestr2,
        params.color
      ],
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'active'], false], 0.7,
        ['boolean', ['feature-state', 'hover'], false], 0.4,
        ['boolean', ['feature-state', 'focus'], false], 1,
        0.2,
      ],
    },
  })

  params.map.addLayer({
    id: 'editLayer_line',
    type: 'line',
    source: 'editLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-color': ['case',
        ['boolean', ['feature-state', 'edited'], false], 'transparent',
        ['boolean', ['feature-state', 'selected'], false], params.activeColor,
        ['boolean', ['feature-state', 'focus'], false], params.activeColor,
        ['==', ['get', 'name'], 'rosreestr'], polygonColor.rosreestr,
        ['==', ['get', 'name'], 'kugi'], polygonColor.kugi,
        ['==', ['get', 'name'], 'kumi'], polygonColor.kumi,
        ['==', ['get', 'name'], 'pais'], polygonColor.pais,
        ['==', ['get', 'name'], 'federal'], polygonColor.federal,
        ['==', ['get', 'name'], 'rosreestr2'], polygonColor.rosreestr2,
        params.color
      ],
      'line-width': ['case',
        ['boolean', ['feature-state', 'focus'], false], 7,
        1
      ]
    },
  })
}

export { addEditedLayer, removeEditedLayer }
