<template>
  <div class="rosreestr">
    <q-form ref="formRef">
      <div class="rosreestr__field" data-testid="Статус">
        <span class="label-select">Статус</span>
        <q-select
          v-model="form.solutionStatus"
          :options="statuses"
          :rules="[val => !!val || val === 0 || 'Обязательное поле']"
          outlined
          emit-value
          map-options
          dense
        />
      </div>

      <div class="rosreestr__field rosreestr__field--datepicker" data-testid="Дата рассмотрения">
        <span class="label-select">Дата рассмотрения</span>
        <FlatpickrComponent
          :maxDate="new Date()"
          :dateInit="currentScheme.form.solutionDate"
          dense
          @update="(value) => date = value"
        />
        <div v-if="isErrorDate" class="text-negative error">Обязательное поле</div>
      </div>

      <div class="rosreestr__field" data-testid="Номер результата рассмотрения">
        <span class="label-select">Номер результата рассмотрения</span>
        <q-input
          v-model="form.solutionNumber"
          :rules="[val => !!val || 'Обязательное поле']"
          placeholder="Номер результата рассмотрения"
          outlined
          dense
        />
      </div>
    </q-form>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, defineExpose, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import FlatpickrComponent from '@/components/common/datepicker/Flatpickr'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const props = defineProps({
  currentScheme: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['close'])

const formRef = ref()
const date = ref(null)
const isErrorDate = ref(null)

const form = reactive({
  id: '',
  regionId: '',
  solutionNumber: null,
  solutionDate: null,
  solutionStatus: null
})

const statuses = computed(() => store.state.schemes.statuses)

const updateScheme = () => {
  if (!date.value) {
    isErrorDate.value = true
  }

  const formEl = formRef.value

  formEl.validate().then((result) => {
    if (result && !isErrorDate.value) {
      store.commit('modals/setDisableModal', true)

      ApiService.schemes.updateSolutionScheme(form)
        .then(() => {
          store.dispatch('schemes/fetchScheme', form.id)
          emits('close')
          store.commit('modals/setDisableModal', false)
          $q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Успешно',
            timeout: 5000
          })
        })
        .catch((error) => {
          store.commit('modals/setDisableModal', false)

          const errorText = error?.response?.data?.message
          $q.notify({
            type: 'negative',
            position: 'bottom',
            message: errorText ? errorText : 'Ошибка',
            timeout: 5000
          })
        })
    }
  })
}

onMounted(() => {
  date.value = props.currentScheme?.form?.solutionDate ? new Date(props.currentScheme.form.solutionDate) : null

  form.solutionNumber = props.currentScheme.form.solutionNumber
  form.solutionStatus = props.currentScheme?.form?.solutionStatus
  form.solutionDate = props.date ? new Intl.DateTimeFormat('sv-SE').format(date.value) : null,
  form.id = props.currentScheme.form.id
})

watch(date, (value) => {
  if (value) {
    form.solutionDate = new Intl.DateTimeFormat('sv-SE').format(new Date(value))
    isErrorDate.value = false
  } else {
    isErrorDate.value = true
  }
})

watch(statuses, () => {
  form.solutionStatus = props.currentScheme?.form?.solutionStatus
})

defineExpose({ updateScheme })
</script>

<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 250px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    position: relative;
    display: block;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__field--datepicker:not(:last-child) {
    margin-bottom: 25px;
  }

  &__section {

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .input {
      width: 100%;
    }
  }

  .error {
    position: absolute;
    font-size: 11px;
    padding-left: 12px;
    padding-top: 8px;
  }
}

</style>
