// eslint-disable-next-line
import Vue, { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import Paragraph from '@/components/uikit/typography/Paragraph'
import Heading from '@/components/uikit/typography/Heading'
import { accessDirective } from '@/directives/access'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

Vue.configureCompat({
  WATCH_ARRAY: false,
  COMPONENT_V_MODEL: false,
  INSTANCE_ATTRS_CLASS_STYLE: false
})

const app = createApp(App)
app.use(Quasar, quasarUserOptions)
app.use(store)
app.use(router)
// eslint-disable-next-line
app.component('Paragraph', Paragraph)
// eslint-disable-next-line
app.component('Heading', Heading)

app.config.globalProperties.$http = Axios
app.directive('access', accessDirective)


// Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: 'https://b044a90f20b34366a5b139f4da0e46b6@sentry.eltc.ru/185',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.VUE_APP_MODE
})

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
app.mount('#app')
