<template>
  <Modal
    v-if="isShowCreateOrgModal"
    title="Создание новой организации"
    showClose
    @closeModal="closeCreateOrgModal"
    @cancelClick="closeCreateOrgModal"
    @confirmClick="$refs.createOrgForm.sendForm()"
  >
    <OrgForm
      ref="createOrgForm"
      @close="closeCreateOrgModal"
      @createOrg="createOrg"
    />
  </Modal>

  <Modal
    v-if="isShowChangeOrgModal"
    title="Редактирование организации"
    showClose
    @closeModal="closeChangeOrgModal"
    @cancelClick="closeChangeOrgModal"
    @confirmClick="$refs.changeOrgForm.sendForm()"
  >
    <OrgForm
      ref="changeOrgForm"
      @close="closeChangeOrgModal"
      @editOrg="editOrg"
      :editingOrg="editingOrg"
    />
  </Modal>

  <Modal
    v-if="isShowDeleteOrgModal"
    title="Удаление организации"
    showClose
    @closeModal="closeDeleteOrgModal"
    @cancelClick="closeDeleteOrgModal"
    @confirmClick="deleteOrg"
  >
    Вы уверены, что хотите удалить организацию?
  </Modal>

  <Modal
    v-if="isShowLinkOrgModal"
    title="Замена организации"
    showClose
    @closeModal="closeLinkOrgModal"
    @cancelClick="closeLinkOrgModal"
    @confirmClick="$refs.linkOrgForm.sendForm()"
  >
    <LinkOrg
      ref="linkOrgForm"
      @linkOrg="linkOrg"
    />
  </Modal>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import Modal from '@/components/common/modals/Modal'
import OrgForm from './forms/orgs/OrgForm'
import LinkOrg from './forms/orgs/LinkOrg'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const isShowCreateOrgModal = computed(() => store.state.modals.isShowCreateOrgModal)
const isShowChangeOrgModal = computed(() => store.state.modals.isShowChangeOrgModal)
const isShowDeleteOrgModal = computed(() => store.state.modals.isShowDeleteOrgModal)
const isShowLinkOrgModal = computed(() => store.state.modals.isShowLinkOrgModal)
const editingOrg = computed(() => store.state.orgs.editingOrg)
const deletingOrg = computed(() => store.state.orgs.deletingOrg)
const linkingOrg = computed(() => store.state.orgs.linkingOrg)

const closeCreateOrgModal = () => {
  store.commit('modals/setIsShowCreateOrgModal', false)
}

const closeChangeOrgModal = () => {
  store.commit('modals/setIsShowChangeOrgModal', false)
}

const closeDeleteOrgModal = () => {
  store.commit('modals/setIsShowDeleteOrgModal', false)
}

const closeLinkOrgModal = () => {
  store.commit('modals/setIsShowLinkOrgModal', false)
}

const deleteOrg = () => {
  ApiService.org.deleteOrg(deletingOrg.value)
    .then(() => {
      store.commit('modals/setDisableModal', false)
      showSuccessMessage()
      closeLinkOrgModal()
      store.commit('orgs/setDeletingOrg', null)
      closeDeleteOrgModal()
      store.commit('orgs/setChangeCount')
    })
    .catch((error) => {
      store.commit('modals/setDisableModal', false)
      showErrorMessage(error)
    })
}

const showSuccessMessage = () => {
  $q.notify({
    type: 'positive',
    position: 'bottom',
    message: 'Успешно',
    timeout: 5000
  })
}

const showErrorMessage = (error) => {
  const errorText = error?.response?.data?.message
  $q.notify({
    type: 'negative',
    position: 'bottom',
    message: errorText ? errorText : 'Ошибка',
    timeout: 5000
  })
}

const createOrg = (org) => {
  store.commit('modals/setDisableModal', true)

  ApiService.org.createOrg(org)
    .then(() => {
      store.commit('modals/setDisableModal', false)
      showSuccessMessage()
      store.commit('modals/setIsShowCreateOrgModal', false)
      store.commit('orgs/setChangeCount')
    })
    .catch((error) => {
      showErrorMessage(error)
      store.dispatch('auth/fetchUser')
      store.commit('modals/setDisableModal', false)
    })
}

const editOrg = (data) => {
  ApiService.org.changeOrg(editingOrg.value.id, data)
    .then(() => {
      store.commit('modals/setDisableModal', false)
      showSuccessMessage()
      store.commit('modals/setIsShowChangeOrgModal', false)
      store.commit('orgs/setEditingOrg', null)
      store.commit('orgs/setChangeCount')
    })
    .catch((error) => {
      store.commit('modals/setDisableModal', false)
      showErrorMessage(error)
    })
}

const linkOrg = (org) => {
  ApiService.org.replaceOrg(linkingOrg.value, { replaceWithId: org })
    .then(() => {
      store.commit('modals/setDisableModal', false)
      showSuccessMessage()
      closeLinkOrgModal()
      store.commit('orgs/setLinkingOrg', null)
      store.commit('orgs/setChangeCount')
    })
    .catch((error) => {
      store.commit('modals/setDisableModal', false)
      showErrorMessage(error)
    })
}
</script>
