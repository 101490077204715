<template>
  <div class="data" :class="{attention: isEmptyData}">
    <span :title="isEmptyData ? 'Необходимо заполнить атрибут' : ''">{{ truncateText(value) }}&nbsp;</span>
    <AttentionIcon
      v-if='isEmptyData'
      :width="12"
      :height="12"
      class="data__icon"
    />
    <button class="data__edit"
      v-access="access"
      type="button"
      :title="title"
      @click="$emit('openModal')"
    >
      <EditIcon :width="12" :height="12" />
    </button>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import AttentionIcon from '@/modules/map/components/icons/AttentionIcon'
import EditIcon from '@/modules/map/components/icons/EditIcon'

const props = defineProps({
  value: {
    type: String,
    required: true,
    default: ''
  },
  title: {
    type: String,
    required: true,
    default: 'Редактировать'
  },
  access: {
    type: String,
    required: true,
    default: ''
  }
})

const emits = defineEmits(['openModal'])

const truncateText = (text) => {
  if (!text) {
    return ''
  }

  if (text.length > 50) {
    return `${text.slice(0, 50)}...`
  }

  return text
}

const checkEmptyData = (value) => {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'нет данных'
      || value.toLowerCase() === 'участок не проверен'
      || value.toLowerCase() === 'данные не верны'
      || value.toLowerCase() === 'организация не указана'
  }
}

const isEmptyData = computed(() => checkEmptyData(props.value))

</script>
<style lang="scss" scoped>
.data {
  &__edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    opacity: 0;
    width: 18px;
    top: 0;
    right: 0;
    bottom: 0;
    // left: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    fill: $color-main;
  }

  &__icon {
    position: relative;
    z-index: 0;
    top: 2px;
    fill: $color-active;
  }

  &:hover .data__edit {
    opacity: 1;
  }
}
</style>
