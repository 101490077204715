<template>
  <q-form ref="formRef">
    <span class="label-select">Широта (N)</span>
    <div class="field field--validate">
      <div class="input">
        <span class="label-select">Градусы</span>
        <q-input
          outlined
          v-model="lat.degree"
          placeholder="Градусы"
          no-error-icon
          :rules="[val => !!val || val === 0 || 'Обязательное поле', val => val >= 52 || 'Меньше 52', val => val <= 57 || 'Больше 57']"
          dense
        />
      </div>
      <div class="input">
        <span class="label-select">Минуты</span>
        <q-input
          outlined
          v-model="lat.minute"
          placeholder="Минуты"
          no-error-icon
          :rules="[val => !!val || val === 0 || 'Обязательное поле', val => val >= 0 || 'Меньше 0', val => val <= 59 || 'Больше 59']"
          dense
        />
      </div>
      <div class="input">
        <span class="label-select">Секунды</span>
        <q-input
          outlined
          v-model="lat.second"
          placeholder="Секунды"
          no-error-icon
          :rules="[val => !!val || val === 0 || 'Обязательное поле', val => val >= 0 || 'Меньше 0', val => val <= 59 || 'Больше 59']"
          dense
        />
      </div>
    </div>
    <span class="label-select">Долгота (E)</span>
    <div class="field field--validate">
      <div class="input">
        <span class="label-select">Градусы</span>
        <q-input
          outlined
          v-model="lng.degree"
          placeholder="Градусы"
          no-error-icon
          :rules="[val => !!val || val === 0 || 'Обязательное поле', val => val >= 84 || 'Меньше 84', val => val <= 90 || 'Больше 90']"
          dense
        />
      </div>
      <div class="input">
        <span class="label-select">Минуты</span>
        <q-input
          outlined
          v-model="lng.minute"
          placeholder="Минуты"
          no-error-icon
          :rules="[val => !!val || val === 0 || 'Обязательное поле', val => val >= 0 || 'Меньше 0', val => val <= 59 || 'Больше 59']"
          dense
        />
      </div>
      <div class="input">
        <span class="label-select">Секунды</span>
        <q-input
          outlined
          v-model="lng.second"
          placeholder="Секунды"
          no-error-icon
          :rules="[val => !!val || val === 0 || 'Обязательное поле', val => val >= 0 || 'Меньше 0', val => val <= 59 || 'Больше 59']"
          dense
        />
      </div>
    </div>
    <div class="buttons">
      <Button
        class="button"
        type="stroke"
        text="Искать по координатам"
        @click="searchPoint"
      />
      <span @click="resetSearch">Сбросить</span>
    </div>
  </q-form>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import Button from '@/components/uikit/buttons/Button'

const store = useStore()

const formRef = ref()

const lat = ref({
  degree: '',
  minute: '',
  second: ''
})

const lng = ref({
  degree: '',
  minute: '',
  second: ''
})

const coordY = computed(() => Number(lat.value.degree) + Number(lat.value.minute)/60 + Number(lat.value.second)/3600)
const coordX = computed(() => Number(lng.value.degree) + Number(lng.value.minute)/60 + Number(lng.value.second)/3600)

const resetSearch = () => {
  lat.value = { degree: '', minute: '', second: '' }
  lng.value = { degree: '', minute: '', second: '' }
  formRef.value.reset()
  store.commit('map/setSearchingPoint', null)
}

const searchPoint = () => {
  formRef.value.validate()
    .then((result) => {
      if (result) {
        store.commit('map/setSearchingPoint', [coordX.value, coordY.value])
      }
    })
}
</script>
<style lang='scss' scoped>
.field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;

  &--validate:not(:last-child) {
    margin-bottom: 0;
  }

  &--validate:has(.q-field--error) {
    margin-bottom: 15px;
  }
}

.input {
  display: block;
  width: calc(33% - 6px);
}

.buttons {
  display: flex;
  align-items: center;

  .button {
    font-size: 13px;
  }

  span {
    margin-left: 10px;
    font-size: 13px;
    cursor: pointer;
    color: $color-active;
  }
}
</style>
