<template>
  <div class="rosreestr" data-testid="form">
    <q-form ref="formRef">
      <div class="rosreestr__field native-input" data-testid="region">
        <Select
          v-model="form.regionId"
          label="Муниципалитет"
          :options="regions"
          :rules="[val => !!val || 'Обязательное поле']"
          :use-input="true"
        />
      </div>
      <div class="rosreestr__field" data-testid="ownership">
        <Select
          v-model="form.landOwnershipId"
          label="Категория прав собственности"
          :options="landOwnerships"
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>
      <div class="rosreestr__field" data-testid="landTypeId">
        <Select
          v-model="form.landTypeId"
          label="Категория угодий"
          :options="landTypes"
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>
      <div class="rosreestr__field" data-testid="useCategoryId">
        <Select
          v-model="form.kugiUseCategoryId"
          label="Категория пользования"
          :options="useCategories"
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>
    </q-form>
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted, defineExpose } from 'vue'
import { useStore } from 'vuex'
import Select from '@/components/common/selects/Select'

const store = useStore()

const formRef = ref()
const form = reactive({
  regionId: null,
  landOwnershipId: null,
  landTypeId: null,
  kugiUseCategoryId: null
})

const regions = computed(() => store.state.dictionaries.regions)
const user = computed(() => store.state.auth.user)
const landOwnerships = computed(() => store.state.dictionaries.landOwnerships.filter((category) => !category.isHidden))
const landTypes = computed(() => store.state.dictionaries.landTypes.filter((category) => !category.isHidden))
const useCategories = computed(() => store.state.dictionaries.kugiUseCategories.filter((category) => !category.isHidden))

const sendForm = () => {
  const formEl = formRef.value
  formEl.validate().then((result) => {
    if (result) {
      store.commit('map/setErrorKn', '')
      store.commit('map/setIsCreateSection', true)
      store.commit('map/setCreatingSection', form)
    }
  })
}

onMounted(() => {
  if (user.value && user.value?.region) {
    form.regionId = user.value.region?.value
  }
})

defineExpose({ sendForm })
</script>
<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 250px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    display: block;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__section {
    .input {
      width: 100%;
    }
  }

  .error {
    margin-top: -10px;
    // border-color: $color-active;
    color: $color-active;
  }
}
</style>
