const BASE_URL = '/private/overlaps'
const BASE_URL_V2 = '/v2/private'

class OverlapsService {
  constructor(api) {
    this.api = api
  }

  getOverlaps(params) {
    return this.api.get(BASE_URL, { params })
  }

  // ApiService.map
  // checkOverlaps(hash) {
  //   return this.api.get(`/sections/${hash}/overlaps`)
  // }

  deleteOverlaps(overlaps) {
    return this.api.post(`${BASE_URL}/delete`, { data: overlaps })
  }
}

export { OverlapsService }
