<template>
  <div class="container">
    <div class="table" ref="tableRef" data-testid="filter">
      <q-scroll-area
        :style="style"
        :vertical-thumb-style="{ width: '5px', opacity: '0.5' }"
        :horizontal-thumb-style="{ height: '5px', opacity: '0.5' }"
      >
        <table>
          <thead>
            <tr>
              <th>Статус проверки</th>
              <th>Номер поля</th>
              <th>Площадь</th>
              <th>Муниципалитет</th>
              <th>Фактическая эксплуатация</th>
              <th>Сложность ввода в эксплуатацию</th>
              <th>Сведения о состоянии неиспользуемых земель</th>
              <th>Год ввода в оборот</th>
              <th>Фактический пользователь</th>
              <th>Вид с/х угодий</th>
              <th>Вид культуры</th>
              <th>ОКПД 2</th>
              <th>Тип севооборота</th>
              <th>Урожайность</th>
              <th>Валовый сбор</th>
              <th>Вид итоговой продукции</th>
              <th>Удален</th>
              <th>Примечание</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="area in areas.data" :key="area.id">
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Статус проверки')"
                  :access="'none'"
                  title="Редактировать статус проверки"
                  @openModal="() => {}"
                />
              </td>
              <td @click="setField(area.id)">
                <router-link :to="`/map`" target="_blank">{{area.id}}</router-link>
              </td>
              <td>{{ findField(area.props[0]?.common, "Площадь") }}</td>
              <td>{{ findField(area.common, "Муниципалитет") }}</td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Фактическая эксплуатация')"
                  :access="'fields_edit'"
                  title="Редактировать атрибут"
                  @openModal="editAttributesField(area)"
                />
              </td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Сложность ввода в эксплуатацию')"
                  :access="'fields_edit'"
                  title="Редактировать атрибут"
                  @openModal="editAttributesField(area)"
                />
              </td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Сведения о состоянии неиспользуемых земель')"
                  :access="'fields_edit'"
                  title="Редактировать атрибут"
                  @openModal="editAttributesField(area)"
                />
              </td>
              <td>
                <TableData
                  :value="area.operationYear"
                  :access="'fields_edit'"
                  title="Редактировать атрибут"
                  @openModal="editField(area)"
                />
              </td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Фактический пользователь')"
                  :access="'fields_edit'"
                  title="Редактировать атрибут"
                  @openModal="editAttributesField(area)"
                />
              </td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Вид с/х угодий')"
                  :access="'fields_edit'"
                  title="Редактировать атрибут"
                  @openModal="editAttributesField(area)"
                />
              </td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Вид культур')"
                  :access="'fields_edit'"
                  title="Редактировать атрибут"
                  @openModal="editAttributesField(area)"
                />
              </td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'ОКПД 2')"
                  :access="'fields_edit'"
                  title="Редактировать атрибут"
                  @openModal="editAttributesField(area)"
                />
              </td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Тип севооборота')"
                  :access="'fields_edit'"
                  title="Редактировать атрибут"
                  @openModal="editAttributesField(area)"
                />
              </td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Урожайность')"
                  :access="'none'"
                  title="Редактировать атрибут"
                  @openModal="() => {}"
                />
              </td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Валовый сбор урожая')"
                  :access="'none'"
                  title="Редактировать атрибут"
                  @openModal="() => {}"
                />
              </td>
              <td>
                <TableData
                  :value="findField(area.props[0]?.common, 'Вид итоговой продукции')"
                  :access="'fields_edit'"
                  title="Редактировать атрибут"
                  @openModal="editAttributesField(area)"
                />
              </td>
              <td>{{ findField(area.props[0]?.common, "Удален") ?? '' }}</td>
              <td>{{ truncateText(findField(area.props[0]?.common, "Примечание")) }}</td>
            </tr>
          </tbody>
        </table>
      </q-scroll-area>
    </div>
    <Pagination
      v-if="areas.meta && areas.meta.lastPage && areas.meta.lastPage > 1"
      :last="areas.meta.lastPage"
      :currentPage="page"
      @goToPage="nextPage"
      class="table__pagination"
    />
  </div>
</template>
<script setup>
import { defineProps, ref, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Pagination from '@/components/common/pagination/Pagination'
import TableData from '@/modules/map/components/tables/TableData'

const props = defineProps(['areas'])
const route = useRoute()
const router = useRouter()
const store = useStore()

const page = ref(1)
const style = ref('')
const tableRef = ref()

const appliedSectionsFilter = computed(() => store.state.mapFilter.filter)
const appliedFieldsFilter = computed(() => store.state.mapFilter.fieldsFilter)

const truncateText = (text) => {
  if (!text) {
    return ''
  }

  if (text.length > 50) {
    return `${text.slice(0, 50)}...`
  }

  return text
}

const setField = (id) => {
  const field = {
    id,
    filter: {
      sections: appliedSectionsFilter.value,
      fields: appliedFieldsFilter.value
    }
  }
  localStorage.setItem('field', JSON.stringify(field))
}

const nextPage = (nextPage) => {
  document.querySelector('.table').scrollTo(0, 0)
  page.value = nextPage
  router.push({ to: 'map', query: { ...route.query, page: nextPage }, hash: '#table' }).catch(() => {})
}

const findField = (array, name) => {
  const value = array?.length > 0 && array?.find((field) => field.name === name)?.value
    ? array.find((field) => field.name === name).value
    : '-'
  if (Array.isArray(value)) {
    return value.join(', ')
  }
  return value
}

const editField = (field) => {
  store.commit('fields/setEditingField', null)
  store.commit('fields/setEditingAttributesField', { ...field, periodId: null })
  store.commit('modals/setIsShowEditAttributesFieldModal', true)
}

const editAttributesField = (field) => {
  store.commit('fields/setEditingField', null)
  store.commit('fields/setEditingAttributesField', { ...field, periodId: field.props[0]?.form?.periodId })
  store.commit('modals/setIsShowEditAttributesFieldModal', true)
}

watch(() => route.query.page, (value) => {
  page.value = Number(value)
})

onMounted(() => {
  style.value = `width: ${tableRef.value.clientWidth}px; height: ${tableRef.value.clientHeight}px`
  page.value = Number(route.query.page)
})
</script>
<style lang="scss" scoped>
.container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.table {
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  top: 60px;
  left: 0;
  bottom: 60px;
  right: 0;
  // width: 100%;
  // height: calc(100% - 120px);
  // margin-top: 60px;
  padding-bottom: 7px;
  padding-right: 7px;
  // overflow: auto;

  &__pagination {
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 5px 25px;
  }

  &__icon {
    position: relative;
    z-index: 0;
    top: 2px;
    fill: $color-active;
  }

  table {
    border-collapse: collapse;
    // width: 100%;
    font-size: 12px;
  }

  thead td,
  thead th {
    background-color: $color-gray2;
  }

  thead {
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: $color-gray1;
  }

  tbody {
    background-color: #fff;
  }

  th, td {
    position: relative;
    box-sizing: border-box;
    min-width: 114px;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
  }

  td {
    border-bottom: 1px solid $color-gray2;

    &:hover {
      color: $color-active;
      opacity: 0.8;
    }
  }

  .table__head td {
    position: relative;
    top: 1px;
    height: 60px;
    background-color: #fff;
  }
}
</style>
