<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path class="close-icon" d="M7 7L17 17" stroke="#2F3E4D" stroke-width="2" />
    <path class="close-icon" d="M17.0005 7L7.00049 17" stroke="#2F3E4D" stroke-width="2" />
  </svg>
</template>

<script setup>
</script>
