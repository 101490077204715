import ApiService from '@/services'

const actions = {
  fetchRegisters({ commit }, params) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      ApiService.register.getRegisters(params)
        .then((response) => {
          commit('setRegisters', response.data)
          resolve(response.data)
          commit('setLoading', false)
        })
        .catch((error) => {
          reject(error)
          commit('setLoading', false)
        })
    })
  },
  fetchUsers({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.register.getUsers()
        .then((response) => {
          commit('setUsers', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  setRegisters(state, data){
    state.registers = data
  },
  setLoading(state, data){
    state.isLoading = data
  },
  setUsers(state, data){
    state.users = data
  },
}

const state = () => ({
  registers: [],
  isLoading: false,
  users: []
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
