export default [
  {
    name: 'sh_kugi',
    // label: 'Схемы ЗУ КУГИ',
    // value: 'sh_kugi_fill',
    options: {
      type: 'vector',
      tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/sh_kugi/{z}/{x}/{y}.mvt']
    },
    layers: [{
      id: 'sh_kugi_fill',
      type: 'fill',
      source: 'sh_kugi',
      'source-layer': 'sh_kugi',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-color': ['case',
          ['boolean', ['feature-state', 'active'], false], '#dc1c0a',
          ['boolean', ['feature-state', 'edited'], false], 'transparent',
          '#98ff98' // #ff00a6
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'active'], false], 0.7,
          ['boolean', ['feature-state', 'hover'], false], 0.5,
          0.4,
        ],
      },
    },
    {
      id: 'sh_kugi_line',
      type: 'line',
      source: 'sh_kugi',
      'source-layer': 'sh_kugi',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'line-width':  ['case',
          ['boolean', ['feature-state', 'active'], false], 2,
          1
        ],
        'line-color': ['case',
          ['boolean', ['feature-state', 'active'], false], '#dc1c0a',
          ['boolean', ['feature-state', 'edited'], false], 'transparent',
          '#98ff98' // #8c095b
        ],
      },
    }]
  }
]
