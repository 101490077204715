<template>
<q-form ref="sections">
  <div v-access="'section_filter_area'" aria-label="filter-area" class="label label-select">
    Площадь участка, Га.
  </div>
  <div v-access="'section_filter_area'" class="filter__section filter__section--validate filter__section_flex">
    <div class="input">
      <span class="label-select">от</span>
      <q-input
        v-model.number="filter.areaMin"
        type="number"
        outlined
        placeholder="от"
        :rules="[val => val >= 0 || 'Меньше 0']"
        dense
      />
    </div>
    <div class="input">
      <span class="label-select">до</span>
      <q-input
        v-model.number="filter.areaMax"
        type="number"
        outlined
        placeholder="до"
        :rules="[val => val >= 0 || 'Меньше 0', val => !val || val >= filter.areaMin || 'Меньше от']"
        dense
      />
    </div>
  </div>
  <div v-access="'section_filter_cadastral_number'" class="filter__section">
    <div class="input input-search">
      <span class="label-select">Поиск по номеру</span>
      <q-input
        v-model="filter.cadastralNumber"
        type="text"
        outlined
        placeholder="Поиск по номеру"
        dense
      />
    </div>
  </div>
  <div v-access="'section_filter_region_id'" class="filter__section">
    <Select
      v-model="groupRegion"
      label="Группировка по виду муниципалитета"
      :options="optionsGroupMunicipality"
    />
  </div>
  <div  v-if="municipalities.length > 0" v-access="'section_filter_region_id'" class="filter__section native-input">
    <Select
      label="Муниципалитет"
      v-model="filter.regionId"
      :options="sortOptions(municipalities, filter.regionId)"
      :isSort="true"
      :clearable="true"
      :allable="true"
      :multiple="true"
      :use-input="true"
    />
  </div>
  <div v-access="'section_filter_layers'" class="filter__section">
    <Select
      v-model="accounted"
      label="Учтенные/неучтенные"
      :options="accountOptions"
      :clearable="true"
    />
  </div>
  <div v-if="optionsOwnershipCategory.length" v-access="'section_filter_land_ownership_ids'" class="filter__section">
    <div class="tooltip-label">
      Категория прав собственности
      <TooltipModal
        :id="clarification.ownershipCategory.id"
        :isOpen="openedTooltip === clarification.ownershipCategory.id"
        :content="clarification.ownershipCategory.content"
        :isModal="false"
        @setOpenedTooltip="openTooltip"
      />
    </div>
    <div class="relative">
      <Select
        v-model="filter.landOwnershipIds"
        label=""
        :options="sortOptions(optionsOwnershipCategory, filter.landOwnershipIds)"
        :isSort="true"
        :clearable="true"
        :allable="true"
        :multiple="true"
      />
    </div>
  </div>
  <div v-if="optionsKugiUseCategories.length" v-access="'section_filter_kugi_use_category_ids'" class="filter__section">
    <div class="tooltip-label">
      Категория пользования
      <TooltipModal
        :id="clarification.useCategory.id"
        :isOpen="openedTooltip === clarification.useCategory.id"
        :content="clarification.useCategory.content"
        :isModal="false"
        @setOpenedTooltip="openTooltip"
      />
    </div>
    <div class="relative">
      <Select
        v-model="filter.kugiUseCategoryIds"
        label=""
        :options="sortOptions(optionsKugiUseCategories, filter.kugiUseCategoryIds)"
        :isSort="true"
        :clearable="true"
        :allable="true"
        :multiple="true"
      />
    </div>
  </div>
  <div v-if="user.permissions?.includes('orgs_read')"  v-access="'section_filter_owners'" class="filter__section native-input">
    <div class="tooltip-label">
      Правообладатель
      <TooltipModal
        :id="clarification.owner.id"
        :isOpen="openedTooltip === clarification.owner.id"
        :content="clarification.owner.content"
        :isModal="false"
        @setOpenedTooltip="openTooltip"
      />
    </div>
    <div class="relative">
      <OrgsSelect
        v-model="filter.owners"
        :clearable="true"
        :multiple="true"
        :selectedOrgs="filter.owners"
      />
      <q-badge v-if="filter.owners.length > 1" color="accent" floating rounded>
        {{filter.owners.length}}
      </q-badge>
    </div>
  </div>
  <div v-if="optionsLandType.length" v-access="'section_filter_land_type_ids'" class="filter__section">
    <Select
      v-model="filter.landTypeIds"
      label="Категория угодий"
      :options="sortOptions(optionsLandType, filter.landTypeIds)"
      :isSort="true"
      :clearable="true"
      :allable="true"
      :multiple="true"
    />
  </div>
  <div v-access="'section_filter_especially'" class="filter__section">
    <Select
      v-model="filter.especially"
      label="Особо ценные угодья"
      :options="especiallyTypes"
      dense
    />
  </div>
  <div v-access="'section_filter_land_permitted_use_type'" class="filter__section">
    <Select
      v-model="filter.landPermittedUseType"
      label="Вид разрешенного использования"
      :options="landPermittedUseTypes"
      :use-input="true"
      :scroll-size="200"
      :clearable="true"
    />
  </div>
  <div v-access="'section_filter_check_status_id'" class="filter__section">
    <Select
      v-model="filter.checkStatusId"
      label="Статус проверки"
      :options="optionsCheckStatus"
      :clearable="true"
    />
  </div>
  <div v-access="'section_filter_check_status_inner_id'" class="filter__section">
    <Select
      v-model="filter.checkStatusInnerId"
      label="Статус внутренней проверки"
      :options="optionsCheckInnerStatus"
      :clearable="true"
    />
  </div>
  <div v-access="'section_filter_has_overlap'" class="filter__section">
    <Select
      v-model="filter.hasOverlap"
      label="Пересечения"
      :options="overlapStatus"
    />
  </div>
  <div v-access="'section_filter_has_overlaps_pkk_id'" class="filter__section">
    <Select
      v-model="filter.hasOverlapsPkkId"
      label="Имеет пересечения на ПКК"
      :options="hasOverlapsPkkStatus"
      :clearable="true"
    />
  </div>
  <div v-access="'section_filter_rr_update_statuses'" class="filter__section">
    <Select
      v-model="filter.rrUpdateStatuses"
      label="Статус обновления данных из Росреестра"
      :options="rrUpdateStatuses"
      :clearable="true"
      :multiple="true"
    />
  </div>
  <div v-access="'section_filter_land_survey'" class="filter__section">
    <Select
      v-model="filter.landSurvey"
      label="Статус границ"
      :options="landSurveyTypes"
    />
  </div>
  <div v-access="'section_filter_deleted'" class="filter__section">
    <Select
      v-model="filter.deleted"
      label="Удаленные/неудаленные"
      :options="deletedStatus"
    />
  </div>
  <!-- <div class="filter__section">
    <Select
      v-model="filter.fieldsAvailableStatus"
      label="Наличие полей"
      :options="fieldAvailableStatus"
      :clearable="true"
    />
  </div> -->
</q-form>
</template>
<script setup>
import { defineEmits, defineProps, defineExpose, watch, ref, reactive, computed, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useQuasar } from 'quasar'
import { isEqual, cloneDeep } from 'lodash'
import TooltipModal from '@/components/common/tooltips/TooltipModal'
import Select from '@/components/common/selects/Select'
import OrgsSelect from '@/components/common/selects/OrgsSelect'
import { clarification } from '@/utils/clarification.js'
import { formatNumber } from '@/utils/format-number.js'
import { paramsToFilter } from '@/modules/map/utils/filter/params-to-filter'
import { filterToParams } from '@/modules/map/utils/filter/filter-to-params'

const store = useStore()
const router = useRouter()
const route = useRoute()
const $q = useQuasar()

const props = defineProps({
  filterType: {
    type: String, // ['user', 'map']
    required: true
  }
})
const emits = defineEmits(['setFilter', 'setIsLoad'])

const user = computed(() => store.state.auth.user)

const filterInitValues = {
  areaMin: '0',
  areaMax: null,
  layers: [],
  regionId: user.value.region?.value ? [Number(user.value.region.value)] : [],
  kugiUseCategoryIds: [],
  landTypeIds: [],
  landOwnershipIds: [],
  cadastralNumber: '',
  deleted: 'hide',
  checkStatusId: null,
  checkStatusInnerId: null,
  owners: [],
  hasOverlap: 'all',
  hasOverlapsPkkId: null,
  fieldsAvailableStatus: null,
  especially: null,
  rrUpdateStatuses: [],
  landSurvey: null,
  landPermittedUseType: null,
  page: 1
}

const filter = reactive(cloneDeep(filterInitValues))

const accounted = ref(null)
const accountOptions = [
  { value: 'accounted', label: 'Учтенные участки' },
  { value: 'unaccounted', label: 'Неучтенные участки' }
]

const groupRegion = ref({ label: 'Не выбрано', value: 'default' })
const optionsGroupMunicipality = [
  { label: 'Муниципальный округ', value: 'county' },
  { label: 'Городской округ', value: 'urban_country' },
  { label: 'Муниципальный район', value: 'district' },
  { label: 'Не выбрано', value: 'default' },
]

const openedTooltip = ref(null)

const deleteCount = computed(() => store.state.mapFilter.deleteCount)
const editCount = computed(() => store.state.mapFilter.editCount)
const view = computed(() => store.state.map.view)
const visibleEntity = computed(() => store.state.map.visibleEntity)
const optionsLayer = computed(() => store.state.dictionaries.layers)
const municipalities = computed(() => store.state.dictionaries.regions)
const optionsKugiUseCategories = computed(() => store.state.dictionaries.kugiUseCategories.filter((category) => !category.isHidden))
const optionsLandType = computed(() => store.state.dictionaries.landTypes.filter((category) => !category.isHidden))
const optionsOwnershipCategory = computed(() => store.state.dictionaries.landOwnerships.filter((category) => !category.isHidden))
const optionsCheckStatus = computed(() => store.state.dictionaries.checkStatuses)
const optionsCheckInnerStatus = computed(() => store.state.dictionaries.checkInnerStatuses)
const deletedStatus = computed(() => store.state.dictionaries.deletedStatus)
const overlapStatus = computed(() => store.state.dictionaries.overlapStatus)
const hasOverlapsPkkStatus = computed(() => store.state.dictionaries.hasOverlapsPkkStatus)
const rrUpdateStatuses = computed(() => store.state.dictionaries.rrUpdateStatuses)

const especiallyTypes = computed(() => store.state.dictionaries.especiallyTypes.concat([{ label: 'Все', value: null }]))
const landSurveyTypes = computed(() => store.state.dictionaries.landSurvey.concat([{ label: 'Все', value: null }]))
const orgsByIds = computed(() => store.state.orgs.orgsByIds?.filter((org) => !org.isHidden))
const landPermittedUseTypes = computed(() => store.state.dictionaries.landPermittedUseTypes)

const sortOptions = (options, filterValue) => options?.filter((item) => !item.value)
  .concat(options.filter((item) => filterValue.includes(item.value)))
  .concat(options.filter((item) => item.value && !filterValue.includes(item.value)))

const initAccounted = () => {
  if (filter?.layers?.length === 1 && filter.layers[0] === 'rosreestr') {
    accounted.value = 'accounted'
  } else if (filter?.layers?.length > 0 && !filter?.layers?.includes('rosreestr')) {
    accounted.value = 'unaccounted'
  } else {
    accounted.value = null
  }
}

const openTooltip = (id) => {
  openedTooltip.value = id
}

// const setRegionValue = () => {
//   filter.regionId = user.value.region?.value ? [Number(user.value.region.value)] : filter.regionId
// }

const setInitialFilter = () => {
  const values = cloneDeep(filterInitValues)
  Object.keys(values).forEach((key) => {
    filter[key] = values[key]
  })
  filter.regionId = user.value.region?.value ? [Number(user.value.region.value)] : []
}

const setLayers = () => {
  if (filter.layers.length === 0) {
    filter.layers = optionsLayer.value.map(layer => layer.value)
  }
  emits('setFilter', filter)
}

const applyPaginatedFilter = () => {
  const { sections } = getFilter()
  store.dispatch('mapFilter/fetchPaginatedSections', {
    ...sections,
    page: filter.page,
    searchType: 'sections',
    perPage: 20
  })
    .then((res) => {
      const lastPage = res.meta?.lastPage
      const currentPage = filter.page

      emits('setIsLoad', false)
      if (view.value === 'table' && Number.isInteger(lastPage) && Number.isInteger(currentPage) && (currentPage > lastPage)) {
        filter.page = 1
        router.push({ to: 'map', query: { ...route.query, page: 1 }, hash: '#table' }).catch(() => {})
        emits('setIsLoad', true)
      }
    })
    .catch((error) => {
      emits('setIsLoad', false)
      store.dispatch('auth/fetchUser')

      const errorText = error?.response?.data?.message
      $q.notify({
        type: 'negative',
        position: 'bottom',
        message: errorText ? errorText : 'Ошибка',
        timeout: 5000
      })
    })
}

const applyFilter = async (hash) => {
  store.dispatch('settings/fetchMaintenance')
  store.commit('map/setMapState', 'static')
  emits('setIsLoad', true)

  const { sections } = getFilter()
  store.commit('mapFilter/setFilter', sections)

  store.dispatch('mapFilter/fetchCadastralByFilter', {
    ...sections,
    type: props.filterType
  })
    .then(() => {
      emits('setIsLoad', false)
    })
    .catch((error) => {
      emits('setIsLoad', false)
      store.dispatch('auth/fetchUser')

      const errorText = error?.response?.data?.message
      $q.notify({
        type: 'negative',
        position: 'bottom',
        message: errorText ? errorText : 'Ошибка',
        timeout: 5000
      })
    })

  const urlObj = filterToParams(filter)

  if (urlObj.value) {
    delete urlObj.value
  }

  if (!isEqual(route.query, urlObj)) {
    router.push({ to: 'map', query: { ...urlObj }, hash }).catch(() => {})
  }

  sessionStorage.setItem('filter', JSON.stringify(urlObj))


  if ((hash === '#table' || view.value === 'table') && (visibleEntity.value === 'sections' )) {
    applyPaginatedFilter()
  }

  emits('setFilter')
  store.commit('mapFilter/setFilterCount')
}

const initFilter = async () => {
  const hash = route.hash

  if (route.params.id) {
    await router.push({ path: '/map', params: {}, hash }).catch(() => {})
  }

  applyFilter(hash)
}

const clearFilter = () => {
  // filterType.value = 'user'
  setInitialFilter()
  accounted.value = null
  groupRegion.value = { label: 'Не выбрано', value: 'default' }
  setLayers()
  initFilter()
}

const getFilter = () => {
  let sections = {}

  for (let key in filter) {
    if (Object.keys(filterInitValues).includes(key)) {
      if (Array.isArray(filter[key]) && filter[key].length > 0) {
        sections[key] = filter[key]
      } else if (!Array.isArray(filter[key]) && filter[key]) {
        sections[key] = key === 'areaMin' || key === 'areaMax' ? Number(filter[key]) : filter[key]
      } else if ((key === 'especially' || key === 'landSurvey') && typeof filter[key] === 'boolean') {
        sections[key] = filter[key]
      }
    }
  }

  if (!sections['areaMin']) {
    sections['areaMin'] = 0
  }

  return {
    sections
  }
}

watch(() => filter.owners, (value) => {
  const orgs = value.filter((org) => !orgsByIds.value.map((org) => org.id).includes(org))

  if (orgs?.flat().length > 0) {
    store.dispatch('orgs/fetchOrgs', orgs?.flat())
  }
})

watch(accounted, (value) => {
  if (optionsLayer.value.length) {
    if (value === 'accounted') {
      filter.layers = optionsLayer.value.filter((layer) => layer.value === 'rosreestr').map((layer) => layer.value)
    }

    if (value === 'unaccounted') {
      filter.layers = optionsLayer.value.filter((layer) => layer.value !== 'rosreestr').map((layer) => layer.value)
    }

    if (!value) {
      filter.layers = optionsLayer.value.map((layer) => layer.value)
    }
  }
})

watch(filter, () => {
  openedTooltip.value = null
}, { deep: true })

watch(() => filter.kugiUseCategoryIds, (value) => {
  if (value.includes(7) && window.location.href.includes('lk.sovhoz.tech/')) {
    window.ym(75689863,'reachGoal','map-filters-usecategory-zufreefromrights-choose')
  }
}, { deep: true })

watch(optionsLayer, (value, oldValue) => {
  if (value?.length && !oldValue.length) {
    if (filter.layers.length === 0) {
      filter.layers = value.map(layer => layer.value)
      initAccounted()
    }
    const hash = route.hash

    if (user.value?.name !== 'demo') {
      applyFilter(hash)
    }
  }
}, { deep: true })

watch(deleteCount, applyFilter)

watch(editCount, () => {
  const { sections } = getFilter()
  store.commit('mapFilter/setFilter', sections)
  const hash = route.hash
  applyFilter(hash)
})

watch(groupRegion, (value) => {
  if (!value || value === 'default') {
    filter.regionId = []
  } else {
    filter.regionId = municipalities.value
      .filter((municipality) => municipality.type === value)
      .map((municipality) => municipality.value)
  }
})

watch(view, (value) => {
  if (value === 'table' && (visibleEntity.value === 'sections')) {
    applyPaginatedFilter()
  }
})

watch(() => route.query.page, (value) => {
  const hash = route.hash

  filter.page = value && Number.isInteger(Number(value)) ? Number(value) : 1
  if (hash === '#table' || view.value === 'table') {
    applyPaginatedFilter()
  }
})

watch(() => route.hash, (value) => {
  if(value !== '#table') {
    store.commit('map/setView', 'map')
  }
})

onMounted(() => {
  if (optionsLayer.value.length < 1 && user.value?.name !== 'demo') {
    store.commit('mapFilter/setIsLoadingFilter', true)
  }

  const hash = route.hash
  if (sessionStorage.getItem('filter')) {
    const filterValues = JSON.parse(sessionStorage.getItem('filter'))

    Object.keys(filterValues).forEach((key) => {
      filter[key] = filterValues[key]
    })
  }

  const filledFilter = paramsToFilter(filter, route.query)

  Object.keys(filter).forEach((key) => {
    if (typeof filledFilter[key] !== 'undefined') {
      filter[key] = filledFilter[key]
    }
  })

  if (filter.layers === 'rosreestr') {
    filter.layers = ['rosreestr']
  }

  if (!filter.regionId || filter.regionId?.length === 0) {
    filter.regionId = user.value.region?.value ? [Number(user.value.region.value)] : []
  }

  if (filter.regionId && Array.isArray(filter.regionId) && filter.regionId.length > 0) {
    filter.regionId = filter.regionId
    // .filter((id) => id?.value)
    // .map((id) => Number(id.value))
      .map((id) => Number(id))
  }

  const page = route.query.page
  filter.page = page && Number.isInteger(Number(page)) ? Number(page) : 1

  if (optionsLayer.value?.length && user.value?.name !== 'demo') {
    applyFilter(hash)
  }

  // if (user.value.role !== 'apkmunicipalitet' && optionsLayer.value?.length && props.filterType === 'map') {
  //   applyFilter(hash)
  // }

  // if (user.value.role === 'apkmunicipalitet' && optionsLayer.value?.length && props.filterType === 'map') {
  //   applyFilter(hash)
  // }

  setLayers()
  initAccounted()

  const { sections } = getFilter()
  store.commit('mapFilter/setFilter', sections)

  if (view.value === 'table') {
    store.commit('mapFilter/setPaginatedSectionsByFilter', {})

    const currentPage = route.query.page
    if (currentPage !== '1') {
      filter.page = 1
      router.push({ to: 'map', query: { ...route.query, page: 1 }, hash: '#table' }).catch(() => {})
    } else {
      applyPaginatedFilter()
    }
  }
})

defineExpose({ applyFilter, clearFilter })
</script>
<style lang="scss" scoped>
.filter {
  width: 100%;
  max-width: 344px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__form {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    flex-grow: 1;
    position: relative;
    padding: 16px 30px 16px 30px;
    height: calc(100% - 40px - 59px);
    // height: 100%;

    overflow-y: auto;
    overflow-x: hidden;
  }

  // &__footer {
  //   // position: absolute;
  //   // bottom: 0;
  //   width: 100%;
  // }

  // &__info-container {
  //   display: flex;
  // }

  // &__info {
  //   border-top: 1px solid $color-gray3;
  //   // position: absolute;
  //   padding: 5px 25px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   min-height: 59px;
  //   // bottom: 40px;
  //   width: 100%;

  //   &--fields {
  //     width: 50%;
  //     flex-direction: column;
  //     padding: 10px;

  //     &:last-child {
  //       border-left: 1px solid $color-gray3;
  //     }
  //   }
  // }

  .tooltip-label {
    position: relative;
    display: inline-block;
    font-weight: 500;
  }

  &__section {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &--validate:not(:last-child) {
      margin-bottom: 0;
    }

    &--validate:has(.q-field--error) {
      margin-bottom: 10px;
    }

    &--checkbox {
      margin-left: -10px;
    }

    &_flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .input {
        width: calc(50% - 6px);
      }
    }
  }

  .button {
    width: 100%;
    border-radius: 0;
  }

  &__entity {
    position: relative;
    padding: 15px 0;

    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   left: -30px;
    //   right: -45px;
    //   height: 1px;
    //   background-color: $color-gray3;
    // }

    // &--coords::after {
    //   display: none;
    // }
  }

  &__toggle-entity {
    width: 100%;
    background-color: transparent;
    border: none;
    text-decoration: underline dotted;
    color: $color-active;
    cursor: pointer;
  }

  &__clear-filter {
    background-color: transparent;
    border: none;
    color: $color-active;
    cursor: pointer;
  }
}

.label {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 16px;
}
</style>
