export default [
  // INACTIVE (static, already drawn)
  // line stroke
  {
    'id': 'gl-draw-line-static',
    'type': 'line',
    'filter': ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#C3CEDA',
      'line-width': 3
    }
  },
  // polygon fill
  {
    'id': 'gl-draw-polygon-fill-static',
    'type': 'fill',
    'filter': ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    'paint': {
      'fill-color': ['case',
        ['==', ['get', 'user_status'], 'not_sure'], '#F2C40C',
        ['==', ['get', 'user_status'], 'sure'], '#30B21B',
        ['==', ['get', 'user_status'], 'not_connected'], '#4970E6',
        ['==', ['get', 'user_status'], 'declined'], '#F25749',
        '#F25749'
      ],
      'fill-outline-color': '#C3CEDA',
      'fill-opacity': 0.3
    }
  },
  // polygon outline
  {
    'id': 'gl-draw-polygon-stroke-static',
    'type': 'line',
    'filter': ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': ['case',
        ['==', ['get', 'user_status'], 'not_sure'], '#F2C40C',
        ['==', ['get', 'user_status'], 'sure'], '#30B21B',
        ['==', ['get', 'user_status'], 'not_connected'], '#4970E6',
        ['==', ['get', 'user_status'], 'declined'], '#F25749',
        '#F25749'
      ],
      'line-width': 3
    }
  },

  // INACTIVE (simple_select, after drawn)
  // line stroke
  {
    'id': 'gl-draw-line-simple',
    'type': 'line',
    'filter': ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'simple_select']],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#F2D749',
      'line-width': 3
    }
  },
  // polygon fill
  {
    'id': 'gl-draw-polygon-fill-simple',
    'type': 'fill',
    'filter': ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'simple_select']],
    'paint': {
      'fill-color': '#99E52E',
      'fill-outline-color': '#99E52E',
      'fill-opacity': 0.3
    }
  },
  // polygon outline
  {
    'id': 'gl-draw-polygon-stroke-simple',
    'type': 'line',
    'filter': ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'simple_select']],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#F2D749',
      'line-width': 3
    }
  },
  // vertex point halos
  {
    'id': 'gl-draw-polygon-and-line-vertex-halo-simple',
    'type': 'circle',
    'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'simple_select']],
    'paint': {
      'circle-radius': 5,
      'circle-color': '#99E52E'
    }
  },
  // vertex points
  {
    'id': 'gl-draw-polygon-and-line-vertex-simple',
    'type': 'circle',
    'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'simple_select']],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#30B21B',
    }
  },

  // ACTIVE (being drawn)
  // line stroke
  {
    'id': 'gl-draw-line-active',
    'type': 'line',
    'filter': ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#F2D749',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  // polygon fill
  {
    'id': 'gl-draw-polygon-fill-active',
    'type': 'fill',
    'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    'paint': {
      'fill-color': '#99E52E',
      'fill-outline-color': '#99E52E',
      'fill-opacity': 0.3
    }
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    'id': 'gl-draw-polygon-stroke-active',
    'type': 'line',
    'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#F2D749',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  // vertex midpoint halos
  {
    'id': 'gl-draw-polygon-and-line-midpoint-halo-active',
    'type': 'circle',
    'filter': ['all', ['==', 'meta', 'midpoint'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#99E52E'
    }
  },
  // midpoint points
  {
    'id': 'gl-draw-polygon-and-line-midpoint-active',
    'type': 'circle',
    'filter': ['all', ['==', 'meta', 'midpoint'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    'paint': {
      'circle-radius': 2,
      'circle-color': '#30B21B',
    }
  },

  // vertex point halos
  {
    'id': 'gl-draw-polygon-and-line-vertex-halo-active',
    'type': 'circle',
    'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    'paint': {
      'circle-radius': 5,
      'circle-color': '#99E52E'
    }
  },
  // vertex points
  {
    'id': 'gl-draw-polygon-and-line-vertex-active',
    'type': 'circle',
    'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#30B21B',
    }
  },

  // {
  //   'type': 'Polygon',
  //   'filter': ['all', ['==', 'status', 'red']],
  //   'paint': {
  //     'fill-color': '#333',
  //     'circle-radius': 3,
  //     'circle-color': '#30B21B',
  //   }
  // },
]
