<template>
  <div class="rosreestr" data-testid="form">
    <q-form ref="formRef">
      <div class="rosreestr__field" data-testid="checkStatusInnerId">
        <Select
          v-model="form.checkStatusInnerId"
          label="Статус проверки"
          :options="checkStatuses"
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>
      <div v-if="showDataComment" data-testid="dataCommentInner" class="rosreestr__field">
        <span class="label-select">Комментарий к данным</span>
        <q-input
          v-model="form.dataComment"
          placeholder="Комментарий к данным"
          outlined
          type="textarea"
          no-error-icon
          :rules="[val => !!val || 'Обязательное поле']"
          dense
        />
      </div>
    </q-form>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, defineExpose, ref, reactive, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import Select from '@/components/common/selects/Select'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const props = defineProps({
  fields: {
    type: Object,
    required: true
  },
  id: {
    type: String,
    required: true
  }
})

const emits = defineEmits(['close'])

const formRef = ref()
const form = reactive({
  id: null,
  checkStatusInnerId: null,
  dataCommentInner: ''
})

const checkStatuses = computed(() => store.state.dictionaries.checkInnerStatuses)
const showDataComment = computed(() => {
  const checkStatus = checkStatuses.value.find(item => item.value === form.checkStatusInnerId)
  return checkStatus && checkStatus.showDataComment
})

const sendForm = () => {
  const formEl = formRef.value

  formEl.validate().then((result) => {
    if (result) {
      store.commit('modals/setDisableModal', true)

      ApiService.map.updateArea(form)
        .then(() => {
          store.dispatch('mapFilter/fetchCurrentPage', { id: form.id })
          store.commit('mapFilter/setEditCount')
          emits('close')
          store.commit('modals/setDisableModal', false)
          $q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Успешно',
            timeout: 5000
          })
        })
        .catch((error) => {
          store.dispatch('auth/fetchUser')
          store.commit('modals/setDisableModal', false)

          const errorText = error?.response?.data?.message
          $q.notify({
            type: 'negative',
            position: 'bottom',
            message: errorText ? errorText : 'Ошибка',
            timeout: 5000
          })
        })
    }
  })
}

onMounted(() => {
  form.id = props.id
  form.checkStatusInnerId = props.fields.checkStatusInnerId
  form.dataCommentInner = props.fields.dataCommentInner
})

defineExpose({ sendForm })
</script>
<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 255px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    display: block;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
</style>
