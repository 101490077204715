<template>
  <q-checkbox
    v-model="statusId"
    color="accent"
    :title="message"
    :disable="!userPermissions?.includes('fields_change_check_status') || disable || isEmptyAttributes"
  />
</template>
<script setup>
import { computed, defineProps, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import ApiService from '@/services'

const props = defineProps({
  title: String,
  disable: Boolean,
  fullProps: Object,
  id: Number,
})

const statusId = ref(false)
const emptyFields = ref([])

const store = useStore()
const $q = useQuasar()

const actualExploitations = computed(() => store.state.dictionaries.actualExploitations)
const landTypes = computed(() => store.state.dictionaries.fieldLandTypes)
const orgs = computed(() => store.state.orgs.orgsByIds)
const cultureTypes = computed(() => store.state.dictionaries.typeCulture)
const visibleEntity = computed(() => store.state.map.visibleEntity)
const userPermissions = computed(() => store.state.auth.user.permissions)

const statuses = computed(() => store.state.dictionaries.checkStatuses)
const trueStatus = computed(() => statuses.value?.find((status) => status.name === 'Данные верны')?.value)
const falseStatus = computed(() => statuses.value?.find((status) => status.name === 'Требуется проверка')?.value)
const isEmptyAttributes = computed(() => !!emptyFields.value?.length && props.fullProps.form.checkStatusId === falseStatus.value)
const message = computed(() => emptyFields.value.length ? `Не заполнены: ${Array.from(new Set(emptyFields.value)).join(', ')}` : props.title)

statusId.value = trueStatus.value === props.fullProps.form.checkStatusId

const checkField = (options, fieldAttributeId, errorAttribute) => {
  const currentOption = options?.find((option) => option.value === fieldAttributeId || option.id === fieldAttributeId)

  if (!fieldAttributeId || currentOption?.isNull) {
    emptyFields.value = emptyFields.value.concat([errorAttribute])
  }
}

const checkFields = () => {
  checkField(actualExploitations.value, props.fullProps?.form?.actualExploitationId, 'Фактическая эксплуатация')
  checkField(landTypes.value, props.fullProps?.form?.fieldLandTypeId, 'Вид с/х угодий')

  const currentActualExploitation = actualExploitations.value?.find((value) => value.value === props.fullProps?.form?.actualExploitationId)

  const currentLandTypeId = landTypes.value?.find((value) => value.value === props.fullProps?.form?.fieldLandTypeId)

  if (currentActualExploitation && currentActualExploitation.name === 'Используется' && props.fullProps?.form?.factUserId) {
    checkField(orgs.value, props.fullProps?.form?.factUserId, 'Фактический пользователь')
  }

  if (currentActualExploitation && currentActualExploitation.name === 'Используется' && currentLandTypeId && currentLandTypeId.name.includes('Пашня')) {
    checkField(cultureTypes.value, props.fullProps?.form?.cultureTypeId, 'Вид культур')
  }
}

checkFields()

watch([actualExploitations, landTypes, orgs, cultureTypes], () => {
  checkFields()
})

watch(statusId, (value) => {
  if (props.id) {
    store.commit('mapFilter/setIsLoadingFilter', true)
    ApiService.fields.updateCheckStatusField({
      checkStatusId: value ? trueStatus.value : falseStatus.value,
      periodId: props.fullProps.form.periodId,
      id: props.id,
      dataComment: null
    })
      .then(() => {
        store.commit('mapFilter/setIsLoadingFilter', false)

        if (visibleEntity.value === 'fields') {
          store.commit('mapFilter/setEditCount')

          if (props?.id) {
            store.dispatch('fields/fetchTileField', props.id)
          }
        }

        $q.notify({
          type: 'positive',
          position: 'bottom',
          message: 'Успешно',
          timeout: 5000
        })
      })
      .catch((error) => {
        store.commit('mapFilter/setIsLoadingFilter', false)
        if (props?.id) {
          store.dispatch('fields/fetchTileField', props.id)
        }
        const errorText = error?.response?.data?.message
        $q.notify({
          type: 'negative',
          position: 'bottom',
          message: errorText ? errorText : 'Ошибка',
          timeout: 5000
        })
      })
  } else {
    statusId.value = trueStatus.value === props.fullProps.form.checkStatusId

  }
})
</script>
