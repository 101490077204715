<template lang="pug">

</template>
<script>
import { toRaw } from 'vue'
import { setSectionSubscribe } from '@/utils/subscribes'
import localforage from 'localforage'

export default {
  name: 'SubscribesMap',
  data() {
    return {
      storage: []
    }
  },
  watch: {
    countDeleteNotifications() {
      localforage.getItem('notifications')
        .then((notifications) => {
          this.storage = notifications ?? []
        })
        .catch((error) => {
          console.log(error)
        })
    },
    subscribedSections(sections) {
      sections.forEach((section) => {
        setSectionSubscribe(section, ({ data, type }) => {
          this.storage = [...this.storage, data]
          localforage.setItem('notifications', this.getUniqNotifications(this.storage).map(toRaw))
            .then(() => {
              this.$store.commit('subscribes/setCountUpdate')
            })
            .catch((error) => console.log(error))

          if (type === 'section-locked') {
            this.$store.commit('subscribes/addLockedSection', data.hash)
            this.$q.notify({
              type: 'info',
              position: 'bottom',
              message: data?.message ?? '',
              timeout: 5000
            })

          }

          if (type === 'section-unlocked') {
            this.$store.commit('subscribes/removeLockedSection', data.hash)
            this.$q.notify({
              type: 'info',
              position: 'bottom',
              message: data?.message ?? '',
              timeout: 5000
            })
          }
        })
      })
    }
  },
  mounted() {
    localforage.getItem('notifications')
      .then((notifications) => {
        this.storage = notifications ?? []
      })
      .catch((error) => {
        console.log(error)
      })
  },
  computed: {
    countDeleteNotifications() {
      return this.$store.state.subscribes.countDelete
    },
    subscribedSections() {
      return this.$store.state.subscribes.subscribedSections
    }
  },
  methods: {
    getUniqNotifications(allNotifications) {
      const notifications = []
      allNotifications.forEach((item) => {
        if (!notifications.find((notification) => notification.id === item.id && notification.message === item.message && notification.time === item.time)) {
          notifications.push(item)
        }
      })
      return notifications
    }
  }
}
</script>
