// import api from '../api'

const actions = {

}

const mutations = {
  setTypeFile(state, data) {
    state.typeFile = data
  },
  setCommentFile(state, data) {
    state.comment = data
  },
  setIdFile(state, data) {
    state.id = data
  },
  setIsShowSlider(state, data) {
    state.isShowSlider = data
  },
  setActiveSlide(state, data) {
    state.activeSlide = data
  },
}

const state = () => ({
  typeFile: '', // 'files', 'images'
  comment: '',
  id: null,
  isShowSlider: false,
  activeSlide: 1
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
