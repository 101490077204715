<template lang="pug">

</template>
<script>
import { Marker } from '@/utils/maplibre-gl/index.js'
import '@/utils/maplibre-gl/style.css'

export default {
  name: 'MarkersMap',
  components: {

  },
  props: ['map'],
  data() {
    return {
      marker: null,
    }
  },
  watch: {
    searchingPoint: {
      handler(value) {
        if (this.marker) {
          this.marker.remove()
        }

        if (this.map && value) {
          this.map.flyTo({ center: value, zoom: 7 })
          this.marker = new Marker()
            .setLngLat(value)
            .addTo(this.map)
        }
      }, deep: true }
  },
  computed: {
    searchingPoint() {
      return this.$store.state.map.searchingPoint
    },
  },
}
</script>
