<template>
  <q-table
    class="table"
    :columns="columns"
    :rows="rows"
    dense
    hide-bottom
    :rows-per-page-options="[0]"
    row-key="name"
  />
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const columns = [
  { name: 'id', label: 'ID поля', field: 'id', align: 'center' },
  { name: 'exploitation', label: 'Фактическая эксплуатация', field: 'exploitation', align: 'center' },
  { name: 'complexity', label: 'Сложность ввода в эксплуатацию', field: 'complexity', align: 'center' },
  { name: 'unused', label: 'Сведения о состоянии неиспользуемых земель', field: 'unused', align: 'center' },
  { name: 'user', label: 'Фактический пользователь', field: 'user', align: 'center' },
  { name: 'land', label: 'Вид с/х угодий', field: 'land', align: 'center' },
  { name: 'culture', label: 'Вид культур', field: 'culture', align: 'center' },
]


const joiningFields = computed(() => store.state.fields.joiningFields)
const rows = computed(() => joiningFields.value
  .map((field) => ({
    id: field.id,
    exploitation: field.props[0]?.common?.find((prop) => prop.name === 'Фактическая эксплуатация')?.value ?? '',
    complexity: field.props[0]?.common?.find((prop) => prop.name === 'Сложность ввода в эксплуатацию')?.value ?? '',
    unused: field.props[0]?.common?.find((prop) => prop.name === 'Сведения о состоянии неиспользуемых земель')?.value ?? '',
    user: field.props[0]?.common?.find((prop) => prop.name === 'Фактический пользователь')?.value ?? '',
    land: field.props[0]?.common?.find((prop) => prop.name === 'Вид с/х угодий')?.value ?? '',
    culture: field.props[0]?.common?.find((prop) => prop.name === 'Вид культур')?.value ?? '',
  })))
</script>

