import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

const client = axios.create()

client.interceptors.request.use(
  (config) => {
    config.baseURL = process.env.VUE_APP_API_URL || (process.env.NODE_ENV === 'development' ? 'https://lk.stage.sovhoz.tech/api' : 'https://api.sovhoz.tech/api')

    if (localStorage.getItem('token')) {
      config.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    return config
  },
  reason => Promise.reject(reason)
)

const api = applyCaseMiddleware(client)

const clientPost = axios.create()

clientPost.interceptors.request.use(
  (config) => {
    config.baseURL = process.env.VUE_APP_API_URL || (process.env.NODE_ENV === 'development' ? 'https://lk.stage.sovhoz.tech/api/private' : 'https://api.sovhoz.tech/api/private')

    config.headers.common['Content-Type'] = 'multipart/form-data'

    if (localStorage.getItem('token')) {
      config.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    return config
  },
  reason => Promise.reject(reason)
)

const apiPost = applyCaseMiddleware(clientPost)

export default api
export { apiPost }
