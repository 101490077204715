<template>
  <div class="tooltip" ref="tooltip" @click="openTooltip" :title="isOpen ? 'Справка' : ''">
    ?
  </div>
  <teleport to="body">
    <div v-if="isOpen" class="tooltip__content" ref="content" :style="{ top, left, height: isModal ? '502px' : '25vh' }" :class='{ modal: isModal }'>
      <q-scroll-area class="fit" :thumb-style="{ width: '5px' }">
        <div class="tooltip__text" v-html="content" :style="{height: isModal ? '502px' : `calc(25vh - 50px)`}" />
      </q-scroll-area>
      <button class="tooltip__button" @click.stop="closeTooltip" title="Закрыть" />
    </div>
  </teleport>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from 'vue'

const MOBILE_WIDTH = 600
const MODAL_MOBILE_WIDTH = 1024

const props = defineProps(['id', 'content', 'isOpen', 'isModal'])
const emits = defineEmits(['setOpenedTooltip'])

const left = ref('0')
const top = ref('0')
const tooltip = ref(null)

onMounted(() => {
  if (!props.isModal) {
    top.value = window.innerWidth <= MOBILE_WIDTH ? '-1px' : 'calc(50% - 10px)'
    left.value = window.innerWidth <= MOBILE_WIDTH ? '-1px' : '340px'
  }

  if (props.isModal) {
    top.value = window.innerWidth <= MODAL_MOBILE_WIDTH ? '-1px' : 'calc(50% - 10px)'
    left.value = 'calc(50% + 240px)'
    // window.innerWidth <= MODAL_MOBILE_WIDTH ? '-1px' : '480px'
  }
})

const closeTooltip = () => {
  emits('setOpenedTooltip', null)
}

const onClickOutside = (evt) => {
  const elements = document.querySelectorAll('.tooltip, .tooltip *')
  if (![...elements].includes(evt.target)) {
    document.removeEventListener('click', onClickOutside)
    closeTooltip()
  }
}

const openTooltip = (evt) => {
  if (evt.target.classList.contains('tooltip')) {
    document.addEventListener('click', onClickOutside)

    const boundingClientRect = tooltip.value.getBoundingClientRect()

    if (!props.isModal && boundingClientRect.top && boundingClientRect.right && window.innerWidth > MOBILE_WIDTH) {
      top.value = `${boundingClientRect.top - 12}px`
      left.value = `${boundingClientRect.right + 15}px`
    }

    if (props.isModal && window.innerWidth > MODAL_MOBILE_WIDTH && boundingClientRect.top && boundingClientRect.right && window.innerWidth > MOBILE_WIDTH) {
      // const modalBoundingRect = document.querySelector('.modal__container').getBoundingClientRect()
      // top.value = `${boundingClientRect.top - modalBoundingRect.top - 12}px`
      top.value = `${evt.y - 20}px`
    }

    if (props.isModal && window.innerWidth <= MOBILE_WIDTH) {
      top.value = `${evt.y - 20}px`
      left.value = '0'
    }

    emits('setOpenedTooltip', null)
    emits('setOpenedTooltip', props.id)
  }
}
</script>
<style lang='scss' scoped>
.tooltip {
  box-sizing: border-box;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  right: -28px;
  height: 20px;
  width: 20px;
  border: 1px solid $color-gray5;
  border-radius: 50%;
  color: $color-gray5;
  top: -1px;
  cursor: pointer;

  &__content {
    position: fixed;
    z-index: 100;
    //top: 50%;
    left: 340px;
    //transform: translateY(-50%);
    width: calc(100vw - 350px);
    max-width: 700px;
    max-height: 25vh;
    height: auto;
    padding: 25px;
    background-color: #1B232F;
    color: white;
    border-radius: 2px;

    @include below('m_lg') {
      width: 100vw;
      left: 0;
      top: 0;
      height: 100vh;
      max-height: 100vh;
    }

    &::after {
      content: '';
      position: absolute;
      left: -10px;
      top: 10px;
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-top-width: 11px;
      border-bottom-width: 11px;
      border-right: 11px solid #1B232F;
    }

    &.modal {
      width: 100%;
      max-width: calc(50vw - 240px - 10px);
      left: 0;
      top: 0;
      max-height: 25vh;

      @include below('t_lg') {
        width: calc(100% + 2px);
        max-width: calc(100% + 2px);
        max-height: 500px;
        height: 500px;
      }
    }
  }

  &__text {
    max-height: calc(25vh - 50px);
    width: calc(100vw - 400px);
    max-width: 650px;
    padding-right: 10px;
    // overflow-y: auto;

    @include below('t_lg') {
      left: 0;
      top: 0;
      width: calc(100% - 50px);
      height: 450px;
      max-height: 450px;
    }
  }

  &__button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;

    &::before, &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 20px;
      height: 2px;
      background: white;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.tooltip__content.modal .tooltip__text {
  height: 100%;
  width: 100%;

  @include below('m_lg') {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
</style>
