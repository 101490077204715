const ACTIVE_COLOR = '#dc1c0a'
const ROSREESTR_COLOR = '#99E52E'
// const KUGI_COLOR = '#F2C40C'
// const KUMI_COLOR = '#b625f5'
const PAIS_COLOR = '#4970E5'
// const FEDERAL_COLOR = '#fa6400'
// const ROSREESTR2_COLOR = '#00ffbf'

const FILL_OPACITY = [
  'case',
  ['boolean', ['feature-state', 'edited'], false], 0,
  ['boolean', ['feature-state', 'active'], false], 0.7,
  ['boolean', ['feature-state', 'focus'], false], 1,
  ['boolean', ['feature-state', 'hover'], false], 0.4,
  0.2,
]

const LINE_WIDTH = ['case',
  ['boolean', ['feature-state', 'focus'], false], 7,
  ['boolean', ['feature-state', 'field'], false], 3,
  1
]

const FILL_COLOR = ['case',
  ['boolean', ['feature-state', 'edited'], false], 'transparent',
  ['boolean', ['feature-state', 'selected'], false], 'transparent',
  ['boolean', ['feature-state', 'active'], false], ACTIVE_COLOR,
  ['==', ['get', 'name'], 'rosreestr'], ROSREESTR_COLOR,
  ['==', ['get', 'name'], 'kugi'], PAIS_COLOR,
  ['==', ['get', 'name'], 'kumi'], PAIS_COLOR,
  ['==', ['get', 'name'], 'pais'], PAIS_COLOR,
  ['==', ['get', 'name'], 'federal'], PAIS_COLOR,
  ['==', ['get', 'name'], 'rosreestr2'], PAIS_COLOR,
]

const LINE_COLOR = ['case',
  ['boolean', ['feature-state', 'edited'], false], 'transparent',
  ['boolean', ['feature-state', 'focus'], false], ACTIVE_COLOR,
]

const polygonColor = {
  rosreestr: ROSREESTR_COLOR,
  kugi: PAIS_COLOR,
  kumi: PAIS_COLOR, // KUMI_COLOR
  pais: PAIS_COLOR, // PAIS_COLOR
  federal: PAIS_COLOR, // FEDERAL_COLOR
  rosreestr2: PAIS_COLOR, // ROSREESTR2_COLOR
}

export { polygonColor }

export default [{
  name: 'rosreestr',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/rosreestr/{z}/{x}/{y}.mvt']
  },
  layers: [
    // {
    //   id: 'rosreestr_pattern',
    //   type: 'fill',
    //   source: 'rosreestr',
    //   'source-layer': 'rosreestr',
    //   layout: {
    //     'visibility': 'none'
    //   },
    //   paint: {
    //     'fill-pattern': 'pattern-cell',
    //   },
    // },
    {
      id: 'rosreestr_fill',
      type: 'fill',
      source: 'rosreestr',
      'source-layer': 'rosreestr',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'fill-color': FILL_COLOR.concat([ROSREESTR_COLOR]),
        'fill-opacity': FILL_OPACITY,
      },
    // maxzoom: 14,
    },
    {
      id: 'rosreestr_line',
      type: 'line',
      source: 'rosreestr',
      'source-layer': 'rosreestr',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'line-width': LINE_WIDTH,
        'line-color': LINE_COLOR.concat([ROSREESTR_COLOR])
      },
    // maxzoom: 14,
    }]
},
{
  name: 'kugi',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/kugi/{z}/{x}/{y}.mvt']
  },
  layers: [
    // {
    //   id: 'kugi_pattern',
    //   type: 'fill',
    //   source: 'kugi',
    //   'source-layer': 'kugi',
    //   layout: {
    //     'visibility': 'none'
    //   },
    //   paint: {
    //     'fill-pattern': 'pattern-cell',
    //   },
    // },
    {
      id: 'kugi_fill',
      type: 'fill',
      source: 'kugi',
      'source-layer': 'kugi',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'fill-color': FILL_COLOR.concat([PAIS_COLOR]),
        'fill-opacity': FILL_OPACITY,
      },
    // maxzoom: 14,
    },{
      id: 'kugi_line',
      type: 'line',
      source: 'kugi',
      'source-layer': 'kugi',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'line-width': LINE_WIDTH,
        'line-color': LINE_COLOR.concat([PAIS_COLOR])
      },
    // maxzoom: 14,
    }]
},
{
  name: 'kumi',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/kumi/{z}/{x}/{y}.mvt']
  },
  layers: [
    // {
    //   id: 'kumi_pattern',
    //   type: 'fill',
    //   source: 'kumi',
    //   'source-layer': 'kumi',
    //   layout: {
    //     'visibility': 'none'
    //   },
    //   paint: {
    //     'fill-pattern': 'pattern-cell',
    //   },
    // },
    {
      id: 'kumi_fill',
      type: 'fill',
      source: 'kumi',
      'source-layer': 'kumi',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'fill-color': FILL_COLOR.concat([PAIS_COLOR]),
        'fill-opacity': FILL_OPACITY,
      },
    // maxzoom: 14,
    },{
      id: 'kumi_line',
      type: 'line',
      source: 'kumi',
      'source-layer': 'kumi',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'line-width': LINE_WIDTH,
        'line-color': LINE_COLOR.concat([PAIS_COLOR])
      },
    // maxzoom: 14,
    }]
},
{
  name: 'pais',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/pais/{z}/{x}/{y}.mvt']
  },
  layers: [
    // {
    //   id: 'pais_pattern',
    //   type: 'fill',
    //   source: 'pais',
    //   'source-layer': 'pais',
    //   layout: {
    //     'visibility': 'none'
    //   },
    //   paint: {
    //     'fill-pattern': 'pattern-cell',
    //   },
    // },
    {
      id: 'pais_fill',
      type: 'fill',
      source: 'pais',
      'source-layer': 'pais',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'fill-color': FILL_COLOR.concat([PAIS_COLOR]),
        'fill-opacity': FILL_OPACITY,
      },
    // maxzoom: 14,
    },{
      id: 'pais_line',
      type: 'line',
      source: 'pais',
      'source-layer': 'pais',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'line-width': LINE_WIDTH,
        'line-color': LINE_COLOR.concat([PAIS_COLOR])
      },
    // maxzoom: 14,
    }]
},
{
  name: 'federal',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/federal/{z}/{x}/{y}.mvt']
  },
  layers: [
    // {
    //   id: 'federal_pattern',
    //   type: 'fill',
    //   source: 'federal',
    //   'source-layer': 'federal',
    //   layout: {
    //     'visibility': 'none'
    //   },
    //   paint: {
    //     'fill-pattern': 'pattern-cell',
    //   },
    // },
    {
      id: 'federal_fill',
      type: 'fill',
      source: 'federal',
      'source-layer': 'federal',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'fill-color': FILL_COLOR.concat([PAIS_COLOR]),
        'fill-opacity': FILL_OPACITY,
      },
    // maxzoom: 14,
    },{
      id: 'federal_line',
      type: 'line',
      source: 'federal',
      'source-layer': 'federal',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'line-width': LINE_WIDTH,
        'line-color': LINE_COLOR.concat([PAIS_COLOR])
      },
    // maxzoom: 14,
    }]
},
{
  name: 'rosreestr2',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/rosreestr2/{z}/{x}/{y}.mvt']
  },
  layers: [
    // {
    //   id: 'rosreestr2_pattern',
    //   type: 'fill',
    //   source: 'rosreestr2',
    //   'source-layer': 'rosreestr2',
    //   layout: {
    //     'visibility': 'none'
    //   },
    //   paint: {
    //     'fill-pattern': 'pattern-cell',
    //   },
    // },
    {
      id: 'rosreestr2_fill',
      type: 'fill',
      source: 'rosreestr2',
      'source-layer': 'rosreestr2',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'fill-color': FILL_COLOR.concat([PAIS_COLOR]),
        'fill-opacity': FILL_OPACITY,
      },
    // maxzoom: 14,
    },{
      id: 'rosreestr2_line',
      type: 'line',
      source: 'rosreestr2',
      'source-layer': 'rosreestr2',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'line-width': LINE_WIDTH,
        'line-color': LINE_COLOR.concat([PAIS_COLOR])
      },
    // maxzoom: 14,
    }]
},
]
