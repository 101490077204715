<template>
  <div class="filter" data-testid="filter" @keyup.enter="handleEnter">
    <q-scroll-area
      :style="maintenanceNotice ? {'max-width': '344px', height: 'calc(100vh - 83px - 67px - 50px - 40px)'} : {'max-width': '344px', height: 'calc(100vh - 83px - 67px - 50px)'}"
      :vertical-thumb-style="{ width: '5px', opacity: '0.5' }"
      :horizontal-thumb-style="{ height: '5px', opacity: '0.5' }"
    >
      <div class="filter__form">
        <div v-if="accessFieldFilter || accessSchemasKugi" class="filter__entity">
          <div class="filter__section">
            <Select
              label="Показывать на карте"
              v-model="entity"
              :options="entityOptions"
            />
          </div>
        </div>
        <div v-if="visibleEntity === 'sections'" class="filter__entity">
          <SectionFilter
            ref="sectionsRef"
            :filter-type="filterType"
            @setFilter="setFilter"
            @setIsLoad="(value) => {isLoad = value}"
          />
        </div>
        <div v-if="visibleEntity === 'fields'" class="filter__entity" v-access="'fields_filter'">
          <FieldFilter ref="fieldsRef" />
        </div>
        <div v-if="visibleEntity === 'schemes'" class="filter__entity" v-access="'docs_schemas_view'">
          <SchemeFilter ref="schemesRef" />
        </div>
        <div class="filter__entity filter__entity--coords">
          <button
            class="filter__toggle-entity"
            type="button"
            :style="{marginBottom: isShowCoordSearch ? '15px' : '0' }"
            @click="isShowCoordSearch = !isShowCoordSearch"
          >
            {{isShowCoordSearch? 'Свернуть' : 'Развернуть' }} поиск по координатам
          </button>
          <div v-if="isShowCoordSearch" class="filter__section">
            <CoordinateConverter />
          </div>
        </div>
      </div>
    </q-scroll-area>
    <div class="filter__info-container">
      <div v-if="visibleEntity === 'sections'" class="filter__info">
        <InfoCard
          v-if="infoFilter"
          label="Подходящих участков:"
          :text="`${formatNumber(infoFilter.count)} (${formatNumber(Number(infoFilter.area).toFixed(2))}ГА)`"
          aria-label="Количество и площадь подходящих участков"
        />
        <button
          class="filter__clear-filter"
          type="button"
          @click="clearFilter"
          aria-label="Сбросить"
        >
          Сбросить фильтр
        </button>
      </div>
      <div v-if="accessFieldFilter && visibleEntity === 'fields'" class="filter__info">
        <InfoCard
          v-if="infoFilter"
          label="Подходящих полей:"
          :text="`${formatNumber(fieldsByFilter.count)} (${formatNumber(Number(fieldsByFilter.area).toFixed(2))}ГА)`"
          aria-label="Количество и площадь подходящих полей"
        />
        <button
          class="filter__clear-filter"
          type="button"
          @click="clearFieldFilter"
          aria-label="Сбросить"
        >
          Сбросить фильтр
        </button>
      </div>
      <div v-if="accessSchemasKugi && visibleEntity === 'schemes'" class="filter__info">
        <InfoCard
          v-if="schemes"
          label="Подходящих схем:"
          :text="`${formatNumber(schemes.length)}`"
          aria-label="Количество подходящих схем"
        />
        <button
          class="filter__clear-filter"
          type="button"
          @click="clearSchemesFilter"
          aria-label="Сбросить"
        >
          Сбросить фильтр
        </button>
      </div>
    </div>
    <div class="filter__footer">
      <Button class="button" text="Поиск" @click="handleSearch" :isLoad="isLoad" aria-label="Поиск" />
    </div>
  </div>
</template>
<script setup>
import { watch, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Button from '@/components/uikit/buttons/Button'
import InfoCard from '@/components/common/cards/InfoCard'
import CoordinateConverter from '@/modules/map/components/CoordinateConverter'
import Select from '@/components/common/selects/Select'
import SectionFilter from '@/modules/map/components/filters/SectionFilter'
import FieldFilter from '@/modules/map/components/filters/FieldFilter'
import SchemeFilter from '@/modules/map/components/filters/SchemeFilter'
import { formatNumber } from '@/utils/format-number.js'

const store = useStore()
const route = useRoute()
const router = useRouter()

const setFilter = () => {
  filterType.value = 'user'
}

const isShowCoordSearch = ref(false)
const isLoad = ref(false)

const sectionsRef = ref()
const fieldsRef = ref()
const schemesRef = ref()
const user = computed(() => store.state.auth.user)

const schemes = computed(() => store.state.schemes.schemes)
const visibleEntity = computed(() => store.state.map.visibleEntity)

const filterType = ref(visibleEntity.value ? 'user' : 'map' ) // ['map', 'user']
const accessFieldFilter = computed(() => user.value.permissions?.includes('fields_filter'))
const accessSchemasKugi = computed(() => user.value.permissions.includes('docs_schemas_view'))

const countWinterFilter = computed(() => store.state.map.countWinterFilter)
const countSummerFilter = computed(() => store.state.map.countSummerFilter)

const maintenanceNotice = computed(() => store.state.settings.maintenanceNotice)

const entity = ref(visibleEntity.value) // ['sections', 'fields', 'schemes']
// const entityOptions = [
//   { value: 'sections', label: 'Участки' },
//   { value: 'fields', label: 'Поля' }
// ]

const entityOptions = computed(() => {
  const options = [{ value: 'sections', label: 'Участки' }]

  if (accessFieldFilter.value) {
    options.push({ value: 'fields', label: 'Поля' })
  }

  if (accessSchemasKugi.value) {
    options.push({ value: 'schemes', label: 'Схемы' })
  }

  return options
})

const infoFilter = computed(() => store.state.mapFilter.cadastralByFilter)
const fieldsByFilter = computed(() => store.state.mapFilter.fieldsByFilter)


// const setRegionValue = () => {
//   filter.regionId = user.value.region?.value ? [Number(user.value.region.value)] : filter.regionId
// }

const clearSchemesFilter = () => {
  schemesRef.value.clearFilter()
}

const clearFilter = () => {
  sectionsRef.value.clearFilter()
}

const clearFieldFilter = () => {
  fieldsRef.value.clearFieldFilter()
}

const handleSearch = async () => {
  filterType.value = 'user'

  if (visibleEntity.value === 'schemes') {
    schemesRef.value?.applyFilter()
  }

  if (visibleEntity.value === 'sections') {
    sectionsRef.value?.applyFilter()
  }

  if (visibleEntity.value === 'fields') {
    fieldsRef.value?.applyFilter()
  }
}

const handleEnter = (evt) => {
  const hash = route.hash

  if (evt.keyCode === 13 || evt.key === 'Enter') {
    // applyFilter(hash)
    handleSearch()
  }
}

watch(entity, (value) => {
  if (value) {
    if (filterType.value !== 'map' && user.value?.name !== 'demo') {
      store.commit('mapFilter/setIsLoadingFilter', true)
    }
    setTimeout(() => {
      store.commit('map/setVisibleEntity', value)
    }, 100)
  }

  if (value !== 'sections') {
    const query = { page: 1 }

    if (route.query?.field) {
      query.field = route.query?.field
    }

    if (route.query?.periodIds) {
      query.periodIds = route.query?.periodIds
    }

    if (route.query?.factUsers) {
      query.factUsers = route.query?.factUsers
    }

    router.push({ to: 'map', query }).catch(() => {})
  }
})

watch([countWinterFilter, countSummerFilter], () => {
  entity.value = 'fields'
  store.commit('map/setVisibleEntity', 'fields')
})

onMounted(() => {
  if (!entity.value) {
    const factUsers = route.query?.factUsers
    const periodIds = route.query?.periodIds
    const fieldId = route.query?.field
    const isFieldsVisible = factUsers || periodIds || fieldId || localStorage.getItem('field')

    if (!route.params.id && (user.value.role === 'apkmunicipalitet' || isFieldsVisible)) {
      entity.value = 'fields'
    } else {
      entity.value = 'sections'
    }
  }
  // store.commit('map/setVisibleEntity', 'sections')
})
</script>
<style lang="scss">
.filter .q-scrollarea__content {
  max-width: 344px;
}
</style>
<style lang="scss" scoped>
.filter {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__form {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    flex-grow: 1;
    position: relative;
    padding: 16px 30px 16px 30px;
    height: calc(100% - 40px - 59px);
    // height: 100%;

    overflow-y: auto;
    overflow-x: hidden;
  }

  &__footer {
    // position: absolute;
    // bottom: 0;
    width: 100%;
  }

  &__info-container {
    display: flex;
  }

  &__info {
    border-top: 1px solid $color-gray3;
    // position: absolute;
    padding: 5px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 59px;
    // bottom: 40px;
    width: 100%;

    &--fields {
      width: 50%;
      flex-direction: column;
      padding: 10px;

      &:last-child {
        border-left: 1px solid $color-gray3;
      }
    }
  }

  .tooltip-label {
    position: relative;
    display: inline-block;
    font-weight: 500;
  }

  &__section {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &--validate:not(:last-child) {
      margin-bottom: 0;
    }

    &--validate:has(.q-field--error) {
      margin-bottom: 10px;
    }

    &--checkbox {
      margin-left: -10px;
    }

    &_flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .input {
        width: calc(50% - 6px);
      }
    }
  }

  .button {
    width: 100%;
    border-radius: 0;
  }

  &__entity {
    position: relative;
    padding: 15px 0;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -45px;
      height: 1px;
      background-color: $color-gray3;
    }

    &--coords::after {
      display: none;
    }
  }

  &__toggle-entity {
    width: 100%;
    background-color: transparent;
    border: none;
    text-decoration: underline dotted;
    color: $color-active;
    cursor: pointer;
  }

  &__clear-filter {
    background-color: transparent;
    border: none;
    color: $color-active;
    cursor: pointer;
  }
}

.label {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 16px;
}
</style>
