<template lang="pug">

</template>
<script>
import { toRaw } from 'vue'
import localforage from 'localforage'
import { polygonColor } from '@/modules/map/layers/rosreestr'
import { addEditedLayer, removeEditedLayer } from '../utils/layers/edited-layer'

export default {
  name: 'StoragePolygons',
  data() {
    return {
      polygonStorage: [],
      hoveredState: null
    }
  },
  props: ['map', 'mapDraw', 'activePolygon', 'activePolygonV2'],
  emits: ['clickHandler', 'setFilter', 'setPolygonStorage'],
  mounted() {
    this.$store.dispatch('map/fetchCacheLifeTime')
    localforage.getItem('polygons')
      .then((polygons) => {
        if (polygons && polygons.length > 0) {
          this.polygonStorage = polygons.filter((polygon) => {
            return Date.now() - polygon.createdAt < this.cacheLifeTime * 1000
          })

          if (this.map.getSource('editLayer_data')) {
            this.repaintPolygonsFromStorage({ polygons: this.polygonStorage, map: this.map })
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })

    this.map.on('load', () => {
      this.repaintPolygonsFromStorage({ polygons: this.polygonStorage, map: this.map })
    })
  },
  watch: {
    cacheLifeTime() {
      this.polygonStorage = this.polygonStorage.filter((polygon) => {
        return Date.now() - polygon.createdAt < this.cacheLifeTime * 1000
      })
    },
    polygonStorage: {
      handler(value, oldValue) {
        this.$emit('setPolygonStorage', value)
        const polygons = value.map((item) =>({
          ...toRaw(item),
          geometry: toRaw(item.geometry),
          properties: toRaw(item.properties)
        }))
        localforage.setItem('polygons', polygons).catch((error) => console.log(error))
        if (this.map._loaded) {
          if (oldValue?.length > 0) {
            oldValue.forEach((polygon) => {
              if (this.map.getSource(polygon.properties.layer)) {
                this.map.setFeatureState(
                  { source: polygon.properties.layer, sourceLayer: polygon.properties.layer, id: polygon.id },
                  { selected: false, active: false, hover: false, edited: false }
                )
              }
            })
          }

          value.forEach((polygon) => {
            if (this.map.getSource(polygon.properties.layer)) {
              this.map.setFeatureState(
                { source: polygon.properties.layer, sourceLayer: polygon.properties.layer, id: polygon.id },
                { selected: false, active: false, hover: false, edited: true }
              )
            }
          })

          this.repaintPolygonsFromStorage({ polygons: this.polygonStorage, map: this.map })
        }
      }, deep: true
    },
    '$route.params.id'(_value, oldValue) {
      const activePolygons = this.polygonStorage.filter((polygon) => polygon.properties.hash === oldValue)

      if (activePolygons.length > 0) {
        this.setStateStoragePolygon(activePolygons[0].id, { active: false })
      }
    },
    // activePolygonV2(value, oldValue) {
    //   console.log(value, oldValue)
    // },
    activePolygon(value, oldValue) {
      if (value?.id) {
        const activePolygons = this.polygonStorage.filter((polygon) => polygon.properties.hash === value.properties.hash)

        if (activePolygons && activePolygons.length > 0) {
          const restPolygons = this.polygonStorage.filter((polygon) => polygon.properties.hash !== value.properties.hash)
          const newPolygon = {
            ...activePolygons[0],
            id: value.id,
          }
          this.setStateStoragePolygon(value.id, { active: true })
          if (this.map.getSource(activePolygons[0].properties.layer)) {
            this.map.setFeatureState(
              {
                source: activePolygons[0].properties.layer,
                sourceLayer: activePolygons[0].properties.layer,
                id: value.id,
              },
              { active: false, edited: true }
            )
          }

          this.polygonStorage = [
            ...restPolygons,
            newPolygon
          ]
        }
      }

      if (value?.id && oldValue?.id && oldValue.id !== value.id) {

        const activePolygons = this.polygonStorage.filter((polygon) => polygon.properties.hash === oldValue.properties.hash)
        if (activePolygons && activePolygons.length > 0) {
          this.setStateStoragePolygon(oldValue.properties.id, { active: false })
        }
      }

    },
    editingLayer: {
      handler(value) {
        if (value?.oldHash && this.polygonStorage.map((polygon) => polygon.properties.hash).includes(value.oldHash)) {
          const editingPolygon = this.polygonStorage.filter((polygon) => polygon.properties.hash === value.oldHash)
          this.polygonStorage = [
            ...this.polygonStorage.filter((polygon) => polygon.properties.hash !== this.editingLayer.oldHash),
            {
              ...editingPolygon[0],
              properties: {
                ...editingPolygon[0].properties,
                layer: value.layer,
                name: value.layer,
                hash: value.hash,
                land_ownership_id: this.editingLayer.landOwnershipId,
                land_type_id: this.editingLayer.landTypeId,
                actual_exploitation_id: this.editingLayer.actualExploitationId,
                complexity_exploitation_id: this.editingLayer.complexityExploitationId,
                kugi_use_category_id: this.editingLayer.kugiUseCategoryId,
                owners: this.editingLayer.owners,
                // user_fact_id: this.editingLayer.userFactId,
              },
              createdAt: Date.now()
            }
          ]
        } else if (this.polygonStorage.map((polygon) => polygon.properties.hash).includes(this.editingLayer.hash)) {
          const editingPolygon = this.polygonStorage.filter((polygon) => polygon.properties.hash === this.editingLayer.hash)
          this.polygonStorage = [
            ...this.polygonStorage.filter((polygon) => polygon.properties.hash !== this.editingLayer.hash),
            {
              ...editingPolygon[0],
              properties: {
                ...editingPolygon[0].properties,
                layer: this.editingLayer.layer,
                name: this.editingLayer.layer,
                land_ownership_id: this.editingLayer.landOwnershipId,
                land_type_id: this.editingLayer.landTypeId,
                kugi_use_category_id: this.editingLayer.kugiUseCategoryId,
                owners: this.editingLayer.owners,
              },
              createdAt: Date.now()
            }
          ]
        }
      }, deep: true
    },
  },
  computed: {
    editingLayer() {
      return this.$store.state.mapFilter.editingLayer
    },
    isShowFields() {
      return this.$store.state.fields.isShowFields
    },
    cacheLifeTime() {
      return this.$store.state.map.cacheLifeTime
    },
  },
  methods: {
    addEditedLayer,
    removeEditedLayer,
    setStateStoragePolygon(id, state) {
      if (this.map.getSource('editLayer_data')) {
        this.map.setFeatureState(
          {
            source: 'editLayer_data',
            id,
          },
          state
        )
      }
    },
    addToStorage({ geometry, data }) {
      this.polygonStorage = [
        ...this.polygonStorage.filter((polygon) => polygon.properties.hash !== data.hash),
        {
          geometry,
          id: data?.id,
          properties: {
            id: data?.id,
            layer: data?.layer,
            name: data?.layer,
            hash: data?.hash,
            cadastralNumber: data?.cadastralNumber,
            land_ownership_id: data?.land_ownership_id,
            land_type_id: data?.land_type_id,
            // actual_exploitation_id: data?.actual_exploitation_id, // fields
            // complexity_exploitation_id: data?.complexity_exploitation_id, // fileds
            kugi_use_category_id: data?.kugi_use_category_id,
            owners: data?.owners,
          },
          source: 'editLayer_data',
          sourceLayer: 'editLayer_data',
          createdAt: Date.now()
        }
      ]
    },
    updatePolygon(geometry, response) {
      const activePolygons = this.polygonStorage.filter((polygon) => polygon.properties.hash === this.activePolygon.properties.hash)

      if (activePolygons && activePolygons.length > 0) {
        const restPolygons = this.polygonStorage.filter((polygon) => polygon.properties.hash !== this.activePolygon.properties.hash)
        const newPolygon = {
          ...activePolygons[0],
          id: this.activePolygon.id,
          geometry: geometry.geometry,
          properties: {
            ...activePolygons[0].properties,
            id: this.activePolygon.id,
          }
        }
        this.polygonStorage = [
          ...restPolygons,
          newPolygon
        ]
        this.setStateStoragePolygon(this.activePolygon.properties.id, { active: true })
      }

      if (activePolygons.length < 1) {

        this.addToStorage({ geometry: geometry.geometry, data: {
          id: this.activePolygon.id,
          layer: this.activePolygon.source,
          name: this.activePolygon.source,
          hash: this.activePolygon.properties.hash,
          land_ownership_id: this.activePolygon.properties?.land_ownership_id,
          land_type_id: this.activePolygon.properties?.landTypeId,
          kugi_use_category_id: this.activePolygon.properties?.kugiUseCategoryId,
          owners: this.activePolygon.properties?.owners,
          cadastralNumber: response?.data?.cadastralNumber
        } })
      }
    },
    repaintPolygonsFromStorage({ polygons, map }) {
      this.removeEditedLayer(map)
      map.off('click', 'editLayer_fill', this.clickHandler)
      map.off('touchstart', 'editLayer_fill', this.clickHandler)
      map.off('mousemove', 'editLayer_fill', this.mouseMove)
      map.off('mouseleave', 'editLayer_fill', this.mouseLeave)

      if (polygons.length > 0) {
        this.addEditedLayer({
          polygons,
          map,
          color: polygonColor.rosreestr,
          activeColor: '#dc1c0a',
        })
        map.on('click', 'editLayer_fill', this.clickHandler)
        map.on('touchstart', 'editLayer_fill', this.clickHandler)
        map.on('mousemove', 'editLayer_fill', this.mouseMove)
        map.on('mouseleave', 'editLayer_fill', this.mouseLeave)
        // this.countRepaintStorage++ // Для группировки

        this.mapDraw.deleteAll()
        this.mapDraw.changeMode('static')

        const editedLayers = [
          { id: 'editLayer_fill' },
          { id: 'editLayer_line' },
          // { id:'editLayer_pattern' }
        ]
        editedLayers.forEach(this.setFilter)

        if (this.activePolygon?.id) {
          this.setStateStoragePolygon(this.activePolygon.id, { active: true })
        }
      }
    },
    clickHandler(evt) {
      this.$emit('clickHandler', evt)
    },
    mouseMove(e) {
      if (this.isShowFields) {
        return
      }

      if (e.features.length > 0) {
        if (this.hoveredState) {
          this.map.setFeatureState(
            {
              source: e.features[0].source,
              // sourceLayer: e.features[0].sourceLayer,
              id: this.hoveredState.id,
            },
            { hover: false }
          )
        }
        this.hoveredState = e.features[0]
        this.map.setFeatureState(
          {
            source: this.hoveredState.source,
            // sourceLayer: this.hoveredState.sourceLayer,
            id: this.hoveredState.id,
          },
          { hover: true }
        )
      }
    },
    mouseLeave() {
      if (this.hoveredState) {
        this.map.setFeatureState(
          {
            source: this.hoveredState.source,
            // sourceLayer: this.hoveredState.sourceLayer,
            id: this.hoveredState.id,
          },
          { hover: false }
        )
      }
      this.hoveredState = null
    },
    setFilter(layer) {
      this.$emit('setFilter', layer)
    },
  }
}
</script>
