const paramsToFilter = (filter, params) => {
  const filledFilter = {}
  Object.keys(filter).forEach((key) => {
    if (Array.isArray(filter[key]) && Array.isArray(params[key]) && params[key].every(Number)) {
      filledFilter[key] = params[key].map(Number)
    } else if (Array.isArray(filter[key]) && typeof params[key] === 'string' && Number(params[key]) ) {
      filledFilter[key] = [Number(params[key])]
    } else if (key !== 'cadastralNumber' && params[key] !== 'true' && params[key] !== 'false' && !Array.isArray(filter[key]) && Number(params[key])) {
      filledFilter[key] = Number(params[key])
    } else if (params[key] === 'true') {
      filledFilter[key] = true
    } else if (params[key] === 'false') {
      filledFilter[key] = false
    } else if (params[key]) {
      filledFilter[key] = params[key] ?? filter[key]
    }
  })

  return filledFilter
}

export { paramsToFilter }
