<template>
  <div class="rosreestr">
    <q-form ref="form">
      <span class="label-select">Выберите файл</span>
      <q-file
        v-model="file"
        accept=".xml"
        outlined
        dense
      />
      <p class="text-negative">{{ error }}</p>
    </q-form>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits, defineExpose, watch } from 'vue'
import { useStore } from 'vuex'
import proj4 from 'proj4'
import { cloneDeep } from 'lodash'

const store = useStore()

const emits = defineEmits(['uploadPolygon'])

const file = ref(null)
const coordinates = ref([])
const error = ref('')

const mode = computed(() => store.state.map.mode)

const onChange = () => {
  const firstProjectionZone1 = '+title= МСК-42 зона 1 Кемеровская область +proj=tmerc +lat_0=0 +lon_0=85.46666666666 +k=1 +x_0=1300000 +y_0=-5512900.56 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,0.35,0.79,-0.22 +units=m +no_defs'
  const firstProjectionZone2 = '+title= МСК-42 зона 2 Кемеровская область +proj=tmerc +lat_0=0 +lon_0=88.46666666666 +k=1 +x_0=2300000 +y_0=-5512900.56 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,0.35,0.79,-0.22 +units=m +no_defs'
  const secondProjection = '+title=long/lat:WGS84 +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees'
  store.commit('modals/setDisableModal', true)

  try {
    const reader = new FileReader()
    reader.readAsText(file.value)

    const array = []

    reader.addEventListener('load', () => {
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(reader.result, 'text/xml')
      Array.from(xmlDoc.getElementsByTagName('Entity_Spatial')).forEach((el) => {
        const subArray = []

        Array.from(el.getElementsByTagName('Spatial_Element')).forEach((element) => {
          const subSubArray = []
          Array.from(element.getElementsByTagName('NewOrdinate')).forEach((coor) => {
            const firstProjection = coor.getAttribute('Y')[0] === '1' ? firstProjectionZone1 : firstProjectionZone2
            const points = proj4(firstProjection, secondProjection, [Number(coor.getAttribute('Y')), Number(coor.getAttribute('X'))])
            subSubArray.push(points)
          })

          subArray.push(subSubArray)
        })

        array.push(subArray)
      })

      coordinates.value = cloneDeep(array)

      if (coordinates.value.length === 0) {
        error.value = 'Не удалось прочитать файл'
      } else {
        error.value = ''
      }
      store.commit('modals/setDisableModal', false)
    })
  } catch (error) {
    error.value = 'Не удалось прочитать файл'
    store.commit('modals/setDisableModal', false)
  }
}

const addPolygon = () => {
  if (!error.value && coordinates.value.length) {
    const geometry = {
      type: coordinates.value.length === 1 ? 'Polygon' : 'MultiPolygon',
      coordinates: coordinates.value.length === 1 ? coordinates.value[0] : coordinates.value,
    }

    emits('uploadPolygon', geometry)
    if (mode.value === 'create-scheme') {
      store.commit('schemes/setSchemeXml', file.value)
    }
  }
}

// const addCreatePolygon = () => {
//   if (!error.value && coordinates.value.length) {
//     store.commit('map/setPolygonFromXml', coordinates.value)
//   }
// }

watch(file, (value) => {
  if (value) {
    onChange()
  }
})

defineExpose({ addPolygon })
</script>

<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 250px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    display: block;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__section {

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .input {
      width: 100%;
    }
  }
}
</style>
