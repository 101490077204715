<template>
  <q-form ref="formRef">
    <div class="input-file">
      <span class="label-select">Выберите файл</span>
      <q-file
        v-model="file"
        :rules="[val => !!val || 'Обязательное поле']"
        outlined
        accept=".png, .jpg, .jpeg"
        dense
      />
      <p v-if="imageError" class="text-negative">{{ imageError }}</p>
    </div>
    <div class="input">
      <span class="label-select">Примечание</span>
      <q-input
        v-model="imageForm.description"
        placeholder="Примечание"
        outlined
        type="textarea"
        no-error-icon
        :rules="[val => val.length <= 2000 || 'Не более 2000 символов']"
        dense
      />
    </div>
  </q-form>
</template>
<script setup>
import { ref, reactive, watch, computed, defineExpose, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const formRef = ref()

const file = ref()
const imageError = ref('')

const imageForm = reactive({
  description: ''
})

const emits = defineEmits(['close'])

const informations = computed(() => store.state.mapFilter.currentPage)

const saveImage = () => {
  const formData = new FormData()
  formData.append('comment', imageForm.description)
  formData.append('file', file.value)

  const formEl = formRef.value
  formEl.validate().then((result) => {
    if (result && !imageError.value) {
      store.commit('modals/setDisableModal', true)
      ApiService.map.createFile(informations.value.id, 'images', formData)
        .then(() => {
          store.commit('modals/setDisableModal', false)
          imageForm.description = ''
          store.dispatch('details/fetchImages', { id: informations.value.id })
          emits('close')
          $q.notify({
            type: 'positive',
            position: 'bottom-right',
            message: 'Успешно',
            timeout: 5000
          })
        })
        .catch((error) => {
          store.dispatch('auth/fetchUser')
          store.commit('modals/setDisableModal', false)

          const errorText = error?.response?.data?.message
          $q.notify({
            type: 'negative',
            position: 'bottom-right',
            message: errorText ? errorText : 'Ошибка',
            timeout: 5000
          })
        })
    }
  })
}

watch(file, (value) => {
  if (value) {
    const maxSize = 10 * 1024 * 1024
    if (file.value.size > maxSize) {
      imageError.value = 'Размер файла более 10Мб'
    } else {
      imageError.value = ''
    }
  }
})

defineExpose({ saveImage })
</script>
<style lang="scss" scoped>
.input-file {
  margin-bottom: 5px;
}
</style>
