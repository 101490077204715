const BASE_URL = '/private'
const BASE_URL_V2 = '/v2/private'

class SettingsService {
  constructor(api, apiPost) {
    this.api = api
    // this.apiPost = apiPost
  }

  getMaintenance() {
    return this.api.get(`${BASE_URL}/maintenance`)
  }

  getMapInfo() {
    return this.api.get(`${BASE_URL}/settings/map/info`)
  }

  getNews() {
    return this.api.get(`${BASE_URL}/settings/general/what-news`)
  }
}

export { SettingsService }
