<template>
  <div class="info-popup">
    <div v-if="!activePolygon || !activePolygon.id" class="info-popup__field">
      <p class="text-main">Загружается...</p>
    </div>
    <div v-if="activePolygon?.id" class="info-popup__field">
      <p class="title-text">Номер поля</p>
      <p class="text-main">{{ activePolygon.id }}</p>
    </div>
    <template v-for="attribute in attributes" :key="attribute">
      <div v-if="activePolygon?.props?.find((field) => field.name === attribute)" class="info-popup__field">
        <p class="title-text">{{ attribute }}</p>
        <p class="text-main" :class="{empty: checkEmptyData(attribute)}">{{ getValue(attribute) }}</p>
      </div>
    </template>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'

const props = defineProps(['activePolygon'])

const attributes = ['Сезон посадки', 'Площадь', 'Фактическая эксплуатация', 'Вид с/х угодий', 'Вид культур', 'Фактический пользователь', 'Статус проверки']

const getValue = (fieldName) => {
  const fieldValue = props.activePolygon?.props?.find((field) => field.name === fieldName)?.value
  if (Array.isArray(fieldValue)) {
    return fieldValue.join(',')
  }
  return fieldValue ?? 'Нет данных'
}

const checkEmptyData = (fieldName) => {
  const fieldValue = props.activePolygon?.props?.find((field) => field.name === fieldName)?.value
  if (Array.isArray(fieldValue)) {
    return false
  }

  return !fieldValue
    || fieldValue.toLowerCase() === 'нет данных'
    || fieldValue.toLowerCase() === 'данные не верны'
    || fieldValue.toLowerCase() === 'организация не указана'
}
</script>
<style lang="scss" scoped>
.info-popup {
  position: absolute;
  display: block;
  z-index: 100000;
  /*bottom: 0;*/
  /*right: 0;*/
  width: 224px;
  padding: 12px;
  background: #FFFFFF;
  border-radius: 4px;

  p {
    margin: 0;
  }

  &__field:not(:last-child) {
    margin-bottom: 5px;
  }

  .empty {
    color: $color-active;
  }

  .title-text {
    margin: 0;
    font-size: 10px;
    line-height: 1.2;
  }

  .text-main {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $color-main;

    &.empty {
      color: $negative;
    }
  }
}
</style>
