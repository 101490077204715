
const firesDateFrom = new Intl.DateTimeFormat('sv-SE').format(new Date(Date.now() - 24 * 60 * 60 * 1000))
const firesDateTo = new Intl.DateTimeFormat('sv-SE').format(new Date())

const thematicLayers = [
  {
    type: 'farm',
    group: 'thematic',
    name: 'osmlanduse_data',
    label: 'Типы ландшафта',
    value: 'osmlanduse',
    options: {
      type: 'raster',
      tiles: [
        'https://maps.heigit.org/osmlanduse/service/?service=wms&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&format=image/png&layers=osmlanduse:osm_lulc&styles&transparent=true&tiled=true&bbox={bbox-epsg-3857}',
      ]
    },
    layers: [
      {
        id: 'osmlanduse',
        type: 'raster',
        source: 'osmlanduse_data',
        'source-layer': 'osmlanduse_data',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'raster-opacity': 1
        }
      },
    ]
  },
  {
    type: 'farm',
    group: 'thematic',
    name: 'fire_data',
    label: 'Карта пожаров',
    value: 'fire',
    options: {
      type: 'raster',
      tiles: [
        `https://firms.modaps.eosdis.nasa.gov/mapserver/wms/fires/0725c12bb6af166e92c8dc34f41eac55?REQUEST=GetMap&layers=fires_modis&WIDTH=512&HEIGHT=512&TIME=${firesDateFrom}/${firesDateTo}&BBOX={bbox-epsg-3857}&srs=EPSG:3857`
      ]
    },
    layers: [
      {
        id: 'fire',
        type: 'raster',
        source: 'fire_data',
        'source-layer': 'fire_data',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'raster-opacity': 1
        }
      },
    ]
  },
  {
    type: 'farm',
    group: 'thematic',
    name: 'mo_dem',
    label: 'Карта высот',
    value: 'mo_dem_fill',
    options: {
      type: 'vector',
      tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/mo_dem_fill/{z}/{x}/{y}.mvt']
    },
    layers: [{
      id: 'mo_dem_fill',
      type: 'fill',
      source: 'mo_dem',
      'source-layer': 'mo_dem_fill',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'H'],
          0, '#E6E27F',
          50, '#DFD177',
          100, '#D8C070',
          150, '#D0AE69',
          200, '#C99D62',
          250, '#C18C5B',
          500, '#B97B54',
          750, '#B16A4E',
          1000, '#925245',
          1500, '#734339',
          2000, '#59342C',
        ],
        'fill-opacity': 0.1,
      },
    },]
  },
  {
    type: 'farm',
    group: 'thematic',
    name: 'mo_soilmap',
    label: 'Карта почв',
    value: 'mo_soilmap_fill',
    options: {
      type: 'vector',
      tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/mo_soilmap_fill/{z}/{x}/{y}.mvt']
    },
    layers: [{
      id: 'mo_soilmap_fill',
      type: 'fill',
      source: 'mo_soilmap',
      'source-layer': 'mo_soilmap_fill',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-color': [
          'match',
          ['get', 'DOMINANT'],
          ['Ареносоли'], '#FDDFDF',
          ['Гистосоли'], '#3EBEBD',
          ['Глайсоли'], '#849FFB',
          ['Грейзем'], '#4B6667',
          ['Камбисоли'], '#DDE032',
          ['Лептосоли'], '#BFBFBF',
          ['Подзолювисоли'], '#9E7A7A',
          ['Регосоли'], '#D8EACD',
          ['Солончаки'], '#FCBDDF',
          ['Файоземы'], '#C57C45',
          ['Флувисоли'], '#52FFDF',
          ['Черноземы'], '#1d3b52',
          '#FDDFDF',
        ],
        'fill-opacity': 0.6,
      },
    }]
  },
]

export { thematicLayers }
