const actions = {}

const mutations = {
  setIsBlockingMessages(state, data){
    state.isBlockingMessages = data
  },
  addLockedSection(state, data) {
    if (!state.lockedSections.includes(data)) {
      state.lockedSections = state.lockedSections.concat([data])
    }
  },
  removeLockedSection(state, data) {
    state.lockedSections = state.lockedSections.filter((section) => section !== data)
  },
  addLockedField(state, data) {
    state.lockedFields = state.lockedFields.concat([data])
  },
  removeLockedField(state, data) {
    state.lockedFields = state.lockedFields.filter((field) => field !== data)
  },
  setCountUpdate(state) {
    state.countUpdate += 1
  },
  setCountDelete(state) {
    state.countDelete += 1
  },
  setSubscribedSections(state, data) {
    if (!state.subscribedSections.includes(data)) {
      state.subscribedSections = [ ...state.subscribedSections, data ]
    }
  },
  setSubscribedFields(state, data) {
    state.subscribedFields = [ ...state.subscribedFields, data ]
  },
}

const state = () => ({
  isBlockingMessages: false,
  lockedSections: [],
  lockedFields: [],
  countUpdate: 0,
  countDelete: 0,
  subscribedSections: [],
  subscribedFields: []
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
