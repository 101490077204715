import api from '@/api'
import ApiService from '@/services'

const state = {
  jobs: [],
  meta: {},
  isLoadingKptHistory: false,
  users: [],
}

const actions = {
  fetchHistory({ commit }, params) {
    commit('setIsLoadingKptHistory', true)
    return new Promise((resolve, reject) => {
      api.get('/private/import/kpt/jobs', { params })
        .then((response) => {
          commit('setJobs', response.data.data)
          commit('setMeta', response.data.meta)
          commit('setIsLoadingKptHistory', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingKptHistory', false)
          reject(error)
        })
    })
  },
  fetchUsers({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.kpt.getUsers()
        .then((response) => {
          commit('setUsers', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  setJobs(state, data){
    state.jobs = data
  },
  setMeta(state, data){
    state.meta = data
  },
  setIsLoadingKptHistory(state, data){
    state.isLoadingKptHistory = data
  },
  setUsers(state, data) {
    state.users = data
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
