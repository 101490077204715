<template lang="pug">

</template>
<script>
import * as Turf from '@turf/turf'
import { municipalCenters as centers } from '../utils/map/centers'
import { addGeojsonLayer, removeGeojsonLayer } from '../utils/layers/geojson-layer'
import ApiService from '@/services'

export default {
  name: 'FocusSectionMap',
  props: ['map', 'focusPolygon', 'polygonStorage'],
  emits: ['setFocusPolygon', 'setMapCenter', 'removeGeojsonLayerHandlers', 'addGeojsonLayerHandlers'],
  watch: {
    cadastralByFilter: {
      handler() {
        if (this.cadastralByFilter?.hashes?.length === 1 && this.visibleEntity === 'sections') {
          this.focusSingleArea(this.cadastralByFilter.hashes[0])
        }
      },
      deep: true,
    },
    focusPolygon: {
      handler(value, oldValue) {
        if (value && !oldValue) {
          this.addFocusStyle(value)
        }

        if (!value && oldValue) {
          this.removeFocusStyle(oldValue)
        }

        if (value && oldValue) {
          this.removeFocusStyle(oldValue)
          this.addFocusStyle(value)
        }
      }, deep: true
    },
  },
  methods: {
    addGeojsonLayer,
    removeGeojsonLayer,
    focusSingleArea(hash) {
      if (this.map && hash) {
        ApiService.map.getGeoPolygon(hash)
          .then((response) => {
            if (response?.data?.geojson) {
              this.removeGeojsonLayer(this.map)
              this.$emit('removeGeojsonLayerHandlers')

              this.addGeojsonLayer({
                polygons: [{
                  type: 'Feature',
                  geometry: response.data.geojson,
                  properties: {
                    hash: response.data.hash,
                    layer: response.data.layer,
                    name: response.data.layer,
                    id: response.data.id
                  }
                }],
                map: this.map,
                color: '#000000',
                activeColor: '#dc1c0a'
              })

              this.$emit('addGeojsonLayerHandlers')

              this.$emit('setFocusPolygon', {
                id: response.data.id,
                source: response.data.layer,
                sourceLayer: response.data.layer
              })

              const currentZoom = this.map.getZoom()
              this.map.fitBounds(Turf.bbox(response.data.geojson), { maxZoom: currentZoom < 12 ? 12 : currentZoom })
            }
            // else {
            //   this.findCurrentSectionPolygon(hash)
            // }
          })
          // .catch(() => {
          //   this.findCurrentSectionPolygon(hash)
          // })
      }
    },
    findCurrentSectionPolygon(hash) { // TODO delete?
      if (
        this.currentSection?.form?.regionId &&
        centers[this.currentSection.form.regionId] &&
        this.user?.region?.value !== this.currentSection?.form?.regionId
      ) {
        this.$emit('setMapCenter', centers[this.currentSection.form.regionId])
      }

      let feature = null
      let count = 0
      const timer = setInterval(() => {
        count++

        if (this.currentSection && this.map) {
          feature = this.map
            .querySourceFeatures(this.currentSection.layer, {
              sourceLayer: this.currentSection.layer,
            })
            .filter((polygon) => polygon.properties.hash === hash)[0]

          if (feature) {
            setTimeout(() => {
              this.map.setFeatureState(
                {
                  source: this.currentSection.layer,
                  sourceLayer: this.currentSection.layer,
                  id: feature.id,
                },
                { active: false }
              )

              this.$emit('setFocusPolygon', {
                source: this.currentSection.layer,
                sourceLayer: this.currentSection.layer,
                id: feature.id,
              })

              const currentZoom = this.map.getZoom()
              this.map.fitBounds(Turf.bbox(feature.geometry), { maxZoom: currentZoom < 12 ? 12 : currentZoom })
            }, 1000)
          }

          if (feature || count > 50) {
            clearInterval(timer)
          }
          console.log('find')
        }
      }, 500)
    },
    addFocusStyle(value) {
      if (!this.currentPolygon && this.polygonStorage?.length === 0) {
        this.map.setFeatureState(
          {
            source: value.source,
            sourceLayer: value.sourceLayer,
            id: value.id,
          },
          { focus: true }
        )
      }

      if (!this.currentPolygon && this.polygonStorage?.length > 0) {
        if (this.map.getSource(value.source) && this.map.getSource('editLayer_data')) {
          this.map.setFeatureState(
            {
              source: value.source,
              sourceLayer: value.sourceLayer,
              id: value.id,
            },
            { focus: false, edited: true }
          )
        }

        this.map.setFeatureState(
          {
            source: 'editLayer_data',
            id: value.id,
          },
          { focus: true }
        )

        if (this.map.getSource('geojsonLayer_data')) {
          this.map.setFeatureState(
            {
              source: 'editLayer_data',
              id: value.id,
            },
            { focus: false }
          )

          this.map.setFeatureState(
            {
              source: 'geojsonLayer_data',
              id: value.id,
            },
            { focus: true }
          )
        }
      }

      if (this.currentPolygon && this.map.getSource(value.source) && this.map.getSource('geojsonLayer_data')) {
        this.map.setFeatureState(
          {
            source: value.source,
            sourceLayer: value.sourceLayer,
            id: value.id,
          },
          { focus: false, edited: true }
        )

        if (this.map.getSource('editLayer_data')) {
          this.map.setFeatureState(
            {
              source: 'editLayer_data',
              id: value.id,
            },
            { focus: false, edited: true }
          )
        }

        this.map.setFeatureState(
          {
            source: 'geojsonLayer_data',
            id: value.id,
          },
          { focus: true }
        )
      }
    },
    removeFocusStyle(oldValue) {
      if (this.map.getSource(oldValue.source)) {
        this.map.setFeatureState(
          {
            source: oldValue.source,
            sourceLayer: oldValue.sourceLayer,
            id: oldValue.id,
          },
          { focus: false }
        )
      }

      if (this.map.getSource('editLayer_data')) {
        this.map.setFeatureState(
          {
            source: 'editLayer_data',
            id: oldValue.id,
          },
          { focus: false }
        )
      }

      if (this.map.getSource('geojsonLayer_data')) {
        this.map.setFeatureState(
          {
            source: 'geojsonLayer_data',
            id: oldValue.id,
          },
          { focus: false }
        )
      }
    },
  },
  computed: {
    currentPolygon() {
      return this.$store.state.details.polygon
    },
    currentSection() {
      return this.$store.state.mapFilter.currentPage
    },
    user() {
      return this.$store.state.auth.user
    },
    cadastralByFilter() {
      return this.$store.state.mapFilter.cadastralByFilter
    },
    visibleEntity() {
      return this.$store.state.map.visibleEntity
    },
  }
}
</script>
