<template>
  <footer class="footer">
    <div class="content">
      <p>
        <span>Техническая поддержка:&nbsp;</span>
        <a href="mailto:platformsovhoz@yandex.ru">platformsovhoz@yandex.ru</a>
      </p>
      <div class="copyright">© SOVHOZ.AI. {{ getCurrentYear() }}</div>
    </div>
  </footer>
</template>
<script setup>
const getCurrentYear = () => {
  const date = new Date()
  const year = date.getFullYear()
  return year
}
</script>
<style lang="scss" scoped>
.footer {
  background-color: $color-main;
  color: #ffffff;

  .content {
    box-sizing: border-box;
    padding: 24px 30px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    p {
      margin: 0;
    }
  }
}
</style>
