const BASE_URL = '/private/import/kpt'
const BASE_URL_V2 = '/v2/private'

class KptService {
  constructor(api) {
    this.api = api
  }

  getKptJobs(params) {
    return this.api.get(`${BASE_URL}/jobs`, { params })
  }

  getDataKptJob(id) {
    return this.api.get(`${BASE_URL}/jobs/${id}/data`)
  }

  getUsers() {
    return this.api.get(`${BASE_URL}/users-list`)
  }
}

export { KptService }
