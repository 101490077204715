<template>
  <div class="modal" data-testid="modal">
    <div class="modal__container" :class="{ wide: isWide, full: isFullScreen }">
      <div class="modal__header">
        <p class="bold">{{title}}</p>
        <div class="modal__close" aria-label="Закрыть модалку">
          <CloseButton @close="$emit('closeModal')" />
        </div>
      </div>
      <q-scroll-area :style="style" :thumb-style="{ width: '5px' }" :horizontal-thumb-style="{ height: '5px' }">
        <div ref="contentRef" class="modal__content">
          <slot />
        </div>
      </q-scroll-area>
      <div class="modal__footer">
        <Button
          class="button"
          aria-label="Подтвердить"
          :disabled="isDisableConfirmModal"
          :text="textConfirm"
          :isLoad="isDisableModal"
          @click="$emit('confirmClick')"
        />
        <Button
          v-if="showClose"
          class="button"
          text="Отменить"
          type="stroke"
          aria-label="Отменить"
          @click="$emit('cancelClick')"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
// import PerfectScrollbar from 'perfect-scrollbar'
import Button from '@/components/uikit/buttons/Button'
import CloseButton from '@/components/uikit/buttons/CloseButton'

const store = useStore()
const contentRef = ref()
const height = ref(0)

const props = defineProps({
  showClose: {
    type: Boolean,
    default: false
  },
  isWide: {
    type: Boolean,
    default: false
  },
  isFullScreen: {
    type: Boolean,
    default: false
  },
  title: {
    default: '',
    type: String,
  },
  textConfirm: {
    default: 'Подтвердить',
  }
})

const emits = defineEmits(['closeModal', 'confirmClick', 'cancelClick'])

const width = computed(() => props.isFullScreen ? '99.9' : '100')
const style = computed(() => `
  width: ${width.value}%;
  height: ${height.value}px;
  max-height: 345px;
  flex-grow: 1;

  @media(max-height: 642px) {
    max-height: calc(100vh - 68px - 85px - 67px);
  }
`)
const isDisableModal = computed(() => store.state.modals.isDisableModal)
const isDisableConfirmModal = computed(() => store.state.modals.isDisableConfirmModal)

onMounted(() => {
  store.commit('modals/setDisableConfirmModal', false)
  height.value = contentRef.value.getBoundingClientRect()?.height
})
</script>
<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  @media(max-height: 642px) {
    top: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-height: 574px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    min-width: 480px;
    // height: calc(100vh - 68px);
    max-height: 574px;
    // min-height: 500px;

    // max-height: calc(100vh - 68px);

    @media(max-height: 642px) {
      // top: calc(50% + 34px);
      top: 50%;
      // max-height: calc(100vh - 68px);
      max-height: 100vh;
    }

    @include below('m_lg') {
      width: 480px;
      max-width: 100%;
      min-width: 480px;
    }

    @include below('m_md') {
      width: 100%;
      min-width: 100%;
    }
  }

  &__container.wide {
    min-width: 620px;
    max-width: 620px;

    @include below('m_lg') {
      width: 480px;
      max-width: 100%;
      min-width: 480px;
    }

    @include below('m_md') {
      width: 100%;
      min-width: 100%;
    }
  }

  &__container.full {
    min-width: 620px;
    max-width: 80%;
    width: 80%;

    @include below('m_lg') {
      max-width: 100%;
      min-width: 480px;
    }

    @include below('m_md') {
      width: 100%;
      min-width: 100%;
    }
  }

  &__content {
    box-sizing: border-box;
    padding: 30px;
    position: relative;
    // max-height: calc(100vh - 68px - 85px - 67px);
    // max-height: (500px - 155px);
    // flex-grow: 1;

    // @media(max-height: 642px) {
    //   max-height: calc(100vh - 68px - 85px - 67px);
    // }
  }

  &__close {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__footer {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 20px 30px;
    border-radius: 0 0 4px 4px;
    background-color: #F4F7FA;
    border-top: 1px solid $color-gray3;

    .button {
      width: calc(50% - 10px);
    }
  }

  .full &__footer {
    justify-content: center;
    gap: 20px;

    .button {
      max-width: 240px;
    }
  }

  &__header {
    box-sizing: border-box;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 20px 30px;
    border-radius: 4px 4px 0 0;
    background-color: #F4F7FA;
    border-bottom: 1px solid $color-gray3;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 18px;
    }
  }
}
</style>

