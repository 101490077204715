const removeKugiSchemesLayer = (map) => {
  if (map.getSource('kugi_scheme_data')) {
    map.removeLayer('kugi_scheme_fill')
    map.removeLayer('kugi_scheme_line')
    map.removeSource('kugi_scheme_data')
  }
}

const addKugiSchemesLayer = (params) => {
  const source = {
    type: 'FeatureCollection',
    features: params.polygons.map((el) => {
      return {
        id: el.id,
        type: 'Feature',
        geometry: el.geometry,
        properties: el.properties,
      }
    })
  }

  if (!params.map.getSource('kugi_scheme_data')) {
    params.map.addSource('kugi_scheme_data', {
      type: 'geojson',
      data: source
    })
  }

  params.map.addLayer({
    id: 'kugi_scheme_fill',
    type: 'fill',
    source: 'kugi_scheme_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'fill-color': ['case',
        ['boolean', ['feature-state', 'active'], false], '#dc1c0a',
        '#98ff98' // #ff00a6
      ],
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'active'], false], 0.7,
        ['boolean', ['feature-state', 'hover'], false], 0.5,
        0.4,
      ],
    },
  })

  params.map.addLayer({
    id: 'kugi_scheme_line',
    type: 'line',
    source: 'kugi_scheme_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-width':  ['case',
        ['boolean', ['feature-state', 'active'], false], 2,
        1
      ],
      'line-color': ['case',
        ['boolean', ['feature-state', 'active'], false], '#dc1c0a',
        '#98ff98' // #8c095b
      ],
    },
  })
}

export { addKugiSchemesLayer, removeKugiSchemesLayer }
