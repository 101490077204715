<template>
  <div v-if="isVisibleToggle" class="toggles">
    <a href="#map" :class="{active: view === 'map'}" @click="toggleMap" class="toggles__item toggles__item--left">Карта</a>
    <a href="#table" :class="{active: view === 'table'}" @click="toggleTable" class="toggles__item toggles__item--right">Списком</a>
    <button v-if="activeWinterPeriod" class="efis" @click="clickWinter">Озимый 2023</button>
    <button v-if="activeSummerPeriod" class="efis" @click="clickSummer">Яровой 2024</button>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const page = ref(1)

const view = computed(() => store.state.map.view)
const isVisibleSchemeLayer = computed(() => store.state.schemes.isVisibleSchemeLayer)
const informations = computed(() => store.state.mapFilter.currentPage)
const activeTileField = computed(() => store.state.fields.activeTileField)
const mode = computed(() => store.state.map.mode)
const activeWinterPeriod = computed(() => store.state.dictionaries.periods.find((period) => period.active && period.isWinter))
const activeSummerPeriod = computed(() => store.state.dictionaries.periods.find((period) => period.active && !period.isWinter))
const isVisibleToggle = computed(
  () => view.value === 'table'
    || (!isVisibleSchemeLayer.value
      && !informations.value
      && !activeTileField.value
      && mode.value !== 'edit-group'
      && mode.value !== 'group-edit-field'
    )
)

const toggleMap = () => {
  store.commit('mapFilter/setCurrentPage', null)
  store.commit('map/setView', 'map')
}

const toggleTable = () => {
  store.commit('mapFilter/setCurrentPage', null)
  store.commit('map/setView', 'table')
}

// const clickEfis = () => {
//   store.commit('map/setCountEfisFilter')
// }

const clickWinter = () => {
  store.commit('map/setCountWinterFilter')
}

const clickSummer = () => {
  store.commit('map/setCountSummerFilter')
}
</script>
<style lang="scss" scoped>
.toggles {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;

  @include below('t_lg') {
    display: none;
  }

  &__item {
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    height: 40px;
    border: 1px solid transparent;
    background-color: $color-gray1;
  }

  &__item.active {
    background-color: $color-active;
    color: #ffffff;
  }

  &__item--left {
    border-radius: 8px 0 0 8px;
  }

  &__item--right {
    border-radius: 0 8px 8px 0;
  }

  .efis {
    min-width: 80px;
    height: 40px;
    border: 1px solid transparent;
    background-color: $color-gray1;
    border-radius: 8px;
    margin-left: 10px;
    cursor: pointer;
    color: $color-main;
  }
}
</style>
