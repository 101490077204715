<template>
  <div class="rosreestr" data-testid="form">
    <q-form ref="formRef">
      <div class="rosreestr__field input" data-testid="comment">
        <span class="label-select">Описание</span>
        <q-input
          v-model="form.comment"
          :rules="[val => !!val || 'Обязательное поле', val => val.length >= 5 || 'Длина не менее 5 символов']"
          placeholder="Описание"
          outlined
          type="textarea"
          no-error-icon
          dense
        />
      </div>
    </q-form>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, defineExpose, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import ApiService from '@/services'

const store =useStore()
const $q = useQuasar()

const props = defineProps({
  editingPin: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['close', 'changeVisiblePinInfo'])

const formRef = ref()

const form = reactive({
  comment: '',
  id: null
})

const savePin = () => {
  const formEl = formRef.value
  formEl.validate().then((result) => {
    if (result) {
      store.commit('modals/setDisableModal', true)

      ApiService.map.updatePoint(form)
        .then(() => {
          emits('close')
          emits('changeVisiblePinInfo', false)
          store.commit('modals/setDisableModal', false)
          store.dispatch('map/fetchPoints')
          $q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Успешно',
            timeout: 5000
          })
        })
        .catch((error) => {
          store.dispatch('auth/fetchUser')
          store.commit('modals/setDisableModal', false)

          const errorText = error?.response?.data?.message
          $q.notify({
            type: 'negative',
            position: 'bottom',
            message: errorText ? errorText : 'Ошибка',
            timeout: 5000
          })
        })
    }
  })
}

onMounted(() => {
  form.comment = props.editingPin.comment
  form.id = props.editingPin.id
})

defineExpose({ savePin })
</script>

<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 250px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    display: block;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}

</style>
