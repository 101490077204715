<template>
  <Modal
    v-if="isShowCreateSchemeModal"
    v-show='!isShowCreateOrgModal && !isShowChangeOrgModal'
    title="Загрузить схему земельного участка"
    showClose
    @cancelClick="closeCreateSchemeModal"
    @closeModal="closeCreateSchemeModal"
    @confirmClick="$refs.createSchemeForm.saveScheme()"
  >
    <SchemeForm
      type="create"
      ref="createSchemeForm"
      @close="closeCreateSchemeModal"
    />
  </Modal>

  <Modal
    v-if="isShowUploadSchemeModal"
    title="Загрузить координаты"
    showClose
    @cancelClick="onCancelUploadScheme"
    @closeModal="onCancelUploadScheme"
    @confirmClick="$refs.uploadAreaForm.addPolygon()"
  >
    <UploadScheme ref="uploadAreaForm" @uploadPolygon="uploadPolygon" />
  </Modal>

  <Modal
    v-if="isShowChangeSchemeModal"
    v-show='!isShowCreateOrgModal && !isShowChangeOrgModal'
    title="Редактировать схему"
    showClose
    @cancelClick="closeChangeSchemeModal"
    @closeModal="closeChangeSchemeModal"
    @confirmClick="$refs.changeSchemeForm.saveScheme()"
  >
    <SchemeForm
      type="edit"
      ref="changeSchemeForm"
      :currentScheme="currentScheme"
      @close="closeChangeSchemeModal"
    />
  </Modal>

  <Modal
    v-if="isShowSolutionSchemeModal"
    title="Редактировать результаты рассмотрения"
    showClose
    @cancelClick="closeSolutionSchemeModal"
    @closeModal="closeSolutionSchemeModal"
    @confirmClick="$refs.solutionSchemeForm.updateScheme()"
  >
    <ChangeSolutionScheme
      ref="solutionSchemeForm"
      :currentScheme="currentScheme"
      @close="closeSolutionSchemeModal"
    />
  </Modal>

  <Modal
    v-if="isShowKnSchemeModal"
    title="Редактировать кадастровый номер"
    showClose
    @cancelClick="closeKnSchemeModal"
    @closeModal="closeKnSchemeModal"
    @confirmClick="$refs.knSchemeForm.updateScheme()"
  >
    <ChangeKnScheme
      ref="knSchemeForm"
      :currentScheme="currentScheme"
      @close="closeKnSchemeModal"
    />
  </Modal>

  <Modal
    v-if="isShowDeleteSchemeModal"
    title="Удалить схему"
    showClose
    @cancelClick="closeDeleteSchemeModal"
    @closeModal="closeDeleteSchemeModal"
    @confirmClick="deleteScheme"
  >
    Вы уверены, что хотите удалить схему?
  </Modal>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import Modal from '@/components/common/modals/Modal'
import SchemeForm from './forms/schemes/SchemeForm'
import UploadScheme from './forms/schemes/UploadScheme'
import ChangeSolutionScheme from './forms/schemes/ChangeSolutionScheme'
import ChangeKnScheme from './forms/schemes/ChangeKnScheme'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const isShowCreateSchemeModal = computed(() => store.state.modals.isShowCreateSchemeModal)
const isShowCreateOrgModal = computed(() => store.state.modals.isShowCreateOrgModal)
const isShowChangeOrgModal = computed(() => store.state.modals.isShowChangeOrgModal)
const isShowUploadSchemeModal = computed(() => store.state.modals.isShowUploadSchemeModal)
const isShowChangeSchemeModal = computed(() => store.state.modals.isShowChangeSchemeModal)
const isShowSolutionSchemeModal = computed(() => store.state.modals.isShowSolutionSchemeModal)
const isShowKnSchemeModal = computed(() => store.state.modals.isShowKnSchemeModal)
const isShowDeleteSchemeModal = computed(() => store.state.modals.isShowDeleteSchemeModal)
const schemesStorage = computed(() => store.state.schemes.schemesStorage)
const currentScheme = computed(() => store.state.schemes.currentScheme)
const deletedSchemes = computed(() => store.state.schemes.deletedSchemes)

const closeCreateSchemeModal = () => {
  store.commit('modals/setIsShowCreateSchemeModal', false)
  store.commit('map/setMode', 'default')
  store.commit('schemes/setGeometryScheme', null)
}

const closeChangeSchemeModal = () => {
  store.commit('modals/setIsShowChangeSchemeModal', false)
}

const closeSolutionSchemeModal = () => {
  store.commit('modals/setIsShowSolutionSchemeModal', false)
}

const closeKnSchemeModal = () => {
  store.commit('modals/setIsShowKnSchemeModal', false)
}

const closeDeleteSchemeModal = () => {
  store.commit('modals/setIsShowDeleteSchemeModal', false)
}

const onCancelUploadScheme = () => {
  store.commit('modals/setIsShowUploadSchemeModal', false)
  store.commit('schemes/setGeometryScheme', null)
  store.commit('map/setMode', 'default')
}

const uploadPolygon = (geometry) => {
  store.commit('schemes/setGeometryScheme', geometry)
}

const deleteScheme = () => {
  store.commit('modals/setDisableModal', true)

  ApiService.schemes.deleteScheme(currentScheme.value.form.id)
    .then(() => {
      store.commit('modals/setDisableModal', false)
      $q.notify({
        type: 'positive',
        position: 'bottom',
        message: 'Успешно',
        timeout: 5000
      })
      addToStorage(currentScheme.value.form.id)
      closeDeleteSchemeModal()
      store.commit('schemes/setActiveScheme', null)
      store.commit('schemes/setCurrentScheme', null)
      store.commit('schemes/setCountEditedScheme')
    })
    .catch((error) => {
      const errorText = error?.response?.data?.message
      $q.notify({
        type: 'negative',
        position: 'bottom',
        message: errorText ? errorText : 'Ошибка',
        timeout: 5000
      })
      store.dispatch('auth/fetchUser')
      store.commit('modals/setDisableModal', false)
    })
}

const addToStorage = (id) => {
  const schemePolygon = schemesStorage.value.filter((polygon) => polygon.properties.back_id === id)
  if (schemePolygon.length > 0) {
    const newStorage = [
      ...schemesStorage.value.filter((polygon) => polygon.properties.back_id !== id),
      {
        ...schemePolygon[0],
        properties: {
          ...schemePolygon[0].properties,
          deleted: true
        },
        createdAt: Date.now()
      }
    ]
    store.commit('schemes/setSchemesStorage', newStorage)
  } else {
    const schemes = [
      ...deletedSchemes.value,
      {
        createdAt: Date.now(),
        id: currentScheme.value.form.id
      }
    ]
    store.commit('schemes/setDeletedSchemes', schemes)
  }
}
</script>
