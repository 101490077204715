<template>
  <div class="rosreestr">
    <q-form ref="formRef" @validation-error="onValidateError">
      <div class="rosreestr__field rosreestr__field--validate native-input" data-testid="Муниципалитет">
        <Select
          label="Муниципалитет"
          v-model="form.regionId"
          :options="municipalities"
          :use-input="true"
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>

      <div class="rosreestr__field">Реквизиты заявления</div>

      <div class="rosreestr__field rosreestr__field--validate" data-testid="Заявленная площадь">
        <span class="label-select">Заявленная площадь, кв. м.</span>
        <q-input
          v-model="form.declaredArea"
          :rules="[val => !!val || 'Обязательное поле']"
          placeholder="Заявленная площадь, кв. м."
          outlined
          type="number"
          dense
        />
      </div>

      <div class="rosreestr__field rosreestr__field--datepicker" data-testid="Дата заявления">
        <div class="label-select">Дата заявления</div>
        <FlatpickrComponent
          dense
          :dateInit="type === 'create' ? date : currentScheme.form.schemaCreatedAt"
          :maxDate="new Date()"
          @update="(value) => date = value"
        />
        <div v-if="isErrorDate" class="text-negative error">Обязательное поле</div>
      </div>

      <div class="rosreestr__field rosreestr__field--validate" data-testid="Номер заявления">
        <span class="label-select">Номер заявления</span>
        <q-input
          v-model="form.schemaNumber"
          placeholder="Номер заявления"
          outlined
          dense
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>

      <CreateOrgButton />

      <div v-if="user.permissions?.includes('orgs_read')" class="rosreestr__field rosreestr__field--validate native-input" data-testid="user">
        <OrgsSelect
          v-model="form.schemaSubmitterId"
          label="Заявитель"
          :clearable="false"
          :multiple="false"
          :selectedOrgs="[form.schemaSubmitterId]"
          :rules="[val => !!val || 'Обязательное поле']"
        />

        <EditOrgButton :org="{ id: form.schemaSubmitterId }" />
      </div>

      <div class="rosreestr__field" data-testid="comment">
        <span class="label-select">Примечание</span>
        <q-input
          v-model="form.note"
          placeholder="Примечание"
          outlined
          type="textarea"
          no-error-icon
          dense
        />
      </div>
    </q-form>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch, defineProps, defineEmits, defineExpose, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import FlatpickrComponent from '@/components/common/datepicker/Flatpickr'
import EditOrgButton from '@/components/common/buttons/EditOrgButton'
import CreateOrgButton from '@/components/common/buttons/CreateOrgButton'
import OrgsSelect from '@/components/common/selects/OrgsSelect'
import Select from '@/components/common/selects/Select'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) => ['create', 'edit'].includes(value)
  },
  currentScheme: {
    type: Object,
    required: false,
    default: () => null
  }
})

const emits = defineEmits(['close'])
const formRef = ref()

const form = reactive({
  regionId: null,
  layer: 'sh_kugi',
  schemaNumber: '',
  schemaCreatedAt: '',
  schemaSubmitterId: '',
  note: '',
  declaredArea: ''
})

const date = ref('')
const isErrorDate = ref(null)


const user = computed(() => store.state.auth.user)
const municipalities = computed(() => store.state.dictionaries.regions)
const schemeXml = computed(() => store.state.schemes.schemeXml)
const geometryScheme = computed(() => store.state.schemes.geometryScheme)
const areaScheme = computed(() => store.state.schemes.areaScheme)
const schemesStorage = computed(() => store.state.schemes.schemesStorage)

const onValidateError = (ref) => {
  ref.$el.scrollIntoView()
}

const addToStorage = (id) => {
  const scheme = {
    id,
    type: 'Feature',
    geometry: geometryScheme.value,
    properties: {
      back_id: id,
      deleted: false,
      layer: 'sh_kugi',
      name: 'sh_kugi'
    },
    source: 'kugi_scheme_data',
    sourceLayer: 'kugi_scheme_data',
    createdAt: Date.now()
  }
  const newStorage = [
    ...schemesStorage.value,
    scheme
  ]
  store.commit('schemes/setSchemesStorage', newStorage)
}

const onError = (error) => {
  store.commit('modals/setDisableModal', false)
  const errorText = error?.response?.data?.message
  $q.notify({
    type: 'negative',
    position: 'bottom',
    message: errorText ? errorText : 'Ошибка',
    timeout: 5000
  })
}

const onSuccess = () => {
  store.commit('schemes/setCountEditedScheme')
  store.commit('modals/setDisableModal', false)
  emits('close')
  $q.notify({
    type: 'positive',
    position: 'bottom',
    message: 'Успешно',
    timeout: 5000
  })
}

const saveCreatingScheme = () => {
  const formEl = formRef.value

  if (!date.value) {
    isErrorDate.value = true
  }

  formEl.validate().then((result) => {
    if (result && !isErrorDate.value) {
      const formData = new FormData()
      formData.append('file', schemeXml.value)
      formData.append('region_id', form.regionId)
      formData.append('layer', form.layer)
      formData.append('schema_number', form.schemaNumber)
      formData.append('schema_created_at', form.schemaCreatedAt)
      formData.append('schema_submitter_id', form.schemaSubmitterId)
      formData.append('declared_area', form.declaredArea)
      formData.append('note', form.note)
      formData.append('area', areaScheme.value)
      formData.append('geometry', JSON.stringify(geometryScheme.value))

      store.commit('modals/setDisableModal', true)

      ApiService.schemes.createScheme(formData)
        .then((response) => {
          addToStorage(response.data.form.id)
          store.dispatch('schemes/fetchScheme', response.data.form.id)
          store.commit('schemes/setActiveScheme', {
            id: response.data.form.id,
            source: 'kugi_scheme_data'
          })

          onSuccess()
        })
        .catch((error) => {
          onError(error)
        })
    }
  })
}

const saveEditingScheme = () => {
  const formEl = formRef.value

  if (!date.value) {
    isErrorDate.value = true
  }

  formEl.validate().then((result) => {
    if (result && !isErrorDate.value) {
      store.commit('modals/setDisableModal', true)

      ApiService.schemes.updateScheme(form)
        .then(() => {
          store.dispatch('schemes/fetchScheme', form.id)

          onSuccess()
        })
        .catch((error) => {
          onError(error)
        })
    }
  })
}

const saveScheme = () => {
  if (props.type === 'create') {
    saveCreatingScheme()
  }

  if (props.type === 'edit') {
    saveEditingScheme()
  }
}

onMounted(() => {
  if (props.type === 'edit') {
    date.value = props.currentScheme?.form?.schemaCreatedAt ? new Date(props.currentScheme?.form?.schemaCreatedAt) : ''

    form.regionId = props.currentScheme?.form?.region
    form.schemaNumber = props.currentScheme?.form?.schemaNumber
    form.schemaSubmitterId = props.currentScheme?.form?.schemaSubmitter
    form.schemaCreatedAt = new Intl.DateTimeFormat('sv-SE').format(date.value),
    form.note = props.currentScheme?.form?.note
    form.declaredArea = props.currentScheme?.form?.declaredArea
    form.id = props.currentScheme?.form?.id
  }

  let ids = [1, 3626]

  if (form.schemaSubmitterId) {
    ids = [1, 3626, form.schemaSubmitterId]
  }
  store.dispatch('orgs/fetchOrgs', [ ...ids ])
})

watch(date, (value) => {
  if (value) {
    form.schemaCreatedAt = new Intl.DateTimeFormat('sv-SE').format(new Date(value))
    isErrorDate.value = false
  } else {
    isErrorDate.value = true
  }
})

watch(() => form.schemaSubmitterId, (value) => {
  if (value) {
    store.dispatch('orgs/fetchOrgs', [ value ])
  }
})

defineExpose({ saveScheme })
</script>

<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 250px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    position: relative;
    display: block;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &--validate:not(:last-child) {
      margin-bottom: 0;
    }

    &--validate:has(.q-field--error) {
      margin-bottom: 5px;
    }
  }

  &__field--datepicker:not(:last-child) {
    margin-bottom: 25px;
  }

  &__section {

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .input {
      width: 100%;
    }
  }

  .error {
    position: absolute;
    font-size: 11px;
    padding-left: 12px;
    padding-top: 8px;
  }
}
</style>
