import ApiService from '@/services'

const actions = {
  fetchReports({ commit }, page) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      ApiService.reports.getReports({ page })
        .then((response) => {
          commit('setReports', response.data)
          resolve(response.data)
          commit('setLoading', false)
        })
        .catch((error) => {
          reject(error)
          commit('setLoading', false)
        })
    })
  },
  fetchEfisReports({ commit }, page) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      ApiService.reports.getEfisReports({ page })
        .then((response) => {
          commit('setEfisReports', response.data)
          resolve(response.data)
          commit('setLoading', false)
        })
        .catch((error) => {
          reject(error)
          commit('setLoading', false)
        })
    })
  },
}

const mutations = {
  setReports(state, data){
    state.reports = data
  },
  setEfisReports(state, data){
    state.efisReports = data
  },
  setLoading(state, data){
    state.isLoading = data
  },
}

const state = () => ({
  reports: [],
  efisReports: [],
  isLoading: false
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
