<template>
  <div class="columns">
    <p v-if="overlapSections.length === 1" class="bold">{{ `${sectionForInheritace?.cadastralNumber} (${sectionForInheritace?.percent ?? 0}%)` ?? '' }}</p>
    <p v-else-if="overlapSections.length > 1">
      <q-select
        v-model="hashSectionForInheritace"
        outlined
        :options="overlapSections.map((section) => ({label: `${section.cadastralNumber} (${section.percent}%)`, value: section.hash }))"
        emit-value
        map-options
        dense
      />
    </p>
    <p></p>
    <p class="bold" :class="{head: overlapSections.length > 1}">{{ currentSection?.cadastralNumber ?? '' }}</p>
    <template v-for="attribute in attributeNames" :key="attribute">
      <p v-if="getAttributeValue(attribute, sectionForInheritace?.common)" class="name">
        {{ attribute }}
      </p>
      <p v-if="getAttributeValue(attribute, sectionForInheritace?.common)">
        <span class="attribute">
          {{ getAttributeValue(attribute, sectionForInheritace?.common) }}
        </span>
      </p>
      <p class="arrow" v-if="getAttributeValue(attribute, sectionForInheritace?.common)">
        <q-icon name="mdi-arrow-right" size="20px" />
      </p>
      <p v-if="getAttributeValue(attribute, sectionForInheritace?.common)">
        <span class="attribute">
          {{ getAttributeValue(attribute, currentSection?.common) }}
        </span>
      </p>
    </template>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, defineExpose, computed } from 'vue'
import { useQuasar } from 'quasar'
import ApiService from '@/services'

const $q = useQuasar()

const props = defineProps({
  currentSection: {
    type: Object,
    required: true
  },
  overlapSections: {
    type: Array,
    required: true
  }
})

const emits = defineEmits(['close', 'switchLoading', 'updateCurrentSection'])

const attributeNames = [
  'Статус границ',
  'Категория угодий',
  'Особо ценные угодья',
  'Категория прав собственности',
  'Категория пользования',
  'Правообладатель',
  'Номер договора',
  'Срок договора',
  'Примечания',
]

const attributesForCopy = [
  'kugiUseCategoryId',
  'landTypeId',
  'landOwnershipId',
  'especially',
  'landSurvey',
  'comment',
  'owners',
  'contractNumber',
  'contractStart',
  'contractEnd'
]

const getAttributeValue = (title, commonAttributes) => {
  const attribute = commonAttributes?.find((attr) => attr.name === title)
  let result = ''
  if (Array.isArray(attribute?.value)) {
    result = attribute?.value.join(', ')
  } else {
    result = attribute?.value ?? ''
  }
  return result
}

const hashSectionForInheritace = ref(props.overlapSections[0].hash)
const sectionForInheritace = computed(() => props.overlapSections?.find((section) => section.hash === hashSectionForInheritace.value) ?? null)

const saveAttributes = async () => {
  const form = {}

  attributesForCopy.forEach((attribute) => {
    if (typeof sectionForInheritace.value.form[attribute] !== 'undefined') {
      if (attribute === 'contractNumber' || attribute === 'contractStart' || attribute === 'contractEnd') {
        if (typeof form.contractData === 'undefined') {
          form.contractData = {}
        }
        form.contractData[attribute] = sectionForInheritace.value.form[attribute]
      } else {
        form[attribute] = sectionForInheritace.value.form[attribute]
      }
    }
  })

  if (Object.values(form).length > 0) {
    emits('switchLoading', true)
    try {
      await ApiService.map.updateArea({ ...form, id: props.currentSection.hash })
      emits('close')
      emits('updateCurrentSection')
      $q.notify({
        type: 'positive',
        position: 'bottom',
        message: 'Успешно',
        timeout: 5000
      })
    } catch (err) {
      const errorText = err?.response?.data?.message

      $q.notify({
        type: 'negative',
        position: 'bottom',
        message: errorText ? errorText : 'Ошибка',
        timeout: 5000
      })
    } finally {
      emits('switchLoading', false)
    }
  }

}

defineExpose({
  saveAttributes
})
</script>
<style lang="scss">
.columns {
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  row-gap: 10px;
  align-items: end;

  p {
    max-width: 190px;
    margin: 0;
  }

  .name {
    grid-column: 1 / 4;
    max-width: 420px;
  }

  .head {
    line-height: 40px;
  }
}

.attribute {
  display: block;
  box-sizing: border-box;
  border: 1px solid $color-gray3;
  border-radius: 4px;
  padding: 6px;
  min-height: 40px;
  width: 100%;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
