<template>
  <div class="info">
    <span v-if="field?.efisNumber">
      Номер ЕФИС:&nbsp;
      <b>{{ field.efisNumber }}</b>
    </span>
    <br v-if="field?.efisNumber">
    <span v-if="mainSection?.hash || otherSections.length">Номер(а)&nbsp;участка(ов): </span>
    <span v-if="mainSection?.cadastralNumber && mainSection?.hash">
      <b class="link">
        <router-link :to="`/map/${mainSection.hash}`" target="_blank">
          {{ mainSection.cadastralNumber }}
        </router-link>
      </b>
    </span>
    <template v-for="section, index in otherSections" :key="section.hash">
      <span v-if="mainSection?.hash">,&nbsp;</span>
      <span class="link">
        <router-link :to="`/map/${section.hash}`" target="_blank">
          {{ section.cadastralNumber }}
        </router-link>
      </span>
      <span v-if="index < otherSections?.length - 1">, </span>
    </template>
    <br v-if="otherSections.length || mainSection?.hash">
    <template v-for="attribute in attributes" :key="attribute">
      <span v-if="getValue(mainSection, attribute)">
        {{ attribute }}:
        <b>
          {{ getValue(mainSection, attribute) }}
        </b>
      </span>
      <br v-if="getValue(mainSection, attribute)">
    </template>
    <span v-if="otherSections?.length" class="more-info" @click="showMoreInfo">Еще</span>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const attributes = ['Особо ценные угодья', 'Категория прав собственности', 'Категория пользования', 'Правообладатель']

const props = defineProps(['field'])

const mainSection = computed(() => props.field.sections?.main ?? (props.field.sections?.mainBySumm ?? null))
const otherSections = computed(() => props.field.sections?.others?.length ? props.field.sections?.others : [])
const otherSectionsInfo = computed(() => attributes.map((item) => ({
  title: item,
  sections: otherSections.value.map((section) => ({
    cadastralNumber: section.cadastralNumber,
    value: getValue(section, item)
  }))
    .filter((section) => section.value)
})
)
  .filter((item) => item.sections?.length)
)

const getValue = (section, attribute) => {
  if (section?.common?.length) {
    const foundAttribute = section.common.find((item) => item.name === attribute)
    if (Array.isArray(foundAttribute?.value)) {
      return foundAttribute?.value?.join(' ,')
    }
    return foundAttribute?.value ?? ''
  }

  return ''
}

const showMoreInfo = () => {
  store.commit('modals/setIsShowSectionInfoModal', true)
  store.commit('fields/setSectionsByField', otherSectionsInfo.value)
}
</script>
<style lang="scss" scoped>
.info {
  margin-bottom: 12px;
}

.more-info {
  display: block;
  color: $color-active;
  text-decoration: underline dotted;
  cursor: pointer;
}

.link {
  text-decoration: underline;

  &:hover {
    color: $color-hover;
  }
}
</style>
