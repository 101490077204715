const clarification = {
  useCategory: {
    id: 1,
    content: `
      <p>
        <b>Свободные от прав</b> - земельные участки, находящиеся в государственной или муниципальной собственности, свободные от прав третьих лиц.
      </p>
      <br>
      <p>
        <b>Аренда</b> - договор аренды земельного участка, находящегося в государственной или муниципальной собственности, заключается на торгах, проводимых в форме аукциона, за исключением случаев, предусмотренных пунктом 2 статьи 39.6 ЗК РФ. Арендатор земельного участка вправе передать свои права и обязанности по договору аренды земельного участка третьему лицу. В указанных случаях ответственным по договору аренды земельного участка перед арендодателем становится новый арендатор земельного участка, за исключением передачи арендных прав в залог. При этом заключение нового договора аренды земельного участка не требуется.
      </p>
      <br>
      <p>
        <b>Постоянное бессрочное пользование</b> - предоставление земельного участка, находящегося в государственной или муниципальной собственности, в постоянное (бессрочное) пользование осуществляется на основании решения уполномоченного органа.<br>
        Земельные участки предоставляются в постоянное (бессрочное) пользование исключительно органам государственной власти и органам местного самоуправления; государственным и муниципальным учреждениям (бюджетным, казенным, автономным); казенным предприятиям; центрам исторического наследия президентов Российской Федерации, прекративших исполнение своих полномочий. Интересы перечисленных выше заявителей могут представлять иные юридические лица, уполномоченные заявителем в установленном порядке.
      </p>
      <br>
      <p>
        <b>Сервитут</b> - это право на ограниченное использование чужого недвижимого имущества в частных или общественных целях (проход, проезд, проведение коммуникаций и пр.) без нарушения имущественных прав собственника. Сервитут может быть установлен решением исполнительного органа государственной власти или органа местного самоуправления.
      </p>
      <br>
      <p>
        <b>Безвозмездное пользование</b> - в безвозмездное пользование могут предоставляться земельные участки, находящиеся в государственной или муниципальной собственности, на условиях и в порядке, которые установлены статьей 39.10 Земельного Кодекса, а также участки, находящиеся в собственности граждан или юридических лиц, иным гражданам или юридическим лицам на основании договора, в том числе в виде служебного надела.
      </p>
      <br>
      <p>
        <b>Пожизненное наследуемое владение</b> - право пожизненного наследуемого владения земельным участком, находящимся в государственной или муниципальной собственности, приобретается гражданами (физ.лицами) по основаниям и в порядке, которые предусмотрены земельным законодательством. Распоряжение земельным участком, находящимся в пожизненном наследуемом владении, не допускается, за исключением случая перехода права на земельный участок по наследству.
      </p>
    `
  },
  ownershipCategory: {
    id: 2,
    content: `
      <p>
        <b>Федеральная</b> - федеральной собственностью являются земли, не находящиеся в собственности граждан, юридических лиц, муниципальных образований или субъектотв РФ.
        В федеральной собственности находятся земельные участки:<br>
        · которые признаны таковыми федеральными законами;<br>
        · право собственности Российской Федерации на которые возникло при разграничении государственной собственности на землю;<br>
        · которые приобретены Российской Федерацией по основаниям, предусмотренным гражданским законодательством.
      </p>
      <br>
      <p>
        <b>Собственность субъекта</b> - собственностью субъекта являются земли:<br>
        · которые признаны таковыми федеральными законами;<br>
        · право собственности субъектов Российской Федерации на которые возникло при разграничении государственной собственности на землю;<br>
        · которые приобретены субъектами Российской Федерации по основаниям, предусмотренным гражданским законодательством;<br>
        · которые безвозмездно переданы субъектам Российской Федерации из федеральной собственности.
      </p>
      <br>
      <p>
        <b>Муниципальная</b> - к муниципальным землям отнесены те, которые располагаются в ведомстве муниципалитета:<br>
        · которые признаны таковыми федеральными законами и принятыми в соответствии с ними законами субъектов Российской Федерации;<br>
        · право муниципальной собственности на которые возникло при разграничении государственной собственности на землю;  которые приобретены по основаниям, установленным гражданским законодательством;<br>
        · которые безвозмездно переданы в муниципальную собственность из федеральной собственности.<br>
        <i>Примечание:<br>
        · если иное не предусмотрено другими федеральными законами, земельный участок, от права собственности на который собственник отказался, является с даты государственной регистрации прекращения права собственности на него собственностью городского округа, городского или сельского поселения либо в случае расположения такого земельного участка на межселенной территории собственностью муниципального района по месту расположения земельного участка;<br>
        · в собственность МО могут безвозмездно передаваться земли, находящиеся в собственности субъектов РФ, в собственности других МО, в целях их предоставления отдельным категориям граждан и (или) некоммерческим организациям, созданным гражданами.
        </i>
      </p>
      <br>
      <p>
        <b>Частная</b> - частной собственностью считаются земли, которые были приобретены за личные средства человека или юридического лица на законных основаниях.<br>
        <i>Примечание: земельные участки, находящиеся в государственной или муниципальной собственности, могут быть предоставлены в собственность граждан и юридических лиц, за исключением земельных участков, которые в соответствии с земельным кодексом или федеральными законами не могут находиться в частной собственности.</i>
      </p>
      <br>
      <p>
        <b>Земли, государственная собственность на которые не разграничена</b> - земельные участки, право государственной собственности на которые не было разграничено на момент вступления в силу ст. 3.1 Закона о введении в действие ЗК РФ. Предоставление земельных участков, государственная собственность на которые не разграничена, в отношении земельных участков, расположенных на территории сельского поселения, входящего в состав муниципального района, и земельных участков, расположенных на межселенных территориях муниципального района, осуществляется органом местного самоуправления муниципального района
      </p>
      <br>
      <p>
        <b>Долевая (паевая)</b> - это случай, когда земельным участком владеют два и более лиц с четко определенными долями для каждого собственника.<br>
        <i>Примечание: имущество, находящееся в долевой собственности, может быть разделено между ее участниками по соглашению между ними. Каждый участник долевой собственности вправе требовать выдела своей доли из общего имущества.</i>
      </p>
    `
  },
  owner: {
    id: 3,
    content: `
      <p>
        <b>Правообладатели</b> - собственники земельных участков, землепользователи, землевладельцы и арендаторы земельных участков.
      </p>
    `
  },
  landCategory: {
    id: 4,
    content: `
      <p>
        <b>Сельскохозяйственные угодья</b> - собственники земельных участков, землепользователи, землевладельцы и арендаторы земельных участков.
      </p>
      <p>
        <b>Несельскохозяйственные угодья</b> - земельные площади, включающие лесные земли; древесно-кустарниковые насаждения, не входящие в состав лесных земель; болота; земли под водой, дорогами, прогонами, постройками, улицами, переулками, площадями, дворами; прочие земли (земли, не используемые после добычи полезных ископаемых, овраги, горные хребты, ледники, каменистые земли), оленьи пастбища.
      </p>
    `
  }
}

export { clarification }
