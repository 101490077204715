const others = [
  {
    type: 'farm',
    group: 'other',
    name: 'markers',
    label: 'Отметки',
    value: 'markers',
    options: {
      type: 'geojson',
      data: {
        'type': 'FeatureCollection',
        'features': []
      }
    },
    layers: [{
      id: 'markers',
      type: 'symbol',
      source: 'markers',
      layout: {
        'visibility': 'none',
        'icon-image': '{icon}',
        'icon-allow-overlap': true
      }

    }]
  },
  {
    type: 'farm',
    group: 'other',
    name: 'mo_nkz_data',
    label: 'Новокузнецкий МО',
    value: 'mo_nkz_line',
    options: {
      type: 'vector',
      tiles: [(process.env.VUE_APP_GEOSERVER_JAVA_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/borders-nkz/{z}/{x}/{y}.mvt']
    },
    layers: [{
      id: 'mo_nkz_line',
      type: 'line',
      source: 'mo_nkz_data',
      'source-layer': 'borders-nkz',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'line-color': '#ed0933',
      }
    },]
  },
  {
    type: 'farm',
    group: 'other',
    name: 'mo_krap_data',
    label: 'Крапивинский МО',
    value: 'mo_krap_fill',
    options: {
      type: 'raster',
      tiles: [(process.env.VUE_APP_GEOSERVER_JAVA_URL || 'https://geo.sovhoz.tech/geoserver') + '/gwc/service/wmts?layer=sovhoz:mo_krap&tilematrixset=EPSG:900913&bbox=84.44775%2C52.1625%2C89.40275%2C56.8345&Service=WMTS&Request=GetTile&Version=1.0.0&format=image/png8&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}']
    },
    layers: [{
      id: 'mo_krap_fill',
      type: 'raster',
      source: 'mo_krap_data',
      'source-layer': 'mo_krap',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'raster-opacity': 1
      }
    },]
  },
  // {
  //   type: 'farm',
  //   group: 'other',
  //   name: 'research_contours_data',
  //   label: 'Контуры обследования',
  //   value: 'research_contours_fill',
  //   options: {
  //     type: 'raster',
  //     tiles: [(process.env.VUE_APP_GEOSERVER_JAVA_URL || 'https://geo.sovhoz.tech/geoserver') + '/gwc/service/wmts?layer=sovhoz:research_contours&tilematrixset=EPSG:900913&bbox=84.44775%2C52.1625%2C89.40275%2C56.8345&Service=WMTS&Request=GetTile&Version=1.0.0&format=image/png8&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}']
  //   },
  //   layers: [{
  //     id: 'research_contours_fill',
  //     type: 'raster',
  //     source: 'research_contours_data',
  //     'source-layer': 'research_contours',
  //     layout: {
  //       'visibility': 'none'
  //     },
  //   },]
  // },
  {
    type: 'non-farm',
    group: 'other',
    name: 'mo_substract',
    label: 'Прочие земли не с/х категории',
    value: 'mo_substract_fill',
    icon: 'unsuitable',
    options: {
      type: 'vector',
      tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/mo_substract_fill/{z}/{x}/{y}.mvt']
    },
    layers: [{
      id: 'mo_substract_fill',
      type: 'fill',
      source: 'mo_substract',
      'source-layer': 'mo_substract_fill',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-pattern': 'pattern-unsuitable',
      },
    },]
  },
  {
    type: 'non-farm',
    group: 'other',
    name: 'license',
    label: 'Лицензии на недропользование',
    icon: 'licence',
    value: 'license_fill',
    options: {
      type: 'vector',
      tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/license/{z}/{x}/{y}.mvt']
    },
    layers: [{
      id: 'license_fill',
      type: 'fill',
      source: 'license',
      'source-layer': 'license',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-pattern': 'pattern-subsoil-use'
      },
    },]
  },
]

export { others }
