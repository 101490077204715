import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

const setBroadcast = () => {
  const WS_HOST = process.env.VUE_APP_WS_HOST || 'lk.stage.sovhoz.tech'
  const WS_AUTH_ENDPOINT = process.env.VUE_APP_WS_AUTH_ENDPOINT || 'https://lk.stage.sovhoz.tech/api/broadcasting/auth'
  window.Pusher = Pusher
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '0fe1527536a01516908d',
    wsHost: WS_HOST,
    wsPath: '/ws',
    wsPort: 80,
    wssPort: 443,
    encrypted: true,
    forceTLS: true,
    // forceTLS: false,
    disableStats: true,
    cluster: 'ap1',
    enabledTransports: [
      'ws',
      'wss'
    ],
    authEndpoint: WS_AUTH_ENDPOINT,
    auth: {
      headers: {
        Authorization: 'Bearer ' + localStorage['token'],
        Accept: 'application/json',
      },
    },
  })
}

const setSectionSubscribe = (hash, callback) => {
  if (!window.Echo) {
    setBroadcast()
  }

  if (!window.Echo.channel(`section.${hash}`).subscription?.callbacks?._callbacks?.['_section-locked']) {
    window.Echo.channel(`section.${hash}`).listen('.section-locked', (data) => {
      callback({ data: data?.eventData, type: 'section-locked' })
    })
  }

  if (!window.Echo.channel(`section.${hash}`).subscription?.callbacks?._callbacks?.['_section-unlocked']) {
    window.Echo.channel(`section.${hash}`).listen('.section-unlocked', (data) => {
      callback({ data: data?.eventData, type: 'section-unlocked' })
    })
  }
}

const setFieldSubscribe = (fieldId, callback) => {
  if (!window.Echo) {
    setBroadcast()
  }

  if (!window.Echo.channel(`field.${fieldId}`).subscription?.callbacks?._callbacks?.['_field-locked']) {
    window.Echo.channel(`field.${fieldId}`).listen('.field-locked', (data) => {
      callback({ data: data?.eventData, type: 'field-locked' })
    })
  }

  if (!window.Echo.channel(`field.${fieldId}`).subscription?.callbacks?._callbacks?.['_field-unlocked']) {
    window.Echo.channel(`field.${fieldId}`).listen('.field-unlocked', (data) => {
      callback({ data: data?.eventData, type: 'field-unlocked' })
    })
  }
}

const setHarveSubscribe = (id, callback) => {
  if (!window.Echo) {
    setBroadcast()
  }

  if (!window.Echo.channel(`user.${id}`).subscription?.callbacks?._callbacks?.['_harvests-actualize']) {
    window.Echo.channel(`user.${id}`).listen('.harvests-actualize', (data) => {
      callback(data)
    })
  }
}

const setEnrichmentsSubscribe = (id, callback) => {
  if (!window.Echo) {
    setBroadcast()
  }

  if (!window.Echo.channel(`user.${id}`).subscription?.callbacks?._callbacks?.['_fertilizers-actualize']) {
    window.Echo.channel(`user.${id}`).listen('.fertilizers-actualize', (data) => {
      callback(data)
    })
  }
}

export default (id, callback) => {
  !window.Echo && setBroadcast()

  !window.Echo.private(`user.${id}`).subscription?.callbacks?._callbacks?.['_section-locked'] && window.Echo.private(`user.${id}`).listen('.section-locked', (data) => {
    callback({ data: data?.eventData, type: 'section-locked' })
  })

  !window.Echo.private(`user.${id}`).subscription?.callbacks?._callbacks?.['_field-locked'] && window.Echo.private(`user.${id}`).listen('.field-locked', (data) => {
    callback({ data: data?.eventData, type: 'field-locked' })
  })

  !window.Echo.private(`user.${id}`).subscription?.callbacks?._callbacks?.['_section-unlocked'] && window.Echo.private(`user.${id}`).listen('.section-unlocked', (data) => {
    callback({ data: data?.eventData, type: 'section-unlocked' })
  })

  !window.Echo.private(`user.${id}`).subscription?.callbacks?._callbacks?.['_field-unlocked'] && window.Echo.private(`user.${id}`).listen('.field-unlocked', (data) => {
    callback({ data: data?.eventData, type: 'field-unlocked' })
  })
}

export { setSectionSubscribe, setFieldSubscribe, setHarveSubscribe, setEnrichmentsSubscribe }
