<template>
  <Modal
    v-if="isShowCreateFieldModal"
    v-show="!isShowCreateOrgModal && !isShowChangeOrgModal"
    title="Создать поле"
    showClose
    @cancelClick="closeCreateFieldModal"
    @closeModal="closeCreateFieldModal"
    @confirmClick="$refs.createFieldForm.saveField()"
  >
    <FieldForm
      type="create"
      ref="createFieldForm"
      @cancelCreate="closeCreateFieldModal"
      @close="closeCreateFieldModal"
    />
  </Modal>

  <Modal
    v-if="isShowEditAttributesFieldModal"
    v-show="!isShowCreateOrgModal && !isShowChangeOrgModal"
    title="Редактировать атрибуты поля"
    showClose
    @cancelClick="closeEditAttributesFieldModal"
    @closeModal="closeEditAttributesFieldModal"
    @confirmClick="$refs.editFieldForm.saveField()"
  >
    <FieldForm
      :type="editingAttributesField.periodId ? 'edit' : 'edit-field'"
      ref="editFieldForm"
      :editingField="editingAttributesField"
      @cancelCreate="closeEditAttributesFieldModal"
      @close="closeEditAttributesFieldModal"
    />
  </Modal>

  <Modal
    v-if="isShowEnrichmentFieldModal"
    title="Атрибуты о внесении удобрений"
    showClose
    @cancelClick="closeEnrichmentFieldModal"
    @closeModal="closeEnrichmentFieldModal"
    @confirmClick="$refs.editEnrichmentFieldForm.saveField()"
  >
    <EnrichmentField
      ref="editEnrichmentFieldForm"
      :editingField="editingAttributesField"
      @close="closeEnrichmentFieldModal"
    />
  </Modal>

  <Modal
    v-if="isShowGroupEditFieldModal"
    v-show="!isShowCreateOrgModal && !isShowChangeOrgModal"
    title="Редактирование атрибутов"
    showClose
    @cancelClick="closeGroupEditFieldModal"
    @closeModal="closeGroupEditFieldModal"
    @confirmClick="$refs.editGroupFieldForm.saveField()"
  >
    <FieldForm
      type="group"
      ref="editGroupFieldForm"
      :editingFields="groupEditingFields"
      @cancelCreate="closeGroupEditFieldModal"
      @close="closeGroupEditFieldModal"
    />
  </Modal>

  <Modal
    v-if="isShowGroupEditOperationYearsModal"
    title="Редактирование атрибутов"
    showClose
    @cancelClick="closeGroupEditOperationYearsModal"
    @closeModal="closeGroupEditOperationYearsModal"
    @confirmClick="$refs.editOperationYearsForm.updateOperationYear()"
  >
    <EditOperationYearsForm
      type="group"
      ref="editOperationYearsForm"
      :editingFields="groupEditingFields"
      @cancelCreate="closeGroupEditOperationYearsModal"
      @close="closeGroupEditOperationYearsModal"
    />
  </Modal>

  <Modal
    v-if="isShowEditFieldModal"
    title="Изменить границы поля"
    showClose
    @cancelClick="closeEditFieldModal"
    @closeModal="closeEditFieldModal"
    @confirmClick="editFieldPolygon"
  >
    Вы уверены, что хотите изменить границы поля?
  </Modal>

  <Modal
    v-if="isShowDeleteFieldModal"
    title="Удалить поле"
    showClose
    @cancelClick="closeDeleteFieldModal"
    @closeModal="closeDeleteFieldModal"
    @confirmClick="deleteField"
  >
    Вы уверены, что хотите удалить поле?
  </Modal>

  <Modal
    v-if="isShowUndeleteFieldModal"
    title="Восстановить поле"
    showClose
    @cancelClick="closeUndeleteFieldModal"
    @closeModal="closeUndeleteFieldModal"
    @confirmClick="undeleteField"
  >
    Вы уверены, что хотите восстановить поле?
  </Modal>

  <Modal
    v-if="isShowCheckStatusFieldModal"
    title="Статус проверки поля"
    @closeModal="closeCheckStatusFieldModal"
    @confirmClick="$refs.checkStatusFieldForm.sendForm()"
  >
    <CheckStatusField
      ref="checkStatusFieldForm"
      :field="editingStatusField"
      @close="closeCheckStatusFieldModal"
    />
  </Modal>

  <Modal
    v-if="isFieldOverlapsModal"
    title="Проверка пересечений"
    showClose
    textConfirm='Посмотреть'
    @cancelClick="closeFieldOverlapsModal"
    @closeModal="closeFieldOverlapsModal"
    @confirmClick="showFieldOverlaps"
  >
    Найдены пересечения:<br>
    <span v-for="overlap in fieldOverlaps" :key="overlap.overlapsField.id">
      Поле: {{ overlap.overlapsField.id }}. Площадь пересечения {{ (Number(overlap.area) / 10000).toFixed(2) }}Га
      <br>
    </span>
    <span v-for="overlap in fieldOtherLayersOverlaps" :key="overlap.layer">
      Слой: {{ overlap.layer }}. Площадь пересечения {{ (Number(overlap.overlapArea) / 10000).toFixed(2) }}Га
      <br>
    </span>
  </Modal>

  <Modal
    v-if="isShowSplitFieldModal"
    title="Разделение поля"
    showClose
    textConfirm="Подтвердить"
    @cancelClick="closeSplitFieldModal"
    @closeModal="closeSplitFieldModal"
    @confirmClick="saveSplitField"
  >
    <p>Будут созданы поля:</p>
    <span v-for="field, index in newFieldsFromSplit" :key="index">
      Поле {{ index + 1 }}: {{ (field.area / 10000).toFixed(2) }}Га
      <br>
    </span>
  </Modal>

  <Modal
    v-if="isShowSectionInfoModal"
    title="Атрибуты участков"
    textConfirm="Закрыть"
    @closeModal="closeSectionInfoModal"
    @confirmClick="closeSectionInfoModal"
  >
    <table class="table">
      <tbody>
        <template v-for="group in sectionsByField" :key="group.title">
          <tr>
            <td colspan="2">
              <b>{{ group.title }}</b>
            </td>
          </tr>
          <tr v-for="item in group.sections" :key="item.cadastralNumber">
            <td>{{ item.cadastralNumber }}</td>
            <td>{{ item.value }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </Modal>

  <Modal
    title="Объединение полей"
    v-if="isShowJoinFieldsModal"
    :isFullScreen="true"
    showClose
    textConfirm="Подтвердить"
    @closeModal="closeJoinFieldsModal"
    @cancelClick="closeJoinFieldsModal"
    @confirmClick="$refs.joinFieldsForm.joinFields()"
  >
    <JoiningFields />
    <JoinFieldsForm ref="joinFieldsForm" />
  </Modal>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import Modal from '@/components/common/modals/Modal'
import CheckStatusField from './forms/fields/CheckStatusField'
import EnrichmentField from './forms/fields/EnrichmentField'
import FieldForm from './forms/fields/FieldForm'
import EditOperationYearsForm from './forms/fields/EditOperationYearsForm'
import JoiningFields from '@/modules/mapModals/components/tables/JoiningFields'
import JoinFieldsForm from '@/modules/mapModals/components/forms/fields/JoinFields'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const isShowCreateOrgModal = computed(() => store.state.modals.isShowCreateOrgModal)
const isShowChangeOrgModal = computed(() => store.state.modals.isShowChangeOrgModal)
const isShowCreateFieldModal = computed(() => store.state.modals.isShowCreateFieldModal)
const isShowEditAttributesFieldModal = computed(() => store.state.modals.isShowEditAttributesFieldModal)
const isShowGroupEditFieldModal = computed(() => store.state.modals.isShowGroupEditFieldModal)
const isShowGroupEditOperationYearsModal = computed(() => store.state.modals.isShowGroupEditOperationYearsModal)
const isShowEditFieldModal = computed(() => store.state.modals.isShowEditFieldModal)
const isShowDeleteFieldModal = computed(() => store.state.modals.isShowDeleteFieldModal)
const isShowUndeleteFieldModal = computed(() => store.state.modals.isShowUndeleteFieldModal)
const isShowCheckStatusFieldModal = computed(() => store.state.modals.isShowCheckStatusFieldModal)
const isFieldOverlapsModal = computed(() => store.state.modals.isFieldOverlapsModal)
const isShowEnrichmentFieldModal = computed(() => store.state.modals.isShowEnrichmentFieldModal)
const isShowSplitFieldModal = computed(() => store.state.modals.isShowSplitFieldModal)
const isShowSectionInfoModal = computed(() => store.state.modals.isShowSectionInfoModal)
const isShowJoinFieldsModal = computed(() => store.state.modals.isShowJoinFieldsModal)
const editingAttributesField = computed(() => store.state.fields.editingAttributesField)
const groupEditingFields = computed(() => store.state.fields.groupEditingFields)
const visibleEntity = computed(() => store.state.map.visibleEntity)
const activeTileField = computed(() => store.state.fields.activeTileField)
const editingField = computed(() => store.state.fields.editingField)
const deletingField = computed(() => store.state.fields.deletingField)
const undeletingField = computed(() => store.state.fields.undeletingField)
const editingStatusField = computed(() => store.state.fields.editingStatusField)
const fieldOverlaps = computed(() => store.state.fields.fieldOverlaps)
const fieldOtherLayersOverlaps = computed(() => store.state.fields.fieldOtherLayersOverlaps)
const newFieldsFromSplit = computed(() => store.state.fields.newFieldsFromSplit)
const splitingField = computed(() => store.state.fields.splitingField)
const sectionsByField = computed(() => store.state.fields.sectionsByField)

const closeSectionInfoModal = () => {
  store.commit('modals/setIsShowSectionInfoModal', false)
  store.commit('fields/setSectionsByField', [])
}

const closeCreateFieldModal = () => {
  store.commit('fields/setCreatingField', null)
  store.commit('modals/setIsShowCreateFieldModal', false)
}

const closeEditAttributesFieldModal = () => {
  store.commit('fields/setEditingAttributesField', null)
  store.commit('modals/setIsShowEditAttributesFieldModal', false)
}

const closeGroupEditFieldModal = () => {
  store.commit('fields/setGroupEditingFields', [])
  store.commit('modals/setIsShowGroupEditFieldModal', false)
}

const closeGroupEditOperationYearsModal = () => {
  store.commit('fields/setGroupEditingFields', [])
  store.commit('modals/setIsShowGroupEditOperationYearsModal', false)
}

const closeEditFieldModal = () => {
  store.commit('fields/setEditingField', null)
  store.commit('modals/setIsShowEditFieldModal', false)
}

const closeDeleteFieldModal = () => {
  store.commit('fields/setDeletingField', null)
  store.commit('modals/setIsShowDeleteFieldModal', false)
}

const closeUndeleteFieldModal = () => {
  store.commit('fields/setUndeletingField', null)
  store.commit('modals/setIsShowUndeleteFieldModal', false)
}

const closeCheckStatusFieldModal = () => {
  store.commit('fields/setEditingStatusField', null)
  store.commit('modals/setIsShowCheckStatusFieldModal', false)
}

const closeFieldOverlapsModal = () => {
  store.commit('modals/setIsFieldOverlapsModal', false)
  store.commit('fields/setFieldOverlaps', [])
  store.commit('fields/setFieldOtherLayersOverlaps', [])
  store.commit('fields/setIsShowFieldOverlaps', false)
}

const closeSplitFieldModal = () => {
  store.commit('modals/setIsShowSplitFieldModal', false)
  store.commit('fields/setNewFieldsFromSplit', [])
}

const closeEnrichmentFieldModal = () => {
  store.commit('modals/setIsShowEnrichmentFieldModal', false)
}

const closeJoinFieldsModal = () => {
  store.commit('modals/setIsShowJoinFieldsModal', false)
}

const showFieldOverlaps = () => {
  store.commit('fields/setIsShowFieldOverlaps', false)
  store.commit('fields/setIsShowFieldOverlaps', true)
  store.commit('modals/setIsFieldOverlapsModal', false)
}

const showSuccessMessage = () => {
  $q.notify({
    type: 'positive',
    position: 'bottom',
    message: 'Успешно',
    timeout: 5000
  })
}

const showErrorMessage = (error) => {
  const errorText = error?.response?.data?.message
  $q.notify({
    type: 'negative',
    position: 'bottom',
    message: errorText ? errorText : 'Ошибка',
    timeout: 5000
  })
}

const editFieldPolygon = () => {
  const id = editingField.value.id
  const geometry = editingField.value.geometry
  const area = editingField.value.area

  if (id && geometry) {
    store.commit('modals/setDisableModal', true)

    ApiService.fields.updatePolygonField(id, { geometry, area })
      .then(() => {
        if (visibleEntity.value === 'fields') {
          store.dispatch('fields/fetchTileField', activeTileField.value.id)
        }

        store.commit('fields/setEditingField', null)
        store.commit('modals/setDisableModal', false)
        showSuccessMessage()
        closeEditFieldModal()
        store.commit('fields/setView', 'default')
        store.commit('fields/setIsUpdatedPolygonField', true)
      })
      .catch((error) => {
        showErrorMessage(error)
        store.dispatch('auth/fetchUser')
        store.commit('modals/setDisableModal', false)
      })
  }
}

const deleteField = () => {
  store.commit('modals/setDisableModal', true)

  ApiService.fields.deleteField(deletingField.value)
    .then(() => {
      if (visibleEntity.value === 'fields') {
        store.commit('mapFilter/setEditCount')
        store.dispatch('fields/fetchTileField', activeTileField.value.id)
        store.commit('mapFilter/setEditFieldCount')
      }

      store.commit('fields/setDeletingField', null)
      store.commit('modals/setDisableModal', false)
      showSuccessMessage()
      closeDeleteFieldModal()
    })
    .catch((error) => {
      showErrorMessage(error)
      store.dispatch('auth/fetchUser')
      store.commit('modals/setDisableModal', false)
    })
}

const undeleteField = () => {
  store.commit('modals/setDisableModal', true)

  ApiService.fields.undeleteField(undeletingField.value)
    .then(() => {
      if (visibleEntity.value === 'fields') {
        store.commit('mapFilter/setEditCount')
        store.dispatch('fields/fetchTileField', activeTileField.value.id)
        store.commit('mapFilter/setEditFieldCount')
      }

      store.commit('fields/setUndeletingField', null)
      store.commit('modals/setDisableModal', false)
      showSuccessMessage()
      closeUndeleteFieldModal()
    })
    .catch((error) => {
      showErrorMessage(error)
      store.dispatch('auth/fetchUser')
      store.commit('modals/setDisableModal', false)
    })
}

const saveSplitField = () => {
  store.commit('modals/setDisableModal', true)
  ApiService.fields.splitField(
    splitingField.value.id,
    newFieldsFromSplit.value
  )
    .then(async (res) => {
      const fieldIds = res.data.map((field) => field.id)
      const geometryRequests = fieldIds.map((id) => ApiService.fields.getField(id))

      await Promise.all(geometryRequests)

      store.commit('modals/setDisableModal', false)
      if (visibleEntity.value === 'fields') {
        store.dispatch('fields/fetchTileField', activeTileField.value.id)
        store.commit('mapFilter/setEditFieldCount')
      }

      store.commit('fields/setEditingField', null)
      store.commit('fields/setIsUpdatedPolygonField', true)
      showSuccessMessage()
      closeSplitFieldModal()
    })
    .catch((error) => {
      showErrorMessage(error)
      store.dispatch('auth/fetchUser')
      store.commit('modals/setDisableModal', false)
    })
}
</script>

<style lang="scss">
.table {
  width: 100%;
}
</style>
