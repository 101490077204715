const BASE_URL = '/private'
// const BASE_URL_V2 = '/v2/private'

class ReportsService {
  constructor(api) {
    this.api = api
  }

  getReports(params) {
    return this.api.get(`${BASE_URL}/files`, { params })
  }

  getEfisReports(params) {
    return this.api.get(`${BASE_URL}/export/efis`, { params })
  }

  downloadEfisReport(id) {
    return this.api.get(`${BASE_URL}/export/efis/${id}/download`, { responseType: 'arraybuffer' })
  }

  createEfisReport(filter) {
    return this.api.post(`${BASE_URL}/export/efis`, filter)
  }
}

export { ReportsService }
