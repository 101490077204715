const addLayerMapType = (map) => {
  const activeLayer = localStorage.getItem('mapStyle') ? localStorage.getItem('mapStyle') : 'hybrid'
  if (map.getLayer('gl-draw-line-static.cold')) {
    map.addLayer({
      id: 'streets',
      source: { 'type': 'raster', 'tiles': ['https://mt0.google.com/vt/lyrs=m&hl=ru&x={x}&y={y}&z={z}'], 'tileSize': 256 },
      type: 'raster',
      layout: { visibility: activeLayer === 'streets' ? 'visible' : 'none' },
    }, 'gl-draw-line-static.cold')

    map.addLayer({
      id: 'satellite',
      source: { 'type': 'raster', 'tiles': ['https://mt0.google.com/vt/lyrs=s&hl=ru&x={x}&y={y}&z={z}'], 'tileSize': 256 },
      type: 'raster',
      layout: { visibility: activeLayer === 'satellite' ? 'visible' : 'none' },
    }, 'gl-draw-line-static.cold')

    // https://core-sat.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}&scale=1&lang=ru_RU

    map.addLayer({
      id: 'hybrid',
      source: { 'type': 'raster', 'tiles': ['https://mt1.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}'], 'tileSize': 256 },
      type: 'raster',
      layout: { visibility: activeLayer === 'hybrid' ? 'visible' : 'none' },
    }, 'gl-draw-line-static.cold')

    map.addLayer({
      id: 'background-1',
      type: 'background',
      paint: {
        'background-color': '#000000',
      },
      layout: { visibility: activeLayer === 'background' ? 'visible' : 'none' },
    }, 'gl-draw-line-static.cold')
  } else {
    map.addLayer({
      id: 'streets',
      source: { 'type': 'raster', 'tiles': ['https://mt0.google.com/vt/lyrs=m&hl=ru&x={x}&y={y}&z={z}'], 'tileSize': 256 },
      type: 'raster',
      layout: { visibility: activeLayer === 'streets' ? 'visible' : 'none' },
    })

    map.addLayer({
      id: 'satellite',
      source: { 'type': 'raster', 'tiles': ['https://mt0.google.com/vt/lyrs=s&hl=ru&x={x}&y={y}&z={z}'], 'tileSize': 256 },
      type: 'raster',
      layout: { visibility: activeLayer === 'satellite' ? 'visible' : 'none' },
    })

    map.addLayer({
      id: 'hybrid',
      source: { 'type': 'raster', 'tiles': ['https://mt1.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}'], 'tileSize': 256 },
      type: 'raster',
      layout: { visibility: activeLayer === 'hybrid' ? 'visible' : 'none' },
    })

    map.addLayer({
      id: 'background-1',
      type: 'background',
      paint: {
        'background-color': '#000000',
      },
      layout: { visibility: activeLayer === 'background' ? 'visible' : 'none' },
    })
  }
}

export { addLayerMapType }
