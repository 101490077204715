<template lang="pug">

</template>
<script>

export default {
  name: 'GroupMap',
  components: {

  },
  props: ['map', 'filterLayers', 'isLoadMap', 'countRepaintStorage'],
  emits: ['setLegendDefault'],
  data() {
    return {
      ownerGroup: [],
      userFactGroup: [],
      patterns: ['line', 'cell', 'star', 'sun', 'line-2', 'line-3'],
      patternOptions: [],
      mapStylesOptions: {}
    }
  },
  mounted() {
    this.mapStylesOptions = {
      layer: {
        group: this.layersGroup,
        attribute: 'name',
        legend:  this.layersGroup.map((item) => ({ ...item, label: item.name, checked: true })).filter((layer) => this.filterLayers.includes(layer.value))
      },
      ownership: {
        group: this.ownershipsGroup,
        attribute: 'land_ownership_id',
        legend: this.ownershipsGroup ? this.ownershipsGroup.map((item) => ({ ...item, label: item.name, checked: true })) : []
      },
      exploitation: {
        group: this.exploitationGroup,
        attribute: 'actual_exploitation_id',
        legend: this.exploitationGroup ? this.exploitationGroup.map((item) => ({ ...item, label: item.name, checked: true })) : []
      },
      land: {
        group: this.landGroup,
        attribute: 'land_type_id',
        legend: this.landGroup ? this.landGroup.map((item) => ({ ...item, label: item.name, checked: true })) : []
      },
      complexity: {
        group: this.complexityGroup,
        attribute: 'complexity_exploitation_id',
        legend: this.complexityGroup ? this.complexityGroup.map((item) => ({ ...item, label: item.name, checked: true })) : []
      },
      use: {
        group: this.useGroup,
        attribute: 'kugi_use_category_id',
        legend: this.useGroup ? this.useGroup.map((item) => ({ ...item, label: item.name, checked: true })) : []
      },
      owner: {
        group: [],
        attribute: 'owner_id',
        legend: []
      },
      factUser: {
        group: [],
        attribute: 'user_fact_id',
        legend: []
      }
    }


    // this.legendDefault = this.ownershipsGroup.map((item) => ({ ...item, label: item.name, checked: true }))
    // this.$emit('setLegendDefault', this.layersGroup
    //   .map((item) => ({ ...item, label: item.name, checked: true }))
    //   .filter((layer) => this.filterLayers.includes(layer.value))
    // )

    this.$emit('setLegendDefault', this.layersGroup)

  },
  watch: {
    countRepaintStorage() {
      const isPattern = this.groupMode === 'owner' || this.groupMode === 'factUser'

      this.setStyleStoragePolygons(this.mapStylesOptions[this.groupMode].group, this.mapStylesOptions[this.groupMode].attribute, isPattern)
    },
    isLoadMap(value) {
      if (value) {
        // this.setStyle(this.ownershipsGroup, 'land_ownership_id')
        this.setStyle(this.layersGroup, 'name')
        // this.map.loadImage(
        //   require('@/assets/icons/pattern-cell.png'),
        //   (err, image) => {
        //     if (err) throw err
        //     this.map.addImage('pattern-cell', image)
        //   }
        // )

        // this.map.loadImage(
        //   require('@/assets/icons/pattern-star.png'),
        //   (err, image) => {
        //     if (err) throw err
        //     this.map.addImage('pattern-star', image)
        //   }
        // )

        // this.map.loadImage(
        //   require('@/assets/icons/pattern-sun.png'),
        //   (err, image) => {
        //     if (err) throw err
        //     this.map.addImage('pattern-sun', image)
        //   }
        // )

        // this.map.loadImage(
        //   require('@/assets/icons/pattern-line.png'),
        //   (err, image) => {
        //     if (err) throw err
        //     this.map.addImage('pattern-line', image)
        //   }
        // )

        // this.map.loadImage(
        //   require('@/assets/icons/pattern-line-2.png'),
        //   (err, image) => {
        //     if (err) throw err
        //     this.map.addImage('pattern-line-2', image)
        //   }
        // )

        // this.map.loadImage(
        //   require('@/assets/icons/pattern-line-3.png'),
        //   (err, image) => {
        //     if (err) throw err
        //     this.map.addImage('pattern-line-3', image)
        //   }
        // )
      }
    },
    ownershipsGroup: {
      handler() {
        this.mapStylesOptions.ownership = {
          group: this.ownershipsGroup,
          attribute: 'land_ownership_id',
          legend: this.ownershipsGroup.map((item) => ({ ...item, label: item.name, checked: true }))
        }

        // this.$emit('setLegendDefault', this.ownershipsGroup.map((item) => ({ ...item, label: item.name, checked: true })))

      // if (this.map._loaded) {
      //   this.setStyle(this.ownershipsGroup, 'land_ownership_id')
      // }
      }, deep: true },
    landGroup: {
      handler() {
        this.mapStylesOptions.land = {
          group: this.landGroup,
          attribute: 'land_type_id',
          legend: this.landGroup.map((item) => ({ ...item, label: item.name, checked: true }))
        }
      }, deep: true },
    exploitationGroup: {
      handler() {
        this.mapStylesOptions.exploitation = {
          group: this.exploitationGroup,
          attribute: 'actual_exploitation_id',
          legend: this.exploitationGroup.map((item) => ({ ...item, label: item.name, checked: true }))
        }
      }, deep: true },
    complexityGroup: {
      handler() {
        this.mapStylesOptions.complexity = {
          group: this.complexityGroup,
          attribute: 'complexity_exploitation_id',
          legend: this.complexityGroup.map((item) => ({ ...item, label: item.name, checked: true }))
        }
      }, deep: true },
    useGroup: {
      handler() {
        this.mapStylesOptions.use = {
          group: this.useGroup,
          attribute: 'kugi_use_category_id',
          legend: this.useGroup.map((item) => ({ ...item, label: item.name, checked: true }))
        }
      }, deep: true },
    groupMode(value) {
      // if (value === 'owner') {
      //   if (this.orgs.length < 1) {
      //     this.$store.commit('mapFilter/setIsLoadingFilter', true)
      //     this.$store.dispatch('dictionaries/fetchOrgs')
      //       .then(() => {
      //         this.ownerGroup = this.orgs.slice().filter((org) => org.ownerArea > 10).sort((a, b) => b.ownerArea - a.ownerArea)
      //         const colors = this.randomColor(this.ownerGroup.length)
      //         this.mapStylesOptions.owner.group = this.ownerGroup
      //           .map((org, i) => ({
      //             ...org,
      //             value: Number(org.value),
      //             color: colors[i],
      //             pattern: this.patterns[this.getRandomNumber(0, this.patterns.length - 1)]
      //           }))
      //         this.setStyle(this.mapStylesOptions.owner.group, this.mapStylesOptions.owner.attribute, true)
      //         this.$emit('setLegendDefault', [])
      //         this.$store.commit('mapFilter/setIsLoadingFilter', false)
      //       })
      //       .catch(() => {
      //         this.$store.commit('mapFilter/setIsLoadingFilter', false)
      //       })
      //   } else {
      //     this.ownerGroup = this.orgs.slice().filter((org) => org.ownerArea > 10).sort((a, b) => b.ownerArea - a.ownerArea)
      //     const colors = this.randomColor(this.ownerGroup.length)
      //     this.mapStylesOptions.owner.group = this.ownerGroup
      //       .map((org, i) => ({
      //         ...org,
      //         value: Number(org.value),
      //         color: colors[i],
      //         pattern: this.patterns[this.getRandomNumber(0, this.patterns.length - 1)]
      //       }))
      //     this.setStyle(this.mapStylesOptions.owner.group, this.mapStylesOptions.owner.attribute, true)
      //     this.$emit('setLegendDefault', [])
      //   }
      //   return
      // }

      // if (value === 'factUser') {
      //   if (this.orgs.length < 1) {
      //     this.$store.commit('mapFilter/setIsLoadingFilter', true)
      //     this.$store.dispatch('dictionaries/fetchOrgs')
      //       .then(() => {
      //         this.userFactGroup = this.orgs.slice().filter((org) => org.userFactArea > 10).sort((a, b) => b.userFactArea - a.userFactArea)
      //         const colors = this.randomColor(this.userFactGroup.length)
      //         this.mapStylesOptions.factUser.group = this.userFactGroup
      //           .map((org, i) => ({
      //             ...org,
      //             value: Number(org.value),
      //             color: colors[i],
      //             pattern: this.patterns[this.getRandomNumber(0, this.patterns.length - 1)]
      //           }))
      //         this.setStyle(this.mapStylesOptions.factUser.group, this.mapStylesOptions.factUser.attribute, true)
      //         this.$emit('setLegendDefault', [])
      //         this.$store.commit('mapFilter/setIsLoadingFilter', true)
      //       })
      //       .catch(() => {
      //         this.$store.commit('mapFilter/setIsLoadingFilter', false)
      //       })
      //   } else {
      //     this.userFactGroup = this.orgs.slice().filter((org) => org.userFactArea > 10).sort((a, b) => b.userFactArea - a.userFactArea)
      //     const colors = this.randomColor(this.userFactGroup.length)

      //     this.mapStylesOptions.factUser.group = this.userFactGroup
      //       .map((org, i) => ({
      //         ...org,
      //         value: Number(org.value),
      //         color: colors[i],
      //         pattern: this.patterns[this.getRandomNumber(0, this.patterns.length - 1)]
      //       }))
      //     this.setStyle(this.mapStylesOptions.factUser.group, this.mapStylesOptions.factUser.attribute, true)
      //     this.$emit('setLegendDefault', [])
      //   }
      //   return
      // }

      const { group, attribute, legend } = this.mapStylesOptions[value]

      this.setStyle(group, attribute)
      this.$emit('setLegendDefault', legend)
    },
    // filterLayers: {
    // handler(value) {
    //   this.mapStylesOptions.layer = {
    //     group: this.layersGroup,
    //     attribute: 'name',
    //     legend:  this.layersGroup.map((item) => ({ ...item, label: item.name, checked: true })).filter((layer) => this.filterLayers.includes(layer.value))
    //   }

    //   if (this.groupMode === 'layer') {
    //     this.$emit('setLegendDefault', this.layersGroup
    //       // .map((item) => ({ ...item, label: item.name, checked: true }))
    //       // .filter((layer) =>
    //       //   this.filterLayers.includes(layer.value)
    //       // )
    //     )

    //     if (this.map._loaded) {
    //       this.setStyle(this.layersGroup, 'name')
    //     }
    //   }
    // }, deep: true }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    groupMode() {
      return this.$store.state.map.groupMode
    },
    layersGroup() {
      // return this.$store.state.map.layersGroup.filter((layer) =>
      //   this.filterLayers.includes(layer.value)
      // )
      return this.$store.state.map.layersGroup
    },
    ownershipsGroup() {
      return this.$store.state.dictionaries.landOwnerships
    },
    exploitationGroup() {
      return this.$store.state.dictionaries.actualExploitations
    },
    complexityGroup() {
      return this.$store.state.dictionaries.complexityExploitation
    },
    useGroup() {
      return this.$store.state.dictionaries.kugiUseCategories
    },
    landGroup() {
      return this.$store.state.dictionaries.landTypes
    },
    orgs() {
      return this.$store.state.orgs.orgsByIds
        .filter((category) => !category.isHidden)
        .map(({ id, name, ownerArea, userFactArea }) => ({ value: id.toString(), name, ownerArea, userFactArea }))
    },
  },
  methods: {
    getRandomNumber(min, max) {
      return Math.round(Math.random() * (max - min) + min)
    },
    randomColor(number) {
      const array = []

      while (array.length < number) {
        const r = this.getRandomNumber(50, 255)
        const g = this.getRandomNumber(50, 255)
        const b = this.getRandomNumber(50, 255)
        const color = `rgb(${r}, ${g}, ${b})`
        if (!array.includes(color)) {
          array.push(color)
        }
      }
      return array
    },
    setStyleStoragePolygons(group, attribute, isPattern = false) {
      if (this.groupMode === 'layer') {
        return
      }

      const options = ['case',
        ['boolean', ['feature-state', 'edited'], false], 'transparent',
        ['boolean', ['feature-state', 'selected'], false], 'transparent',
        ['boolean', ['feature-state', 'active'], false], '#dc1c0a']

      group.forEach((item) => {
        options.push(['==', ['get', attribute], item.value])
        const color = item.color ? item.color : '#8f9c99'
        options.push(color)
      })

      if (this.map.getSource('editLayer_data')) {
        this.map.setPaintProperty('editLayer_fill','fill-color', [...options, ['==', ['get', 'name'], 'old'], '#05bdfa', '#8f8a89'])
        this.map.setPaintProperty('editLayer_line','line-color', [...options, ['==', ['get', 'name'], 'old'], '#05bdfa', '#8f8a89'])
        this.map.setPaintProperty('editLayer_fill','fill-opacity', [
          'case',
          ['boolean', ['feature-state', 'edited'], false], 0,
          ['boolean', ['feature-state', 'active'], false], 0.5,
          ['boolean', ['feature-state', 'focus'], false], 1,
          ['boolean', ['feature-state', 'hover'], false], 0.4,
          0.2,
        ])
      }

      if (isPattern && this.map.getSource('editLayer_data')) {
        this.map.setLayoutProperty('editLayer_pattern', 'visibility', 'visible')
        this.map.setPaintProperty('editLayer_pattern','fill-pattern', [...this.patternOptions, 'pattern-cell'])
      } else if (!isPattern && this.map.getSource('editLayer_data')) {
        this.map.setLayoutProperty('editLayer_pattern', 'visibility', 'none')
      }
    },
    setStyle(group, attribute, isPattern = false) {
      const options = ['case',
        ['boolean', ['feature-state', 'edited'], false], 'transparent',
        ['boolean', ['feature-state', 'selected'], false], 'transparent',
        ['boolean', ['feature-state', 'active'], false], '#dc1c0a']

      group.forEach((item) => {
        options.push(['==', ['get', attribute], item.value])
        const color = item.color ? item.color : '#8f9c99'
        options.push(color)
      })

      const patternOptions = ['case']
      if (isPattern) {
        group.forEach((item) => {
          patternOptions.push(['==', ['get', attribute], item.value])
          const pattern = item.pattern ? `pattern-${item.pattern}` : 'pattern-cell'
          patternOptions.push(pattern)
        })
      }

      this.patternOptions = patternOptions

      const layers = this.layersGroup.map((layer) => layer.value)
      layers.forEach((layer) => {
        if (this.user.permissions?.includes(`layers_${layer}`)) {
          this.map.setPaintProperty(`${layer}_fill`,'fill-color', [...options, ['==', ['get', 'name'], 'old'], '#05bdfa', '#8f8a89'])
          this.map.setPaintProperty(`${layer}_line`,'line-color', [...options, ['==', ['get', 'name'], 'old'], '#05bdfa', '#8f8a89'])
          this.map.setPaintProperty(`${layer}_fill`,'fill-opacity', [
            'case',
            ['boolean', ['feature-state', 'edited'], false], 0,
            ['boolean', ['feature-state', 'active'], false], 0.5,
            ['boolean', ['feature-state', 'focus'], false], 1,
            ['boolean', ['feature-state', 'hover'], false], 0.4,
            0.2,
          ])

          if (isPattern) {
            this.map.setLayoutProperty(`${layer}_pattern`, 'visibility', 'visible')
            this.map.setPaintProperty(`${layer}_pattern`,'fill-pattern', [...patternOptions, 'pattern-cell'])
          } else {
            this.map.setLayoutProperty(`${layer}_pattern`, 'visibility', 'none')
          }
        }
      })


      if (isPattern) {
        this.setStyleStoragePolygons(group, attribute, true)
      } else {
        this.setStyleStoragePolygons(group, attribute)
      }
    },
  }
}
</script>
