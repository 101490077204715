// import Vue from 'vue'

const processPermissionDirective = (el, binding, vnode) => {
  if (binding.value === '' || !binding.value) {
    el.style.display = el._display
  } else if (!vnode.context.$store.state.auth.user.permissions.includes(binding.value)) {
    el.style.display = 'none'
  } else {
    el.style.display = el._display
  }
}

const accessDirective = {
  beforeMount: (el, binding, vnode) => {
    el._display = el.style.display
    processPermissionDirective(el, binding, vnode)
  },
  updated: (el, binding, vnode) => {
    processPermissionDirective(el, binding, vnode)
  }
}

export { accessDirective }
