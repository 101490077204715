const BASE_URL = '/private'
const BASE_URL_V2 = '/v2/private'

class OrgService {
  constructor(api) {
    this.api = api
  }

  createOrg(org) {
    return this.api.post(`${BASE_URL}/dictionaries/orgs`, org)
  }

  getOrg(params) {
    return this.api.get(`${BASE_URL}/dictionaries/orgs`, { params })
  }

  changeOrg(id, org) {
    return this.api.put(`${BASE_URL}/dictionaries/org/${id}`, org)
  }

  getOwners(params) {
    return this.api.get(`${BASE_URL}/dictionaries/orgs/owners`, { params })
  }

  getFactUsers(params) {
    return this.api.get(`${BASE_URL}/dictionaries/orgs/fact-users`, { params })
  }

  getOrgs(params) {
    return this.api.get(`${BASE_URL}/dictionaries/orgs/table`, { params })
  }

  // orgType, search
  // orgType  ['INDIVIDUAL', 'LEGAL']
  getCompanyHints(params) {
    return this.api.get(`${BASE_URL}/org/suggest_company_name`, { params })
  }

  // name, parts
  getPersonHints(params) {
    return this.api.get(`${BASE_URL}/org/suggest_people_name`, { params })
  }

  getSearchOrgs(params) {
    return this.api.get(`${BASE_URL}/dictionaries/orgs/search`, { params })
  }

  getApprovedOrgs(params) {
    return this.api.get(`${BASE_URL}/dictionaries/orgs/search-approved`, { params })
  }

  replaceOrg(id, params) {
    return this.api.patch(`${BASE_URL}/dictionaries/org/${id}`, { ...params })
  }

  deleteOrg(id) {
    return this.api.delete(`${BASE_URL}/dictionaries/org/${id}`)
  }

  exportOrgs(params) {
    return this.api.get(`${BASE_URL}/dictionaries/orgs/export`, { params })
  }
}

export { OrgService }
