<template lang="pug">

</template>
<script>
import ApiService from '@/services'

export default {
  name: 'OverlapsMap',
  data() {
    return {
      editingPolygonsWithoutCurrent: [],
      deletingOverlaps: [],
      deletingSections: [],
    }
  },
  watch: {
    countConfirmCheckPolygonWithoutCurrent() {
      this.confirmCheckPolygonWithoutCurrent()
    },
    countDeletingOverlaps() {
      this.saveWithoutCurrentPolygons()
    },
    editingPolygonsWithoutCurrent: {
      handler(value) {
        if (value.length === 0) {
          this.$store.commit('mapFilter/setIsLoadingFilter', false)
          this.$store.commit('mapFilter/setEditCount')
        }
      }, deep: true
    }
  },
  methods: {
    confirmCheckPolygonWithoutCurrent() {
      const intersections = this.overlaps.map((overlap, index) => ({
        ...overlap,
        action: this.actions[index],
      }))

      const overlapsWithActions = []
      const deletingSections = []

      intersections.forEach(({ overlapsSegment, segment, action, area }) => {
        if (action === 'edit') {
          if (overlapsSegment.area - area < 0.1) {
            deletingSections.push(overlapsSegment.hash)
          } else {
            overlapsWithActions.push({ segment: overlapsSegment, overlapsSegment: segment })
          }
        }

        if (action === 'cut') {
          overlapsWithActions.push({ segment, overlapsSegment })
        }
      })

      this.deletingOverlaps = overlapsWithActions
      this.deletingSections = deletingSections

      this.$store.commit('modals/setDisableModal', false)
      this.$store.commit('mapFilter/setIsShowCheckOverlaps', false)
      this.$store.commit('modals/setIsShowCheckPolygonModal', false)
      this.$store.commit('modals/setIsShowEditWithoutCurrentModal', true)
    },
    saveWithoutCurrentPolygons() {
      this.deletingOverlaps.forEach((overlap) => {
        if (overlap?.segment?.hash) {
          this.$store.commit('subscribes/setSubscribedSections', overlap?.segment?.hash)
        }
      })

      if (this.deletingOverlaps.length > 0) {
        this.$store.commit('modals/setDisableModal', true)
        ApiService.overlaps.deleteOverlaps(this.deletingOverlaps)
          .then(() => {
            this.deletingOverlaps = []
            this.$store.commit('modals/setDisableModal', false)
            this.$store.commit('modals/setIsShowEditWithoutCurrentModal', false)
            this.$store.dispatch('mapFilter/fetchCurrentPage', { id: this.$route.params.id })
          })
          .catch((error) => {
            this.$store.commit('modals/setDisableModal', false)
            const errorText = error?.response?.data?.message
            this.$q.notify({
              type: 'negative',
              position: 'bottom',
              message: errorText ? errorText : 'Ошибка',
              timeout: 5000
            })
          })
      }

      if (this.deletingSections.length > 0) {
        this.$store.commit('modals/setDisableModal', true)

        Promise.allSettled(this.deletingSections.map((hash) => ApiService.map.deleteArea(hash)))
          .finally(() => {
            this.$store.commit('mapFilter/setIsLoadingFilter', false)
            this.$store.commit('modals/setDisableModal', false)
            this.$store.commit('modals/setIsShowEditWithoutCurrentModal', false)

            this.deletingSections = []
            this.$store.commit('mapFilter/setEditCount')
          })
      }

      if (this.deletingOverlaps.length < 1 && this.deletingSections.length < 1) {
        this.$store.commit('modals/setIsShowEditWithoutCurrentModal', false)
      }
    },
  },
  computed: {
    overlaps() {
      return this.$store.state.map.overlaps
    },
    countConfirmCheckPolygonWithoutCurrent() {
      return this.$store.state.map.countConfirmCheckPolygonWithoutCurrent
    },
    countDeletingOverlaps() {
      return this.$store.state.map.countDeletingOverlaps
    },
    actions() {
      return this.$store.state.map.actions
    },
    isShowCheckOverlaps() {
      return this.$store.state.mapFilter.isShowCheckOverlaps
    },
    currentPolygon() {
      return this.$store.state.details.polygon
    },
  }
}
</script>
