// eslint-disable-next-line
import { createStore } from 'vuex'

import Auth from '@/modules/auth'
import { details, fields, hoverInfo, files, map, mapFilter, schemes } from '@/modules/map'
import events from '@/modules/events'
// import { reviewer, sections } from '@/modules/withoutBorders'
import reports from '@/modules/reports'
import dashboard from '@/modules/dashboard'
import kpt from '@/modules/kpt'
import modals from '@/modules/mapModals'
import passports from '@/modules/passports'
import register from '@/modules/registers'
import dictionaries from './dictionaries'
import subscribes from '@/modules/subscribes'
import settings from './settings'
import orgs from './orgs'
// import statistics from '@/modules/statistics'
// import areaCheck from '@/modules/areaCheck'
// import questionnaires from '@/modules/questionnaires'

import api from '@/api'

const store = {
  modules: {
    auth: Auth,
    // sections,
    details,
    // areaCheck,
    mapFilter,
    // questionnaires,
    // reviewer,
    reports,
    // statistics,
    dictionaries,
    events,
    hoverInfo,
    map,
    dashboard,
    kpt,
    modals,
    passports,
    schemes,
    fields,
    files,
    register,
    subscribes,
    settings,
    orgs
  },
  state: {
    isShowMap: true,
    polygons: [],
    filters: null,
    selectedRegion: null,
  },
  mutations: {
    setIsShowMap(state, data) {
      state.isShowMap = data
    },
    setPolygons(state, data) {
      state.polygons = data
    },
    setMapRectangles(state, data) {
      state.polygons = data
    },
    setSelectedRegions(state, data) {
      state.selectedRegion = data
    },
    setFilters(state, data) {
      state.filters = data
    },
  },
  actions: {
    showMap({ commit }, data) {
      commit('setIsShowMap', data)
    },
    selectRegion({ commit }, data) {
      commit('setSelectedRegions', data)
    },
    fetchFilters({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('/private/sections/filters')
          .then((response) => {
            let data
            if (response.data && response.data.statuses) {
              data = {
                ...response.data,
                statuses: [{ name: 'Все', value: null }].concat(response.data.statuses)
              }
            } else {
              data = response.data
            }
            commit('setFilters', data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchPolygons({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('/private/polygons')
          .then((response) => {
            commit('setPolygons', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}

export { store }

export default createStore(store)

