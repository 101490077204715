<template>
  <div class="slider">
    <Swiper
      class="slider__container"
      ref="swiper"
      :style="{'--swiper-navigation-color': '#fff'}"
      :grabCursor="true"
      :zoom="true"
      :options="swiperOptions"
      :navigation="true"
      :modules="modules"
      :initialSlide="activeSlide"
      @activeIndexChange="onChangeSlide"
    >
      <SwiperSlide
        class="slider__slide"
        v-for="slide, index in images" :key="slide.original.id"
      >
        <div class="swiper-zoom-container">
          <img
            :src="slide.original.url"
            :style="{draggable: scale[index] > 1, transform: `translate3D(0, 0, 0) scale(${scale[index]}) rotate(${rotate[index]}deg)`}" class="slider__image"
          >
        </div>
        <span class="slider__description">
          Дата добавления: {{slide.original.createdAt}}
          <br>
          Пользователь: {{slide.original.user}}
          <br>
          Примечание: {{slide.original.comment}}
          <br>
          <a
            :href="slide.original.url"
            target="_blank"
            download
            title="Скачать"
            v-access="imagesDownloadAccess"
            class="slider__link"
          >
            <span>Скачать</span>
            <svg width="10" height="14" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.015625 15H13.9844V17.0156H0.015625V15ZM13.9844 6L7 12.9844L0.015625 6H4V0H10V6H13.9844Z" fill="#ffffff" />
            </svg>
          </a>
        </span>
      </SwiperSlide>
    </Swiper>

    <div class="slider__controls">
      <button class="slider__rotate" @click="rotateLeftSlide" title="Повернуть влево">
        <svg viewBox="0 0 24 24" width="24" height="24">
          <path d="M7.11 8.53L5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z" fill="#ffffff" />
        </svg>
      </button>
      <button class="slider__rotate" @click="rotateRightSlide" title="Повернуть вправо">
        <svg viewBox="0 0 24 24" width="24" height="24">
          <path d="M15.55 5.55L11 1v3.07C7.06 4.56 4 7.92 4 12s3.05 7.44 7 7.93v-2.02c-2.84-.48-5-2.94-5-5.91s2.16-5.43 5-5.91V10l4.55-4.45zM19.93 11c-.17-1.39-.72-2.73-1.62-3.89l-1.42 1.42c.54.75.88 1.6 1.02 2.47h2.02zM13 17.9v2.02c1.39-.17 2.74-.71 3.9-1.61l-1.44-1.44c-.75.54-1.59.89-2.46 1.03zm3.89-2.42l1.42 1.41c.9-1.16 1.45-2.5 1.62-3.89h-2.02c-.14.87-.48 1.72-1.02 2.48z" fill="#ffffff" />
        </svg>
      </button>
      <button class="slider__close" @click="closeSlider" title="Закрыть">
        <svg viewBox="0 0 24 24" width="24" height="24">
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#ffffff" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { Navigation, Zoom } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/navigation'

const store = useStore()

const modules = [Navigation, Zoom]
const swiperOptions = {
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction'
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  zoom: {
    maxRatio: 2,
    minRatio: 1
  }
}

const props = defineProps({
  images: {
    type: Array,
    required: true,
    default: () => []
  },
  activeSlide: {
    type: Number,
    required: true,
    default: () => 0
  }
})

const emits = defineEmits(['close'])

const rotate = ref({})
const scale = ref({})
const currentIndex = ref(0)

const imagesDownloadAccess = computed(() => `map_${store.state.mapFilter?.currentPage?.layer}_download_images`)

const onChangeSlide = (swiper) => {
  currentIndex.value = swiper.activeIndex
}

const closeSlider = () => {
  emits('close')
}

const rotateLeftSlide = () => {
  rotate.value = { ...rotate.value }
  rotate.value[currentIndex.value] -= 90
  scale.value = { ...scale.value }
}

const rotateRightSlide = () => {
  rotate.value = { ...rotate.value }
  rotate.value[currentIndex.value] += 90
  scale.value = { ...scale.value }
}

onMounted(() => {
  props.images.forEach((slide, index) => {
    rotate.value = { ...rotate.value, [index]: 0 }
    scale.value = { ...scale.value, [index]: 1 }
  })
  currentIndex.value = props.activeSlide
})
</script>

<style lang='scss' scoped>
  .slider {
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &__link {
      display: flex;
      align-items: center;

      span {
        margin-right: 5px;
      }
    }

    &__container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__controls {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;

      button {
        width: 46px;
        height: 46px;
        background-color: transparent;
        border: 0;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &__slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(#000, 0.8);
    }

    &__image {
      width: auto;
      height: auto;
      max-height: calc(100vh - 180px);
      max-width: 100vw;
    }

    &__description {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 15px;
      color: #fff;
      background-color: #000;
    }

    .swiper-button-prev {
      position: absolute;
      z-index: 1;
      width: 44px;
      height: 44px;
      // color: #fff;
      top: calc(50% - 22px);
      left: 10px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 5px;
        width: 36px;
        height: 36px;
        border-left: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
        transform: rotate(45deg);
      }
    }

    .swiper-button-next {
      position: absolute;
      z-index: 1;
      width: 44px;
      height: 44px;
      // color: #fff;
      top: calc(50% - 22px);
      right: 10px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 5px;
        width: 36px;
        height: 36px;
        border-right: 3px solid #ffffff;
        border-top: 3px solid #ffffff;
        transform: rotate(45deg);
      }
    }

    .swiper-pagination {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      padding: 15px;
      bottom: unset;
      width: 100%;
      text-align: left;
      color: #fff;
      background-color: #000;
    }
  }
</style>
