const BASE_URL = '/private/docs/passport-agro'
// const BASE_URL_V2 = '/v2/private'

class PassportsService {
  constructor(api) {
    this.api = api
  }

  getPassport(params) {
    return this.api.get(`${BASE_URL}/view`, { params })
  }

  getPassportDates(params) {
    return this.api.get(BASE_URL, { params })
  }

  getPassportDoc(id) {
    return this.api.get(`${BASE_URL}/download/${id}`, { responseType: 'arraybuffer' })
  }

  createPassport(id) {
    return this.api.post(BASE_URL, { regionId: id })
  }

  signPassport(params) {
    return this.api.post(`${BASE_URL}/sign/${params.id}`, params)
  }
}

export { PassportsService }
