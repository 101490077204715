<template>
  <div class="history-card" data-testid="card">
    <div class="history-card__date" :class="{ failed: history.failed }">
      <span>
        {{ history.createdAt }}
      </span>
    </div>
    <div class="history-card__user item">
      <div class="label">
        Пользователь внесший изменение
      </div>
      <Paragraph size="sm" weight="middle" data-testid="user">
        {{ history.user }}<br>
        {{ history.area }}
      </Paragraph>

    </div>
    <div class="history-card__changes" v-for="(param, paramIndex) in history.params" :key="paramIndex">
      <div class="item">
        <div class="label">
          Параметр
        </div>
        <Paragraph size="sm" weight="middle" data-testid="name">
          {{ param.name }}
        </Paragraph>
      </div>
      <div v-if="param.oldValue" class="item">
        <div class="label">
          Старое значение:
        </div>
        <Paragraph size="sm" weight="middle" data-testid="oldValue">
          {{ truncateText(param.oldValue) }}
        </Paragraph>
      </div>
      <div class="item" v-if="param.name === 'Координаты' && param.oldValue && param.oldValue !== 'Пусто'">
        <Button class="button" type="stroke" :text="!oldPolygon ? 'Показать на карте' : 'Отменить'" @click="showOldPolygon(param.oldValue)" data-testid="button" />
      </div>
      <div v-if="param.newValue" class="item">
        <div class="label">
          Новое значение:
        </div>
        <Paragraph size="sm" weight="middle" data-testid="newValue">
          {{ truncateText(param.newValue) }}
        </Paragraph>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from 'vue'
import { useStore } from 'vuex'
import Button from '@/components/uikit/buttons/Button'

const store = useStore()

defineProps(['history'])

const oldPolygon = computed(() => store.state.map.oldPolygon)

const showOldPolygon = (polygon) => {
  if (oldPolygon.value) {
    store.commit('map/setOldPolygon', null)
  } else {
    const geometry = JSON.parse(polygon).geometry ? JSON.parse(polygon).geometry : JSON.parse(polygon)
    store.commit('map/setOldPolygon', { type: 'Feature', geometry })
  }
}

const truncateText = (text) => {
  if (!text) {
    return ''
  }

  if (text.length > 140) {
    return `${text.slice(0, 140)}...`
  }

  return text
}
</script>

<style lang="scss" scoped>
.history-card {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__changes {
    padding: 20px 0;
    border-top: 1px solid $color-gray2;
    border-bottom: 1px solid $color-gray2;
  }

  &__date {
    color: white;
    position: relative;
    display: inline-block;
    padding: 4px 10px 4px 0;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    &:before {
      content: '';
      background-color: $color-gray5;
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      left: -29px;
      width: calc(29px + 100%);
      height: 100%;
      z-index: 1;
      border-radius: 0 4px 4px 0;
    }

    span {
      position: relative;
      z-index: 2;
    }
  }

  .button {
    width: 100%;
  }
}
.item {
  word-break: break-word;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}


.label {
  color: $color-gray5;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px;
}

.history-card__date.failed:before {
  background-color: #F25749;
}

</style>
