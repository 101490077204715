<template>
  <div class="container">
    <q-scroll-area :style="maintenanceNotice ? 'height: calc(100vh - 68px - 40px)' : 'height: calc(100vh - 68px)'" :thumb-style="{ width: '5px', opacity: '0.5' }">
      <div class="wrapper">
        <h2 class="title">Объединение полей</h2>
        <p>
          <span v-if="fields.length < 10">Выбрано полей:&nbsp;</span>
          <span v-if="fields.length >= 10">Выбрано максимальное количество полей:&nbsp;</span>
          {{ fields.length }} ({{ getTotalArea(fields.map((field) => field.area)) }}га)
        </p>
        <hr v-if="fields.length > 0">
        <p v-for="item in fields" :key="item.id">Номер поля: {{ item.id }} ({{ getArea(item.area) }}га)</p>
        <Button
          class="button"
          v-if="fields.length > 1"
          :disabled="!isAutomerging"
          type="stroke"
          :text="mergingType === 'auto' ? 'Отменить объединение' : 'Автоматическое объединение'"
          @click="autoMerge"
        />

        <Button
          class="button"
          v-if="fields.length > 1"
          type="stroke"
          :text="mergingType === 'points' ? 'Отменить объединение' : 'Объединение по точкам'"
          @click="mergeFromPoints"
        />

        <Button
          class="button"
          v-if="fields.length > 1"
          type="stroke"
          :text="mergingType === 'polygon' ? 'Отменить объединение' : 'Объединение полигоном'"
          @click="mergePolygons"
        />

        <div v-if="mergingType === 'polygon' && !savingField" class="buttons">
          <button
            class="icon-button"
            :class="{ active: stateMapdraw === 'edit' }"
            title="Полигон"
            @click="onToolClick('edit')"
          >
            <EditPolygonIcon :width="19" :height="19" />
          </button>

          <button
            class="icon-button"
            :class="{ active: stateMapdraw === 'paintMode' }"
            title="Линия"
            @click="onToolClick('paintMode')"
          >
            <PaintIcon :width="24" :height="24" />
          </button>
        </div>

        <Button
          class="button"
          v-if="savingField && fields.length > 1"
          type="stroke"
          text="Сохранить"
          @click="saveJoiningField"
        />
      </div>
    </q-scroll-area>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, computed, watch, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import * as Turf from '@turf/turf'
import Button from '@/components/uikit/buttons/Button'
import PaintIcon from '@/modules/map/components/icons/PaintIcon'
import EditPolygonIcon from '@/components/uikit/icons/EditPolygonIcon'

import ApiService from '@/services'

const store = useStore()

const props = defineProps({
  fields: {
    type: Array,
    required: true
  },
  mapDraw: {
    type: Object,
    required: false
  },
  drawnPolygons: {
    type: Object,
    required: false
  },
})

const emits = defineEmits(['addJoiningPolygon', 'removeJoiningPolygon', 'setPolygonPainting'])

const automergingFields = ref([])
const savingField = ref(null)
const mergingType = ref('') // ['auto', 'points', 'polygon', '']
const stateMapdraw = ref('') // ['paintMode', 'edit']

const bufferedUnionPolygon = computed(() => {
  const unionPolygon = Turf.union(...automergingFields.value)
  const bufferedUnionPolygon = Turf.buffer(unionPolygon, -0.15, { units: 'meters' })
  return bufferedUnionPolygon
})

const isAutomerging = computed(() => bufferedUnionPolygon.value.geometry.type === 'Polygon')

const maintenanceNotice = computed(() => store.state.settings.maintenanceNotice)

const saveJoiningField = () => {
  ApiService.fields.getFieldsInfo(props.fields.map((field) => field.id))
    .then((res) => {
      store.commit('fields/setJoiningFields', res.data)
      store.commit('modals/setIsShowJoinFieldsModal', true)
    })
}

const getArea = (area) => {
  const value = area ? Number(area) : 0
  const areaGa = value / 10000

  return areaGa.toFixed(2)
}

const getTotalArea = (areas) => {
  const totalArea = areas.reduce((total, current) => {
    const value = current ? Number(current) : 0
    total += value
    return total
  }, 0)

  return getArea(totalArea)
}

const checkPolygonArea = (polygon) => {
  ApiService.fields.checkPolygonField({ geometry: polygon })
    .then((res) => {
      if (res.data?.area) {
        store.commit('fields/setJoiningFieldsPolygon', { area: res.data.area, geometry: polygon })
      }
    })
    .catch(() => {})
}

const onToolClick = (tool) => {
  stateMapdraw.value === tool ? stateMapdraw.value = '' : stateMapdraw.value = tool
}

const cancelMerge = () => {
  savingField.value = null
  mergingType.value = ''
  stateMapdraw.value = ''
  emits('setPolygonPainting', false)
  emits('removeJoiningPolygon')
  store.commit('fields/setJoiningFieldsPolygon', null)
  store.commit('fields/setIsShowFieldOverlaps', false)
}

const autoMerge = () => {
  savingField.value = null
  emits('removeJoiningPolygon')

  if (isAutomerging.value && mergingType.value !== 'auto') {
    mergingType.value = 'auto'
    emits('addJoiningPolygon', bufferedUnionPolygon.value)
    checkPolygonArea(bufferedUnionPolygon.value)
    savingField.value = bufferedUnionPolygon.value
  } else if (mergingType.value === 'auto') {
    cancelMerge()
  }
}

const mergeFromPoints = () => {
  savingField.value = null
  emits('removeJoiningPolygon')

  if (mergingType.value !== 'points') {
    const holes = []
    const pointsCollections = props.fields.map((polygon) => {
      if (polygon.geojson.coordinates.length > 1) {
        holes.push(polygon.geojson.coordinates.slice(1))
      }
      return Turf.explode(getPolygonFromGeometry(polygon))
    })

    const points = pointsCollections.reduce((acc, item) => {
      return acc.concat(item.features.slice(0, item.features.length - 1))
    }, [])

    let polygon = Turf.convex({ type: 'FeatureCollection', features: points })
    if (holes.length) {
      polygon = {
        properties: {},
        type: 'Feature',
        geometry: {
          type: polygon.geometry.type,
          coordinates: [polygon.geometry.coordinates[0], ...holes.flat()]
        }
      }
    }
    mergingType.value = 'points'
    emits('addJoiningPolygon', polygon)
    checkPolygonArea(polygon)
    savingField.value = polygon
  } else {
    cancelMerge()
  }
}

const mergePolygons = () => {
  savingField.value = null
  emits('removeJoiningPolygon')

  if (mergingType.value !== 'polygon') {
    mergingType.value = 'polygon'
    emits('setPolygonPainting', true)
  } else {
    cancelMerge()
  }
}

const getPolygonFromGeometry = (geometry) => (
  {
    type: 'Feature',
    properties: {},
    id: geometry.id,
    geometry: {
      ...geometry.geojson
    }
  }
)

watch(() => props.drawnPolygons, (value) => {
  if (value) {
    const unionPolygon = Turf.union(...props.fields.map((field) => getPolygonFromGeometry(field)), value)
    props.mapDraw.deleteAll()
    emits('addJoiningPolygon', unionPolygon)
    stateMapdraw.value = ''
    checkPolygonArea(unionPolygon)
    savingField.value = unionPolygon
  }
})

watch(() => props.fields, (value, oldValue) => {
  if (oldValue.length < value.length) {
    if (value.length === 1) {
      automergingFields.value = [Turf.buffer(getPolygonFromGeometry(value[0]), 0.15, { units: 'meters' })]
    } else {
      automergingFields.value = automergingFields.value.concat(Turf.buffer(getPolygonFromGeometry(value[value.length - 1]), 0.15, { units: 'meters' }))
    }
  } else {
    automergingFields.value = value.map((polygon) => Turf.buffer(getPolygonFromGeometry(polygon), 0.15, { units: 'meters' }))
  }
})

watch(stateMapdraw, (value) => {
  if (!value) {
    props.mapDraw.deleteAll()
    props.mapDraw.changeMode('static')
    return
  }

  if (value === 'paintMode') {
    props.mapDraw.changeMode('draw_paint_mode')
    return
  }

  if (value === 'edit') {
    props.mapDraw.changeMode('draw_polygon')
  }
})

onBeforeUnmount(() => {
  emits('removeJoiningPolygon')
  emits('setPolygonPainting', false)
})
</script>

<style lang="scss" scoped>
.container {
  width: 344px;
  height: 100%;
  background-color: #F4F7FA;
  position: absolute;
  left: 0;
  border-left: 1px solid $color-gray3;
  top: 0;
  z-index: 3;
}

.wrapper {
  height: 100%;
  padding: 30px;
}

.title {
  margin-bottom: 24px;
}

.button {
  width: 100%;
  margin-bottom: 12px;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
}

.icon-button {
  background-color: white;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border: 2px solid $color-gray3;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    border-color: $color-active;
  }
}
</style>
