import ApiService from '@/services'

const state = {
  events: null,
  types: [],
  isLoadingEvents: false
}

const mutations = {
  setEvents(state, data) {
    state.events = data

    // if (data.meta.currentPage === 1) {
    //   state.events = data
    // } else {
    //   state.events = {
    //     ...data,
    //     data: state.events.data.concat(data.data)
    //   }
    // }
  },
  setIsLoadingEvents(state, data){
    state.isLoadingEvents = data
  },
}
const actions = {
  fetchEvents({ commit }, params) {
    commit('setIsLoadingEvents', true)

    return new Promise((resolve, reject) => {
      ApiService.admin.getEventsV2(params)
        .then((response)=> {
          commit('setEvents', response.data)
          commit('setIsLoadingEvents', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingEvents', false)
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  // getters,
}
