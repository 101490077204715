<template>
  <table class="table">
    <thead>
      <tr>
        <th>№ п/п</th>
        <th>Кадастровый номер</th>
        <th>Площадь пересечения</th>
        <th>Слой</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="area, index in overlaps" :key="area.cadastralNumber">
        <td>{{ index + 1 }}</td>
        <td>
          <router-link v-if="area.overlapsSegment.layer !== 'zu_lf'" :to="`/map/${area.overlapsSegment.hash}`" target="_blank">
            {{ area.overlapsSegment.cadastralNumber }}
          </router-link>
          <span v-else>{{ area.overlapsSegment.cadastralNumber }}</span>
        </td>
        <td>
          {{ area && area.area ? `${area.area}Га` : '' }} ({{ area && area.segment && area.segment.area ? (area.area / area.segment.area * 100).toFixed(2) : '' }}%)
        </td>
        <td>{{ area && area.overlapsSegment && area.overlapsSegment.layer ? layers[area.overlapsSegment.layer] : '' }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { defineProps } from 'vue'

defineProps(['overlaps'])

const layers = {
  rosreestr: 'Росреестр',
  rosreestr2: 'Участки на оформлении',
  kugi: 'КУГИ',
  kumi: 'КУМИ',
  federal: 'Федеральные земли',
  pais: 'Паевые земли',
  zu_lf: 'Лесной фонд',
}
</script>

<style lang="scss" scoped>
.table {
  border-collapse: collapse;
  width: 100%;

  thead td,
  thead th {
    background-color: $color-gray2;
    border: 5px solid $color-gray1;
  }

  tbody {
    background-color: #fff;
  }

  th, td {
    padding: 10px 5px;
    text-align: center;
  }

  td {
    border: 5px solid $color-gray1;
  }

}
</style>
