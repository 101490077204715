import ApiService from '@/services'

const actions = {
  fetchFieldsStatistic({ commit }, params) {
    commit('setIsLoadingDashboard', true)
    commit('setFields', [])

    return new Promise((resolve, reject) => {
      ApiService.dashboard.getFieldsStatistic(params)
        .then((response) => {
          if (response.data) {
            commit('setFields', response.data)
          }
          commit('setIsLoadingDashboard', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingDashboard', false)
          reject(error)
        })
    })
  },
  fetchOverlaps({ commit }, params) {
    commit('setIsLoadingDashboard', true)

    return new Promise((resolve, reject) => {
      ApiService.dashboard.getOverlaps(params)
        .then((response) => {
          if (response.data) {
            commit('setOverlaps', response.data)
          }
          commit('setIsLoadingDashboard', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingDashboard', false)
          reject(error)
        })
    })
  },
  fetchStatistic({ commit }, params) {
    commit('setIsLoadingDashboard', true)

    return new Promise((resolve, reject) => {
      ApiService.dashboard.getStatistic(params)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            commit('setStatistic', response.data[0])
          }
          commit('setIsLoadingDashboard', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingDashboard', false)
          reject(error)
        })
    })
  },
  fetchUserActivity({ commit }, params) {
    commit('setIsLoadingDashboard', true)

    return new Promise((resolve, reject) => {
      ApiService.dashboard.getUserActivity(params)
        .then((response) => {
          if (response.data) {
            commit('setUserActivity', response.data)
          }
          commit('setIsLoadingDashboard', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingDashboard', false)
          reject(error)
        })
    })
  },
  fetchKptStatistic({ commit }, params) {
    commit('setIsLoadingDashboard', true)

    return new Promise((resolve, reject) => {
      ApiService.dashboard.getKptStatistic(params)
        .then((response) => {
          if (response.data) {
            commit('setKptStatistic', response.data)
          }
          commit('setIsLoadingDashboard', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingDashboard', false)
          reject(error)
        })
    })
  },
  fetchProblemFields({ commit }, params) {
    commit('setIsLoadingDashboard', true)
    commit('setProblemFields', null)

    return new Promise((resolve, reject) => {
      ApiService.dashboard.getProblemFields(params)
        .then((response) => {
          if (response.data) {
            commit('setProblemFields', response.data)
          }
          commit('setIsLoadingDashboard', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingDashboard', false)
          reject(error)
        })
    })
  },
  fetchProblemFieldsByMo({ commit }) {
    commit('setIsLoadingDashboard', true)
    commit('setProblemFieldsByMo', [])

    return new Promise((resolve, reject) => {
      ApiService.dashboard.getProblemsFieldsByMo()
        .then((response) => {
          if (response.data) {
            commit('setProblemFieldsByMo', response.data)
          }
          commit('setIsLoadingDashboard', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingDashboard', false)
          reject(error)
        })
    })
  },
  fetchProblemSections({ commit }, params) {
    commit('setIsLoadingDashboard', true)
    commit('setProblemSections', null)

    return new Promise((resolve, reject) => {
      ApiService.dashboard.getProblemSections(params)
        .then((response) => {
          if (response.data) {
            commit('setProblemSections', response.data)
          }
          commit('setIsLoadingDashboard', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingDashboard', false)
          reject(error)
        })
    })
  },
  fetchOperationYearsStatistic({ commit }, params) {
    commit('setIsLoadingDashboard', true)
    commit('setOperationYearsStatistic', null)

    return new Promise((resolve, reject) => {
      ApiService.dashboard.getOperationYearFields(params)
        .then((response) => {
          if (response.data) {
            commit('setOperationYearsStatistic', response.data)
          }
          commit('setIsLoadingDashboard', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingDashboard', false)
          reject(error)
        })
    })
  },
}

const mutations = {
  setOperationYearsStatistic(state, data) {
    state.operationYearsStatistic = data
  },
  setStatistic(state, data){
    state.statistic = data
  },
  setUserActivity(state, data){
    state.userActivity = data
  },
  setKptStatistic(state, data){
    state.kptStatistic = data
  },
  setIsLoadingDashboard(state, data) {
    state.isLoadingDashboard = data
  },
  setOverlaps(state, data) {
    state.overlaps = data
  },
  setFields(state, data) {
    state.fields = data
  },
  setProblemFields(state, data) {
    state.problemFields = data
  },
  setProblemSections(state, data) {
    state.problemSections = data
  },
  setProblemFieldsByMo(state, data) {
    state.problemFieldsByMo = data
  }
}

const state = () => ({
  isLoadingDashboard: false,
  statistic: {
    dataGeneral: {
      sectionCount: 0,
      totalArea: 0
    },
    dataGraphs: {
      actualExploitation: {},
      complexityExploitation: {},
      landOwnerships: {},
      checkStatuses: {},
      history: []
    },
    dataMunicipal: {}
  },
  userActivity: {},
  kptStatistic: [],
  overlaps: [],
  fields: [],
  problemFields: null,
  problemSections: null,
  operationYearsStatistic: null,
  problemFieldsByMo: []
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
