const BASE_URL_V2 = '/v2/private'

class FieldsService {
  constructor(api) {
    this.api = api
  }

  getField(id) {
    return this.api.get(`${BASE_URL_V2}/fields/${id}`)
  }

  getFields(hash) {
    return this.api.get(`${BASE_URL_V2}/sections/${hash}/fields`)
  }

  getDeletedFields(hash) {
    return this.api.get(`${BASE_URL_V2}/sections/${hash}/fields/deleted`)
  }

  createField(form) {
    return this.api.post(`${BASE_URL_V2}/fields`, form)
  }

  updatePolygonField(id, form) {
    return this.api.put(`${BASE_URL_V2}/fields/${id}/polygon`, form)
  }

  getFieldHistory(id) {
    return this.api.get(`${BASE_URL_V2}/fields/${id}/history`)
  }

  updateCheckStatusField(form) {
    return this.api.put(`${BASE_URL_V2}/fields/${form.id}/status`, form)
  }

  updateFieldAttributes(id, form) {
    return this.api.put(`${BASE_URL_V2}/fields/${id}`, form)
  }

  updateFieldProps(id, form) {
    return this.api.put(`${BASE_URL_V2}/fields/${id}/props`, form)
  }

  updateGroupFieldProps(data) {
    return this.api.put(`${BASE_URL_V2}/fields`, data)
  }
  updateGroupField(data) {
    return this.api.put(`${BASE_URL_V2}/fields/update`, data)
  }

  checkPolygonField(geometry) {
    return this.api.post(`${BASE_URL_V2}/fields/area-check-field`, geometry)
  }

  deleteField(id) {
    return this.api.delete(`${BASE_URL_V2}/fields/${id}`)
  }

  undeleteField(id) {
    return this.api.get(`${BASE_URL_V2}/fields/${id}/undelete`)
  }

  checkFieldOverlaps(id) {
    return this.api.get(`${BASE_URL_V2}/fields/${id}/overlaps`)
  }

  checkFieldPolygonOverlaps(polygon) {
    return this.api.post(`${BASE_URL_V2}/fields/overlaps-check`, polygon)
  }

  cutFieldPolygonOverlaps(polygon) {
    return this.api.post(`${BASE_URL_V2}/fields/overlaps-cut`, polygon)
  }

  updateEfisAttributes(id, data) {
    return this.api.put(`${BASE_URL_V2}/fields/${id}/efis`, data)
  }

  splitField(id, data) {
    return this.api.put(`${BASE_URL_V2}/fields/${id}/split`, data)
  }

  getFieldsInfo(ids) {
    return this.api.post(`${BASE_URL_V2}/fields/info`, { ids })
  }

  joinFields(form) {
    return this.api.post(`${BASE_URL_V2}/fields/join`, form)
  }
}

export { FieldsService }
