import ApiService from '@/services'

const actions = {
  fetchSchemes({ commit }, params) {
    commit('setIsLoadingScheme', true)
    commit('setSchemes', [])

    return new Promise((resolve, reject) => {
      ApiService.schemes.getSchemes(params)
        .then((response) => {
          commit('setSchemes', response.data)
          commit('setIsLoadingScheme', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setSchemes', [])
          commit('setIsLoadingScheme', false)
          reject(error)
        })
    })
  },
  fetchScheme({ commit }, id) {
    commit('setIsLoadingScheme', true)
    commit('setCurrentScheme', null)

    return new Promise((resolve, reject) => {
      ApiService.schemes.getScheme(id)
        .then((response) => {
          commit('setCurrentScheme', response.data)
          commit('setIsLoadingScheme', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setCurrentScheme', null)
          commit('setActiveScheme', null)
          commit('setIsLoadingScheme', false)
          reject(error)
        })
    })
  },
  fetchStatuses({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.schemes.getSchemeStatuses()
        .then((response) => {
          commit('setStatuses', response.data.map((name, value) => ({ value, name })))
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchMunicipalities({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.schemes.getSchemeRegions()
        .then((response) => {
          commit('setMunicipalities', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSubmitters({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.schemes.getSchemeSubmitters()
        .then((response) => {
          commit('setSubmitters', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  setGeometryScheme(state, data) {
    state.geometryScheme = data
  },
  setAreaScheme(state, data) {
    state.areaScheme = data
  },
  setAreaSchemeError(state, data) {
    state.areaSchemeError = data
  },
  setSchemeXml(state, data){
    state.schemeXml = data
  },
  setCurrentScheme(state, data){
    state.currentScheme = data
  },
  setActiveScheme(state, data){
    state.activeScheme = data
  },
  setIsLoadingScheme(state, data){
    state.isLoadingScheme = data
  },
  setStatuses(state, data) {
    state.statuses = data.map((item) => ({ ...item, label: item.name }))
  },
  setCountEditedScheme(state) {
    state.countEditedScheme += 1
  },
  setIsVisibleSchemeLayer(state, data) {
    state.isVisibleSchemeLayer = data
  },
  setSchemesStorage(state, data) {
    state.schemesStorage = data
  },
  setDeletedSchemes(state, data) {
    state.deletedSchemes = data
  },
  setSchemes(state, data) {
    state.schemes = data
  },
  setMunicipalities(state, data) {
    state.municipalities = data.map((item) => ({ ...item, label: item.name }))
  },
  setSubmitters(state, data) {
    state.submitters = data.map((item) => ({ ...item, label: item.name }))
  },
}

const state = () => ({
  geometryScheme: null,
  areaScheme: null,
  schemeXml: null,
  currentScheme: null,
  activeScheme: null,
  isLoadingScheme: false,
  statuses: [],
  countEditedScheme: 0,
  isVisibleSchemeLayer: false,
  schemesStorage: [],
  deletedSchemes: [],
  schemes: [],
  municipalities: [],
  submitters: [],
  areaSchemeError: ''
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
