import { polygonColor } from '@/modules/map/layers/rosreestr'

const removeCreatedLayer = (map) => {
  if (map.getSource('createLayer_data')) {
    map.removeLayer('createLayer_fill')
    map.removeLayer('createLayer_line')
    map.removeSource('createLayer_data')
  }
}

const addCreatedLayer = (params) => {
  const source = {
    type: 'FeatureCollection',
    features: params.polygons.map((el) => {
      return {
        id: el.id,
        type: 'Feature',
        geometry: el.geometry,
        properties: el.properties,
      }
    })
  }

  if (!params.map.getSource('createLayer_data')) {
    params.map.addSource('createLayer_data', {
      type: 'geojson',
      data: source
    })
  }

  params.map.addLayer({
    id: 'createLayer_fill',
    type: 'fill',
    source: 'createLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'fill-color': ['case',
        ['boolean', ['feature-state', 'edited'], false], 'transparent',
        ['boolean', ['feature-state', 'selected'], false], 'transparent',
        ['boolean', ['feature-state', 'active'], false], params.activeColor,
        ['==', ['get', 'name'], 'rosreestr'], polygonColor.rosreestr,
        ['==', ['get', 'name'], 'kugi'], polygonColor.kugi,
        ['==', ['get', 'name'], 'kumi'], polygonColor.kumi,
        ['==', ['get', 'name'], 'pais'], polygonColor.pais,
        ['==', ['get', 'name'], 'federal'], polygonColor.federal,
        ['==', ['get', 'name'], 'rosreestr2'], polygonColor.rosreestr2,
        ['==', ['get', 'name'], 'old'], '#05bdfa',
        params.color
      ],
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'edited'], false], 0,
        ['boolean', ['feature-state', 'active'], false], 1,
        ['boolean', ['feature-state', 'hover'], false], 0.4,
        ['==', ['get', 'name'], 'old'], 0.8,
        0.2,
      ],
    },
  })

  params.map.addLayer({
    id: 'createLayer_line',
    type: 'line',
    source: 'createLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-color': ['case',
        ['boolean', ['feature-state', 'edited'], false], 'transparent',
        ['==', ['get', 'name'], 'rosreestr'], polygonColor.rosreestr,
        ['==', ['get', 'name'], 'kugi'], polygonColor.kugi,
        ['==', ['get', 'name'], 'kumi'], polygonColor.kumi,
        ['==', ['get', 'name'], 'pais'], polygonColor.pais,
        ['==', ['get', 'name'], 'federal'], polygonColor.federal,
        ['==', ['get', 'name'], 'rosreestr2'], polygonColor.rosreestr2,
        ['==', ['get', 'name'], 'old'], '#05bdfa',
        params.color
      ],
    },
  })
}

export { addCreatedLayer, removeCreatedLayer }
