const removeFieldStorageLayer = (map) => {
  if (map.getSource('fieldStorageLayer_data')) {
    map.removeLayer('fieldStorageLayer_fill')
    map.removeLayer('fieldStorageLayer_line')
    map.removeSource('fieldStorageLayer_data')
  }
}

const addFieldSorageLayer = (params) => {
  const source = {
    type: 'FeatureCollection',
    features: params.polygons.map((el) => {
      return {
        id: el.id,
        type: 'Feature',
        geometry: el.geometry,
        properties: el.properties,
      }
    })
  }

  if (!params.map.getSource('fieldStorageLayer_data')) {
    params.map.addSource('fieldStorageLayer_data', {
      type: 'geojson',
      data: source
    })
  }

  params.map.addLayer({
    id: 'fieldStorageLayer_fill',
    type: 'fill',
    source: 'fieldStorageLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'fill-color': ['case',
        ['boolean', ['feature-state', 'active'], false], params.activeColor,
        params.color
      ],
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'edited'], false], 0,
        ['boolean', ['feature-state', 'active'], false], 0.7,
        ['boolean', ['feature-state', 'focus'], false], 1,
        ['boolean', ['feature-state', 'hover'], false], 0.4,
        0.2,
      ],
    },
  })

  params.map.addLayer({
    id: 'fieldStorageLayer_line',
    type: 'line',
    source: 'fieldStorageLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-color': ['case',
        ['boolean', ['feature-state', 'active'], false], params.activeColor,
        ['boolean', ['feature-state', 'edited'], false], 'transparent',
        params.color
      ],
      'line-width': ['case',
        ['boolean', ['feature-state', 'field'], false], 3,
        1
      ]
    },
  })
}

export { addFieldSorageLayer, removeFieldStorageLayer }
