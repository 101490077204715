<template>
  <div class="polygon-controls" :class="{ section: isSection }">
    <div class="polygon-controls__info" :class="{loading: !areaPolygon && areaPolygon !== 0}">
      <span class="area">
        <b>S:&nbsp;</b>
      </span>
      <span v-if="areaPolygon" class="area">{{ (Number(areaPolygon) / 10000).toFixed(5) }} Га.</span>
      <div v-if="!error">
        <span v-if="areaPolygon">Подходящая площадь для полигона</span>
        <span v-if="areaPolygon === 0">Нарисуйте границы полигона</span>
      </div>
      <div v-if="error">
        <span>{{ error }}</span>
      </div>
    </div>

    <div class="polygon-controls__buttons">
      <button
        class="button edit"
        :class="{ active : stateMapdraw === 'edit' }"
        title="Редактировать"
        type="button"
        @click="onToolClick('edit')"
      >
        <EditPolygonIcon :width="19" :height="19" />
      </button>

      <button
        class="button"
        :class="{ active : stateMapdraw === 'paintMode' }"
        aria-label="Нарисовать"
        title="Нарисовать"
        type="button"
        @click="onToolClick('paintMode')"
      >
        <PaintIcon :width="24" :height="24" />
      </button>

      <button
        v-if="isSection"
        class="button"
        aria-label="Загрузить"
        title="Загрузить из XML"
        :class="{ active : isLoadedCreatePolygon }"
        type="button"
        @click="uploadPolygon"
      >
        <UploadPolygonIcon :width="24" :height="24" :active="isLoadedCreatePolygon" />
      </button>

      <button
        class="button"
        :class="{ active : stateMapdraw === 'cut' }"
        title="Вырезать"
        type="button"
        @click="onToolClick('cut')"
      >
        <CutPolygonIcon :width="21" :height="18" />
      </button>

      <button
        class="button"
        aria-label="Удалить полигон"
        title="Удалить"
        type="button"
        @click="deletePolygon"
      >
        <DeletePolygonIcon :width="20" :height="18" />
      </button>

      <button
        class="button"
        :class="{ active : stateMapdraw === 'buffer' }"
        :disabled="!currentPolygon"
        aria-label="Масштабировать полигон"
        title="Масштабировать"
        type="button"
        @click="onToolClick('buffer')"
      >
        <ScalePolygonIcon :width="24" :height="24" />
      </button>

      <button
        class="button"
        :class="{ active : stateMapdraw === 'simplify' }"
        :disabled="!currentPolygon"
        aria-label="Упростить полигон"
        title="Упростить"
        type="button"
        @click="onToolClick('simplify')"
      >
        <SimplifyPolygonIcon :width="24" :height="24" />
      </button>

      <button
        class="button"
        :class="{ active : stateMapdraw === 'deleteRing' }"
        :disabled="stateMapdraw !== 'deleteRing' && (rings?.length < 1 || !currentPolygon)"
        aria-label="Удалить кольцо"
        title="Удалить кольцо"
        type="button"
        @click="onToolClick('deleteRing')"
      >
        <DeleteRingIcon :width="24" :height="24" />
      </button>

      <div class="buttons">
        <button
          :disabled="!areaPolygon || !!error"
          class="button-confirm"
          aria-label="Сохранить"
          type="button"
          @click="handleConfirm"
        >
          Сохранить
        </button>

        <button
          :disabled="polygonCancelCount < 2 ? true : null"
          class="button"
          title="Отменить"
          type="button"
          @click="onCancelEdit"
        >
          <q-icon name="mdi-undo-variant" size="24px" />
        </button>

        <button
          :disabled="polygonStates.length === polygonCancelCount ? true : null"
          class="button"
          title="Вернуть"
          type="button"
          @click="onRedoEdit"
        >
          <q-icon name="mdi-redo-variant" size="24px" />
        </button>
      </div>

      <div v-if="stateMapdraw === DrawState.Buffer || stateMapdraw === DrawState.Simplify" class="sidebar">
        <input
          v-model="inputValue"
          class="input"
          type="number"
          :step="step"
          :min="min"
          :disabled='!areaPolygon || !!error'
          @keydown.enter="handleEnter"
        >
        <button
          type="button"
          :disabled="!areaPolygon || !!error"
          @click="applyTool"
        >
          Применить
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import * as Turf from '@turf/turf'
import { buffer, simplify } from '@turf/turf'
import { cloneDeep } from 'lodash'
import EditPolygonIcon from '@/components/uikit/icons/EditPolygonIcon'
import UploadPolygonIcon from '@/components/uikit/icons/UploadPolygonIcon'
import CutPolygonIcon from '@/components/uikit/icons/CutPolygonIcon'
import DeletePolygonIcon from '@/components/uikit/icons/DeletePolygonIcon'
import DeleteRingIcon from '@/modules/map/components/icons/DeleteRingIcon'
import ScalePolygonIcon from '@/modules/map/components/icons/ScalePolygonIcon'
import SimplifyPolygonIcon from '@/modules/map/components/icons/SimplifyPolygonIcon'
import PaintIcon from '@/modules/map/components/icons/PaintIcon'
import { addInnerRingsLayer, removeInnerRingsLayer } from '@/modules/map/utils/layers/inner-rings-layer'

const DrawState = {
  Edit: 'edit',
  Cut: 'cut',
  Buffer: 'buffer',
  Simplify: 'simplify',
  DeleteRing: 'deleteRing',
  PaintMode: 'paintMode'
}

const store = useStore()

const props = defineProps({
  map: {
    type: Object,
    required: false
  },
  mapDraw: {
    type: Object,
    required: false
  },
  areaPolygon: {
    type: Number,
    required: false
  },
  drawnPolygons: {
    type: Object,
    required: false
  },
  error: {
    type: String,
    required: false
  },
  isSection: {
    type: Boolean,
    required: false,
    default: false
  },
  isLoadedCreatePolygon: {
    type: Boolean,
    required: false,
    default: false
  },
})

const emits = defineEmits(['save', 'deletePolygon', 'uploadPolygon', 'cancelUploadPolygon'])

const stateMapdraw = ref('') // ['edit', 'cut', 'buffer', 'simplify', 'deleteRing']
const inputValue = ref(0)
const step = ref(0.1)
const min = ref(-10)
const initPolygon = ref(null)
const currentPolygon = ref(null)
const rings = ref([])
const polygonStates = ref([])
const isCancel = ref(false)
const polygonCancelCount = ref(0)

const modeState = computed(() => store.state.map.modeState)

const setPolygon = () => {
  try {
    const newPolygon = {
      ...currentPolygon.value,
      geometry: polygonStates.value[polygonCancelCount.value - 1].geometry
    }

    props.mapDraw.deleteAll()
    props.mapDraw.add(newPolygon)
    props.map.fire('draw.update')
    props.mapDraw.changeMode('static')
    stateMapdraw.value = ''
    currentPolygon.value = newPolygon
  } catch (err) {
    props.mapDraw.deleteAll()
  }
}

const onRedoEdit = () => {
  isCancel.value = true
  if (polygonCancelCount.value < polygonStates.value.length) {
    polygonCancelCount.value += 1
    setPolygon()
  } else {
    isCancel.value = false
  }
}

const onCancelEdit = () => {
  isCancel.value = true

  if (polygonCancelCount.value > 1) {
    polygonCancelCount.value -= 1
  }

  setPolygon()
}

const onCancelKeydown = (evt) => {
  if (evt.code == 'KeyZ' && !evt.shiftKey && (evt.ctrlKey || evt.metaKey)) {
    onCancelEdit()
  }
}

const onRedoKeydown = (evt) => {
  if (evt.code == 'KeyZ' && evt.shiftKey && (evt.ctrlKey || evt.metaKey)) {
    onRedoEdit()
  }
}

const setRings = () => {
  if (currentPolygon.value?.geometry?.type === 'MultiPolygon') {
    rings.value = currentPolygon.value.geometry.coordinates.map((coords) => coords).filter((ring) => ring.length > 1).map((ring) => ring.slice(1))
  }

  if (currentPolygon.value?.geometry?.type === 'Polygon') {
    rings.value = currentPolygon.value?.geometry?.coordinates ? [currentPolygon.value.geometry.coordinates].filter((ring) => ring.length > 1).map((ring) => ring.slice(1)) : []
  }
}

const applyTool = () => {
  try {
    if (inputValue.value <= min.value) {
      inputValue.value = min.value
    }

    if (stateMapdraw.value === DrawState.Buffer) {
      scalePolygon()
    }

    if (stateMapdraw.value === DrawState.Simplify) {
      simplifyPolygon()
    }
  } catch (error) {
    stateMapdraw.value = ''
  }
}

const handleEnter = (evt) => {
  if (evt.keyCode === 13 || evt.key === 'Enter') {
    evt.stopPropagation()
    applyTool()
  }
}

const clickHandler = (evt) => {
  const polygon = props.map.queryRenderedFeatures(evt.point)[0]

  const newPolygon = Turf.union(currentPolygon.value, buffer(polygon, 0.2, { units: 'meters' }))
  props.mapDraw.deleteAll()
  props.mapDraw.add({
    ...currentPolygon.value,
    geometry: newPolygon.geometry
  })

  currentPolygon.value = newPolygon
  props.map.fire('draw.update')
  props.mapDraw.changeMode('static')
}

const onToolClick = (tool) => {
  stateMapdraw.value = stateMapdraw.value === tool ? '' : tool
}

const scalePolygon = () => {
  const drawnPolygon = buffer(currentPolygon.value.geometry, inputValue.value, { units: 'meters' })
  props.mapDraw.deleteAll()
  props.mapDraw.add({ ...currentPolygon.value, geometry: drawnPolygon.geometry })
  currentPolygon.value = drawnPolygon
  props.map.fire('draw.update')
  props.mapDraw.changeMode('static')
}

const simplifyPolygon = () => {
  const drawnPolygon = simplify({ ...currentPolygon.value }, { tolerance: inputValue.value, highQuality: true })
  props.mapDraw.deleteAll()
  props.mapDraw.add({ ...currentPolygon.value, geometry: drawnPolygon.geometry })
  currentPolygon.value = drawnPolygon
  props.map.fire('draw.update')
  props.mapDraw.changeMode('static')
}

const handleConfirm = () => {
  if (!props.isSection && window.location.href.includes('lk.sovhoz.tech/')) {
    window.ym(75689863,'reachGoal','map-zucard-fieldstab-createnewfield-createfieldtab-savenewfieldpolygon-click')
  }
  emits('save', props.areaPolygon)
}

const changeMode = (value) => {
  if (value.mode === 'direct_select') {
    stateMapdraw.value = 'edit'
  }
}

const deletePolygon = () => {
  props.mapDraw
    .deleteAll()
    .getAll()
  stateMapdraw.value = ''
  emits('deletePolygon')
}

const uploadPolygon = () => {
  stateMapdraw.value = ''

  if (!props.isLoadedCreatePolygon && modeState.value === 'edit') {
    props.mapDraw.deleteAll()
    emits('uploadPolygon')
  } else if (!props.isLoadedCreatePolygon && modeState.value === 'create') {
    props.mapDraw.deleteAll()
    emits('uploadPolygon')
  } else if (props.isLoadedCreatePolygon) {
    emits('cancelUploadPolygon')
  }
}

watch(polygonStates, (value) => {
  if (!isCancel.value) {
    polygonCancelCount.value = value.length
  }
})

watch(rings, () => {
  if (stateMapdraw.value === DrawState.DeleteRing) {
    removeInnerRingsLayer(props.map)
    props.map.off('click', 'innerRingsLayer_fill', clickHandler)

    if (rings.value?.length > 0) {
      addInnerRingsLayer({
        polygons: rings.value[0].map((coords, index) => ({
          id: index + 1,
          properties: {},
          coordinates: [coords],
          type: 'Polygon'
        })),
        map: props.map,
        color: '#ee0ff2', // #ebd3cb
        activeColor: '#dc1c0a'
      })

      props.map.on('click', 'innerRingsLayer_fill', clickHandler)
    }
  }
})

watch(() => props.drawnPolygons, (value) => {
  if (!isCancel.value) {
    if (polygonStates.value.length >= 20) {
      polygonStates.value = [polygonStates.value[0], ...polygonStates.value.slice(polygonStates.value.length - 18, polygonStates.value.length)]
    } else {
      polygonStates.value.push(value)
    }
  } else {
    setTimeout(() => {
      isCancel.value = false
    }, 500)
  }

  if (props.mapDraw.getAll()?.features[0] && (stateMapdraw.value === DrawState.Cut || stateMapdraw.value === DrawState.Edit || stateMapdraw.value === DrawState.PaintMode)) {
    currentPolygon.value = cloneDeep(props.mapDraw.getAll()?.features[0])
  }

  if (stateMapdraw.value === DrawState.Edit) {
    const ids = props.mapDraw.getAll().features.map((feature) => feature.id)

    if (ids[0]) {
      props.mapDraw.changeMode('direct_select', { featureId: ids[0] })
    }
  }
})

watch(currentPolygon, () => {
  setRings()
})

watch(stateMapdraw, (value) => {
  removeInnerRingsLayer(props.map)
  props.map.off('click', 'innerRingsLayer_fill', clickHandler)

  if (!props.isLoadedCreatePolygon && currentPolygon.value) {
    props.mapDraw.deleteAll()
    props.mapDraw.add(currentPolygon.value)
  }

  if (value === DrawState.Buffer) {
    inputValue.value = 0
    step.value = 0.1
    min.value = -10
  }

  if (value === DrawState.Simplify) {
    inputValue.value = 0.00001
    step.value = 0.000001
    min.value = 0
  }

  if (value !== 'cut' || value !== 'edit') {
    props.mapDraw.changeMode('static')
  }

  if (value === 'cut') {
    props.mapDraw.changeMode('draw_polygon')
    return
  }

  if (value === 'paintMode') {
    props.mapDraw.changeMode('draw_paint_mode')
    return
  }

  if (value === 'edit') {
    const ids = props.mapDraw.getAll().features.map((feature) => feature.id)

    if (ids[0]) {
      props.mapDraw.changeMode('direct_select', { featureId: ids[0] })
    } else {
      props.mapDraw.changeMode('draw_polygon')
    }
  }

  if (value === 'deleteRing' && rings.value?.length > 0) {
    removeInnerRingsLayer(props.map)
    props.map.off('click', 'innerRingsLayer_fill', clickHandler)

    addInnerRingsLayer({
      polygons: rings.value[0].map((coords, index) => ({
        id: index + 1,
        properties: {},
        coordinates: [coords],
        type: 'Polygon'
      })),
      map: props.map,
      color: '#ee0ff2', // #ebd3cb
      activeColor: '#dc1c0a'
    })

    props.map.on('click', 'innerRingsLayer_fill', clickHandler)
  }

  if (!value && !props.isLoadedCreatePolygon && currentPolygon.value) {
    props.mapDraw.deleteAll()
    props.mapDraw.add(currentPolygon.value)
    props.map.fire('draw.update')
    props.mapDraw.changeMode('static')
  }
})

onMounted(() => {
  props.map?.on('draw.modechange', changeMode)
  initPolygon.value = cloneDeep(props.mapDraw.getAll()?.features[0])
  currentPolygon.value = cloneDeep(props.mapDraw.getAll()?.features[0])
  polygonStates.value = [currentPolygon.value]
  document.addEventListener('keydown', onCancelKeydown)
  document.addEventListener('keydown', onRedoKeydown)
})

onUnmounted(() => {
  polygonStates.value = []
  isCancel.value = false
  document.removeEventListener('keydown', onCancelKeydown)
  document.removeEventListener('keydown', onRedoKeydown)

  removeInnerRingsLayer(props.map)
  props.map.off('click', 'innerRingsLayer_fill', clickHandler)

  emits('cancelUploadPolygon')

  const feature = props.mapDraw.getAll().features[0]

  if (feature && props.isSection) {
    props.map.setFeatureState(
      {
        source: feature.properties.layer,
        sourceLayer: feature.properties.layer,
        id: feature.properties.id,
      },
      { selected: false }
    )
  }

  if (feature && props.isSection && props.map.getSource('editLayer_data')) {
    props.map.setFeatureState(
      {
        source: 'editLayer_data',
        id: feature.properties.id,
      },
      { selected: false }
    )
  }

  props.mapDraw.deleteAll()
  props.mapDraw.changeMode('static')
})
</script>

<style lang="scss" scoped>
  .polygon-controls {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    position: absolute;
    bottom: 52px;
    left: 50%;
    transform: translateX(-50%);
    // overflow: hidden;
    max-width: 280px;

    span {
      font-weight: 500;
      font-size: 14px;
    }

    &.section {
      max-width: 320px;
    }

    @include below('d_sm') {
      left: 364px;
      bottom: 20px;
      transform: translateX(0);
    }

    @include below('t_lg') {
      left: 20px;
      bottom: 40px;
      transform: translateX(0);
    }

    &__info {
      position: relative;
      padding: 8px 12px;
      color: white;
      min-height: 52px;
      max-width: 300px;

      &:before {
        content: '';
        position: absolute;
        left: calc(50% - 9px);
        top: 25px;
        margin-top: -12px;
        width: 18px;
        height: 18px;
        border: 2px solid;
        display: none;
        border-left-color: transparent;
        border-right-color: transparent;
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.5s;
        animation: 0.8s linear infinite rotate;
      }

      &.loading{
        pointer-events: none;
        cursor: not-allowed;

        &:before {
          display: block;
          // transition-delay: 0.5s;
          transition-duration: 1s;
          opacity: 1;
        }
      }
    }

    &__buttons {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      height: 80px;
      width: 100%;
      align-items: center;
    }

    .error {
      color: $color-active;
    }

    .area {
      display: inline-block;
      margin-bottom: 4px;
    }

    .buttons {
      display: flex;
      flex-grow: 1;
      width: 100%;
      gap: 0;
      border-top: 2px solid $color-gray3;
    }
  }


  .button-confirm {
    background-color: white;
    height: 40px;
    border: none;
    border-right: 1px solid $color-gray3;
    border-left: 1px solid $color-gray3;
    font-weight: 600;
    flex-grow: 1;
    cursor: pointer;

    &:disabled {
      color: $color-gray4;
    }
  }

  .additional-tools {
    display: flex;
    width: 100%;
    border-top: 1px solid $color-gray3;
  }

  .sidebar {
    position: absolute;
    right: -110px;
    top: 0;
    display: flex;
    flex-direction: column;

    button {
      width: 100px;
      background-color: #fff;
      border: none;
      height: 40px;
    }
  }

  .input {
    padding: 0 0 0 5px;
    width: 100px;
    // flex-grow: 1;
    flex-shrink: 0;
    height: 40px;
    border: none;
    border: 2px solid $color-gray3;
    font-size: 11px;
    background-color: #fff;
  }

  .button {
    background-color: white;
    cursor: pointer;
    height: 40px;
    border: none;
    outline: none;
    width: 40px;
    border-right: 1px solid $color-gray3;
    border-left: 1px solid $color-gray3;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: $color-active;
      border-color: $color-active;
      svg {

        path {
          fill: white;
        }
      }
    }

    svg {

      path {
        fill: #566473;
      }
    }

  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
