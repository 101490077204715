<template>
  <div class="info-popup" data-testid="popup">
    <div v-if="!activePolygon || !activePolygon.cadastralNumber" class="info-popup__field">
      <p class="text-main">Загружается...</p>
    </div>
    <div v-if="activePolygon?.cadastralNumber" class="info-popup__field">
      <p class="title-text">Кадастровый номер</p>
      <p class="text-main">{{ activePolygon.cadastralNumber }}</p>
    </div>
    <template v-for="attribute in attributes" :key="attribute">
      <div v-if="activePolygon?.common?.find((field) => field.name === attribute)" class="info-popup__field">
        <p class="title-text">{{ getName(attribute) }}</p>
        <p class="text-main" :class="{empty: checkEmptyData(attribute)}">{{ getValue(attribute) }}</p>
      </div>
    </template>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'

const attributes = ['Площадь', 'Категория прав собственности', 'Категория угодий', 'Правообладатель', 'Статус проверки']
const props = defineProps(['activePolygon'])

const getName = (fieldName) => {
  const currentFieldName = props.activePolygon?.common.find((field) => field.name === fieldName)?.name
  return currentFieldName ?? ''
}

const getValue = (fieldName) => {
  const fieldValue = props.activePolygon?.common?.find((field) => field.name === fieldName)?.value
  if (Array.isArray(fieldValue)) {
    return fieldValue.join(',')
  }
  return fieldValue ?? 'Нет данных'
}

const checkEmptyData = (fieldName) => {
  const fieldValue = props.activePolygon?.common?.find((field) => field.name === fieldName)?.value
  if (Array.isArray(fieldValue)) {
    return false
  }

  return !fieldValue
    || fieldValue.toLowerCase() === 'нет данных'
    || fieldValue.toLowerCase() === 'данные не верны'
    || fieldValue.toLowerCase() === 'участок не проверен'

}
</script>
<style lang="scss" scoped>
.info-popup {
  position: absolute;
  display: block;
  z-index: 100000;
  /*bottom: 0;*/
  /*right: 0;*/
  width: 224px;
  padding: 12px;
  background: #FFFFFF;
  border-radius: 4px;

  p {
    margin-bottom: 0;
  }

  &__field:not(:last-child) {
    margin-bottom: 5px;
  }

  .empty {
    color: $color-active;
  }

  .title-text {
    margin: 0;
    font-size: 10px;
    line-height: 1.2;
  }

  .text-main {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $color-main;

    &.empty {
      color: $negative;
    }
  }
}
</style>
