<template>
<div></div>
</template>
<script setup>
import { ref, defineProps, defineEmits, defineExpose, onMounted, computed, watch, toRaw } from 'vue'
import { useStore } from 'vuex'
import localforage from 'localforage'
import { MAIN_COLOR } from '@/modules/map/layers/fields'
import { addFieldSorageLayer, removeFieldStorageLayer } from '@/modules/map/utils/layers/field-storage-layer'

const SOURCE = 'fieldStorageLayer_data'
const LAYERS = ['fieldStorageLayer_fill', 'fieldStorageLayer_line']

const store = useStore()

const polygonStorage = ref([])
const hoveredState = ref(null)

const props = defineProps(['map', 'mapDraw', 'activePolygon'])
const emits = defineEmits(['clickHandler', 'setFilter', 'showPolygonInfo'])

const cacheLifeTime = computed(() => store.state.map.cacheLifeTime)
// const cacheLifeTime = computed(() => 3600)
const actualExploitations = computed(() => store.state.dictionaries.actualExploitations.filter((category) => !category.isHidden))
const isCreatedField = computed(() => store.state.fields.isCreatedField)

const setFilter = (layer) => {
  emits('setFilter', layer)
}

const mouseMove = (e) => {
  if (e.features.length > 0) {
    if (hoveredState.value) {
      props.map.setFeatureState(
        {
          source: e.features[0].source,
          // sourceLayer: e.features[0].sourceLayer,
          id: hoveredState.value.id,
        },
        { hover: false }
      )
    }
    hoveredState.value = e.features[0]
    props.map.setFeatureState(
      {
        source: hoveredState.value.source,
        // sourceLayer: this.hoveredState.sourceLayer,
        id: hoveredState.value.id,
      },
      { hover: true }
    )
  }
}
const mouseLeave = () => {
  if (hoveredState.value) {
    props.map.setFeatureState(
      {
        source: hoveredState.value.source,
        // sourceLayer: this.hoveredState.sourceLayer,
        id: hoveredState.value.id,
      },
      { hover: false }
    )
  }
  hoveredState.value = null
}

const showPolygonInfo = (evt) => {
  emits('showPolygonInfo', evt)
}

const repaintPolygonsFromStorage = ({ polygons, map }) => {
  removeFieldStorageLayer(map)
  map.off('click', LAYERS[0], clickHandler)
  map.off('touchstart', LAYERS[0], clickHandler)
  map.off('mousemove', LAYERS[0], mouseMove)
  map.off('mouseleave', LAYERS[0], mouseLeave)
  map.on('contextmenu', LAYERS[0], showPolygonInfo)

  if (polygons.length > 0) {
    addFieldSorageLayer({
      polygons,
      map,
      color: MAIN_COLOR,
      activeColor: '#dc1c0a',
    })
    map.on('click', LAYERS[0], clickHandler)
    map.on('touchstart', LAYERS[0], clickHandler)
    map.on('mousemove', LAYERS[0], mouseMove)
    map.on('mouseleave', LAYERS[0], mouseLeave)
    map.on('contextmenu', LAYERS[0], showPolygonInfo)

    props.mapDraw.deleteAll()
    props.mapDraw.changeMode('static')

    LAYERS.forEach(setFilter)

    if (props.activePolygon) {
      setStateStoragePolygon(props.activePolygon, { active: true })
    }
  }

  setStyleFieldsPolygon(actualExploitations.value, 'actual_exploitation_id')
}

const getLastPolygons = (polygon) => Date.now() - polygon.createdAt < +cacheLifeTime.value * 1000

const setStateStoragePolygon = (id, state) => {
  if (props.map.getSource(SOURCE)) {
    props.map.setFeatureState(
      {
        source: SOURCE,
        id,
      },
      state
    )
  }
}

const addToStorage = ({ geometry, data }) => {
  polygonStorage.value = [
    ...polygonStorage.value.filter((polygon) => polygon.id !== data.id),
    {
      geometry: geometry.geometry,
      id: data?.id,
      properties: {
        id: data?.id,
        back_id: data?.id,
        area: data?.area,
        layer: data?.layer,
        name: data?.layer,
        actual_exploitation_id: data?.actual_exploitation_id, // fields
      },
      source: SOURCE,
      sourceLayer: SOURCE,
      createdAt: Date.now()
    }
  ]
}

const updatePolygon = (geometry, response) => {
  const activePolygons = polygonStorage.value.filter((polygon) => polygon.id === props.activePolygon)

  if (activePolygons && activePolygons.length > 0) {
    const restPolygons = polygonStorage.value.filter((polygon) => polygon.id !== props.activePolygon)
    const newPolygon = {
      ...activePolygons[0],
      id: props.activePolygon,
      geometry: geometry.geometry,
      properties: {
        ...activePolygons[0].properties,
        id: props.activePolygon,
      }
    }
    polygonStorage.value = [
      ...restPolygons,
      newPolygon
    ]
    setStateStoragePolygon(props.activePolygon, { active: true })
  }

  if (activePolygons.length < 1) {
    addToStorage({ geometry: geometry.geometry, data: {
      id: props.activePolygon,
      layer: 'fields',
      name: 'fields',
      area: response?.data?.area,
      actual_exploitation_id: response?.data?.actualExploitationId
    } })
  }
}

const clickHandler = (evt) => {
  emits('clickHandler', evt)
}

const setStyleFieldsPolygon = (group, attribute) => {
  const options = ['case',
    ['boolean', ['feature-state', 'edited'], false], 'transparent',
    ['boolean', ['feature-state', 'selected'], false], 'transparent',
    ['boolean', ['feature-state', 'active'], false], '#dc1c0a']

  group.forEach((item) => {
    options.push(['==', ['get', attribute], item.value])
    const color = item.color ? item.color : '#1589FF'
    options.push(color)
  })

  if (props.map.getSource('fieldStorageLayer_data')) {
    props.map.setPaintProperty('fieldStorageLayer_fill','fill-color', [...options, '#1589FF'])
    props.map.setPaintProperty('fieldStorageLayer_line','line-color', [...options, '#1589FF'])
    props.map.setPaintProperty('fieldStorageLayer_fill','fill-opacity', [
      'case',
      ['boolean', ['feature-state', 'edited'], false], 0,
      ['boolean', ['feature-state', 'active'], false], 0.5,
      ['boolean', ['feature-state', 'focus'], false], 1,
      ['boolean', ['feature-state', 'hover'], false], 0.4,
      0.2,
    ])

  }
}

watch(cacheLifeTime, () => {
  polygonStorage.value = polygonStorage.value.filter(getLastPolygons)
})

watch(polygonStorage, (value, oldValue) => {
  // emits('setPolygonStorage', value)
  const polygons = value.map((item) =>({
    ...toRaw(item),
    geometry: toRaw(item.geometry),
    properties: toRaw(item.properties)
  }))

  localforage.setItem('fields', polygons).catch((error) => console.log(error))

  if (props.map._loaded) {
    if (oldValue?.length > 0) {
      oldValue.forEach((polygon) => {
        if (props.map.getSource(polygon.properties.layer)) {
          props.map.setFeatureState(
            { source: polygon.properties.layer, sourceLayer: polygon.properties.layer, id: polygon.id },
            { active: false, hover: false, edited: false }
          )
        }
      })
    }

    value.forEach((polygon) => {
      if (props.map.getSource(polygon.properties.layer)) {
        props.map.setFeatureState(
          { source: polygon.properties.layer, sourceLayer: polygon.properties.layer, id: polygon.id },
          { active: false, hover: false, edited: true }
        )
      }
    })

    repaintPolygonsFromStorage({ polygons: polygonStorage.value, map: props.map })
  }
}, { deep: true })

watch(() => props.activePolygon, (value, oldValue) => {
  if (value) {
    // if (props.map.getSource('fields')) {
    //   props.map.setFeatureState(
    //     {
    //       source: 'fields',
    //       sourceLayer: 'fields',
    //       id: value,
    //     },
    //     { active: false, edited: true }
    //   )
    // }
    const activePolygons = polygonStorage.value.filter((polygon) => polygon.id === value)

    if (activePolygons && activePolygons.length > 0) {
      const restPolygons = polygonStorage.value.filter((polygon) => polygon.id !== value)
      const newPolygon = {
        ...activePolygons[0],
        id: value,
      }
      setStateStoragePolygon(value, { active: true })

      if (props.map.getSource(activePolygons[0].properties.layer)) {
        props.map.setFeatureState(
          {
            source: activePolygons[0].properties.layer,
            sourceLayer: activePolygons[0].properties.layer,
            id: value,
          },
          { active: false, edited: true }
        )
      }

      polygonStorage.value = [
        ...restPolygons,
        newPolygon
      ]
    }
  }

  if ((!value && oldValue) || (value && oldValue && oldValue !== value)) {

    const activePolygons = polygonStorage.value.filter((polygon) => polygon.id === oldValue)
    if (activePolygons && activePolygons.length > 0) {
      setStateStoragePolygon(oldValue, { active: false })
    }
  }
}, { immediate: true })

onMounted(() => {
  if (!cacheLifeTime.value) {
    store.dispatch('map/fetchCacheLifeTime')
  }
  localforage.getItem('fields')
    .then((polygons) => {
      if (polygons && polygons.length > 0) {
        polygonStorage.value = polygons.filter(getLastPolygons)

        if (props.map?.getSource(SOURCE)) {
          repaintPolygonsFromStorage({ polygons: polygonStorage.value, map: props.map })
        }
      }
    })
    .catch((error) => {
      console.log(error)
    })

  props.map.on('load', () => {
    repaintPolygonsFromStorage({ polygons: polygonStorage.value, map: props.map })
  })
})

defineExpose({ addToStorage, updatePolygon })
</script>
