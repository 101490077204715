<template>
  <MapControlButton
    v-if="isEditButton"
    v-access="'section_access_add_info'"
    :isActive="mode === 'edit-group'"
    title="Массовое редактирование участков"
    @onClick="emits('clickEdit')"
    aria-label="Режим редактирования группы"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="currentColor" />
      <rect width="392" height="776" transform="translate(-104 -729)" fill="white" />
      <path d="M3 17.0156V3H17.0156V17.0156H3ZM3 0.984375C2.46875 0.984375 2 1.1875 1.59375 1.59375C1.1875 2 0.984375 2.46875 0.984375 3V17.0156C0.984375 17.5469 1.1875 18.0156 1.59375 18.4219C2 18.7969 2.46875 18.9844 3 18.9844H17.0156C17.5469 18.9844 18.0156 18.7969 18.4219 18.4219C18.7969 18.0156 18.9844 17.5469 18.9844 17.0156V3C18.9844 2.46875 18.7969 2 18.4219 1.59375C18.0156 1.1875 17.5469 0.984375 17.0156 0.984375H3ZM21 5.01562V21H5.01562V23.0156H21C21.5312 23.0156 22 22.8125 22.4062 22.4062C22.8125 22 23.0156 21.5312 23.0156 21V5.01562H21Z" fill="currentColor" />
      <path d="M15.2227 6.80859L14.2324 7.79883L12.2012 5.76758L13.1914 4.77734C13.293 4.67578 13.4199 4.625 13.5723 4.625C13.7246 4.625 13.8516 4.67578 13.9531 4.77734L15.2227 6.04688C15.3242 6.14844 15.375 6.27539 15.375 6.42773C15.375 6.58008 15.3242 6.70703 15.2227 6.80859ZM5.625 12.3438L11.6172 6.35156L13.6484 8.38281L7.65625 14.375H5.625V12.3438Z" fill="currentColor" />
    </svg>
  </MapControlButton>
  <MapControlButton
    v-if="isCreate && accessCreate"
    :isActive="mapState === 'edit' && mode !== 'edit-group' && modeState === 'create'"
    title="Создание участка"
    @onClick="emits('clickCreate')"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 17.0156V3H17.0156V17.0156H3ZM3 0.984375C2.46875 0.984375 2 1.1875 1.59375 1.59375C1.1875 2 0.984375 2.46875 0.984375 3V17.0156C0.984375 17.5469 1.1875 18.0156 1.59375 18.4219C2 18.7969 2.46875 18.9844 3 18.9844H17.0156C17.5469 18.9844 18.0156 18.7969 18.4219 18.4219C18.7969 18.0156 18.9844 17.5469 18.9844 17.0156V3C18.9844 2.46875 18.7969 2 18.4219 1.59375C18.0156 1.1875 17.5469 0.984375 17.0156 0.984375H3ZM21 5.01562V21H5.01562V23.0156H21C21.5312 23.0156 22 22.8125 22.4062 22.4062C22.8125 22 23.0156 21.5312 23.0156 21V5.01562H21Z" fill="currentColor" />
      <path d="M14.0742 10.5742H10.5742V14.0742H9.42578V10.5742H5.92578V9.42578H9.42578V5.92578H10.5742V9.42578H14.0742V10.5742Z" fill="currentColor" />
    </svg>
  </MapControlButton>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useStore } from 'vuex'
import MapControlButton from './MapControlButton'

const store = useStore()

defineProps({
  isEditButton: {
    type: Boolean,
    required: false
  },
  isCreate: {
    type: Boolean,
    required: false
  }
})
const emits = defineEmits(['clickEdit', 'clickCreate', 'cancelCreate'])

const mode = computed(() => store.state.map.mode)
// TODO mapState, modeState -> mode
const mapState = computed(() => store.state.map.mapState)
const modeState = computed(() => store.state.map.modeState)

const accessLayers = computed(() => store.state.dictionaries.layers)
const userPermissions = computed(() => store.state.auth.user.permissions)
const accessCreate = computed(() => accessLayers.value.map((layer) => `map_${layer.value}_section_create`).some((layer) => userPermissions.value.includes(layer)))
</script>
