const BASE_URL = '/private/dashboard'
// const BASE_URL_V2 = '/v2/private'

class DashboardService {
  constructor(api) {
    this.api = api
  }

  getStatistic(params) {
    return this.api.get(`${BASE_URL}/sections`, { params })
  }

  getUserActivity(params) {
    return this.api.get(`${BASE_URL}/user-activity`, { params })
  }

  getKptStatistic(params) {
    return this.api.get(`${BASE_URL}/kpt`, { params })
  }

  getOverlaps(params) {
    return this.api.get(`${BASE_URL}/overlaps`, { params })
  }

  getFieldsStatistic(params) {
    return this.api.get(`${BASE_URL}/fields`, { params })
  }

  // getProblemFields(params) {
  //   return this.api.get('/sections/fields/with-problem', { params })
  // }
  getProblemFields(params) {
    return this.api.get(`${BASE_URL}/fields/problems`, { params })
  }

  getFieldsByProblem(params) {
    return this.api.get(`${BASE_URL}/fields/problem`, { params })
  }

  // getProblemSections(params, signal) {
  //   return this.api.get('/sections/with-problem', { params, signal })
  // }

  getProblemSections(params) {
    return this.api.get(`${BASE_URL}/sections/problems`, { params })
  }

  getSectionsByProblem(params) {
    return this.api.get(`${BASE_URL}/sections/problem`, { params })
  }

  getMainScreenInfo(params) {
    return this.api.get(`${BASE_URL}/main-screen-info`, { params })
  }

  getOperationYearFields(params) {
    return this.api.get(`${BASE_URL}/field-operation-statistic`, { params })
  }

  getProblemsFieldsByMo() {
    return this.api.get(`${BASE_URL}/fields/data-health-check-table-leader-board`)
  }
}

export { DashboardService }
