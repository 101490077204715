<template>
  <Modal
    v-if="isShowEfisHistoryModal"
    title="Отчет ЕФИС"
    showClose
    @cancelClick="closeEfisHistoryModal"
    @closeModal="closeEfisHistoryModal"
    @confirmClick="$refs.efisHistory.createReport()"
  >
    <EfisHistoryForm ref="efisHistory" @close="closeEfisHistoryModal" />
  </Modal>

  <Modal
    v-if="isShowSuccessEfisModal"
    title="Экспорт отчета"
    textConfirm="Перейти"
    @cancelClick="closeSuccesEfisModal"
    @closeModal="closeSuccesEfisModal"
    @confirmClick="openReports"
  >
    <div class="content">
      Отчет будет сформирован и доступен для скачивания в разделе "Экспорт".
    </div>
  </Modal>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Modal from '@/components/common/modals/Modal'
import EfisHistoryForm from './forms/EfisHistory'

const store = useStore()
const router = useRouter()

const isShowEfisHistoryModal = computed(() => store.state.modals.isShowEfisHistoryModal)
const isShowSuccessEfisModal = computed(() => store.state.modals.isShowSuccessEfisModal)

const closeEfisHistoryModal = () => {
  store.commit('modals/setIsShowEfisHistoryModal', false)
}

const closeSuccesEfisModal = () => {
  store.commit('modals/setIsShowSuccessEfisModal', false)
}

const openReports = () => {
  closeSuccesEfisModal()
  router.push('/reports').catch(() => {})
}

</script>

<style lang='scss' scoped>
.content {
  max-width: 480px;
}
</style>
