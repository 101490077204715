const BASE_URL = '/private/fertilizers'
const BASE_URL_V2 = '/v2/private'

class EnrichmentsService {
  constructor(api) {
    this.api = api
  }

  getEnrichments(params) {
    // periodId, regionId, orgId, cultureTypeId
    return this.api.get(BASE_URL, { params })
  }

  updateEnrichments(id, form) {
    // { khmFl: 1, khmFosf: 1, khmFfo: 1 }
    return this.api.post(`${BASE_URL}/${id}`, form)
  }

  refresh() {
    return this.api.get(`${BASE_URL}/refresh`)
  }
}

export { EnrichmentsService }
