import Notify from 'quasar/src/plugins/Notify.js';
import './styles/quasar.scss'
import iconSet from 'quasar/icon-set/mdi-v4.js'
import lang from 'quasar/lang/ru.js'
import '@quasar/extras/mdi-v4/mdi-v4.css'

const accentColors = {
  dev: '#3e9644',
  stage: '#3232ef',
  production: '#F25749',
  local: '#8d1187'
}

const envMode = process.env.VUE_APP_MODE ? `${process.env.VUE_APP_MODE}` : 'local'

let accentColor = accentColors[envMode]

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      accent: accentColor
    }
  },
  plugins: {
    Notify
  },
  lang: lang,
  iconSet: iconSet
}
