const scales = [
  '#E6E27F',
  '#DFD177',
  '#D8C070',
  '#D0AE69',
  '#C99D62',
  '#C18C5B',
  '#B97B54',
  '#B16A4E',
  '#925245',
  '#734339',
  '#59342C',
]

const soils = [
  { name: 'Ареносоли', color: '#FDDFDF' },
  { name: 'Гистосоли', color: '#3EBEBD' },
  { name: 'Глайсоли', color: '#849FFB' },
  { name: 'Грейзем', color: '#4B6667' },
  { name: 'Камбисоли', color: '#DDE032' },
  { name: 'Лептосоли', color: '#BFBFBF' },
  { name: 'Подзолювисоли', color: '#9E7A7A' },
  { name: 'Регосоли', color: '#D8EACD' },
  { name: 'Солончаки', color: '#FCBDDF' },
  { name: 'Файоземы', color: '#C57C45' },
  { name: 'Флувисоли', color: '#52FFDF' },
  { name: 'Черноземы', color: '#1d3b52' },
]

const landuse = [
  { name: 'Населенные пункты', color: '#ec0150' },
  { name: 'Леса', color: '#4cff05' },
  { name: 'Пахотные земли', color: '#ffffa9' },
  { name: 'Пастбища', color: '#e7e749' },
  { name: 'Многолетние насаждения', color: '#e67f08' },
  { name: 'Промышленные, коммерческие и транспортные объекты', color: '#ce4cf6' },
  { name: 'Искуственные несельскохозяйственные зеленые насаждения', color: '#ffa6ff' },
  { name: 'Шахты, свалки, строительные площадки', color: '#a401ca' },
  { name: 'Водная поверхность', color: '#06cbee' },
  { name: 'Открытые пространства с незначительной растительностью', color: '#e6e6e6' },
  { name: 'Зоны кустарниковой и травянистой растительности', color: '#caf34c' },
  { name: 'Водно-болотные угодья', color: '#a8a5ff' },
  { name: 'Прибрежные водно-болотные угодья', color: '#e6e6ff' },
]

const oopt = [
  { name: 'Природные заповедники', color: '#fb0302', direction: '135deg' },
  { name: 'Национальные парки', color: '#db9909', direction: '135deg' },
  { name: 'Особо охраняемые территории', color: '#4ce600', direction: '90deg' },
  { name: 'Природные парки', color: '#4ce600', direction: '45deg' },
  { name: 'Природные заказники', color: '#1661a2', direction: '45deg' },
  { name: 'Памятники природы', color: '#6d2804', direction: '45deg' },
  { name: 'Дендрологические парки и ботанические сады', color: '#fdfe01', direction: '45deg' },
  { name: 'Лечебно-оздоровительные местности и курорты', color: '#63feda', direction: '45deg' },
  { name: 'Перспективные ООПТ различных категорий', color: '#7f807f', direction: '0deg' },
  { name: 'ООПТ иных категорий', color: '#bb07f0', direction: '45deg' },
]

export { scales, soils, landuse, oopt }
