<template>
  <span v-if="!placeholder" class="label-select">
    {{ label }}
  </span>
  <div class="relative">
    <q-select
      v-model="value"
      ref="refElement"
      outlined
      :label-slot="!!placeholder"
      :multiple="multiple"
      :options="isSort ? sortedOptions : filtredOptions"
      :use-input="useInput"
      :dense="dense"
      :virtual-scroll-slice-size="scrollSize"
      :option-disable="(item) => item === null ? true : item.cannotSelect"
      :loading="isLoading"
      :popup-content-class="filterType === 'culture' ? 'filter' : ''"
      :rules="rules"
      :disable="disable"
      bg-color="white"
      emit-value
      map-options
      @filter="filter"
    >
      <!-- options-dense -->
      <template #label v-if="placeholder">
        {{ placeholder }}
      </template>
      <template #append>
        <ChooseAllButton v-if="multiple && allable" @choose="chooseAllOptions" />
        <ClearButton v-if="clearable && ((multiple && value.length) || (!multiple && value))"  @clear="clear" />
      </template>
    </q-select>
    <q-badge v-if="multiple && value.length > 1" color="accent" floating rounded>
      {{ value.length }}
    </q-badge>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, defineExpose, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import ClearButton from '@/components/uikit/buttons/ClearButton'
import ChooseAllButton from '@/components/uikit/buttons/ChooseAllButton'
import { charMapToCyrillic } from '@/utils/char-map'

const props = defineProps({
  modelValue: {
    required: true
  },
  label: {
    type: String,
    required: false,
    default: ''
  },
  multiple: {
    type: Boolean,
    required: false,
    default: false
  },
  dense: {
    type: Boolean,
    required: false,
    default: true
  },
  useInput: {
    type: Boolean,
    required: false,
    default: false
  },
  clearable: {
    type: Boolean,
    required: false,
    default: false
  },
  allable: {
    type: Boolean,
    required: false,
    default: false
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false
  },
  isSort: {
    type: Boolean,
    required: false,
    default: false
  },
  options: {
    type: Array,
    required: true,
    default: () => []
  },
  scrollSize: {
    type: Number,
    required: false,
    default: 100
  },
  filterType: {
    type: String,
    required: false,
    default: 'default'
  },
  fieldLandTypeIds: {
    type: Array,
    required: false,
    default: () => []
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: Array,
    required: false,
    default: () => []
  },
  disable: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emits = defineEmits(['update:modelValue'])

const store = useStore()

const refElement = ref(null)
const value = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emits('update:modelValue', val)
  }
})

const filtredOptions = ref(props.options)

const sortedOptions = computed(() => sortOptions(filtredOptions.value))

const cultureGroups = computed(() => {
  return store.state.dictionaries.cultureGroups
})

const cultureTypes = computed(() => {
  return store.state.dictionaries.typeCulture.filter((category) => !category.isHidden)
})

const getCultureByGroup = (cultures) => {
  let options = []
  cultureGroups.value.forEach((group) => {
    let cultureByGroup = cultures.filter((culture) => culture.cultureGroupId === group.value)

    if (props.fieldLandTypeIds?.length) {
      cultureByGroup = cultureByGroup
        .filter(
          (culture) => culture.landTypes
            .map((type) => type.value)
            .some((type) => props.fieldLandTypeIds.includes(type))
        )
    }

    if (cultureByGroup.length > 0) {
      options.push({ ...group, label: group.name, cannotSelect: true })
      cultureByGroup.forEach((culture) => {
        options.push(culture)
      })
    }
  })

  return options
}

const filter = (val, update) => {
  update(() => {
    if (props.filterType === 'default') {
      if (val === '') {
        filtredOptions.value = props.options
      } else {
        val = val.toLowerCase()
          .split('').map((key) => charMapToCyrillic[key] ? charMapToCyrillic[key] : key).join('')
        filtredOptions.value = props.options.filter((v) => v.label.toString().toLowerCase().indexOf(val) > -1)
      }
    } else if (props.filterType === 'culture') {
      if (val === '') {
        filtredOptions.value = getCultureByGroup(cultureTypes.value)
      } else {
        val = val.toLowerCase()
          .split('').map((key) => charMapToCyrillic[key] ? charMapToCyrillic[key] : key).join('')
        filtredOptions.value = getCultureByGroup(cultureTypes.value.filter((v) => v.label.toString().toLowerCase().indexOf(val) > -1))
      }
    }
    if (props.isSort) {
      filtredOptions.value = sortOptions(filtredOptions.value)
    }
  })
}

const sortOptions = (options) => {
  return options?.filter((item) => !item.value)
    .concat(options.filter((item) => props.modelValue.includes(item.value)))
    .concat(options.filter((item) => item.value && !props.modelValue.includes(item.value)))
}

const chooseAllOptions = () => {
  let newValue
  if (props.filterType === 'default') {
    newValue = props.options.filter((item) => !item.cannotSelect).map((item) => item.value)
  }

  if (props.filterType === 'culture') {
    newValue = filtredOptions.value.filter((item) => !item.cannotSelect).map((item) => item.value)
  }

  emits('update:modelValue', newValue)
  refElement.value.blur()
}

const clear = () => {
  const initValue = props.multiple ? [] : ''
  emits('update:modelValue', initValue)
  refElement.value.blur()
}

const blur = () => {
  refElement.value?.blur()
}

const showPopup = () => {
  refElement.value?.showPopup()
}

defineExpose({ blur, showPopup })
</script>
<style>
.filter .q-field__native .q-field__input {
  position: absolute;
  z-index: -100;
}

.filter .q-field--focused .q-field__native .q-field__input {
  position: static;
  z-index: 1;
}

.filter .disabled {
  opacity: 1 !important;
  font-style: italic;
}

.filter .disabled:not(:first-child) {
  margin-top: 15px;
}
</style>
