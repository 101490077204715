<template>
  <div class="layout-map-view">
    <div v-if="maintenanceNotice" class="layout-map-view__notification">
      <span>{{ maintenanceNotice }}</span>
    </div>
    <Header />
    <div class="content" :class="{'content--notification': maintenanceNotice}">
      <div
        v-if="!isShowSidebar"
        class="content__button-sidebar"
        aria-label="Развернуть сайдбар"
        @click="isShowSidebar = true"
      >
        Фильтр
      </div>
      <div class="page" aria-label="Фильтр">
        <transition name="sidebar">
          <div class="tabs" v-show="isShowSidebar">
            <div class="tabs__close-sidebar" @click="isShowSidebar = false" aria-label="Закрыть сайдбар">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 7L17 17" stroke="#2F3E4D" stroke-width="2" />
                <path d="M17.0005 7L7.00049 17" stroke="#2F3E4D" stroke-width="2" />
              </svg>
            </div>
            <q-tabs
              v-model="tab"
              :model-value="tab"
              @update:model-value="(value) => {tab = value}"
              active-color="accent"
            >
              <!-- <q-tab v-if="!isVisibleSchemeLayer" name="search" label="Поиск" />
              <q-tab v-if="isVisibleSchemeLayer" name="searchSchemes" label="Поиск по схемам" /> -->
              <q-tab name="search" label="Поиск" />
              <q-tab name="reports" label="Экспорт" />
            </q-tabs>
            <q-separator />
            <q-tab-panels
              v-model="tab"
              animated
              :model-value="tab"
              @update:model-value="(value) => {tab = value}"
            >
              <q-tab-panel name="search">
                <Filter />
              </q-tab-panel>
              <!-- <q-tab-panel v-if="isVisibleSchemeLayer" name="searchSchemes">
                <FilterSchemes @setSchemeFilter="setSchemeFilter" />
              </q-tab-panel> -->
              <q-tab-panel name="reports">
                <template v-if="!isVisibleSchemeLayer && visibleEntity === 'sections' && cadastralByFilter?.reports?.length > 0">
                  <ReportCard
                    v-for="report in cadastralByFilter.reports"
                    v-access="'section_access_report'"
                    :key="report.name"
                    :name="report.name"
                    type="sections"
                    :filter="sectionsFilter"
                    from="map"
                  />
                </template>
                <template v-if="!isVisibleSchemeLayer && visibleEntity === 'fields' && fieldsByFilter?.reports?.length > 0">
                  <ReportCard
                    v-for="report in fieldsByFilter.reports"
                    v-access="'section_access_report'"
                    :key="report.name"
                    :name="report.name"
                    type="fields"
                    :filter="fieldsFilter"
                    from="map"
                  />
                </template>
                <ReportCard
                  v-if="isVisibleSchemeLayer"
                  v-access="'docs_schemas_view'"
                  name="Выгрузка схем"
                  type="scheme"
                  :filter="schemeFilter"
                  from="map"
                />
                <ReportCard
                  v-if="!isVisibleSchemeLayer && visibleEntity === 'fields' && currentPeriod"
                  v-access="'access_export_efis'"
                  name="Отчет ЕФИС по текущему сезону"
                  type="efis"
                  :filter="{ periodIds: [currentPeriod.id], regionIds: [], reportType: 'Отчет ЕФИС по текущему сезону' }"
                  from="map"
                />
                <ReportCard
                  v-if="!isVisibleSchemeLayer && visibleEntity === 'fields'"
                  v-access="'access_export_efis'"
                  name="Отчет ЕФИС по прошлым сезонам"
                  type="efis-history"
                  :filter="{ periodIds: [], regionIds: [], reportType: 'Отчет ЕФИС по прошлым сезонам' }"
                  from="map"
                />
              </q-tab-panel>
            </q-tab-panels>
          </div>
        </transition>
        <slot />
      </div>
      <MapComponent
        class="map"
        :regionId="filter ? districtId : null"
        :isShowSidebar="isShowSidebar"
        :filterLayers="filter.layers"
      />
    </div>
    <Slider
      v-if="isShowSlider"
      :images="images"
      :activeSlide="activeSlide - 1"
      @close="closeSlider"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watch, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import MapComponent from '@/modules/map/components/Map'
import Header from '@/components/base/Header'
import ReportCard from '@/components/common/cards/ReportCard'
// import Filter from '@/modules/map/components/Filter'
import Filter from '@/modules/map/components/filters/Filter'
// import FilterSchemes from '@/modules/map/components/FilterSchemes'
import Slider from '@/modules/map/components/slider/Slider'
// import { cloneDeep } from 'lodash'

const store = useStore()
const router = useRouter()

const isShowSidebar = ref(false)
const filter = ref({ layers: [] })
const schemeFilter = ref({})
const tab = ref('search')

const visibleEntity = computed(() => store.state.map.visibleEntity)
const activeSlide = computed(() => store.state.files.activeSlide)
const isShowSlider = computed(() => store.state.files.isShowSlider)
const images = computed(() => store.state.details.images)
const cadastralByFilter = computed(() => store.state.mapFilter.cadastralByFilter)
const fieldsByFilter = computed(() => store.state.mapFilter.fieldsByFilter)
const fieldsFilter = computed(() => store.state.mapFilter.fieldsFilter)
const sectionsFilter = computed(() => store.state.mapFilter.filter)
const regions = computed(() => store.state.dictionaries.regions)
const districtId = computed(() => {
  if (filter.value.regionId) {
    const regionFiltred = regions.value.find((region) => region.value.toString() === filter.value.regionId.toString())
    // return regionFiltred ? (regionFiltred.districtId || regionFiltred.value) : null
    return regionFiltred?.districtId
  }
  return null
})
const maintenanceNotice = computed(() => store.state.settings.maintenanceNotice)
const maintenancePage = computed(() => store.state.settings.maintenancePage)
// const isVisibleSchemeLayer = computed(() => store.state.schemes.isVisibleSchemeLayer)
const isVisibleSchemeLayer = computed(() => visibleEntity.value === 'schemes')

const currentPeriod = computed(() => store.state.dictionaries.periods.find((period) => period.isNow))

const closeSlider = () => {
  store.commit('files/setIsShowSlider', false)
}

// const setFilter = (filter) => {
//   filter.value = filter
// }

const setSchemeFilter = (filter) => {
  schemeFilter.value = filter
}

onMounted(() => {
  store.dispatch('dictionaries/fetchDictionaries')
  store.dispatch('showMap', true)
  isShowSidebar.value = window.innerWidth > 1023
})

onBeforeUnmount(() => {
  store.commit('map/setVisibleEntity', null)
})

watch(maintenancePage, () => {
  if (maintenancePage.value) {
    router.push('/maintenance')
  }
})

// watch(isVisibleSchemeLayer, (value) => {
//   tab.value = value ? 'searchSchemes' : 'search'
// })
</script>
<style lang="scss" scoped>
  .layout-map-view {
    overflow: hidden;
    height: 100vh;

    &__notification {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      // background-color: rgba($color-active, 0.7);
      background-color: #ff6a6a;

      @include below('d_sm') {
        text-align: center;
      }
    }
  }

  .content {
    position: relative;
    display: flex;
    height: calc(100% - 68px);
    // padding-top: 68px;

    &--notification {
      height: calc(100% - 108px);
    }

    &__button-sidebar {
      position: absolute;
      top: 10px;
      left: 10px;
      cursor: pointer;
      background: #fff;
      border: 2px solid rgba(191, 191, 191, 0.45);
      border-radius: 4px;
      padding: 0 16px;
      z-index: 1;
      font-size: 12px;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .map {
    width: calc(100vw - 344px);
    // height: calc(100% - 68px);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  @include below('t_lg') {
    .map {
      width: 100vw;
    }
  }

  .page {
    z-index: 3;
    height: 100%;
    position: relative;
    background-color: #ffffff;
  }

  .tabs {
    width: 344px;
    height: 100%;

    &__close-sidebar {
      display: none;
      position: absolute;
      z-index: 10;
      padding: 10px;
      top: 0;
      right: 0;
      cursor: pointer;

      @include below('t_lg') {
        display: block;
      }
    }

    @include below('m_md') {
      width: 100vw;
    }
  }

  .sidebar-enter-active,
  .sidebar-leave-active {
    transition: 0.5s;
  }
  .sidebar-enter,
  .sidebar-leave-to {
    margin-left: -344px;
  }
</style>
