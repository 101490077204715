import ApiService from '@/services'

const state = {
  passport: null,
  dates: [],
  isLoadingPassport: false
}

const actions = {
  fetchPassport({ commit }, params) {
    commit('setIsLoadingPassport', true)
    return new Promise((resolve, reject) => {
      ApiService.passports.getPassport(params)
        .then((response) => {
          commit('setPassport', response.data)
          commit('setIsLoadingPassport', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingPassport', false)
          reject(error)
        })
    })
  },
  fetchDates({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('setDates', [])

      ApiService.passports.getPassportDates(params)
        .then((response) => {
          commit('setDates', response?.data?.map((date) => ({ name: date.name, value: date.id })))
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  setPassport(state, data){
    state.passport = data
  },
  setDates(state, data){
    state.dates = data
  },
  setIsLoadingPassport(state, data){
    state.isLoadingPassport = data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
