const removeAutoCreatingFieldLayer = (map) => {
  if (map.getSource('fieldAutoLayer_data')) {
    map.removeLayer('fieldAutoLayer_fill')
    map.removeLayer('fieldAutoLayer_line')
    map.removeSource('fieldAutoLayer_data')
  }
}

const addAutoCreatingFieldLayer = (params) => {
  const source = {
    type: 'FeatureCollection',
    features: params.polygons.map((el) => {
      return {
        id: el.id,
        type: 'Feature',
        geometry: el.geometry,
        properties: el.properties,
      }
    })
  }

  if (!params.map.getSource('fieldAutoLayer_data')) {
    params.map.addSource('fieldAutoLayer_data', {
      type: 'geojson',
      data: source
    })
  }

  params.map.addLayer({
    id: 'fieldAutoLayer_fill',
    type: 'fill',
    source: 'fieldAutoLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'fill-color': ['case',
        ['boolean', ['feature-state', 'active'], false], params.activeColor,
        params.color
      ],
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'active'], false], 0.5,
        ['boolean', ['feature-state', 'hover'], false], 0.6,
        ['boolean', ['feature-state', 'edited'], false], 0,
        0.4,
      ],
    },
  })

  params.map.addLayer({
    id: 'fieldAutoLayer_line',
    type: 'line',
    source: 'fieldAutoLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-color': ['case',
        ['boolean', ['feature-state', 'active'], false], params.activeColor,
        ['boolean', ['feature-state', 'edited'], false], 'transparent',
        params.color
      ],
      'line-width': 1
    },
  })
}

export { addAutoCreatingFieldLayer, removeAutoCreatingFieldLayer }
