<template>
  <div class="rosreestr" data-testid="form">
    <q-form ref="formRef" @validation-error="onValidateError">
      <div v-for="(overlap, index) in overlaps" :key="index">
        <p class="rosreestr__title">
          <span class="bold">{{ index + 1 }}. {{ overlap.overlapsSegment.cadastralNumber }} ({{ layers[overlap.overlapsSegment.layer] }}):</span> пересечение на {{ overlap.area }} га ({{ ( overlap.area / overlap.segment.area * 100).toFixed(2) }}%)
        </p>
        <p v-if="isShowCheckOverlaps && overlap.overlapsSegment.area - overlap.area < 0.1" class="error">
          Этот участок будет удален при его редактировании
        </p>
        <template v-if="isShowCheckOverlaps">
          <div v-if="overlap.overlapsSegment.layer !== 'zu_lf' && layersAccess.includes(overlap.overlapsSegment.layer)" data-testid="action1" class="rosreestr__field">
            <Select
              v-model="actionsSelected[index]"
              label="Действие с границами"
              :options="( overlap.area / overlap.segment.area * 100).toFixed(2) === '100.00' || !layersAccess.includes(overlap.segment.layer) ? optionsNoCut : options"
              :rules="[val => !!val || 'Обязательное поле']"
            />
          </div>
          <div v-if="overlap.overlapsSegment.layer !== 'zu_lf' && !layersAccess.includes(overlap.overlapsSegment.layer)" data-testid="action2" class="rosreestr__field">
            <Select
              v-model="actionsSelected[index]"
              label="Действие с границами"
              :options="(overlap.area / overlap.segment.area * 100).toFixed(2) === '100.00' || !layersAccess.includes(overlap.segment.layer) ? optionsDefault : optionsZulf"
              :rules="[val => !!val || 'Обязательное поле']"
            />
          </div>
          <div v-if="overlap.overlapsSegment.layer === 'zu_lf'" data-testid="action3" class="rosreestr__field">
            <Select
              v-model="actionsSelected[index]"
              label="Действие с границами"
              :options="(overlap.area / overlap.segment.area * 100).toFixed(2) === '100.00' || !layersAccess.includes(overlap.segment.layer) ? optionsDefault : optionsZulf"
              :rules="[val => !!val || 'Обязательное поле']"
            />
          </div>
        </template>
      </div>
    </q-form>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, defineExpose } from 'vue'
import { useStore } from 'vuex'
import Select from '@/components/common/selects/Select'

const store = useStore()

const layers = {
  rosreestr: 'Росреестр',
  rosreestr2: 'Участки на оформлении',
  kugi: 'КУГИ',
  kumi: 'КУМИ',
  federal: 'Федеральные земли',
  pais: 'Паевые земли',
  zu_lf: 'Лесной фонд',
}

const options = [
  { label: 'Вырезать пересечение в пересекающем участке', value: 'edit' },
  { label: 'Вырезать пересечение в текущем участке', value: 'cut' },
  { label: 'Оставить пересечение', value: 'default' }
]

const optionsZulf = [
  { label: 'Вырезать пересечение в текущем участке', value: 'cut' },
  { label: 'Оставить пересечение', value: 'default' }
]

const optionsNoCut = [
  { label: 'Вырезать пересечение в пересекающем участке', value: 'edit' },
  { label: 'Оставить пересечение', value: 'default' }
]

const optionsDefault = [
  { label: 'Оставить пересечение', value: 'default' }
]

const actionsSelected = ref([])
const formRef = ref()

const layersAccess = computed(() => store.state.dictionaries.layers.filter((layer) => user.value.permissions?.includes(`map_${layer.value}_polygon`)).map((layer) => layer.value))

const deleteAccess = computed(() => store.state.dictionaries.layers.filter((layer) => user.value.permissions?.includes(`map_${layer.value}_delete`)).map((layer) => layer.value))

const user = computed(() => store.state.auth.user)
const isShowCheckOverlaps = computed(() => store.state.mapFilter.isShowCheckOverlaps)
const overlaps = computed(() => store.state.map.overlaps)
const actions = computed(() => store.state.map.actions)

const onValidateError = (ref) => {
  ref.$el.scrollIntoView()
}

const savePolygon = () => {
  const formEl = formRef.value

  formEl.validate().then((result) => {
    if (result) {
      store.commit('modals/setDisableModal', false)

      if (isShowCheckOverlaps.value) {
        if (window.location.href.includes('lk.sovhoz.tech/')) {
          window.ym(75689863,'reachGoal','map-zucard-edit-deleteoverlaps-delete-click')
        }
        overlaps.value.forEach((overlap, index) => {
          if (overlap.overlapsSegment.area - overlap.area < 0.1 && !deleteAccess.value.includes(overlap.overlapsSegment.layer)) {
            actionsSelected.value[index] = 'default'
          }
        })
        store.commit('map/setActions', actionsSelected.value)
        store.commit('map/setCountConfirmCheckPolygonWithoutCurrent')

      } else {
        store.commit('map/setCountConfirmCheckPolygon')
      }
    }
  })
}

onMounted(() => {
  actionsSelected.value = [...actions.value]
})

defineExpose({ savePolygon })
</script>
<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 175px;
  padding-bottom: 100px;

  @include below('m_md') {
    width: 100%;
  }

  &__title {
    margin-bottom: 10px;

    .bold {
      font-weight: 700;
    }
  }

  &__field {
    display: block;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .error {
    color: $color-active;
  }
}

</style>
