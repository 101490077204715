import script from "./InheriteAttributes.vue?vue&type=script&setup=true&lang=js"
export * from "./InheriteAttributes.vue?vue&type=script&setup=true&lang=js"

import "./InheriteAttributes.vue?vue&type=style&index=0&id=7816d9fa&lang=scss"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QIcon});
