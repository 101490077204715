<template>
<div class="tools">
  <button @click="emits('deleteOverlaps')">Вырезать</button>
  <button @click="store.commit('fields/setIsShowFieldOverlaps', false)">Отменить</button>
</div>
</template>
<script setup>
import { computed, defineEmits } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const emits = defineEmits(['deleteOverlaps'])
const isShowFieldOverlaps = computed(() => store.state.fields.isShowFieldOverlaps)
const fieldOverlaps = computed(() => store.state.fields.fieldOverlaps)

</script>
<style lang="scss" scoped>
.tools {
  width: 200px;
  height: 40px;
  position: absolute;
  bottom: 52px;
  left: 50%;
  transform: translateX(-50%);

  button {
    width: 50%;
    height: 40px;
    border: 1px solid $color-gray4;
    border-right: none;
    background-color: #fff;
    cursor: pointer;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
      border-left: 1px solid $color-gray4;
    }
  }
}
</style>
