<template>
  <div class="rosreestr" data-testid="form">
    <q-form ref="formRef">
      <div class="rosreestr__field" data-testid="checkStatusId">
        <Select
          v-model="form.checkStatusId"
          label="Статус проверки"
          :options="checkStatuses"
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>
      <div v-if="showDataComment" class="rosreestr__field" data-testid="dataComment">
        <span class="label-select">Комментарий к данным</span>
        <q-input
          v-model="form.dataComment"
          placeholder='Комментарий к данным'
          outlined
          type="textarea"
          no-error-icon
          :rules="[val => !!val || 'Обязательное поле']"
          dense
        />
      </div>
      <div v-if="emptyFields.length > 0" class="rosreestr__field">
        <p class="rosreestr__text">Не заполнены следующие параметры:</p>
        <p v-for="field in emptyFields" :key="field" class="rosreestr__text rosreestr__text--red">{{ field }}</p>
      </div>
    </q-form>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, defineExpose, ref, reactive, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import Select from '@/components/common/selects/Select'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const props = defineProps({
  fields: {
    type: Object,
    required: true
  },
  id: {
    type: String,
    required: true
  }
})

const emits = defineEmits(['close'])

const formRef = ref()

const form = reactive({
  id: null,
  checkStatusId: null,
  dataComment: null,
})

const emptyFields = ref([])

const landTypes = computed(() => store.state.dictionaries.landTypes)
const landOwnerships = computed(() => store.state.dictionaries.landOwnerships)
const checkStatuses = computed(() => store.state.dictionaries.checkStatuses
  .map(item => ({
    ...item,
    name: item.formName,
    label: item.name
  }))
)

const showDataComment = computed(() => {
  const checkStatus = checkStatuses.value.find(item => item.value === form.checkStatusId)
  return checkStatus && checkStatus.showDataComment
})

const checkFields = () => {
  if (!props.fields.landTypeId || (landTypes.value.find((id) => id.value === props.fields.landTypeId) && landTypes.value.find((id) => id.value === props.fields.landTypeId).name === 'Нет данных')) {
    emptyFields.value.push('Категория угодий')
  }

  if (!props.fields.landOwnershipId || (landOwnerships.value.find((id) => id.value === props.fields.landOwnershipId) && landOwnerships.value.find((id) => id.value === props.fields.landOwnershipId).name === 'Нет данных')) {
    emptyFields.value.push('Категория прав собственности')
  }
}

const sendForm = () => {
  if (window.location.href.includes('lk.sovhoz.tech/')) {
    window.ym(75689863,'reachGoal','map-zucard-edit-checkzustatus-change-click')
  }

  const formEl = formRef.value

  formEl.validate().then((result) => {
    emptyFields.value = []

    if (form.checkStatusId === 2) {
      checkFields()
    }

    if (result && emptyFields.value.length < 1) {
      store.commit('modals/setDisableModal', true)
      ApiService.map.updateArea(form)
        .then(() => {
          store.dispatch('mapFilter/fetchCurrentPage', { id: form.id })
          store.commit('mapFilter/setEditCount')
          emits('close')
          store.commit('modals/setDisableModal', false)
          $q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Успешно',
            timeout: 5000
          })
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message
          store.dispatch('auth/fetchUser')
          store.commit('modals/setDisableModal', false)
          $q.notify({
            type: 'negative',
            position: 'bottom',
            message: errorText ? errorText : 'Ошибка',
            timeout: 5000
          })
        })
    }
  })
}

watch(() => form.checkStatusId, () => {
  if (form.checkStatusId === 2) {
    checkFields()
  }

  if (form.checkStatusId !== 2) {
    emptyFields.value = []
  }
})

onMounted(() => {
  form.checkStatusId = props.fields.checkStatusId
  form.dataComment = props.fields.dataComment
  form.id = props.id
})

defineExpose({ sendForm })
</script>

<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 255px;

  @include below('m_md') {
    width: 100%;
  }

  &__text {
    margin-bottom: 5px;

    &--red {
      color: $color-active;
    }
  }

  &__field {
    display: block;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
</style>
