<template>
  <transition name="page">
    <div class="field" aria-label="Информация о поле" :class="{'is-show-filter': isShowFilter}" :style="{ width: `${width}px` }">
      <div v-if="activeTileField" class="field__header">
        <span class="field__number" aria-label="Идентификатор поля">{{ activeTileField.id }}</span>
        <div class="icon">
          <InnerStatusIcon :width="18" :height="24" :className="checkStatusColor()" />
          <q-icon
            v-if="forestOverlaps > 20 || activeTileField?.problems?.length"
            size="22px"
            name="mdi-alert-circle-outline"
            color="negative"
          />
        </div>
        <div class="close" aria-label="Закрыть информацию о поле">
          <CloseButton @close="closeDetails" />
        </div>
        <div class="button-move">
          <q-btn
            unelevated
            @click="isShowFilter = !isShowFilter"
            size="sm"
            round
            color="accent"
            :icon="!isShowFilter ? 'mdi-arrow-left' : 'mdi-arrow-right'"
            :title="!isShowFilter ? 'Скрыть фильтр' : 'Показать фильтр'"
          />
        </div>
      </div>
      <q-scroll-area
        :style="maintenanceNotice ? 'height: calc(100vh - 68px - 40px)' : 'height: calc(100vh - 68px)'"
        :thumb-style="{ width: '5px', opacity: '0.5' }"
      >
        <q-tab-panels v-model="tab" animated class="panels">
          <q-tab-panel v-if="activeTileField" name="information">
            <div class="information">
              <template v-if="activeTileField.common">
                <p class="information__item" v-for="item in activeTileField.common" :key="item.name">
                  {{ item.name }}: <b>{{ item.value }}</b>
                </p>
              </template>
              <SectionByFieldInfo :field="activeTileField" />

              <template v-if="activeTileField?.problems">
                <ProblemCard v-for="problem in activeTileField.problems" :key="problem" :problem="problem" />
              </template>

              <FieldForestOverlapsCard v-if="forestOverlaps > 20" :forestOverlaps="forestOverlaps" />

              <div class="field__table">
                <FieldTable
                  :operation-year="activeTileField.operationYear"
                  :id="activeTileField.id"
                  :props="activeTileField.props"
                  :is-active="true"
                  :efis-veg-type-id="activeTileField.efisVegTypeId"
                />
              </div>
              <p v-if="activeTileField.deletedAt" class="field__delete-date">
                Удалено: {{ activeTileField.deletedAt }}
              </p>

              <FieldActions :activeTileField="activeTileField" />
            </div>
          </q-tab-panel>

          <q-tab-panel name="history">
            <div class="history" v-access="'fields_view_history'" aria-label="История">
              <template v-if="history">
                <HistoryCard v-for="(item, index) in history" :history="item" :key="index" />
              </template>
              <template v-if="history && history.length === 0">
                <NoDataCard :message="'Действия с полем не осуществлялись'" />
              </template>
            </div>
          </q-tab-panel>

          <q-tab-panel name="overlaps">
            <div class="overlaps" aria-label="Пересечения">
              <template v-if="activeTileField.integrityData">
                <div v-for="data in activeTileField.integrityData" :key="data.name">{{ data.name }}: {{ data.value }}</div>
              </template>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </q-scroll-area>

      <q-tabs
        v-model="tab"
        class="tabs"
        active-color="accent"
        vertical
        switch-indicator
        indicator-color="transparent"
      >
        <q-tab v-if="activeTileField" name="information" title="Информация">
          <InformationIcon :active="tab === 'information'" />
        </q-tab>
        <q-tab v-if="activeTileField && user.permissions?.includes('fields_view_history')" name="history" title="История">
          <HistoryIcon :active="tab === 'history'" />
        </q-tab>
        <q-tab v-if="activeTileField" name="overlaps" title="Пересечения">
          <OverlapsIcon :active="tab === 'overlaps'" />
        </q-tab>
      </q-tabs>
      <WidthSlider @setWidth="setWidth" :width="width" />
    </div>
  </transition>
</template>
<script setup>
import { ref, defineEmits, computed, onMounted, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import FieldTable from '@/modules/map/components/tables/FieldTable'
import HistoryCard from '@/components/common/cards/HistoryCard'
import NoDataCard from '@/components/common/cards/NoDataCard'
import InnerStatusIcon from '@/modules/map/components/icons/InnerStatusIcon'
import InformationIcon from '@/modules/map/components/icons/tabs/InformationIcon'
import HistoryIcon from '@/modules/map/components/icons/tabs/HistoryIcon'
import OverlapsIcon from '@/modules/map/components/icons/tabs/OverlapsIcon'
import WidthSlider from '@/modules/map/components/controls/WidthSlider'
import CloseButton from '@/components/uikit/buttons/CloseButton'
import FieldForestOverlapsCard from '@/modules/map/components/cards/FieldForestOverlapsCard'
import ProblemCard from '@/modules/map/components/cards/ProblemCard'
import SectionByFieldInfo from '@/modules/map/components/cards/SectionByFieldInfo.vue'
import FieldActions from '@/modules/map/components/controls/FieldActions.vue'

const emits = defineEmits(['close'])
const store = useStore()
const route = useRoute()
const router = useRouter()

const tab = ref('information') // 'history', 'overlaps'
const isShowFilter = ref(false)
const width = ref(344)

const activeTileField = computed(() => store.state.fields.activeTileField)
const maintenanceNotice = (() => store.state.settings.maintenanceNotice)
const user = computed(() => store.state.auth.user)
const currentPeriod = computed(() => store.state.dictionaries.periods.find((period) => period.isNow))
const cultureTypes = computed(() => store.state.dictionaries.typeCulture)
const history = computed(() => store.state.fields.history)

const forestOverlaps = computed(() => {
  if (activeTileField.value?.integrityData) {
    const forestData = activeTileField.value.integrityData.filter((item) => item.name === 'Лесная растительность')
    const forestPercent = forestData.reduce((total, data) => {
      total += data.percent
      return total
    }, 0)
    return forestPercent
  }

  return 0
})

const setWidth = (value) => {
  width.value = value
}

const propsOfCurrentPeriod = (props) => {
  const currentProps = props.find((prop) => prop.form.periodId === currentPeriod.value?.id)
  return currentProps ? currentProps : props[0]
}

const checkStatusColor = () => {
  const props = propsOfCurrentPeriod(activeTileField.value.props)
  let colorClassName = 'red'
  if (props?.form?.checkStatusId === 2) {
    colorClassName = 'green'
  }

  if (props?.form?.checkStatusId === 1 || props?.form?.checkStatusId === 4) {
    colorClassName = 'yellow'
  }

  return colorClassName
}

const closeDetails = () => {
  const query = { ...route.query }
  delete query.field
  router.replace({ query }).catch(() => {})
  emits('close')
  store.commit('fields/setActiveTileField', null)
  store.commit('fields/setEditingField', null)
}

watch(activeTileField, (value) => {
  if (!value) {
    tab.value = 'information'
  }
})

watch(tab, (value) => {
  if (value === 'history') {
    store.dispatch('fields/fetchHistory', { id: activeTileField.value.id })
  }
})

onMounted(() => {
  store.commit('map/setMapState', 'static')
  if (cultureTypes.value.length < 1) {
    store.dispatch('dictionaries/fetchTypeCulture')
  }
})

onUnmounted(() => {
  store.commit('fields/setActiveTileField', null)
  store.commit('fields/setEditingField', null)
})
</script>
<style lang="scss" scoped>
.panels {
  min-width: 344px;
  background-color: #F4F7FA;
}

.tabs {
  position: absolute;
  top: 0;
  right: -40px;
  background-color: #F4F7FA;
  // height: calc(40px * 2);
  height: auto;

  .q-tab {
    padding: 0;
    min-height: 40px;
  }

  .q-tab__content {
    width: 40px;
    height: 40px;
    padding: 0;
  }

  svg {
    fill: #666666;
  }

  .q-tab--active svg {
    fill: $color-active;
  }

  svg:hover {
    fill: $color-active;
  }
}

.field {
  width: 344px;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  border-left: 1px solid $color-gray3;
  background-color: #F4F7FA;
  transition: left 0.3s ease-in-out;

  &.is-show-filter {
    left: -344px;
  }

  &__delete-date {
    padding-bottom: 20px;
  }

  @include below('t_lg') {
    left: 0;
    z-index: 3;

    .button-move {
      display: none;
    }
  }

  @include below('m_md') {
    width: 100vw;
    left: 0;
  }

  &__header {
    box-sizing: border-box;
    min-height: 50px;
    padding: 5px 30px;
    border-bottom: 1px solid $color-gray3;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;

    @include below('m_md') {
      padding-right: 40px;
    }

    .icon {
      margin-left: 10px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .close {
      margin-left: auto;
      margin-right: 5px;
    }
  }

  &__number {
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  &__tabs {
    height: 100%;
  }

  &__table {
    margin-bottom: 20px;
  }
}

.information,
.history {
  padding: 20px 29px;
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
}

.information__item {
  margin-bottom: 0;
}

.overlaps {
  padding: 20px 29px;
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
}

.tab-name {
  padding: 15px 0;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

.link {
  text-decoration: underline;

  &:hover {
    color: $color-hover;
  }
}

.page-enter-active,
.page-leave-active {
  transition: left 0.5s;
}

.page-enter,
.page-leave-to {
  @include below('t_lg') {
    left: -344px;
  }
}
</style>
