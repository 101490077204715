const removeLineLayer = (map) => {
  if (map.getSource('lineLayer_data')) {
    map.removeLayer('lineLayer_line')
    map.removeSource('lineLayer_data')
  }
}

const addLineLayer = (params) => {
  const source = {
    type: 'FeatureCollection',
    features: params.lines.map((el) => {
      return {
        id: el.id,
        type: 'Feature',
        geometry: el.geometry,
        properties: el.properties,
      }
    })
  }

  if (!params.map.getSource('lineLayer_data')) {
    params.map.addSource('lineLayer_data', {
      type: 'geojson',
      data: source
    })
  }

  params.map.addLayer({
    id: 'lineLayer_line',
    type: 'line',
    source: 'lineLayer_data',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-color': params.color,
      'line-width': 4
    },
  })
}

export { addLineLayer, removeLineLayer }
