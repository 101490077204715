const BASE_URL = '/private'
// const BASE_URL_V2 = '/v2/private'

class AuthService {
  constructor(api) {
    this.api = api
  }

  getUser() {
    return this.api.get(`${BASE_URL}/user`)
  }

  login(user) {
    return this.api.post(`${BASE_URL}/auth/login`, user)
  }

  logout() {
    return this.api.post(`${BASE_URL}/auth/logout`)
  }

  updatePassword(data) {
    return this.api.post(`${BASE_URL}/user/password`, data)
  }
}

export { AuthService }
