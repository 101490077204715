const colhozes = [
  { name: 'zarechnoe', label: 'Заречное' },
  { name: 'imLeninapart1', label: 'им. Ленина, ч.1' },
  { name: 'imLeninapart2', label: 'им. Ленина, ч.2 (Чересполосный уч.)' },
  { name: 'kurakovskiypart1', label: 'Кураковский, ч.1' },
  { name: 'kursk-smolenskiy', label: 'Курск-Смоленский' },
  { name: 'ust-sertinskiy', label: 'Усть-Сертинский' },
  { name: 'mayak_ilicha', label: 'Маяк Ильича' },
  { name: 'chumaiskiy', label: 'Чумайский' },
  { name: 'druzhba', label: 'Дружба' },
  { name: 'chebulunskiy', label: 'Чебулинский' },
  { name: 'letyzhskiy1', label: 'Летяжский, ч.1' },
  { name: 'letyazhskiy2', label: 'Летяжский, ч.2' }
]

const chebulaLayers = colhozes.map(({ name, label }) => (
  {
    type: 'farm',
    group: 'kolhoz-chebula',
    name: `${name}_data`,
    label,
    value: `${name}_data`,
    options: {
      type: 'raster',
      tiles: [(process.env.VUE_APP_GEOSERVER_JAVA_URL || 'https://geo.sovhoz.tech/geoserver') + `/gwc/service/wmts?layer=sovhoz:${name}&tilematrixset=EPSG:900913&bbox=84.44775%2C52.1625%2C89.40275%2C56.8345&Service=WMTS&Request=GetTile&Version=1.0.0&format=image/png8&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}`]
    },
    layers: [{
      id: name,
      type: 'raster',
      source: `${name}_data`,
      'source-layer': name,
      layout: {
        'visibility': 'none'
      },
      paint: {
        'raster-opacity': 0.4
      }
    }]
  }
))

export { chebulaLayers }
