const noPolygonsNames = [
  { name: 'rangenopolygons', label: 'Потенциальные земли', color: '#ffff00' }, // '#015aff'
  { name: 'cropsnopolygons', label: 'Обрабатываемые земли', color: '#ff01c4' },
]

const noPolygonslayers = noPolygonsNames.map(({ name, label, color }) => ({
  type: 'farm',
  group: 'noPolygons',
  name,
  label,
  value: `${name}_fill`,
  // icon: 'forestedness',
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/' + name + '/{z}/{x}/{y}.mvt']
  },
  layers: [{
    id: `${name}_fill`,
    type: 'fill',
    source: name,
    'source-layer': name,
    layout: {
      'visibility': 'none'
    },
    paint: {
      'fill-color': color,
      'fill-opacity': 0.5,
    },
    // maxzoom: 6
  }]
}))

export { noPolygonslayers }
