const filterToParams = (filter) => {
  const urlObj = {}

  Object.keys(filter).forEach((key) => {
    if (filter[key] && Array.isArray(filter[key]) && filter[key].every(Number)) {
      urlObj[key] = filter[key].map(Number)
    } else if (filter[key] && key !== 'cadastralNumber' && key !== 'especially' && key !== 'landSurvey' && !Array.isArray(filter[key]) && Number(filter[key])) {
      urlObj[key] = Number(filter[key])
    } else if (filter[key] || ((key === 'especially' || key === 'landSurvey') && typeof filter[key] === 'boolean')) {
      urlObj[key] = filter[key]
    }
  })

  return urlObj
}

export { filterToParams }
