<template>
  <div class="report-card">
    <div class="report-card__content" @click="download">
      <span>
        {{ name }}
      </span>
      <img src="@/assets/icons/file.png" alt="Скачать">
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import api from '@/api'
import ApiService from '@/services'

const $q = useQuasar()
const store = useStore()

const props = defineProps(['name', 'type', 'filter', 'from'])

const showErrorMessage = (error) => {
  const errorText = error?.response?.data?.message
  $q.notify({
    type: 'negative',
    position: 'bottom',
    message: errorText ? errorText : 'Ошибка',
    timeout: 5000
  })
}

const download = () => {
  if (window.location.href.includes('lk.sovhoz.tech/')) {
    switch (props.type) {
    case 'sections':
      window.ym(75689863,'reachGoal','map-filters-export-makeexcel-zu')
      break
    case 'fields':
      window.ym(75689863,'reachGoal','map-filters-export-makeexcel-fields')
      break
    case 'efis':
      window.ym(75689863,'reachGoal','map-filters-export-getefisreport-actual')
      break
    case 'efis-history':
      window.ym(75689863,'reachGoal','map-filters-export-getefisreport-previous')
      break
    }
  }
  if (props.type === 'scheme') {
    ApiService.schemes.getSchemesReport(props.filter)
      .then(() => {
        store.commit('modals/setDisableModal', false)
        store.commit('modals/setIsShowSuccessEfisModal', true)
      })
      .catch((error) => {
        store.dispatch('auth/fetchUser')
        store.commit('modals/setIsShowSuccessEfisModal', false)
        showErrorMessage(error)
      })
  } else if (props.type === 'efis-history') {
    store.commit('modals/setIsShowEfisHistoryModal', true)
    return
  } else if (props.type === 'sections' || props.type === 'fields') {
    // api.post(`/sections/files/${props.type}`, null, {
    //   params: {
    //     ...props.filter,
    //     from: props.from
    //   }
    // })
    api.post(`v2/private/${props.type}/export`, { ...props.filter, from: 'map' })
      .then(() => {
        store.commit('modals/setDisableModal', false)
        store.commit('modals/setIsShowSuccessEfisModal', true)
      })
      .catch((error) => {
        store.dispatch('auth/fetchUser')
        store.commit('modals/setIsShowSuccessEfisModal', false)
        showErrorMessage(error)
      })
  } else {
    ApiService.reports.createEfisReport(props.filter)
      .then(() => {
        store.commit('modals/setDisableModal', false)
        store.commit('modals/setIsShowSuccessEfisModal', true)
      })
      .catch((error) => {
        store.dispatch('auth/fetchUser')
        store.commit('modals/setIsShowSuccessEfisModal', false)
        showErrorMessage(error)
      })
  }
}
</script>
<style lang="scss" scoped>
.report-card {

  &__content {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray3;
    cursor: pointer;
  }

  img {
    width: 40px;
    height: 40px;
  }
}
.content {
  max-width: 480px;
}
.text {
  color: $color-gray5;
}
</style>
