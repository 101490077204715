<template>
  <div v-if="!activeTileField.deletedAt" class="field__buttons">
    <Button
      class="button"
      v-access="'fields_edit'"
      data-testid="edit-field"
      v-if="mode !== 'edit-field' && activeTileField.geojson?.coordinates"
      type="stroke"
      text="Редактировать границы поля"
      @click="editField"
      :disabled="mode === 'split-field'"
    />

    <Button
      class="button"
      v-access="'fields_edit'"
      data-testid="cancel-edit-field"
      v-if="mode === 'edit-field'"
      type="stroke"
      text="Отменить редактирование границ"
      @click="cancelEditField"
    />

    <Button
      class="button"
      v-access="'fields_edit'"
      data-testid="edit-attributes-field"
      type="stroke"
      text="Редактировать атрибуты"
      @click="editAttributesField"
      :disabled="mode === 'edit-field' || mode === 'split-field'"
    />

    <Button
      class="button"
      v-if="winterPeriodId"
      v-access="'fields_edit'"
      data-testid="edit-attributes-field"
      type="stroke"
      text="Ввести данные об озимом"
      @click="editAttributesField(winterPeriodId)"
      :disabled="mode === 'edit-field' || mode === 'split-field'"
    />

    <Button
      class="button"
      v-if="summerPeriodId"
      v-access="'fields_edit'"
      data-testid="edit-attributes-field"
      type="stroke"
      text="Ввести данные о яровом"
      @click="editAttributesField(summerPeriodId)"
      :disabled="mode === 'edit-field' || mode === 'split-field'"
    />

    <!-- <Button
      class="button"
      v-access="'fields_edit'"
      data-testid="edit-enrichment-field"
      type="stroke"
      text="Атрибуты о внесении удобрений"
      @click="editEnrichmentField"
      :disabled="mode === 'edit-field' || mode === 'split-field'"
    /> -->

    <Button
      class="button"
      v-access="'fields_split'"
      data-testid="split-field"
      type="stroke"
      :text="mode !== 'split-field' ? 'Разделить поле' : 'Отменить разделение поля'"
      @click="splitField"
      :disabled="mode === 'edit-field'"
    />

    <Button
      class="button"
      v-access="'fields_split'"
      v-if="mode === 'split-field' && newFieldsFromSplit?.length"
      data-testid="save-split-field"
      type="stroke"
      text="Сохранить поля"
      @click="saveSplitField"
      :disabled="mode === 'edit-field'"
    />

    <Button
      class="button"
      v-access="'fields_delete'"
      data-testid="delete-field"
      type="stroke"
      text="Удалить поле"
      @click="deleteField"
      :disabled="mode === 'edit-field' || mode === 'split-field'"
    />
  </div>
  <div v-if="activeTileField.deletedAt" class="field__buttons">
    <Button
      class="button"
      v-access="'fields_delete'"
      data-testid="undelete-field"
      type="stroke"
      text="Восстановить поле"
      @click="undeleteField"
    />
  </div>
  <div class="field__buttons">
    <Button
      class="button"
      v-if="fieldOverlaps.length > 0"
      v-access="'fields_edit'"
      data-testid="show-overlaps-field"
      type="stroke"
      :text="!isShowFieldOverlaps ? 'Показать пересечения' : 'Скрыть пересечения'"
      @click="switchFieldOverlaps"
    />
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import Button from '@/components/uikit/buttons/Button'
import ApiService from '@/services'

const props = defineProps(['activeTileField'])
const store = useStore()
const $q = useQuasar()

const mode = computed(() => store.state.map.mode)
const winterPeriodId = computed(() => store.state.dictionaries.periods.find((period) => period.active && period.isWinter)?.id)
const summerPeriodId = computed(() => store.state.dictionaries.periods.find((period) => period.active && !period.isWinter)?.id)
const newFieldsFromSplit = computed(() => store.state.fields.newFieldsFromSplit)
const isShowFieldOverlaps = computed(() => store.state.fields.isShowFieldOverlaps)
const fieldOverlaps = computed(() => store.state.fields.fieldOverlaps)

const editField = () => {
  store.commit('fields/setEditingField', { type: 'Feature', geometry: props.activeTileField.geojson, id: props.activeTileField.id, properties: {} })
  store.commit('map/setMode', 'edit-field')
}

const cancelEditField = () => {
  store.commit('fields/setEditingField', null)
}

const editAttributesField = (periodId = null) => {
  store.commit('fields/setEditingField', null)
  store.commit('fields/setEditingAttributesField', { ...props.activeTileField, periodId })
  store.commit('modals/setIsShowEditAttributesFieldModal', true)
}

// const editEnrichmentField = () => {
//   store.commit('fields/setEditingField', null)
//   store.commit('fields/setEditingAttributesField', props.activeTileField)
//   store.commit('modals/setIsShowEnrichmentFieldModal', true)
// }

const splitField = () => {
  if (mode.value !== 'split-field') {
    store.commit('fields/setSplitingField', { type: 'Feature', geometry: props.activeTileField.geojson, id: props.activeTileField.id, properties: {} })
    store.commit('map/setMode', 'split-field')
  } else {
    store.commit('fields/setSplitingField', null)
    store.commit('map/setMode', 'default')
  }
}

const saveSplitField = () => {
  store.commit('modals/setIsShowSplitFieldModal', true)
}

const deleteField = () => {
  store.commit('fields/setDeletingField', props.activeTileField.id)
  store.commit('modals/setIsShowDeleteFieldModal', true)
}

const undeleteField = () => {
  store.commit('fields/setIsLoadingFields', true)
  store.commit('fields/setFieldOverlaps', [])

  ApiService.fields.checkFieldOverlaps(props.activeTileField.id)
    .then((res) => {
      store.commit('fields/setIsLoadingFields', false)

      if (res.data?.data?.length > 0) {
        store.commit('fields/setFieldOverlaps', res.data.data)
        store.commit('modals/setIsFieldOverlapsModal', true)
      } else {
        store.commit('fields/setUndeletingField', props.activeTileField.id)
        store.commit('modals/setIsShowUndeleteFieldModal', true)
      }
    })
    .catch((error) => {
      store.commit('fields/setIsLoadingFields', false)

      const errorText = error?.response?.data?.message
      $q.notify({
        type: 'negative',
        position: 'bottom',
        message: errorText ? errorText : 'Ошибка',
        timeout: 5000
      })
    })
}

const switchFieldOverlaps = () => {
  store.commit('fields/setIsShowFieldOverlaps', !isShowFieldOverlaps.value)
}
</script>
<style lang="scss" scoped>
.field__buttons {
  margin: 0 auto;
  margin-top: 20px;
  max-width: 286px;

  .button {
    width: 100%;
    padding: 12px;
  }

  .button:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
