<template>
  <div class="rosreestr" data-testid="form">
    <q-form ref="formRef">
      <div class="rosreestr__field native-input" data-testid="region">
        <Select
          v-model="form.regionId"
          label="Муниципалитет"
          :options="regions"
          :rules="[val => !!val || 'Обязательное поле']"
          :use-input="true"
        />
      </div>
      <div class="rosreestr__field" data-testid="layer">
        <Select
          v-model="form.layer"
          label="Источник данных"
          :options="layers"
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>
    </q-form>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, defineExpose, ref, reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import Select from '@/components/common/selects/Select'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const formRef = ref()
const form = reactive({
  layer: null,
  regionId: null,
})

const props = defineProps({
  layer: {
    type: String,
    required: false
  },
  region: {
    type: [String, Number],
    required: false
  },
  fields: {
    type: Object,
    required: false
  },
  hash: {
    type: String,
    required: false
  },
})

const emits = defineEmits(['close'])

const layers = computed(() => store.state.dictionaries.layers)
const regions = computed(() => store.state.dictionaries.regions)

const sendForm = () => {
  const formEl = formRef.value
  formEl.validate().then((result) => {
    if (result) {
      store.commit('modals/setDisableModal', true)

      ApiService.map.updateLayer(props.hash, form)
        .then((response) => {
          let hash = props.hash
          if (response?.data?.hash) {
            hash = response.data.hash
          }

          if (hash === props.hash) {
            store.dispatch('mapFilter/fetchCurrentPage', { id: hash })
          }

          store.commit('mapFilter/setEditingLayer', {
            hash,
            oldHash: props.hash,
            layer: form.layer,
            landOwnershipId: response.data.form.landOwnershipId
          })
          store.commit('mapFilter/setEditCount')
          emits('close')
          store.commit('modals/setDisableModal', false)
          $q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Успешно',
            timeout: 5000
          })
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message

          store.dispatch('auth/fetchUser')
          store.commit('mapFilter/setIsLoadingFilter', false)
          store.commit('modals/setDisableModal', false)
          $q.notify({
            type: 'negative',
            position: 'bottom',
            message: errorText ? errorText : 'Ошибка',
            timeout: 5000
          })
        })
    }
  })
}

onMounted(() => {
  form.layer = props.layer
  form.regionId = props.fields.regionId
})
defineExpose({ sendForm })
</script>
<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 250px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    display: block;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.title {
  margin-bottom: 36px;
}

.button {
  display: block;
  width: 200px;
  margin: 40px auto 0;
}
</style>
