<template>
  <div class="polygon-controls">
    <div v-if="areaSchemeError" class="polygon-controls__info">
      <p class="area">{{ areaSchemeError }}</p>
    </div>
    <div v-if="!areaSchemeError" :class="{loading: !areaScheme && areaScheme !== 0}" class="polygon-controls__info">
      <p class="area">
        <b>S:&nbsp;</b>
      </p>
      <p v-if="areaScheme" class="area">{{ (areaScheme / 10000).toFixed(5) }} Га.</p>
    </div>
    <div class="polygon-controls__buttons">
      <button
        :disabled="!areaScheme"
        class="button-confirm"
        type="button"
        aria-label="Сохранить"
        @click="handleConfirm"
      >
        Сохранить
      </button>
      <button
        type="button"
        class="button-confirm"
        aria-label="Отменить"
        @click="handleCancel"
      >
        Отменить
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const areaScheme = computed(() => store.state.schemes.areaScheme)
const areaSchemeError = computed(() => store.state.schemes.areaSchemeError)

const handleConfirm = () => {
  store.commit('modals/setIsShowCreateSchemeModal', true)
}

const handleCancel = () => {
  store.commit('map/setMode', 'default')
}
</script>

<style lang="scss" scoped>
.polygon-controls {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  position: absolute;
  bottom: 52px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  @include below('d_sm') {
    left: 364px;
    bottom: 20px;
    transform: translateX(0);
  }

  @include below('t_lg') {
    left: 20px;
    bottom: 40px;
    transform: translateX(0);
  }

  &__buttons {
    display: flex;
  }

  &__info {
    position: relative;
    padding: 8px 12px;
    color: white;

    &::before {
      content: '';
      position: absolute;
      left: calc(50% - 9px);
      top: 20px;
      margin-top: -12px;
      width: 18px;
      height: 18px;
      border: 2px solid;
      display: none;
      border-left-color: transparent;
      border-right-color: transparent;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.5s;
      animation: 0.8s linear infinite rotate;
    }

    &.loading{
      pointer-events: none;
      cursor: not-allowed;

      &::before {
        display: block;
        // transition-delay: 0.5s;
        transition-duration: 1s;
        opacity: 1;
      }
    }
  }

  &__buttons {
    height: 40px;
    align-items: center;
  }

  .error {
    color: $color-active;
  }

  .area {
    display: inline-block;
    margin-bottom: 4px;
  }
}

.button-confirm {
  background-color: white;
  height: 100%;
  border: none;
  font-weight: 600;
  flex: 1;
  cursor: pointer;

  &:disabled {
    color: $color-gray4;
  }
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
