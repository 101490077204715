import { chebulaLayers } from './additional/chebula'
import { otherLayers } from './additional/other-layers'
import { noDataLayers } from './additional/no-data'
import { noPolygonslayers } from './additional/no-polygons'
import { landUseLayers } from './additional/landuse'
import { officialLayers } from './additional/official'
import { thematicLayers } from './additional/thematic'
import { efisLayers } from './additional/efis'
import { satellitesLayers } from './additional/satellites'
import { others } from './additional/others'

export default [
  ...landUseLayers,
  ...noDataLayers,
  ...noPolygonslayers,
  ...otherLayers,
  ...chebulaLayers,
  ...officialLayers,
  ...thematicLayers,
  ...efisLayers,
  ...satellitesLayers,
  ...others
]


