<script setup>

import Select from '@/components/common/selects/Select.vue'
import { computed, defineEmits, defineExpose, defineProps, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import ApiService from '@/services'
import { useQuasar } from 'quasar'

const props = defineProps(['editingFields', 'periodId'])
const store = useStore()
const $q = useQuasar()
const emits = defineEmits(['close'])
const operationYears = computed(() => store.state.dictionaries.operationYears.filter((item) => !item.isHidden))
const operationYearId = ref(operationYears.value.find((item) => !item.isNull)?.value)

const onSuccess = () => {
  emits('close')
  store.commit('modals/setDisableModal', false)
  $q.notify({
    type: 'positive',
    position: 'bottom',
    message: 'Успешно',
    timeout: 5000
  })
}

const onError = (error) => {
  store.commit('modals/setDisableModal', false)

  $q.notify({
    type: 'negative',
    position: 'bottom',
    message: error?.response?.data?.message ?? 'Ошибка',
    timeout: 5000
  })
}

const updateOperationYear = () => {
  store.commit('modals/setDisableModal', true)

  ApiService.fields.updateGroupField({ fieldIds: props.editingFields, operationYearId: operationYearId.value })
    .then(() => {
      onSuccess()
    })
    .catch((error) => {
      onError(error)
    })
}

watch(operationYearId, (value) => {
  store.commit('modals/setDisableConfirmModal', !value)
})

defineExpose({ updateOperationYear })
</script>

<template>
  <div class="rosreestr" data-testid="form">
    <q-form ref="formRef">
      <div class="rosreestr__field rosreestr__field--validate">
        <Select
            label="Год ввода в оборот"
            v-model="operationYearId"
            :options="operationYears"
            :use-input="true"
            :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>
    </q-form>
  </div>
</template>

<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 250px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    display: block;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 25px;
    }

    &--org {
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;

      .input {
        max-width: 100%;
      }
    }

    &--validate:not(:last-child) {
      margin-bottom: 5px;
    }

    &--validate:has(.q-field--error) {
      margin-bottom: 10px;
    }
  }

  &__copy a {
    margin-left: auto;
    color: $color-active;
    text-align: right;
    text-decoration: underline;
  }

  &__section {

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .input {
      width: 100%;
    }
  }

  &__create-org {
    display: flex;
  }

  .input {
    flex-grow: 1;
  }

  .error-org {
    display: block;
    position: relative;
    color: $color-active;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .additional {
    color: $color-active;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
<style>
.form .disabled {
  opacity: 1 !important;
  font-style: italic;
}

.form .disabled:not(:first-child) {
  margin-top: 15px;
}
</style>