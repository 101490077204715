<template>
  <Modal
    v-if="isShowCreatePinModal"
    title="Создать отметку"
    showClose
    @cancelClick="closeCreatePinModal"
    @closeModal="closeCreatePinModal"
    @confirmClick="$refs.createPinForm.savePin()"
  >
    <CreatePin
      ref="createPinForm"
      :point="creatingPinPoint"
      @cancelCreate="closeCreatePinModal"
      @close="closeCreatePinModal"
      @changeVisiblePinInfo="changeVisiblePinInfo"
    />
  </Modal>

  <Modal
    v-if="isShowEditPinModal"
    title="Редактировать отметку"
    showClose
    @cancelClick="canselEditPin"
    @closeModal="canselEditPin"
    @confirmClick="$refs.editPinForm.savePin()"
  >
    <EditPin
      ref="editPinForm"
      :editingPin="editingPin"
      @close="canselEditPin"
      @changeVisiblePinInfo="changeVisiblePinInfo"
    />
  </Modal>

  <Modal
    v-if="isShowDeletePinModal"
    title="Удалить отметку"
    showClose
    @cancelClick="closeDeletePinModal"
    @closeModal="closeDeletePinModal"
    @confirmClick="deletePin"
  >
    Вы уверены, что хотите удалить отметку?
  </Modal>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import Modal from '@/components/common/modals/Modal'
import CreatePin from './forms/pins/CreatePinMap'
import EditPin from './forms/pins/EditPinMap'

import ApiService from '@/services'

const store= useStore()
const $q = useQuasar()

const isShowCreatePinModal = computed(() => store.state.modals.isShowCreatePinModal)
const isShowEditPinModal = computed(() => store.state.modals.isShowEditPinModal)
const isShowDeletePinModal = computed(() => store.state.modals.isShowDeletePinModal)
const creatingPinPoint = computed(() => store.state.map.creatingPinPoint)
const editingPin = computed(() => store.state.map.editingPin)
const deletingPin = computed(() => store.state.map.deletingPin)

const closeCreatePinModal = () => {
  store.commit('modals/setIsShowCreatePinModal', false)
}

const closeEditPinModal = () => {
  store.commit('modals/setIsShowEditPinModal', false)
}

const closeDeletePinModal = () => {
  store.commit('modals/setIsShowDeletePinModal', false)
}

const canselEditPin = () => {
  closeEditPinModal()
  store.commit('map/setEditingPin', null)
}

const deletePin = () => {
  store.commit('modals/setDisableModal', true)

  ApiService.map.deletePoint(deletingPin.value)
    .then(() => {
      store.commit('modals/setDisableModal', false)
      $q.notify({
        type: 'positive',
        position: 'bottom',
        message: 'Успешно',
        timeout: 5000
      })
      closeDeletePinModal()
      changeVisiblePinInfo(false)
      store.commit('map/setDeletingPin', null)
      store.dispatch('map/fetchPoints')
    })
    .catch((error) => {
      const errorText = error?.response?.data?.message
      $q.notify({
        type: 'negative',
        position: 'bottom',
        message: errorText ? errorText : 'Ошибка',
        timeout: 5000
      })
      store.dispatch('auth/fetchUser')
      store.commit('modals/setDisableModal', false)
    })
}

const changeVisiblePinInfo = (state) => {
  store.commit('map/setIsShowMarkerInfoPopup', state)
}
</script>
