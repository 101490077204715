import api, { apiPost } from '@/api'
import { OrgService } from '@/services/org.service'
import { DictionariesService } from '@/services/dictionaries.service'
import { AuthService } from '@/services/auth.service'
import { DashboardService } from '@/services/dashboard.service'
import { MapService } from '@/services/map.service'
import { AdminService } from '@/services/admin.service'
import { KptService } from '@/services/kpt.service'
import { OverlapsService } from '@/services/overlaps.service'
import { PassportsService } from '@/services/passports.service'
import { SchemesService } from '@/services/schemes.service'
import { ReportsService } from '@/services/reports.service'
import { FieldsService } from '@/services/fields.service'
import { HarveService } from '@/services/harve.service'
import { RegisterService } from '@/services/register.service'
import { SettingsService } from '@/services/settings.service'
import { EnrichmentsService } from '@/services/enrichments.service'
import { UserService } from '@/services/user.service'

const ApiService = {
  org: new OrgService(api),
  dictionaries: new DictionariesService(api),
  auth: new AuthService(api),
  dashboard: new DashboardService(api),
  map: new MapService(api),
  admin: new AdminService(api),
  kpt: new KptService(api),
  overlaps: new OverlapsService(api),
  passports: new PassportsService(api),
  schemes: new SchemesService(api, apiPost),
  reports: new ReportsService(api),
  fields: new FieldsService(api),
  harve: new HarveService(api),
  register: new RegisterService(api, apiPost),
  settings: new SettingsService(api),
  enrichments: new EnrichmentsService(api),
  user: new UserService(api)
}

export default ApiService
