<template>
  <div class="filter">
    <span aria-label="filter-area" class="label-select">Заявленная площадь, кв. м.</span>
    <div class="filter__section filter__section_flex">
      <div class="input">
        <span class="label-select">от</span>
        <q-input
          v-model.number="filter.declaredAreaMin"
          type="number"
          outlined
          placeholder="от"
          dense
        />
      </div>
      <div class="input">
        <span class="label-select">до</span>
        <q-input
          v-model.number="filter.declaredAreaMax"
          type="number"
          outlined
          placeholder="до"
          dense
        />
      </div>
    </div>

    <div class="filter__section">
      <Select
        label="Муниципалитет"
        v-model="filter.regionIds"
        :options="sortOptions(municipalities, filter.regionIds)"
        :isSort="true"
        :clearable="true"
        :multiple="true"
        :use-input="true"
      />
    </div>

    <div class="filter__section native-input relative">
      <Select
        label="От кого подано"
        v-model="filter.schemaSubmitterIds"
        :options="sortOptions(submitters, filter.schemaSubmitterIds)"
        :isSort="true"
        :clearable="true"
        :multiple="true"
        :use-input="true"
        :scroll-size="300"
      />
    </div>
    <div class="filter__section">
      <div class="input input-search">
        <span class="label-select">Поиск по номеру заявления</span>
        <q-input
          v-model="filter.schemaNumber"
          type="text"
          outlined
          placeholder="Поиск по номеру заявления"
          dense
        />
      </div>
    </div>
    <div class="filter__section">
      <span class="label-select">Выберите период заявления</span>
      <FlatpickrComponent
        :dateInit="dateCreate"
        :maxDate="new Date()"
        dense
        range
        @update="(value) => dateCreate = value"
      />
    </div>
    <div class="filter__section">
      <div class="input input-search">
        <span class="label-select">Поиск по номеру решения</span>
        <q-input
          v-model="filter.solutionNumber"
          type="text"
          outlined
          placeholder="Поиск по номеру решения"
          dense
        />
      </div>
    </div>
    <div class="filter__section">
      <span class="label-select">Выберите период решения</span>
      <FlatpickrComponent
        :dateInit="dateSolution"
        :maxDate="new Date()"
        dense
        range
        @update="(value) => dateSolution = value"
      />
    </div>
    <div class="filter__section">
      <Select
        label="Статус решения"
        v-model="filter.solutionStatus"
        :options="statuses"
        :clearable="true"
      />
    </div>
  </div>
</template>
<script setup>
import { computed, defineEmits, defineExpose, reactive, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import Select from '@/components/common/selects/Select'
import FlatpickrComponent from '@/components/common/datepicker/Flatpickr'

const emits = defineEmits(['setSchemeFilter'])
const filter = reactive({
  declaredAreaMin: null,
  declaredAreaMax: null,
  regionIds: [],
  schemaSubmitterIds: [],
  schemaNumber: null,
  schemaCreatedAtFrom: null,
  schemaCreatedAtTo: null,
  solutionNumber: null,
  solutionDateFrom: null,
  solutionDateTo: null,
  solutionStatus: null
})

const dateCreate = ref('')
const dateSolution = ref('')

const store = useStore()

const municipalities = computed(() => store.state.schemes.municipalities)
const submitters = computed(() => store.state.schemes.submitters)
const statuses = computed(() => store.state.schemes.statuses)
const schemes = computed(() => store.state.schemes.schemes)

const dateFrom = computed(() => {
  if (!dateCreate.value[0]) {
    return null
  }
  return new Intl.DateTimeFormat('ru-RU').format(new Date(dateCreate.value[0]))
})

const dateTo = computed(() => {
  if (!dateCreate.value[1]) {
    return null
  }
  return new Intl.DateTimeFormat('ru-RU').format(new Date(dateCreate.value[1]))
})

const dateSolutionFrom = computed(() => {
  if (!dateSolution.value[0]) {
    return null
  }
  return new Intl.DateTimeFormat('ru-RU').format(new Date(dateSolution.value[0]))
})

const dateSolutionTo = computed(() => {

  if (!dateSolution.value[1]) {
    return null
  }
  return new Intl.DateTimeFormat('ru-RU').format(new Date(dateSolution.value[1]))
})

const countEditedScheme = computed(() => store.state.schemes.countEditedScheme)

const sortOptions = (options, filterValue) => {
  return options.filter((item) => !item.value)
    .concat(options.filter((item) => filterValue.includes(item.value)))
    .concat(options.filter((item) => item.value && !filterValue.includes(item.value)))
}

const clearFilter = () => {
  filter.declaredAreaMax = null
  filter.declaredAreaMin = null
  filter.regionIds = []
  filter.schemaSubmitterIds = []
  filter.schemaNumber = null
  filter.schemaCreatedAtFrom = null
  filter.schemaCreatedAtTo = null
  filter.solutionNumber = null
  filter.solutionDateFrom = null
  filter.solutionDateTo = null
  filter.solutionStatus = null

  dateCreate.value = [null, null]
  dateSolution.value = [null, null]

  applyFilter()
}

const applyFilter = () => {
  const filterData = {}
  for (let field in filter) {
    if (filter[field] || filter[field] === 0) {
      filterData[field] = filter[field]
    }
  }

  emits('setSchemeFilter', filterData)
  store.dispatch('schemes/fetchSchemes', filterData)
    .finally(() => {
      store.commit('mapFilter/setIsLoadingFilter', false)
    })
}

defineExpose({ applyFilter, clearFilter })

onMounted(() => {
  if (schemes.value?.length < 1) {
    store.dispatch('schemes/fetchSchemes')
  }

  if (municipalities.value?.length < 1) {
    store.dispatch('schemes/fetchMunicipalities')
  }

  if (statuses.value?.length < 1) {
    store.dispatch('schemes/fetchStatuses')
  }

  if (submitters.value?.length < 1) {
    store.dispatch('schemes/fetchSubmitters')
  }

  applyFilter()
})

watch(dateFrom, () => {
  filter.schemaCreatedAtFrom = dateFrom.value
  filter.schemaCreatedAtTo = dateTo.value
})

watch(dateTo, () => {
  filter.schemaCreatedAtFrom = dateFrom.value
  filter.schemaCreatedAtTo = dateTo.value
})

watch(dateSolutionFrom, () => {
  filter.solutionDateFrom = dateSolutionFrom.value
  filter.solutionDateTo = dateSolutionTo.value
})

watch(dateSolutionTo, () => {
  filter.solutionDateFrom = dateSolutionFrom.value
  filter.solutionDateTo = dateSolutionTo.value
})

watch(countEditedScheme, () => {
  clearFilter()
})
</script>
<style lang="scss" scoped>
.filter {
  max-width: 344px;
  height: 100%;
  position: relative;

  &__form {
    position: relative;
    padding: 16px 30px 16px 30px;
    height: calc(100% - 49px - 59px);
    overflow-y: auto;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &__info {
    border-top: 1px solid $color-gray3;
    position: absolute;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 59px;
    bottom: 49px;
    width: 100%;
  }

  &__section {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &_flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .input {
        width: calc(50% - 6px);
      }
    }
  }

  .button {
    width: 100%;
    border-radius: 0;
  }
}


.label {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;
}
</style>
