<template>
  <div class="rosreestr" data-testid="form">
    <q-form ref="formRef" @validation-error="onValidateError">
      <div class="rosreestr__field rosreestr__field--validate" data-testid="Сезон посадки">
        <Select
          v-model="form.periodId"
          label="Сезон посадки"
          :options="activePeriods"
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>
      <div class="rosreestr__field">
        <span class="label-select">
          Площадь вносимых азотных удобрений (га)
        </span>
        <q-input
          v-model="form.minN"
          placeholder="Площадь вносимых азотных удобрений (га)"
          outlined
          type="number"
          dense
          :disable="!isUseActualExploitation"
        />
      </div>
      <div class="rosreestr__field">
        <span class="label-select">
          Площадь вносимых фосфорных удобрений (га)
        </span>
        <q-input
          v-model="form.minP2o5"
          placeholder="Площадь вносимых фосфорных удобрений (га)"
          outlined
          type="number"
          dense
          :disable="!isUseActualExploitation"
        />
      </div>
      <div class="rosreestr__field">
        <span class="label-select">
          Площадь вносимых калийных удобрений (га)
        </span>
        <q-input
          v-model="form.minK2o"
          placeholder="Площадь вносимых калийных удобрений (га)"
          outlined
          type="number"
          dense
          :disable="!isUseActualExploitation"
        />
      </div>
      <div class="rosreestr__field">
        <span class="label-select">
          Площадь вносимых органических удобрений (га)
        </span>
        <q-input
          v-model="form.fertOrg"
          placeholder="Площадь вносимых органических удобрений (га)"
          outlined
          type="number"
          dense
          :disable="!isUseActualExploitation"
        />
      </div>
      <div class="rosreestr__field">
        <span class="label-select">
          Площадь известкования почв (га)
        </span>
        <q-input
          v-model="form.khmIzv"
          placeholder="Площадь известкования почв (га)"
          outlined
          type="number"
          dense
          :disable="!isUseActualExploitation"
        />
      </div>
      <div class="rosreestr__field">
        <span class="label-select">
          Площадь гипсования почв (га)
        </span>
        <q-input
          v-model="form.khmGips"
          placeholder="Площадь гипсования почв (га)"
          outlined
          type="number"
          dense
          :disable="!isUseActualExploitation"
        />
      </div>
      <div class="rosreestr__field">
        <span class="label-select">
          Площадь фосфоритования почв (га)
        </span>
        <q-input
          v-model="form.khmFrt"
          placeholder="Площадь фосфоритования почв (га)"
          outlined
          type="number"
          dense
          :disable="!isUseActualExploitation"
        />
      </div>
    </q-form>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, defineExpose, reactive, ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import Select from '@/components/common/selects/Select'
import ApiService from '@/services'

const props = defineProps(['editingField'])
const emits = defineEmits(['close'])
const store = useStore()
const $q = useQuasar()

const formRef = ref()

const form = reactive({
  minN: null,
  minP2o5: null,
  minK2o: null,
  fertOrg: null,
  khmIzv: null,
  khmGips: null,
  khmFrt: null,
  periodId: null
})

const actualExploitationId = ref(null)

const actualExploitations = computed(() => store.state.dictionaries.actualExploitations.filter((category) => !category.isHidden))
const isUseActualExploitation = computed(() => {
  const actualExploitationUseId = actualExploitations.value?.find((category) => category.name === 'Используется')
  return actualExploitationId.value === actualExploitationUseId?.value
})
const dictionaryPeriods = computed(() => store.state.dictionaries.periods)
const activePeriods = computed(() => dictionaryPeriods.value?.filter((period) => period.active))
const visibleEntity = computed(() => store.state.map.visibleEntity)
const activeTileField = computed(() => store.state.fields.activeTileField)

const onValidateError = (ref) => {
  ref.$el.scrollIntoView()
}

const saveField = () => {
  const formEl = formRef.value

  formEl.validate().then((result) => {
    if (result) {
      const data = {
        periodId: form.periodId,
        attributes: {
          minN: form.minN,
          minP2o5: form.minP2o5,
          minK2o: form.minK2o,
          fertOrg: form.fertOrg,
          khmIzv: form.khmIzv,
          khmGips: form.khmGips,
          khmFrt: form.khmFrt,
        }
      }

      store.commit('modals/setDisableModal', true)

      ApiService.fields.updateEfisAttributes(props.editingField.id, data)
        .then(() => {
          emits('close')
          store.commit('modals/setDisableModal', false)
          store.commit('map/setMode', 'default')
          $q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Успешно',
            timeout: 5000
          })

          if (visibleEntity.value === 'fields') {
            store.commit('mapFilter/setEditCount')

            if (activeTileField.value?.id) {
              store.dispatch('fields/fetchTileField', activeTileField.value.id)
            }
          }
        })
        .catch((error) => {
          store.commit('modals/setDisableModal', false)

          const errorText = error?.response?.data?.message
          $q.notify({
            type: 'negative',
            position: 'bottom',
            message: errorText ? errorText : 'Ошибка',
            timeout: 5000
          })
        })
    }
  })
}

watch(() => form.periodId, (value) => {
  const currentProps = props.editingField.props.find((prop) => prop.form.periodId === value)
  if (currentProps?.form) {
    actualExploitationId.value = currentProps?.form?.actualExploitationId

    form.minN = currentProps?.form?.minN || currentProps?.form?.minN === 0 ? parseInt(currentProps.form.minN, 10) : null
    form.minP2o5 = currentProps?.form?.minP2o5 || currentProps?.form?.minP2o5 === 0 ? parseInt(currentProps.form.minP2o5, 10) : null
    form.minK2o = currentProps?.form?.minK2o || currentProps?.form?.minK2o === 0 ? parseInt(currentProps.form.minK2o, 10) : null
    form.fertOrg = currentProps?.form?.fertOrg || currentProps?.form?.fertOrg === 0 ? parseInt(currentProps.form.fertOrg, 10) : null
    form.khmIzv = currentProps?.form?.khmIzv || currentProps?.form?.khmIzv === 0 ? parseInt(currentProps.form.khmIzv, 10) : null
    form.khmGips = currentProps?.form?.khmGips || currentProps?.form?.khmGips === 0 ? parseInt(currentProps.form.khmGips, 10) : null
    form.khmFrt = currentProps?.form?.khmFrt || currentProps?.form?.khmFrt === 0 ? parseInt(currentProps.form.khmFrt, 10) : null
  }
})

onMounted(() => {
  form.periodId = dictionaryPeriods.value.find((period) => period.isNow)?.id
})

defineExpose({ saveField })
</script>

<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 250px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  &__field--validate {
    margin-bottom: 5px;
  }
}
</style>
