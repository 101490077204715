<template>
  <transition name="page">
    <div class="scheme" aria-label="Информация о схеме" :class="{'is-show-filter': isShowFilter}">
      <div class="scheme__header">
        <span class="scheme__number" aria-label='Кадастровый номер'>
          {{ currentScheme?.form?.schemaNumber ?? '' }}
        </span>
        <div class="close" aria-label="Закрыть информацию об участке">
          <CloseButton @close="closeDetails" />
        </div>
        <div class="button-move">
          <q-btn
            unelevated
            @click='isShowFilter = !isShowFilter'
            size='sm'
            round
            color="accent"
            :icon="!isShowFilter ? 'mdi-arrow-left' : 'mdi-arrow-right'"
            :title="!isShowFilter ? 'Скрыть фильтр' : 'Показать фильтр'"
          />
        </div>
      </div>
      <q-scroll-area
        :style="maintenanceNotice ? 'height: calc(100vh - 68px - 50px - 40px)' : 'height: calc(100vh - 68px - 50px)'"
        :thumb-style="{ width: '5px', opacity: '0.5' }"
      >
        <q-tab-panels v-model="tab" class="panels" animated>
          <q-tab-panel name="information">
            <div class="information">
              <div class="common" aria-label="Информация">
                <div v-if="currentScheme" class="additional">
                  <div v-for="(item, index) in currentScheme.common" :key="index" class="information__item">
                    <p class="label">{{ item.name }}</p>
                    <p>{{item.value}}</p>
                  </div>
                </div>

                <div class="information__buttons">
                  <div class="information__button" v-access="'docs_schemas_edit'">
                    <Button
                      class="button"
                      aria-label="Изменить реквизиты заявления"
                      type="stroke"
                      text="Изменить реквизиты заявления"
                      @click="openChangeSchemeModal"
                    />
                  </div>

                  <div class="information__button" v-access="'docs_schemas_change_solution_status'">
                    <Button
                      class="button"
                      aria-label="Изменить результаты рассмотрения"
                      type="stroke"
                      text="Изменить результаты рассмотрения"
                      @click="openSolutionSchemeModal"
                    />
                  </div>

                  <div class="information__button" v-access="'docs_schemas_edit'">
                    <Button
                      class="button"
                      aria-label="Изменить кадастровый номер"
                      type="stroke"
                      text="Изменить кадастровый номер"
                      @click="openKnSchemeModal"
                    />
                  </div>

                  <div class="information__button" v-access="'docs_schemas_delete'">
                    <Button
                      class="button"
                      aria-label="Удалить схему"
                      type="stroke"
                      text="Удалить схему"
                      @click="openDeleteSchemeModal"
                    />
                  </div>
                </div>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </q-scroll-area>

      <q-tabs
        v-model="tab"
        active-color="accent"
        vertical
        switch-indicator
        indicator-color="transparent"
        class="tabs"
      >
        <q-tab name="information">
          <InformationIcon :active="tab === 'information'" />
        </q-tab>
      </q-tabs>
    </div>
  </transition>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Button from '@/components/uikit/buttons/Button'
import InformationIcon from '@/modules/map/components/icons/tabs/InformationIcon'
import CloseButton from '@/components/uikit/buttons/CloseButton'

const store = useStore()

const tab = ref('information')
const isShowFilter = ref(false)

const currentScheme = computed(() => store.state.schemes.currentScheme)
const statuses = computed(() => store.state.schemes.statuses)
const maintenanceNotice = computed(() => store.state.settings.maintenanceNotice)

const closeDetails = () => {
  store.commit('schemes/setCurrentScheme', null)
  store.commit('schemes/setActiveScheme', null)
}
const openChangeSchemeModal = () => {
  store.commit('modals/setIsShowChangeSchemeModal', true)
}

const openSolutionSchemeModal = () => {
  store.commit('modals/setIsShowSolutionSchemeModal', true)
}

const openKnSchemeModal = () => {
  store.commit('modals/setIsShowKnSchemeModal', true)
}

const openDeleteSchemeModal = () => {
  store.commit('modals/setIsShowDeleteSchemeModal', true)
}

onMounted(() => {
  store.commit('map/setMapState', 'static')

  if (statuses.value && statuses.value.length < 1) {
    store.dispatch('schemes/fetchStatuses')
  }
})
</script>

<style lang="scss" scoped>
.scheme {
  width: 344px;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  border-left: 1px solid $color-gray3;
  background-color: #F4F7FA;
  transition: left 0.3s ease-in-out;

  &.is-show-filter {
    left: -344px;
  }

  @include below('t_lg') {
    left: 0;

    .button-move {
      display: none;
    }
  }

  @include below('m_md') {
    width: 100vw;
    left: 0;
  }

  &__header {
    height: 50px;
    padding: 17px 30px;
    border-bottom: 1px solid $color-gray3;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;

    @include below('m_md') {
      padding-right: 40px;
    }
  }

  &__number {
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  &__tabs {
    height: 100%;
  }

  .panels {
    width: 344px;
    background-color: #F4F7FA;
  }

  .tabs {
    position: absolute;
    top: 0;
    right: -40px;
    background-color: #F4F7FA;
    // height: calc(40px * 2);
    height: auto;

    .q-tab {
      padding: 0;
      min-height: 40px;
    }

    .q-tab__content {
      width: 40px;
      height: 40px;
      padding: 0;
    }

    svg {
      fill: #666666;
    }

    .q-tab--active svg {
      fill: $color-active;
    }

    svg:hover {
      fill: $color-active;
    }
  }
}

.information {
  padding: 20px 29px;
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);

  &__link {
    text-decoration: underline;
    text-decoration-style: dashed;
    cursor: pointer;

    &:hover {
      color: $color-gray5;
    }
  }

  &__item {
    margin-bottom: 12px;
  }

  &__buttons {
    margin-top: auto;
  }

  &__button:not(:last-child) {
    margin-bottom: 20px;
  }
}

.tab-name {
  padding: 15px 0;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

.common {
  // border-bottom: 1px solid $color-gray2;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.additional {
  margin-bottom: 20px;
}

.label {
  color: $color-gray5;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px;
}

.button {
  width: 100%;
  padding: 12px;
}

.close {
  margin-left: auto;
  margin-right: 5px;
}

p {
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  font-weight: 500;
}

.page-enter-active,
.page-leave-active {
  transition: left 1.5s;
}

.page-enter,
.page-leave-to {
  @include below('t_lg') {
    left: -344px;
  }
}
</style>
