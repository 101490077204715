<template>
  <div
    class="details"
    :class='{"details--mobile": isMobile, "details--hidden": isShowSidebar, "wrapped": isWrapped, "is-show-filter": isShowFilter}'
    data-testid="editGroup"
  >
    <div class="details__header">
      <div class="details__info">
        <p class="details__cadastral">
          <span class="details__mobile-title">Редактируемые участки</span>
          <span class="details__mobile-total">Участков: {{ Object.keys(areas).length }}</span>
          <span class="details__mobile-total">{{ totalSquare }} Га</span>
        </p>
      </div>

      <div class="button-move">
        <q-btn
          unelevated
          @click="isShowFilter = !isShowFilter"
          size="sm"
          round
          color="accent"
          :icon="!isShowFilter ? 'mdi-arrow-left' : 'mdi-arrow-right'"
          :title="!isShowFilter ? 'Скрыть фильтр' : 'Показать фильтр'"
        />
      </div>

      <div v-if="isMobile" class="close" @click="wrapDetails">
        <template v-if="!isWrapped">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 7L17 17" stroke="#2F3E4D" stroke-width="2" />
            <path d="M17.0005 7L7.00049 17" stroke="#2F3E4D" stroke-width="2" />
          </svg>
        </template>

        <template v-if="isWrapped">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="#E5E5E5" />
            <rect width="392" height="376" transform="translate(-224 -48)" fill="#F4F7FA" />
            <path d="M0 20.0156H24V24H0V20.0156ZM20.7188 4.03125L18.75 6L15 2.25L16.9688 0.28125C17.1562 0.09375 17.3906 0 17.6719 0C17.9531 0 18.1875 0.09375 18.375 0.28125L20.7188 2.625C20.9062 2.8125 21 3.04688 21 3.32812C21 3.60938 20.9062 3.84375 20.7188 4.03125ZM17.7656 6.98438L7.73438 17.0156H3.98438V13.2656L14.0156 3.23438L17.7656 6.98438Z" fill="#666666" />
          </svg>
        </template>
      </div>
    </div>

    <div class="details__areas">
      <q-scroll-area
        :style="maintenanceNotice ? `height: calc(100vh - 68px - 40px - ${scrollAreaHeight + 10}px)` : `height: calc(100vh - 68px - ${scrollAreaHeight + 10}px)`"
        :thumb-style="{ width: '5px', opacity: '0.5' }"
      >
        <div v-for="area in Object.keys(areas)" class="details__area" :key="areas[area].cadastralNumber">
          {{ areas[area].cadastralNumber }} - {{ areas[area].area ? parseFloat(areas[area].area, 10).toFixed(4) : 0 }} Га
        </div>
      </q-scroll-area>

      <div class="details__bottom">
        <strong>Участков: {{ Object.keys(areas).length }}</strong>
        <br>
        <strong>Общая площадь: {{ totalSquare }} Га</strong>

        <div
          v-if="Object.keys(areas).length > 0 && Object.keys(areas).length <= limit"
          v-access="'section_access_add_info'"
          class="details__button"
        >
          <Button
            class="button"
            type="stroke"
            text="Изменить участки"
            @click="showChangeGroupModal"
          />
        </div>

        <div
          v-if="Object.keys(areas).length > 0 && Object.keys(areas).length <= limit"
          v-access="'section_snt'"
          class="details__button"
        >
          <Button
            class="button"
            type="stroke"
            text="СНТ"
            @click="showAddSntModal"
          />
        </div>

        <div
          v-if="Object.keys(areas).length > 0"
          v-access="'section_access_add_info'"
          class="details__button"
        >
          <Button
            class="button"
            type="stroke"
            text="Очистить список"
            aria-label="Очистить список"
            @click="clearEditingGroup"
          />
        </div>

        <div
          v-if="!isPicking"
          v-access="'section_access_add_info'"
          class="details__button"
        >
          <Button
            class="button"
            type="stroke"
            text="Выделить на карте"
            aria-label="Выделить на карте"
            @click="pickDomain"
          />
        </div>

        <div
          v-if="isPicking"
          v-access="'section_access_add_info'"
          class="details__button"
        >
          <Button
            class="button"
            type="stroke"
            text="Отменить выделение"
            aria-label="Отменить выделение"
            @click="cancelPickDomain"
          />
        </div>

        <div
          v-if="Object.keys(areas).length >= limit"
          class="details__notification"
        >
          Достигнуто максимальное количество редактируемых участков ({{ limit }})
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, defineProps, defineEmits, ref, onMounted, watch, computed } from 'vue'
import { useStore } from 'vuex'
import booleanContains from '@turf/boolean-contains'
import Button from '@/components/uikit/buttons/Button'

const store = useStore()

const props = defineProps({
  isShowSidebar: {
    type: Boolean,
    required: false
  },
  isPicking: {
    type: Boolean,
    required: false
  },
  map: {
    type: [Object, null],
    required: true
  },
  mapDraw: {
    type: [Object, null],
    required: true
  },
  pickingDomain: {
    type: [Object, null],
    required: true
  },
  editingIds: {
    type: Array,
    required: true
  }
})

const emits = defineEmits(['setIsPicking', 'setPickingDomain', 'setEditingIds'])

const scrollAreaHeight = ref(440)
const isMobile = ref(window.innerWidth < 1024)
const isWrapped = ref(true)
const isShowFilter = ref(false)
const limit = ref(15)

const areas = computed(() => store.state.map.editingGroup)
const totalSquare = computed(() => {
  const total = Object.keys(areas.value).reduce((total, area) => total + areas.value[area].area, 0)
  return total ? total.toFixed(2) : 0
})

const maintenanceNotice = computed(() => store.state.settings.maintenanceNotice)

const wrapDetails = () => {
  isWrapped.value = !isWrapped.value
}

const showChangeGroupModal = () => {
  store.commit('modals/setIsShowGroupChangeModal', true)
}

const showAddSntModal = () => {
  store.commit('modals/setIsShowAddSntModal', true)
}


const checkPolygonContains = (polygon) => {
  if (polygon.geometry.type === 'Polygon') {
    return booleanContains(props.pickingDomain, polygon)
  }

  if (polygon.geometry.type === 'MultiPolygon') {
    return polygon.geometry.coordinates.every((part) =>
      booleanContains(props.pickingDomain, {
        type: 'Polygon',
        coordinates: part,
      })
    )
  }
}

const pickDomain = () => {
  emits('setIsPicking', true)
  props.mapDraw.changeMode('draw_polygon')
}

const cancelPickDomain = () => {
  emits('setIsPicking', false)
  emits('setPickingDomain', null)
  props.mapDraw.changeMode('static')
  props.mapDraw.deleteAll()
  store.commit('modals/setDisableModal', false)
}

const setHoverPolygon = (status, source, sourceLayer, id) => {
  if (source && sourceLayer && id) {
    props.map.setFeatureState(
      { source, sourceLayer, id },
      { active: status }
    )
  }
}

const clearEditingGroup = () => {
  props.editingIds.forEach(({ source, sourceLayer, id }) =>
    setHoverPolygon(false, source, sourceLayer, id)
  )
  store.commit('map/setEditingGroup', {})
  emits('setEditingIds', [])
}

// onMounted(() => {
//   window.addEventListener('resize', () => {
//     isMobile.value = window.innerWidth < 1024
//   })
// })

watch(areas, (value) => {
  nextTick(() => {
    scrollAreaHeight.value = document.querySelector('.details__bottom')?.clientHeight ?? 440
  })

  if (Object.keys(value).length < 1) {
    props.editingIds.forEach(({ source, sourceLayer, id }) =>
      setHoverPolygon(false, source, sourceLayer, id)
    )
    emits('setEditingIds', [])
  }
})

watch(() => props.pickingDomain, (value) => {
  if (value) {
    const renderedFeatures = props.map.queryRenderedFeatures()
    const features = renderedFeatures
      .filter(
        (feature) =>
          feature.id &&
          feature.properties.CN &&
          checkPolygonContains(feature)
      )

    const ids = [...props.editingIds]

    features.forEach((feature) => {
      store.commit('map/setEditingGroup', {
        ...areas.value,
        [feature.properties.hash]: {
          area: feature.properties.area && parseFloat(feature.properties.area) ? parseFloat(feature.properties.area) : 0,
          cadastralNumber: feature.properties.CN
        }
      })

      if (!props.editingIds.some((id) => id.cn === feature.properties.CN)) {
        ids.push({
          id: feature.id,
          source: feature.source,
          sourceLayer: feature.sourceLayer,
          cn: feature.properties.CN,
        })
      }
    })

    emits('setEditingIds', ids)

    store.commit('map/setEditingGroup', { ...areas.value })

    renderedFeatures.forEach(({ source, sourceLayer, id }) =>
      setHoverPolygon(false, source, sourceLayer, id)
    )
    props.editingIds.forEach(({ source, sourceLayer, id }) =>
      setHoverPolygon(true, source, sourceLayer, id)
    )
    features.forEach(({ source, sourceLayer, id }) =>
      setHoverPolygon(true, source, sourceLayer, id)
    )
  }
})
</script>

<style lang="scss" scoped>
  .details {
    width: 344px;
    height: 100%;
    background-color: #F4F7FA;
    position: absolute;
    left: 0;
    border-left: 1px solid $color-gray3;
    top: 0;
    z-index: 3;
    transition: left 0.3s ease-in-out;

    p {
      margin: 0;
    }

    &.is-show-filter {
      left: -344px;
    }

    @include below('m_md') {
      left: 0;
      width: 100%;
    }

    &--mobile.wrapped {
      width: auto;
      height: 44px;
      bottom: 20px;
      left: 20px;
      top: unset;
      border-radius: 6px;
      display: flex;
      align-items: center;

      @include below('t_lg') {
        left: 20px;
        bottom: 40px;
      }

      @include below('t_lg') {
        font-size: 12px;
      }
    }

    &__mobile-total {
      display: none;
    }

    .wrapped &__mobile-total {
      display: none;

      @include below('t_lg') {
        display: inline;
        // margin-left: 10px;
        font-weight: 700;
      }
    }

    &__mobile-title {
      display: inline;
    }

    .wrapped &__mobile-title {
      @include below('t_lg') {
        display: none;
      }
    }

    &__button {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      button {
        flex-grow: 1;
      }
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__cadastral {
      margin-right: 17px;
      font-weight: 600;

      @include below('t_lg') {
        margin-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &__header {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      border-bottom: 1px solid $color-gray3;
    }

    &--mobile.wrapped &__header {
      padding: 0 10px;

      .close {
        left: 0;
      }
    }

    &--mobile.wrapped &__header {
      border-bottom: none;
    }

    &--mobile.wrapped &__areas {
      display: none;
    }

    &__bottom {
      position: absolute;
      padding: 30px;
      background-color: #F4F7FA;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &__areas {
      padding: 30px;
      padding-bottom: 275px;
      // height: calc(100% - 50px);
    }

    &__notification {
      margin-top: 20px;
      font-weight: 700;
      color: $color-active;
    }

    &__area {
      margin-bottom: 10px;
    }

    .close {
      cursor: pointer;
      padding: 10px;
      margin-right: -10px;
    }
  }

  .details--mobile.details--hidden {
    display: none;
  }
</style>
