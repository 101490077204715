<template>
  <span v-if="!placeholder" class="label-select">
    {{ label }}
  </span>
  <div class="relative">
    <q-select
      v-model="value"
      ref="refElement"
      outlined
      :label-slot="!!placeholder"
      :multiple="multiple"
      :options="isSort ? sortedOptions : filtredOptions"
      use-input
      :dense="dense"
      :virtual-scroll-slice-size="scrollSize"
      :option-disable="(item) => item === null ? true : item.cannotSelect"
      :loading="isLoading"
      :rules="computedRules"
      bg-color="white"
      emit-value
      map-options
      @filter="filterOrgs"
    >
      <!-- options-dense -->
      <template #label v-if="placeholder">
        {{ placeholder }}
      </template>
      <template #append>
        <ClearButton v-if="clearable && ((multiple && value.length) || (!multiple && value))"  @clear="clear" />
      </template>
    </q-select>
    <q-badge v-if="multiple && value.length > 1" color="accent" floating rounded>
      {{ value.length }}
    </q-badge>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, defineExpose, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import ClearButton from '@/components/uikit/buttons/ClearButton'
import { charMapToCyrillic } from '@/utils/char-map'
import ApiService from '@/services'

const props = defineProps({
  modelValue: {
    required: true
  },
  label: {
    type: String,
    required: false,
    default: ''
  },
  multiple: {
    type: Boolean,
    required: false,
    default: false
  },
  dense: {
    type: Boolean,
    required: false,
    default: true
  },
  useInput: {
    type: Boolean,
    required: false,
    default: true
  },
  clearable: {
    type: Boolean,
    required: false,
    default: false
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false
  },
  isSort: {
    type: Boolean,
    required: false,
    default: false
  },
  scrollSize: {
    type: Number,
    required: false,
    default: 500
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  selectedOrgs: {
    type: Array,
    required: false,
    default: () => []
  },
  rules: {
    type: Array,
    required: false,
    default: () => []
  },
  isValidate: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emits = defineEmits(['update:modelValue'])

const store = useStore()

const refElement = ref(null)
const filtredOptions = ref([])
const searchValue = ref('')
const isFocus = ref(false)

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    isFocus.value = false
    emits('update:modelValue', value)
  }
})

const orgsByIds = computed(() => store.state.orgs.orgsByIds?.filter((org) => !org.isHidden))
const sortedOptions = computed(() => sortOptions(filtredOptions.value))

const validateOrg = (value) => {
  const currentOrg = orgsByIds.value.find((org) => org.id === value)
  return (currentOrg?.status !== 'BANKRUPT' && currentOrg?.status !== 'LIQUIDATED') || 'Фактический пользователь имеет статус банкрота или организация ликвидирована'
}

const computedRules = computed(() => {
  if (!props.isValidate) {
    return props.rules
  }

  return props.rules.concat([validateOrg])
})

const getUniqOptions = (options) => {
  const uniqOptions = {}
  options.forEach((option) => {
    uniqOptions[option.value] = { value: option.value, label: option.label }
  })
  return Object.values(uniqOptions)
}

const filterOrgs = (val, update) => {
  update(() => {
    isFocus.value = true
    const option = { value: '', label: 'Введите от 3-х символов', cannotSelect: true }
    if (val === '' || val.length < 3) {
      searchValue.value = val
      filtredOptions.value = [
        option,
        ...orgsByIds.value
          .filter((v) => props.selectedOrgs.includes(v.value))
      ]
    } else {
      val = val.toLowerCase()
        .split('').map((key) => charMapToCyrillic[key] ? charMapToCyrillic[key] : key).join('')

      if (val !== searchValue.value) {
        searchValue.value = val

        ApiService.org.getSearchOrgs({ search: val })
          .then((res) => {
            const allOptions = [...orgsByIds.value, ...res.data]
              .filter((v) => v.name.toLowerCase().indexOf(val) > -1 || props.selectedOrgs.includes(v.value))
              .map((org) => ({ label: org.name, value: org.value }))

            filtredOptions.value = getUniqOptions(allOptions)
          })
      }
    }
  })
}

const sortOptions = (options) => {
  return options?.filter((item) => !item.value)
    .concat(options.filter((item) => props.modelValue.includes(item.value)))
    .concat(options.filter((item) => item.value && !props.modelValue.includes(item.value)))
}

const clear = () => {
  const initValue = props.multiple ? [] : ''
  emits('update:modelValue', initValue)
  refElement.value.blur()
}

const blur = () => {
  isFocus.value = false
  refElement.value?.blur()
}

const showPopup = () => {
  refElement.value?.showPopup()
}

defineExpose({ blur, showPopup })

watch(filtredOptions, (value, oldValue) => {
  if (isFocus.value && value.length && value.length !== oldValue.length) {
    blur()
    showPopup()
  }
})

watch(orgsByIds, () => {
  filtredOptions.value = orgsByIds.value
    .filter((v) => props.selectedOrgs.includes(v.value))
}, { immediate: true })
</script>
<style>
.filter .q-field__native .q-field__input {
  position: absolute;
  z-index: -100;
}

.filter .q-field--focused .q-field__native .q-field__input {
  position: static;
  z-index: 1;
}

.filter .disabled {
  opacity: 1 !important;
  font-style: italic;
}

.filter .disabled:not(:first-child) {
  margin-top: 15px;
}
</style>
