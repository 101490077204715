<template>
  <div v-if="isEditingOrg(org.id)" class="button-edit">
    <q-btn
      @click="editOrg(org)"
      color="primary"
      round
      flat
      size="sm"
      outline
      unelevated
      icon="mdi-pencil"
      title="Редактировать"
    />
  </div>
</template>
<script setup>
import { computed, defineProps } from 'vue'
import { useStore } from 'vuex'

defineProps({
  org: Object
})

const store = useStore()

const orgsOptions = computed(() => store.state.orgs.orgsByIds.filter((category) => !category.isHidden))

const isEditingOrg = (orgId) => {
  const orgValue = orgsOptions.value.find((item) => item.id === orgId)
  return (!!orgValue && !orgValue?.isNull && !orgValue?.isHidden)
}

const editOrg = (org) => {
  const currentOrg = orgsOptions.value.find((item) => item.id === org.id)
  store.commit('orgs/setEditingOrg', currentOrg)
  store.commit('modals/setIsShowChangeOrgModal', true)
}
</script>
<style lang="scss" scoped>
.button-edit {
  position: absolute;
  top: 25px;
  right: -25px;
}
</style>
