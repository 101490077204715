const BASE_URL = '/private/schemas'
// const BASE_URL_V2 = '/v2/private'

class SchemesService {
  constructor(api, apiPost) {
    this.api = api
    this.apiPost = apiPost
  }

  getSchemes(params) {
    return this.api.get(BASE_URL, { params })
  }

  getSchemesReport(params) {
    return this.api.get(`${BASE_URL}/export`, { params })
  }

  getSchemeStatuses() {
    return this.api.get(`${BASE_URL}/statuses`)
  }

  getSchemeLayers() {
    return this.api.get(`${BASE_URL}/layers`)
  }

  getSchemeRegions() {
    return this.api.get(`${BASE_URL}/regions`)
  }

  getSchemeSubmitters() {
    return this.api.get(`${BASE_URL}/submitters`)
  }

  createScheme(scheme) {
    return this.apiPost.post(BASE_URL, scheme)
  }

  getScheme(id) {
    return this.api.get(`${BASE_URL}/${id}`)
  }

  updateScheme(scheme) {
    return this.api.put(`${BASE_URL}/${scheme.id}`, scheme)
  }

  deleteScheme(id) {
    return this.api.delete(`${BASE_URL}/${id}`)
  }

  updateSolutionScheme(scheme) {
    return this.api.put(`${BASE_URL}/${scheme.id}/solution`, scheme)
  }

  updateCadastralNumberScheme(scheme) {
    return this.api.put(`${BASE_URL}/${scheme.id}/cadastral`, scheme)
  }
}

export { SchemesService }
