<template>
<svg viewBox="0 0 24 24" :width='width' :height='height'>
  <g transform="translate(0 -8)" id="g14">
    <path
      d="m8.0275933 9.5013891c-2.8183472.0466368-5.2878242 1.2552559-6.2839838 4.1947259-.98846785 2.916771 1.1714865 8.182008 5.9325763 5.840503 7.9077412-3.889027 7.9038032.963382 9.8807382.963382 2.438571 0 4.94037-1.699566 4.94037-3.873599 0-1.944514.09638-3.806388-.992208-4.87712-1.976935-1.944514-2.973677.987396-8.289071-1.184158-1.694017-.6920746-3.4974127-1.0917169-5.1884217-1.0637339z"
      fill="#8cbe8c"
      fill-rule="evenodd"
      stroke="#555753"
      stroke-linecap="round"
      stroke-linejoin="round"
      id="path2" />
    <rect
      fill="#3c5a6e"
      height="11"
      rx="2.01149"
      width="11"
      x="13"
      y="21"
      id="rect4" />
    <path
      d="m14.000001 26.999999 8.999999-.009599s0 0 0-2c0-2.9904-1-2.9904-4.5-2.9904s-4.5 0-4.5 3c0 2 0 2 .000001 1.999999z"
      fill="#fcffff"
      fill-rule="evenodd"
      opacity=".3"
      id="path6" />
    <path
      style="fill:#ffffff;fill-rule:evenodd;stroke-width:0.64053571"
      d="m 20.20315,23.377428 -0.04037,1.899596 -2.182423,-0.003 -0.05919,2.952822 2.178518,0.01863 -0.04038,1.899598 3.398691,-3.377342 z"
      id="path4922" />
    <path
      style="fill:#ffffff;fill-rule:evenodd;stroke-width:0.64632034"
      d="m 16.780734,30.124787 0.01948,-1.898705 2.223023,0.01017 0.03824,-2.963147 -2.230837,-0.01408 0.01948,-1.898707 -3.423378,3.364856 z"
      id="path4922-9" />
  </g>
</svg>
</template>
<script>
export default {
  name: 'ScalePolygonIcon',
  props: {
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  }
}
</script>
