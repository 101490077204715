<template>
  <div
    class="details" v-if="visibleEntity === 'fields'"
    :class="{'details--mobile': isMobile, 'details--hidden': isShowSidebar, 'wrapped': isWrapped, 'is-show-filter': isShowFilter}"
  >
    <div class="details__header">
      <div class="details__info">
        <p class="details__cadastral">
          <span class="details__mobile-title">Редактируемые поля</span>
          <span class="details__mobile-total">Полей: {{ fields.length }}</span>
          <span class="details__mobile-total">{{ totalSquare }} Га</span>
        </p>
      </div>

      <div v-if="!isMobile" class="button-move">
        <q-btn
          unelevated
          @click="isShowFilter = !isShowFilter"
          size="sm"
          round
          color="accent"
          :icon="!isShowFilter ? 'mdi-arrow-left' : 'mdi-arrow-right'"
          :title="!isShowFilter ? 'Скрыть фильтр' : 'Показать фильтр'"
        />
      </div>

      <div v-if="isMobile" class="close" @click="wrapDetails">
        <template v-if="!isWrapped">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 7L17 17" stroke="#2F3E4D" stroke-width="2" />
            <path d="M17.0005 7L7.00049 17" stroke="#2F3E4D" stroke-width="2" />
          </svg>
        </template>

        <template v-if="isWrapped">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="#E5E5E5" />
            <rect width="392" height="376" transform="translate(-224 -48)" fill="#F4F7FA" />
            <path d="M0 20.0156H24V24H0V20.0156ZM20.7188 4.03125L18.75 6L15 2.25L16.9688 0.28125C17.1562 0.09375 17.3906 0 17.6719 0C17.9531 0 18.1875 0.09375 18.375 0.28125L20.7188 2.625C20.9062 2.8125 21 3.04688 21 3.32812C21 3.60938 20.9062 3.84375 20.7188 4.03125ZM17.7656 6.98438L7.73438 17.0156H3.98438V13.2656L14.0156 3.23438L17.7656 6.98438Z" fill="#666666" />
          </svg>
        </template>
      </div>
    </div>

    <div class="details__areas">
      <q-scroll-area
        :style="maintenanceNotice ? `height: calc(100vh - 68px - 40px - ${scrollAreaHeight + 10}px)` : `height: calc(100vh - 68px - ${scrollAreaHeight + 10}px)`"
        :thumb-style="{ width: '5px', opacity: '0.5' }"
      >
        <div v-for="field in fields" :key="field.backId" class="details__area">
          Номер поля: {{ field.backId }} - {{ field.area ? (Number(field.area) / 10000).toFixed(2) : 0 }} Га
        </div>
      </q-scroll-area>

      <div class="details__bottom">
        <strong>Полей: {{ fields.length }}</strong>
        <br>
        <strong>Общая площадь: {{ totalSquare }} Га</strong>

        <div
          v-if="fields.length > 0 && fields.length <= limit"
          v-access="'fields_edit'"
          class="details__button"
        >
          <Button
            class="button"
            type="stroke"
            text="Изменить год ввода в оборот"
            @click="showGroupEditOperationYearsModal"
          />
        </div>

        <div
          v-if="fields.length > 0 && fields.length <= limit"
          v-access="'fields_edit'"
          class="details__button"
        >
          <Button
            class="button"
            type="stroke"
            text="Изменить поля"
            @click="showChangeGroupModal"
          />
        </div>

        <div
          v-if="fields.length > 0"
          v-access="'fields_edit'"
          class="details__button"
        >
          <Button
            class="button"
            type="stroke"
            text="Очистить список"
            aria-label="Очистить список"
            @click="clearEditingGroup"
          />
        </div>

        <div v-if="fields.length >= limit" class="details__notification">
          Достигнуто максимальное количество редактируемых полей ({{ limit }})
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, defineProps, defineEmits, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import Button from '@/components/uikit/buttons/Button'

const store = useStore()

const props = defineProps({
  isShowSidebar: {
    type: Boolean,
    required: false
  },
  fields: {
    type: Array,
    required: true
  }
})

const emits = defineEmits(['clearEditingGroup'])

const isMobile = ref(window.innerWidth < 1024)
const isWrapped = ref(true)
const isShowFilter = ref(false)
const limit = ref(15)
const scrollAreaHeight = ref(310)

const maintenanceNotice = computed(() => store.state.settings.maintenanceNotice)
const totalSquare = computed(() => {
  const total = props.fields.reduce((total, field) => total + field.area, 0)
  return total ? (total / 10000).toFixed(2) : 0
})
const visibleEntity = computed(() => store.state.map.visibleEntity)

const wrapDetails = () => {
  isWrapped.value = !isWrapped.value
}

const clearEditingGroup = () => {
  emits('clearEditingGroup')
}

const showGroupEditOperationYearsModal = () => {
  store.commit('fields/setGroupEditingFields', props.fields.map((field) => field.backId))
  store.commit('modals/setIsShowGroupEditOperationYearsModal', true)
}

const showChangeGroupModal = () => {
  store.commit('fields/setGroupEditingFields', props.fields.map((field) => field.backId))
  store.commit('modals/setIsShowGroupEditFieldModal', true)
}

watch(() => props.fields, () => {
  nextTick(() => {
    scrollAreaHeight.value = document.querySelector('.details__bottom')?.clientHeight ?? 310
  })
})

// onMounted(() => {
//   window.addEventListener('resize', () => {
//     isMobile.value = window.innerWidth < 1024
//   })
// })

</script>

<style lang="scss" scoped>
.details {
  width: 344px;
  height: 100%;
  background-color: #F4F7FA;
  position: absolute;
  left: 0;
  border-left: 1px solid $color-gray3;
  top: 0;
  z-index: 3;
  transition: left 0.3s ease-in-out;

  p {
    margin: 0;
  }

  &.is-show-filter {
    left: -344px;
  }

  @include below('m_md') {
    left: 0;
    width: 100%;
  }

  &--mobile.wrapped {
    width: auto;
    height: 44px;
    bottom: 20px;
    left: 20px;
    top: unset;
    border-radius: 6px;
    display: flex;
    align-items: center;

    .button-move {
      display: none;
    }

    @include below('t_lg') {
      left: 20px;
      bottom: 40px;
    }

    @include below('t_lg') {
      font-size: 12px;
    }
  }

  &__mobile-total {
    display: none;
  }

  .wrapped &__mobile-total {
    display: none;

    @include below('t_lg') {
      display: inline;
      // margin-left: 10px;
      font-weight: 700;
    }
  }

  &__mobile-title {
    display: inline;
  }

  .wrapped &__mobile-title {
    @include below('t_lg') {
      display: none;
    }
  }

  &__button {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
      flex-grow: 1;
    }
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__cadastral {
    margin-right: 17px;
    font-weight: 600;

    @include below('t_lg') {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    border-bottom: 1px solid $color-gray3;
  }

  &--mobile.wrapped &__header {
    padding: 0 10px;

    .close {
      left: 0;
    }
  }

  &--mobile.wrapped &__header {
    border-bottom: none;
  }

  &--mobile.wrapped &__areas {
    display: none;
  }

  &__bottom {
    position: absolute;
    padding: 30px;
    background-color: #F4F7FA;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__areas {
    padding: 30px;
    padding-bottom: 275px;
    // height: calc(100% - 50px);
  }

  &__notification {
    margin-top: 20px;
    font-weight: 700;
    color: $color-active;
  }

  &__area {
    margin-bottom: 10px;
  }

  .close {
    cursor: pointer;
    padding: 10px;
    margin-right: -10px;
  }
}

.details--mobile.details--hidden {
  display: none;
}
</style>
