const BASE_URL = '/private/harvests'
const BASE_URL_V2 = '/v2/private'

class HarveService {
  constructor(api) {
    this.api = api
  }

  getHarvests(params) {
    // harvestId, periodId
    return this.api.get(BASE_URL, { params })
  }

  getGroupedHarvests(params) {
    // harvestId, periodId
    return this.api.get(`${BASE_URL}/grouped`, { params })
  }

  getHarvestsExport(params) {
    // harvestId, periodId
    return this.api.get(`${BASE_URL}/export`, { params })
  }

  updateHarvest(harvestId, orgId, cultureId, gross) {
    // { gross: 1 }
    return this.api.post(`${BASE_URL}/${harvestId}/orgs/${orgId}/cultures/${cultureId}/gross`, { gross })
  }

  refresh() {
    return this.api.get(`${BASE_URL}/refresh`)
  }

}

export { HarveService }
