const otherLayerNames = [
  { name: 'vodniyfond', label: 'Земли водного фонда', color: '#537adc' },
  { name: 'zemlizapasa', label: 'Земли запаса', color: '#7530ca' },
  { name: 'lesnoyfond', label: 'Земли лесного фонда', color: '#1e824c' },
  { name: 'zemlipromyshlen', label: 'Земли промышленности', color: '#d87300' },
  { name: 'zemlinaselehpunkt', label: 'Земли нас. пунктов', color: '#f56456' },
  { name: 'osoboohrterr', label: 'Земли ООТиО', color: '#fef218' },
  { name: 'kategorianeizvestn', label: 'Категория не установлена', color: '#29ffdf' },
]

const otherLayers = otherLayerNames.map(({ name, label, color }) => ({
  type: 'non-farm',
  group: 'otherlayers',
  name,
  label,
  value: `${name}_fill`,
  options: {
    type: 'vector',
    tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/' + name + '/{z}/{x}/{y}.mvt']
  },
  layers: [{
    id: `${name}_fill`,
    type: 'fill',
    source: name,
    'source-layer': name,
    layout: {
      'visibility': 'none'
    },
    paint: {
      'fill-color': color,
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false], 0.7,
        0.5,
      ],
    },
  }]
}))

export { otherLayers }
