import script from "./Select.vue?vue&type=script&setup=true&lang=js"
export * from "./Select.vue?vue&type=script&setup=true&lang=js"

import "./Select.vue?vue&type=style&index=0&id=c987bbe0&lang=css"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QBadge,QField});
