<template>
  <SectionModals />
  <SchemeModals />
  <PinModals />
  <FieldModals />
  <OrgModals />
  <EfisModals />
  <Modal
    v-if="isShowMapInfoModal"
    title="Статистика"
    textConfirm="Закрыть"
    @cancelClick="closeMapInfoModal"
    @closeModal="closeMapInfoModal"
    @confirmClick="closeMapInfoModal"
  >
    <div v-html="mapInfo"></div>
  </Modal>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/common/modals/Modal'
import SchemeModals from './SchemeModals'
import PinModals from './PinModals'
import FieldModals from './FieldModals'
import OrgModals from './OrgModals'
import EfisModals from './EfisModals'
import SectionModals from './SectionModals'

const store = useStore()

const isShowMapInfoModal = computed(() => store.state.modals.isShowMapInfoModal)
const mapInfo = computed(() => store.state.settings.mapInfo)

const closeMapInfoModal = () => {
  store.commit('modals/setIsShowMapInfoModal', false)
}
</script>
